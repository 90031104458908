import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import "./collection2.css";
import { getRelatedItems } from "../../../services";
import icon from "./G-icon.png";
class ProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      cartModalopen: false,
      stock: "InStock",
      quantity: 1,
      image: "",
    };
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenCartModal = () => {
    this.setState({ cartModalopen: true });
    this.props.onAddToCartClicked();
  };
  onCloseCartModal = () => {
    this.setState({ cartModalopen: false });
  };

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked,
      relatedItems,
      currency,
    } = this.props;

    let RatingStars = [];
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }
    const image = product.pictures[0];

    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }

    return (
      <div>
        <div className="product-box product-box-homepage-1">
          <div
            className="img-wrapper img-wrapper-homepage-1"
            style={{
              backgroundImage: `url(${icon})`,
              backgroundColor: "white",
              backgroundSize: "130px 130px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="lable-block">
              {product.sale == true ? (
                <span className="lable4">on sale</span>
              ) : (
                ""
              )}
              {product.new == true ? <span className="lable3">new</span> : ""}
            </div>
            <div className="front">
              <Link to={`/product/${product.id}`}>
                <img
                  src={`${
                    image
                      ? image
                      : `${process.env.PUBLIC_URL}/assets/images/icon/icon.png`
                  }`}
                  className="img-fluid lazyload bg-img product-only-image"
                  style={{ minWidth: "100%" }}
                  alt=""
                />
              </Link>
            </div>
            <div className="cart-info cart-wrap" style={{ padding: "5px" }}>
              <Link to={`/product/${product.id}`} title="Add to cart">
                <i
                  className="fa fa-shopping-cart"
                  aria-hidden="true"
                  style={{ fontSize: "10px" }}
                />
              </Link>
              <a
                onClick={onAddToWishlistClicked}
                title="Add to Wishlist"
                style={{ cursor: "pointer" }}
              >
                <i
                  className="fa fa-heart"
                  aria-hidden="true"
                  style={{ fontSize: "10px" }}
                />
              </a>
            </div>
          </div>
          <div
            className="product-detail"
            style={{ padding: "5px", paddingBottom: "80px", paddingTop: "0px" }}
          >
            <div>
              <Link to={`/product/${product.id}`}>
                <h6
                  style={{
                    paddingBottom: "0px",
                    color: "black",
                    fontSize: "90%",
                    fontWeight: "bold",
                    marginTop: 5,
                    textTransform: "capitalize",

                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginRight: 5,
                  }}
                  className="product-name"
                >
                  {product.name.slice(0, 25)}...
                </h6>
              </Link>
              <h6
                style={{
                  color: "#18768f",
                  fontSize: "100%",
                  fontWeight: "bold",
                  marginTop: 0,
                }}
                className="product-price"
              >
                ৳{Math.round(parseFloat(product.salePrice) * parseFloat(taka))}{" "}
                <br />
                {/* {product.price ? (
                  <del style={{ fontSize: "70%" }}>
                    <span className="money">
                      ৳
                      {Math.round(parseFloat(product.price) * parseFloat(taka))}
                    </span>
                  </del>
                ) : (
                  ""
                )} */}
              </h6>
              <div style={{ fontSize: "80%" }} className="product-name">
                Total Sales:{product.totalSold}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 5,
                marginTop: 10,
                borderTop: "1px solid gainsboro",
                paddingTop: 5,
              }}
              className="seller-container"
            >
              <div style={{ fontSize: 12, marginTop: 3 }} className="shop-name">
                {product.shop_name.slice(0, 5)}
              </div>
              <div
                style={{
                  fontSize: 11,
                  padding: "3px 7px",
                  border: "1px solid gainsboro",
                  borderRadius: 2,
                  background: "#eeeeee",
                  cursor: "pointer",
                }}
                className="view-seller"
              >
                <a
                  target="_blank"
                  href={`/collection/${product.shop_id}?shop_name=${
                    product.shop_name
                  }`}
                  style={{ color: "black" }}
                >
                  View Seller
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  relatedItems: getRelatedItems(state.data.products, ownProps.product.category),
  symbol: state.data.symbol,
  currency: state.user.currency,
});

export default connect(mapStateToProps)(ProductItem);
