import React, { Component } from "react";
import "./image-zoom.css";
import ReactImageMagnify from "react-image-magnify";
export default class ImageZoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 20,
      width: 20,
    };
  }
  componentDidMount = () => {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    const height = document.getElementById("image-container-div").offsetHeight;
    console.log(height);
    const width = document.getElementById("image-container-div").offsetWidth;
    console.log(width);
    this.setState({
      height,
      width,
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };
  render() {
    const { image } = this.props;

    return (
      <div
        className="image-container"
        id="image-container-div"
        style={{
          border: "2px solid gainsboro",
          borderRadius: 5,
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {/* mobile device te jodi problem kore tahole pore ekhane window innerWidth < 600 hole only img dekhate hobe ReactImageMagnify er poriborte  */}
        <ReactImageMagnify
          className="magnify-image"
          {...{
            smallImage: {
              alt: "Image",
              isFluidWidth: false,
              height: this.state.height - 7,
              width: this.state.width - 5,

              src: image,
            },
            largeImage: {
              src: image,
              width: 1200,
              height: 1200,
            },
          }}
        />
      </div>
    );
  }
}
