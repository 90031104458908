import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CartPage from "../components/common/headers/common/cart-header";
import { removeFromCart } from "../actions";
import { removeCartItemFromFirestore, auth } from "../firebase/firebase.utils";
import { getCartTotal } from "../services";

class CartContainer extends React.Component {
  removeFromReduxAndFirestoreCart = (product) => {
    const { removeFromCart } = this.props;
    removeFromCart(product);
    auth.onAuthStateChanged((userAuth) =>
      removeCartItemFromFirestore(userAuth, product)
    );
  };

  render() {
    const { cart, total, symbol } = this.props;
    console.log(cart);
    let totalItems = 0;
    cart.map((shop) => {
      if (shop.items.length) {
        return (totalItems += shop.items.length);
      } else {
        return (totalItems += 1);
      }
    });
    return (
      <li
        className="onhover-div mobile-cart"
        onClick={() =>
          this.props.history.push(
            `${process.env.PUBLIC_URL}/dashboard/pages/cart`
          )
        }
      >
        <div
          className="cart-qty-cls"
          style={{
            zIndex: 5,
            cursor: "pointer",
            color: "white",
            background: " #18768f",
          }}
          onClick={() =>
            this.props.history.push(
              `${process.env.PUBLIC_URL}/dashboard/pages/cart`
            )
          }
        >
          {totalItems}
        </div>
        <Link to={`${process.env.PUBLIC_URL}/dashboard/pages/cart`}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/cart.png`}
            className="img-fluid"
            alt=""
          />
          <i className="fa fa-shopping-cart" />
        </Link>
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cartList.cart,

    symbol: state.data.symbol,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { removeFromCart }
  )(CartContainer)
);
