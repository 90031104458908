import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../myOrders.css";
import "./my-request.css";
import {
  getAllShipForMeOfSingleUserRedux,
  getOrderTrackingResultRedux,
  updateShipmentRequestRedux,
  getAllD2DRatesRedux,
  updateShipForMeRedux,
} from "../../../../actions";
import PaymentModal from "./paymentModal";
import { CircleLoader } from "react-spinners";
import AddTrackingModal from "./addTracking";
import man from "./plus image.jpeg";
import { uploadImageProduct } from "../../../../firebase/firebase.utils";
import { toast } from "react-toastify";
class MyRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      pending: true,
      approved: false,
      reject: false,
      toggleModal: true,
      booking: null,
      productRequestArray: [],
      bookingObj: null,
      toggleTrackingModal: true,
      submit_loader: false,
      searchOrder: "",
      productObj: null,
      days: -1,
      minutes: 0,
      seconds: 0,
      hours: 0,
      checked: false,
      filterByCategory: "",
      bookingId: "",
      imageUrl: man,
      shipFrom: "",
      method: "",
      productType: "",
      submit_loader: false,
      submit_loader2: false,
      result: null,
      weight: "",
      date: new Date().toLocaleDateString("en-GB"),
      ctnQuantity: "",
      totalCbm: "",
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      shipTo: "",
      shipTo2: "",
      validTo: "",
      shipmentMethod: "D2D",
      productQuantity: "",
      othersProductName: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
    };
  }
  componentDidMount = () => {
    const url = new URLSearchParams(window.location.search);
    const paymentStatus = url.get("paymentStatus");
    if (paymentStatus) {
      if (paymentStatus == "cancel") {
        toast.error("Your payment is cancelled!");
      } else if (paymentStatus == "failure") {
        toast.error("Payment failed. Please try again.");
      } else if (paymentStatus == "success") {
        toast.success("Your payment is successfull!");
      } else {
        toast.error(`Your payment is ${paymentStatus}`);
      }
      // for removing query params from the url upon reload
      // Remove query parameters from the URL
      const url = new URL(window.location);
      url.search = ""; // Clear the query parameters
      window.history.replaceState({}, document.title, url);
    }
    if (this.props.currentUser.id) {
      this.props.getAllShipForMeOfSingleUserRedux(this.props.currentUser.id);
    }
    this.timerID = setInterval(() => this.tick(), 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timerID);
  };

  tick = () => {
    const { productObj } = this.state;
    if (productObj && productObj.validTo) {
      const [day, month, year] = productObj.validTo.split("-");
      let dateFuture = new Date(
        parseInt(year),
        parseInt(month - 1),
        parseInt(day)
      );
      let dateNow = new Date();
      var seconds = Math.floor((dateFuture - dateNow) / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      this.setState({
        days: days + 1,
        hours,
        minutes,
        seconds,
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.currentUser.id &&
      nextProps.currentUser.id !== this.props.currentUser.id
    ) {
      this.props.getAllShipForMeOfSingleUserRedux(nextProps.currentUser.id);
    }
  };

  startToggleModal = async (productRequestArray) => {
    if (productRequestArray == null) {
      this.setState({
        toggleModal: !this.state.toggleModal,
        productRequestArray: [],
      });
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        productRequestArray,
      });
    }
  };
  startTrackingToggleModal = async (booking) => {
    if (booking == null) {
      this.setState({
        toggleTrackingModal: !this.state.toggleTrackingModal,
        bookingObj: null,
      });
    } else {
      this.setState({
        toggleTrackingModal: !this.state.toggleTrackingModal,
        bookingObj: booking,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeBooking = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ image_loader: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageProduct(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ image_loader: false });
    }
  };

  handleBookNowSubmit = async () => {
    this.setState({ submit_loader2: true });
    let bookingObj = {
      bookingId: this.state.bookingId,
      imageUrl: this.state.imageUrl,
      shipFrom: this.state.shipFrom,
      method: this.state.method,
      productType: this.state.productType,
      result: this.state.result,
      weight: this.state.weight,
      date: this.state.date,
      ctnQuantity: this.state.ctnQuantity,
      totalCbm: this.state.totalCbm,
      productContains: this.state.productContains,
      productBrand: this.state.productBrand,
      productsTotalCost: this.state.productsTotalCost,
      shipTo: this.state.shipTo,
      shipTo2: this.state.shipTo2,
      validTo: this.state.validTo,
      shipmentMethod: this.state.shipmentMethod,
      productQuantity: this.state.productQuantity,
      othersProductName: this.state.othersProductName,
      productUrl: this.state.productUrl,
      productVariant: this.state.productVariant,
    };
    await this.props.updateShipForMeRedux({
      ...bookingObj,
    });
    this.setState({
      submit_loader2: false,
      visual: "INPUT",
    });

    this.setState({
      imageUrl: man,
      visual: "INPUT",
      shipFrom: "",
      method: "",
      productType: "",
      methodError: false,
      shipFromError: false,
      productTypeError: false,
      weightError: false,
      submit_loader: false,
      submit_loader2: false,
      result: null,
      weight: "",
      date: new Date().toLocaleDateString("en-GB"),
      ctnQuantity: "",
      totalCbm: 1,
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      imageUrl: "",
      shipmentMethod: "D2D",
      productQuantity: "",
      othersProductName: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
    });
  };

  getTotal = (booking) => {
    let total = 0;
    booking.payments &&
      booking.payments.map((payment) => {
        total += parseInt(payment.amount);
      });
    return total;
  };

  render() {
    const { currentUser, bookingsArray, orderTracking } = this.props;
    const { productObj } = this.state;
    console.log(currentUser);
    const pendingBookings = bookingsArray.filter(
      (booking) => booking.status === "Pending"
    );
    const approvedBookings = bookingsArray.filter(
      (booking) => booking.status == "Rates Provided"
    );

    const rejectedBookings = bookingsArray.filter(
      (booking) => booking.status === "Reject"
    );

    let bookingsToShow = [];
    if (this.state.pending) {
      bookingsToShow = pendingBookings;
    } else if (this.state.approved) {
      bookingsToShow = approvedBookings;
    } else if (this.state.reject) {
      bookingsToShow = rejectedBookings;
    }

    if (this.state.searchOrder) {
      bookingsToShow = bookingsToShow.filter(
        (booking) =>
          (booking.trackingNo &&
            booking.trackingNo
              .toLowerCase()
              .includes(this.state.searchOrder.toLowerCase())) ||
          (booking.date &&
            booking.date
              .toLowerCase()
              .includes(this.state.searchOrder.toLowerCase()))
      );
    }

    if (this.state.filterByCategory) {
      if (
        this.state.filterByCategory == "Paid" ||
        this.state.filterByCategory == "Partially Paid"
      ) {
        bookingsToShow = bookingsToShow.filter(
          (booking) => booking.paymentStatus == this.state.filterByCategory
        );
      } else if (this.state.filterByCategory == "Not Paid") {
        bookingsToShow = bookingsToShow.filter(
          (booking) =>
            booking.paymentStatus == "Not paid" || !booking.paymentStatus
        );
      } else {
        bookingsToShow = bookingsToShow.filter(
          (booking) => booking.status == this.state.filterByCategory
        );
      }
    }

    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        <PaymentModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          productRequestArray={this.state.productRequestArray}
        />
        <AddTrackingModal
          toggleTrackingModal={this.state.toggleTrackingModal}
          startTrackingToggleModal={this.startTrackingToggleModal}
          bookingObj={this.state.bookingObj}
        />

        {/*Dashboard section*/}
        <section
          className="section-b-space main-container-cart"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col-3 left-list-column">
                <div className="left-list-container">
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Buy & Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-request`
                          );
                        }}
                      >
                        My Request Products
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-orders`
                          );
                        }}
                      >
                        My Orders
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        style={{ color: "#f54c3c" }}
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-request`
                          );
                        }}
                      >
                        My Booking
                      </p>

                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Profile
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/information`
                          );
                        }}
                      >
                        Information
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/security`
                          );
                        }}
                      >
                        Security
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.approved ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: true,
                        pending: false,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Approved ({approvedBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.pending ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: true,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Pending ({pendingBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.reject ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: false,
                        reject: true,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Rejected ({rejectedBookings.length})
                  </div>
                </div>
                <div
                  className="row search-bar-row"
                  style={{
                    padding: "20px",
                    paddingRight: "0px",
                    justifyContent: "space-between",
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ maxWidth: "30%" }}>
                    <select
                      title="Filter by category"
                      required
                      name="filterByCategory"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.filterByCategory}
                    >
                      <option value="">Filter by category </option>
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Partially Paid">Partially Paid</option>
                      <option value="Not Paid">Not Paid</option>
                      {this.state.approved ? (
                        <option value="Rates Provided">Rates Provided</option>
                      ) : (
                        <option value="Pending">Pending</option>
                      )}
                    </select>
                  </div>
                  <div style={{ maxWidth: "30%" }}>
                    <form className="form-inline subscribe-form">
                      <div className="form-group mx-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="searchOrder"
                          value={this.state.searchOrder}
                          onChange={this.handleChange}
                          placeholder="Search Booking"
                          style={{
                            padding: 8,
                            width: 250,
                            borderColor: "gainsboro",
                            borderRadius: 5,
                            fontSize: 14,
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-solid"
                        style={{ padding: "7px 10px", marginLeft: -50 }}
                      >
                        <i
                          className="icofont-search"
                          style={{ fontWeight: "bold" }}
                        />
                      </button>
                    </form>
                  </div>
                </div>

                {bookingsToShow.length > 0 ? (
                  <>
                    <div className="row title-header">
                      <div className="col-4">Goods</div>
                      <div className="col">Quantity</div>
                      <div className="col">Weight</div>
                      <div className="col">Total Amount</div>
                      <div className="col">Payment Status</div>
                    </div>
                    {bookingsToShow.map((booking, i) => {
                      return (
                        <div key={i}>
                          <div className="row each-order-container">
                            <div className="d-flex flex-row order-header">
                              <div
                                style={{
                                  fontSize: 11,
                                  marginTop: 2,
                                }}
                              >
                                <i
                                  className="icofont-brand-natgeo"
                                  style={{ color: "darkorange" }}
                                />
                                &nbsp;Booking Number: {booking.bookingId} &nbsp;
                                &nbsp;
                              </div>
                              <div
                                style={{
                                  fontSize: 11,
                                  color: "gray",
                                  marginTop: 2,
                                }}
                              >
                                Booking date: {booking.date}
                              </div>

                              <div
                                style={{
                                  fontSize: 11,
                                  color: "gray",
                                  marginTop: 2,
                                }}
                              >
                                <i
                                  className="icofont-brand-aliexpress"
                                  style={{ color: "darkorange", fontSize: 14 }}
                                />{" "}
                                Booking status:{" "}
                                <span
                                  style={{
                                    color:
                                      booking.status == "Reject"
                                        ? "red"
                                        : "#18768f",
                                  }}
                                >
                                  {booking.status}
                                </span>
                              </div>
                              <div
                                style={{
                                  fontSize: 11,
                                  color: "gray",
                                  marginTop: 2,
                                }}
                              >
                                <i
                                  className="icofont-dollar"
                                  style={{ color: "darkorange", fontSize: 14 }}
                                />
                                Custom & Shipping rate:{" "}
                                <span style={{ color: "#ff8084" }}>
                                  {booking.customerRate || booking.result.perKg}
                                  Tk
                                </span>
                              </div>
                            </div>
                          </div>
                          {!booking.trackingNo &&
                            booking.status !== "Pending" &&
                            booking.status !== "Reject" && (
                              <div className="row">
                                <marquee
                                  style={{
                                    color: "#18768f",
                                    fontSize: "90%",
                                    fontWeight: "bold",
                                    padding: 10,
                                  }}
                                >
                                  অনুগ্রহ করে Start Shipping বাটনে ক্লিক করুন
                                  এবং যথাসময়ে আপনার পার্সেলের ট্রাকিং নাম্বার
                                  যোগ করুন। ট্রাকিং নাম্বার যোগ করা ছাড়া আপনার
                                  পণ্য শিপমেন্ট করা হবে না। ধন্যবাদ।
                                </marquee>
                              </div>
                            )}
                          <div
                            className="d-flex flex-row"
                            style={{
                              fontSize: 12,
                              color: "#333",
                              padding: 10,
                              paddingLeft: 30,
                              justifyContent: "space-between",
                              backgroundColor: "white",
                              marginRight: "-15px",
                              marginLeft: "-15px",
                              paddingBottom: 0,
                            }}
                          >
                            <div>
                              <div
                                className="d-flex flex-row"
                                style={{ justifyContent: "flex-start" }}
                              >
                                Tracking No:&nbsp;
                                <span style={{ color: "gray", fontSize: 12 }}>
                                  {booking.trackingNo ? booking.trackingNo : ""}
                                </span>
                                {!booking.trackingNo && booking.shipping && (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "0px 7px",
                                      border: "2px solid gainsboro",
                                      borderRadius: 8,
                                      backgroundColor: "#f54c3c",
                                      cursor: "pointer",
                                      fontSize: 10,
                                      textAlign: "center",
                                      marginLeft: 10,
                                    }}
                                    onClick={() => {
                                      this.startTrackingToggleModal(booking);
                                    }}
                                  >
                                    + Add
                                  </div>
                                )}
                              </div>

                              <br />
                            </div>
                            &nbsp; &nbsp; &nbsp;
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                maxHeight: "23px",
                              }}
                            >
                              {booking.status == "Pending" &&
                                !booking.shippingChargeCustomer && (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "2px 7px",
                                      border: "2px solid gainsboro",
                                      borderRadius: 8,
                                      backgroundColor: "#18768f",
                                      cursor: "pointer",
                                      fontSize: 10,

                                      textAlign: "center",
                                      marginRight: 12,
                                    }}
                                    data-toggle="modal"
                                    data-target="#editShipmentModal"
                                    onClick={() => {
                                      this.setState({
                                        productObj: booking,
                                        imageUrl: booking.imageUrl
                                          ? booking.imageUrl
                                          : man,
                                        bookingId: booking.bookingId,
                                        shipFrom: booking.shipFrom,
                                        method: booking.method,
                                        productType: booking.productType,
                                        submit_loader: false,
                                        submit_loader2: false,
                                        result: booking.result,
                                        weight: booking.weight,
                                        date: booking.date,
                                        ctnQuantity: booking.ctnQuantity,
                                        totalCbm: booking.totalCbm,
                                        productContains:
                                          booking.productContains,
                                        productBrand: booking.productBrand,
                                        productsTotalCost:
                                          booking.productsTotalCost,
                                        shipTo: booking.shipTo,
                                        shipTo2: booking.shipTo2,
                                        validTo: booking.validTo,
                                        shipmentMethod: booking.shipmentMethod,
                                        productQuantity:
                                          booking.productQuantity,
                                        othersProductName:
                                          booking.othersProductName,
                                        productUrl: booking.productUrl,
                                        productVariant: booking.productVariant,
                                        image_loader: false,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-pencil" /> Edit
                                  </div>
                                )}
                              {booking.status === "Rates Provided" &&
                                !booking.shipping && (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "2px 7px",
                                      border: "2px solid gainsboro",
                                      borderRadius: 8,
                                      backgroundColor: "#18768f",
                                      cursor: "pointer",
                                      fontSize: 10,

                                      textAlign: "center",
                                      marginRight: 12,
                                    }}
                                    data-toggle="modal"
                                    data-target="#shippingInfoModal"
                                    onClick={() => {
                                      this.setState({
                                        productObj: booking,
                                      });
                                    }}
                                  >
                                    Start Shipping
                                  </div>
                                )}
                              {booking.shippingChargeCustomer ? (
                                <Link
                                  to={`${
                                    process.env.PUBLIC_URL
                                  }/pages/dashboard/ship-for/invoice/${
                                    booking.bookingId
                                  }`}
                                  target="_blank"
                                >
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "2px 7px",
                                      border: "2px solid gainsboro",
                                      borderRadius: 7,
                                      backgroundColor: "#a3422b",
                                      cursor: "pointer",
                                      fontSize: 10,
                                      textAlign: "center",
                                      marginRight: 12,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      className="icofont-dollar"
                                      style={{ fontWeight: "bold" }}
                                    />{" "}
                                    invoice
                                  </div>
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row products-box">
                            <div
                              className="col-8"
                              style={{
                                borderRight: "1px solid gainsboro",
                              }}
                            >
                              <div
                                className="row"
                                style={{
                                  padding: 5,
                                  borderTop: "1px solid gainsboro",
                                  paddingBottom: 25,
                                }}
                              >
                                <div
                                  className="col-2"
                                  style={{ margin: "0px auto", marginTop: 5 }}
                                >
                                  <div
                                    style={{
                                      height: 80,
                                      width: 80,
                                      border: "1px solid gainsboro",
                                      overflow: "hidden",
                                      backgroundImage: `url(${
                                        booking.imageUrl
                                      })`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: 5,
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  {booking.othersProductName && (
                                    <div
                                      style={{ color: "#4a4a4a", fontSize: 12 }}
                                    >
                                      {booking.othersProductName.slice(0, 20)}
                                      ...
                                    </div>
                                  )}
                                  <div
                                    style={{ color: "#4a4a4a", fontSize: 12 }}
                                  >
                                    Product type: {booking.productType}
                                  </div>

                                  {booking.payments &&
                                    booking.payments.length > 0 && (
                                      <div
                                        style={{
                                          fontSize: 11,
                                          color: "gray",
                                        }}
                                      >
                                        Total Paid:{" "}
                                        <span
                                          style={{
                                            color: "#555",

                                            fontSize: 10,
                                          }}
                                        >
                                          {this.getTotal(booking)}Tk
                                        </span>
                                      </div>
                                    )}
                                  <div
                                    style={{
                                      fontSize: 11,
                                      color: "gray",
                                    }}
                                  >
                                    Payment status:{" "}
                                    <span
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        background:
                                          booking.paymentStatus &&
                                          booking.paymentStatus == "Paid"
                                            ? "green"
                                            : booking.paymentStatus ==
                                              "Partially Paid"
                                            ? "darkorange"
                                            : "#596fa8",
                                        padding: "1px 3px",
                                        borderRadius: 5,
                                        fontSize: 8,
                                        border: "2px solid gainsboro",
                                      }}
                                    >
                                      {booking.paymentStatus || "Not Paid"}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "2px 5px",
                                      border: "2px solid gainsboro",
                                      borderRadius: 8,
                                      backgroundColor: "#18768f",
                                      cursor: "pointer",
                                      fontSize: 10,
                                      display: "inline",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                    data-toggle="modal"
                                    data-target="#detailInfoModal"
                                    onClick={() => {
                                      if (booking.trackingNo) {
                                        this.setState({
                                          submit_loader: true,
                                        });
                                        this.props.getOrderTrackingResultRedux(
                                          booking.trackingNo
                                        );
                                        this.setState({
                                          submit_loader: false,
                                        });
                                      }

                                      this.setState({
                                        productObj: booking,
                                      });
                                    }}
                                  >
                                    <i
                                      className="icofont-eye"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      &nbsp;detail
                                    </i>
                                  </div>
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 10,
                                    }}
                                  >
                                    {booking.note ? (
                                      <div style={{ marginTop: 10 }}>
                                        <span style={{ color: "#555" }}>
                                          Shipping instruction:
                                        </span>{" "}
                                        {booking.note}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="row"
                                    style={{ marginLeft: 0, marginTop: 5 }}
                                  />
                                </div>
                                <div
                                  className="col"
                                  style={{ color: "rgb(98 98 98)" }}
                                >
                                  {booking.productQuantity
                                    ? `${booking.productQuantity}pc`
                                    : null}
                                </div>
                                <div
                                  className="col"
                                  style={{ color: "rgb(98 98 98)" }}
                                >
                                  {booking.receivedWeight || booking.weight}Kg
                                </div>
                              </div>

                              {booking.status !== "Reject" && (
                                <div
                                  className="row"
                                  style={{
                                    margin: 0,
                                    marginBottom: 40,
                                    marginTop: 65,
                                  }}
                                >
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 1
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-paper" />
                                    <span>Pending</span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 2
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-tick-boxed" />
                                    <span>Approved</span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 3
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-building-alt" />
                                    <span>Abroad</span>
                                    <br />
                                    <span style={{ top: "7px" }}>
                                      Warehouse
                                    </span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 4
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-airplane-alt" />
                                    <span style={{ left: "-15px" }}>
                                      Ready{" "}
                                    </span>
                                    <br />
                                    <span style={{ top: "7px", left: "-15px" }}>
                                      for fly
                                    </span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 5
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-shield-alt" />
                                    <span>Bangladesh</span>
                                    <br />
                                    <span style={{ top: "7px" }}> customs</span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 6
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-castle" />
                                    <span>Paicart</span>
                                    <br />
                                    <span style={{ top: "7px" }}>
                                      {" "}
                                      warehouse
                                    </span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 7
                                        ? "last-progress"
                                        : "last-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-tick-mark" />
                                    <span>Delivered</span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className="col-4"
                              style={{
                                paddingTop: 5,
                                borderTop: "1px solid gainsboro",
                                overflow: "scroll",
                              }}
                            >
                              <div className="row">
                                <div className="col-6">
                                  <div
                                    style={{ fontWeight: "bold", fontSize: 16 }}
                                  >
                                    {booking.shippingChargeCustomer
                                      ? `${booking.shippingChargeCustomer}Tk`
                                      : ""}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 12,
                                      color: "gray",
                                    }}
                                  >
                                    {booking.localShipping
                                      ? `Including china courier cost ${
                                          booking.localShipping
                                        }Tk`
                                      : null}
                                  </div>

                                  <div
                                    style={{
                                      fontSize: 12,
                                      color: "gray",
                                      marginTop: 3,
                                    }}
                                  >
                                    {booking.totalRate
                                      ? "excluding shipping charge"
                                      : null}
                                  </div>
                                </div>
                                <div
                                  className="col-6"
                                  style={{ fontSize: 12, color: "gray" }}
                                >
                                  {booking.paymentStatus == "Paid" ||
                                  booking.paymentStatus == "Partially Paid" ||
                                  booking.paymentRequested ? (
                                    <div
                                      style={{
                                        color: "white",
                                        background:
                                          booking.paymentStatus == "Paid"
                                            ? "darkgreen"
                                            : booking.paymentRequested ||
                                              booking.paymentStatus ==
                                                "Partially Paid"
                                            ? "darkorange"
                                            : "#596fa8",
                                        border: "2px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 11,
                                        marginTop: 5,
                                      }}
                                    >
                                      {booking.paymentStatus == "Paid"
                                        ? "Paid"
                                        : booking.paymentStatus ==
                                          "Partially Paid"
                                        ? "Partially Paid"
                                        : booking.paymentRequested
                                        ? "Pending"
                                        : "Not Paid"}
                                    </div>
                                  ) : booking.status !== "Pending" &&
                                    booking.shippingChargeCustomer ? (
                                    <div
                                      onClick={() => {
                                        this.startToggleModal([booking]);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        color: "white",
                                        background: "orange",
                                        border: "2px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 11,
                                        marginTop: 5,
                                      }}
                                    >
                                      Pay now
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        color: "white",
                                        background: "#596fa8",
                                        border: "2px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 11,
                                        marginTop: 5,
                                      }}
                                    >
                                      Not Paid
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  padding: 5,
                                  margin: 0,
                                  borderTop: "1px solid gainsboro",
                                }}
                              >
                                {booking.warehouse && (
                                  <>
                                    {booking.status !== "Reject" && (
                                      <div style={{ fontSize: 13 }}>
                                        {" "}
                                        Warehouse Address:{" "}
                                        <span
                                          style={{
                                            color: "gray",
                                            fontSize: 13,
                                          }}
                                        >
                                          {booking.warehouse}
                                        </span>
                                        {this.state.index !== i ? (
                                          <span
                                            style={{
                                              marginLeft: 10,
                                              padding: "1px 7px",
                                              background: "aliceblue",
                                              border: "1px solid gray",
                                              borderRadius: 7,
                                              textAlign: "center",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              color: "black",
                                            }}
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                booking.warehouse
                                              );
                                              this.setState({
                                                index: i,
                                              });
                                            }}
                                          >
                                            copy
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              marginLeft: 10,
                                              padding: "1px 4px",
                                              background: "green",
                                              border: "1px solid gray",
                                              borderRadius: 6,
                                              textAlign: "center",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              color: "white",
                                            }}
                                          >
                                            copied!
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    {booking.status !== "Reject" && (
                                      <div
                                        style={{
                                          fontSize: 13,
                                          marginTop: 10,
                                        }}
                                      >
                                        {" "}
                                        Shipping mark:{" "}
                                        <span
                                          style={{
                                            color: "#ff8084",
                                            fontSize: 13,
                                          }}
                                        >
                                          {booking.shippingMark
                                            ? booking.shippingMark
                                            : `Arshipper/
                                        ${this.props.currentUser &&
                                          this.props.currentUser.userId}
                                        -
                                        ${this.props.currentUser &&
                                          this.props.currentUser.displayName}`}
                                        </span>
                                      </div>
                                    )}
                                    {booking.status !== "Reject" && (
                                      <div
                                        style={{
                                          fontSize: 11,
                                          color: "#777",
                                        }}
                                      >
                                        ***Please use this shipping mark on the
                                        box.
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="main-order-section">
                    <div className="col-sm-12">
                      <div>
                        <div
                          className="col-sm-12 empty-cart-cls text-center"
                          style={{ marginTop: 50 }}
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/empty-search.jpg`}
                            className="img-fluid mb-4"
                            alt=""
                          />
                          <h3 style={{ paddingBottom: 50 }}>
                            <strong>No shipment requests</strong>
                          </h3>
                        </div>
                      </div>
                      <div style={{ paddingBottom: 40, textAlign: "center" }}>
                        <Link to="/pages/ship-for-me">
                          <button
                            className="btn btn-solid"
                            style={{
                              padding: 6,
                              fontSize: 10,
                              borderRadius: 5,
                            }}
                          >
                            Do a shipment request
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div>
              <div className="d-flex flex-row main-navigator-option">
                <div
                  className="buy-ship-for-me-mobile"
                  style={{ borderBottom: "2px solid #f54c3c" }}
                >
                  <div>Buy & Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-request`
                        );
                      }}
                    >
                      My Request Products
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-orders`
                        );
                      }}
                    >
                      My Orders
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 160 }}>
                  <div>Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "#f54c3c",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-request`
                        );
                      }}
                    >
                      My Booking
                    </div>

                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 100 }}>
                  <div>Profile</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/information`
                        );
                      }}
                    >
                      Information
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/security`
                        );
                      }}
                    >
                      Security
                    </div>
                  </div>
                </div>
                <div />
                <div />
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.approved ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: true,
                        pending: false,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Approved ({approvedBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.pending ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: true,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Pending ({pendingBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.reject ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: false,
                        reject: true,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Rejected ({rejectedBookings.length})
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    justifyContent: "space-between",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div className="col-5">
                    <select
                      title="Filter by category"
                      required
                      name="filterByCategory"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.filterByCategory}
                      style={{ fontSize: 11 }}
                    >
                      <option value="">Filter by category </option>
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Partially Paid">Partially Paid</option>
                      <option value="Not Paid">Not Paid</option>
                      {this.state.approved ? (
                        <option value="Rates Provided">Rates Provided</option>
                      ) : (
                        <option value="Pending">Pending</option>
                      )}
                    </select>
                  </div>
                  <div className="col-7">
                    <form className="form-inline subscribe-form">
                      <input
                        type="text"
                        className="form-control extra-height"
                        id="exampleFormControlInput1"
                        name="searchOrder"
                        value={this.state.searchOrder}
                        onChange={this.handleChange}
                        placeholder="Search Booking"
                        style={{
                          padding: 8,
                          width: 250,
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />

                      <button
                        type="submit"
                        className="btn btn-solid"
                        style={{ position: "absolute", right: "12px" }}
                      >
                        <i
                          className="icofont-search"
                          style={{ fontWeight: "bold" }}
                        />
                      </button>
                    </form>
                  </div>
                </div>
                {bookingsToShow.length > 0 ? (
                  <>
                    {bookingsToShow.map((booking, i) => {
                      return (
                        <div
                          style={{ borderRadius: 10, marginBottom: 20 }}
                          key={i}
                        >
                          <div
                            className="each-order-container-1"
                            style={{ borderBottom: "1px solid gainsboro" }}
                          >
                            <div
                              className="order-header-1"
                              style={{ marginLeft: -10 }}
                            >
                              <div>
                                <div style={{ fontSize: 12 }}>
                                  Booking number: {booking.bookingId}
                                  <span
                                    style={{
                                      fontSize: 9,
                                      color: "darkgray",
                                      marginLeft: 10,
                                    }}
                                  >
                                    {booking.date}
                                  </span>
                                </div>

                                <div
                                  style={{
                                    fontSize: 11,
                                    color: "gray",
                                  }}
                                >
                                  Tracking no:{" "}
                                  <span style={{ color: "black" }}>
                                    {booking.trackingNo ? (
                                      booking.trackingNo
                                    ) : booking.shipping ? (
                                      <div
                                        style={{
                                          color: "white",
                                          padding: "0px 7px",
                                          border: "2px solid gainsboro",
                                          borderRadius: 8,
                                          backgroundColor: "#f54c3c",
                                          cursor: "pointer",
                                          fontSize: 10,
                                          textAlign: "center",
                                          marginLeft: 0,
                                          display: "inline-block",
                                        }}
                                        onClick={() => {
                                          this.startTrackingToggleModal(
                                            booking
                                          );
                                        }}
                                      >
                                        + Add
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div
                                style={{
                                  color: "darkorange",
                                  fontSize: 11,
                                  fontWeight: "bold",
                                }}
                              >
                                {booking.paymentStatus == "Paid" ||
                                booking.paymentStatus == "Partially Paid" ||
                                booking.paymentRequested ? (
                                  <div
                                    style={{
                                      color: "white",
                                      background:
                                        booking.paymentStatus == "Paid"
                                          ? "darkgreen"
                                          : "orange",
                                      border: "2px solid gainsboro",
                                      borderRadius: 7,
                                      padding: "1px 5px",
                                      display: "inline",
                                      fontSize: 11,
                                      marginTop: 5,
                                    }}
                                  >
                                    {booking.paymentStatus == "Paid"
                                      ? "Paid"
                                      : booking.paymentStatus ==
                                        "Partially Paid"
                                      ? "Partially Paid"
                                      : "Pending"}
                                  </div>
                                ) : booking.status !== "Pending" &&
                                  booking.shippingChargeCustomer ? (
                                  <div
                                    onClick={() => {
                                      this.startToggleModal([booking]);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                      color: "white",
                                      background: "orange",
                                      border: "2px solid gainsboro",
                                      borderRadius: 7,
                                      padding: "1px 5px",
                                      display: "inline",
                                      fontSize: 11,
                                      marginTop: 5,
                                    }}
                                  >
                                    Pay now
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color: "white",
                                      background: "#596fa8",
                                      border: "2px solid gainsboro",
                                      borderRadius: 7,
                                      padding: "1px 5px",
                                      display: "inline",
                                      fontSize: 9,
                                      marginTop: 5,
                                    }}
                                  >
                                    Not Paid
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {!booking.trackingNo &&
                            booking.status !== "Pending" &&
                            booking.status !== "Reject" && (
                              <div
                                className="row"
                                style={{
                                  marginRight: 0,
                                  marginLeft: 0,
                                  background: "white",
                                }}
                              >
                                <marquee
                                  style={{
                                    color: "#18768f",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    padding: 10,
                                  }}
                                >
                                  অনুগ্রহ করে Start Shipping বাটনে ক্লিক করুন
                                  এবং যথাসময়ে আপনার পার্সেলের ট্রাকিং নাম্বার
                                  যোগ করুন। ট্রাকিং নাম্বার যোগ করা ছাড়া আপনার
                                  পণ্য শিপমেন্ট করা হবে না। ধন্যবাদ।
                                </marquee>
                              </div>
                            )}
                          <div
                            className="products-box"
                            style={{
                              borderBottomLeftRadius: 10,
                              borderBottomRightRadius: 10,
                            }}
                          >
                            <div>
                              <div className="row">
                                <div className="col-3">
                                  <div
                                    style={{
                                      height: 60,
                                      width: 60,
                                      overflow: "hidden",
                                      border: "1px solid gainsboro",
                                      borderRadius: 5,
                                      backgroundImage: `url(${
                                        booking.imageUrl
                                      })`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    padding: "0px 10px",
                                    paddingLeft: 0,
                                  }}
                                  className="col"
                                >
                                  <div
                                    style={{
                                      color: "#222",
                                      fontSize: 13,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {booking.othersProductName &&
                                      booking.othersProductName.slice(0, 20)}
                                    ... <br />
                                    <span
                                      style={{ fontSize: 11, color: "gray" }}
                                    >
                                      {" "}
                                      Product type: {booking.productType} <br />
                                      {booking.productQuantity}pc
                                    </span>
                                    <br />
                                    <div
                                      style={{
                                        fontSize: 11,
                                        color: "gray",
                                      }}
                                    >
                                      Booking status:{" "}
                                      <span
                                        style={{
                                          color:
                                            booking.status == "Reject"
                                              ? "red"
                                              : "#18768f",
                                        }}
                                      >
                                        {booking.status}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 11,
                                        color: "gray",
                                      }}
                                    >
                                      Custom & Shipping rate:{" "}
                                      <span
                                        style={{
                                          color: "#ff8084",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {booking.customerRate ||
                                          booking.result.perKg}{" "}
                                        Tk
                                      </span>
                                    </div>
                                    {booking.warehouse &&
                                      booking.status !== "Reject" && (
                                        <>
                                          <div
                                            style={{
                                              fontSize: 10,
                                              color: "gray",
                                              marginTop: 7,
                                            }}
                                          >
                                            Warehouse address:{" "}
                                            <span style={{ color: "black" }}>
                                              {booking.warehouse}
                                            </span>
                                            {this.state.index !== i ? (
                                              <span
                                                style={{
                                                  marginLeft: 10,
                                                  padding: "1px 7px",
                                                  background: "aliceblue",
                                                  border: "1px solid gray",
                                                  borderRadius: 4,
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                  color: "black",
                                                  fontSize: 8,
                                                }}
                                                onClick={() => {
                                                  navigator.clipboard.writeText(
                                                    booking.warehouse
                                                  );
                                                  this.setState({
                                                    index: i,
                                                  });
                                                }}
                                              >
                                                copy
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  marginLeft: 10,
                                                  padding: "1px 4px",
                                                  background: "green",
                                                  border: "1px solid gray",
                                                  borderRadius: 4,
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                  color: "white",
                                                  fontSize: 8,
                                                }}
                                              >
                                                copied!
                                              </span>
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              fontSize: 9,
                                              color: "gray",
                                              marginTop: 7,
                                            }}
                                          >
                                            Shipping Mark:{" "}
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: 10,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {booking.shippingMark
                                                ? booking.shippingMark
                                                : `Arshipper/
                                        ${this.props.currentUser &&
                                          this.props.currentUser.userId}
                                        -
                                        ${this.props.currentUser &&
                                          this.props.currentUser.displayName}`}
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              color: "#777",
                                              fontSize: 9,
                                            }}
                                          >
                                            ***Please use this shipping mark.
                                          </div>
                                        </>
                                      )}
                                    {booking.note && (
                                      <div
                                        style={{
                                          fontSize: 10,
                                          color: "gray",
                                          marginTop: 7,
                                        }}
                                      >
                                        Shipping instruction:{" "}
                                        <span style={{ color: "black" }}>
                                          {booking.note}
                                        </span>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        fontSize: 9,
                                        color: "gray",
                                        marginTop: 10,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: "white",
                                          padding: "0px 5px",
                                          border: "2px solid gainsboro",
                                          borderRadius: 8,
                                          backgroundColor: "#18768f",
                                          cursor: "pointer",
                                          fontSize: 10,
                                          display: "inline",
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          paddingBottom: 2,
                                        }}
                                        onClick={() => {
                                          this.props.history.push(
                                            `/pages/dashboard/ship-for-me/my-request/${
                                              booking.bookingId
                                            }`
                                          );
                                        }}
                                      >
                                        <i
                                          className="icofont-eye"
                                          style={{
                                            fontSize: 9,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          &nbsp;detail
                                        </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="col-3"
                                >
                                  <div
                                    style={{
                                      color: "rgb(98 98 98)",
                                      fontSize: 12,
                                    }}
                                  >
                                    {booking.receivedWeight || booking.weight}kg
                                  </div>
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 12,
                                      marginLeft: 10,
                                    }}
                                  >
                                    X
                                    {booking.customerRate ||
                                      booking.result.perKg}
                                    Tk
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 5,
                              }}
                            >
                              <div style={{ fontSize: 10, color: "gray" }}>
                                {booking.localShipping
                                  ? `Including china courier cost ${
                                      booking.localShipping
                                    }Tk`
                                  : ""}
                              </div>

                              {booking.shippingChargeCustomer ? (
                                <div style={{ fontSize: 12, color: "black" }}>
                                  Total amount {booking.shippingChargeCustomer}
                                  Tk
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {booking.status !== "Reject" && (
                              <div
                                className="row"
                                style={{
                                  margin: 0,
                                  marginBottom: 20,
                                  marginTop: 30,
                                }}
                              >
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 1
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-paper" />
                                  <span>Pending</span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 2
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-tick-boxed" />
                                  <span>Approved</span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 3
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-building-alt" />
                                  <span>Abroad</span>
                                  <br />
                                  <span style={{ top: "-10px" }}>
                                    Warehouse
                                  </span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 4
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-airplane-alt" />
                                  <span style={{ left: "-15px" }}>Ready </span>
                                  <br />
                                  <span style={{ top: "-10px", left: "-15px" }}>
                                    fly
                                  </span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 5
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-shield-alt" />
                                  <span>Bangladesh</span>
                                  <br />
                                  <span style={{ top: "-10px" }}> customs</span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 6
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-castle" />
                                  <span>Paicart</span>
                                  <br />
                                  <span style={{ top: "-10px" }}>
                                    {" "}
                                    warehouse
                                  </span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 7
                                      ? "last-progress-3"
                                      : "last-progress-4"
                                  }`}
                                >
                                  <i className="icofont-tick-mark" />
                                  <span>Delivered</span>
                                </div>
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                padding: "0px 10px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    fontSize: 11,
                                    color: "darkorange",
                                    margin: "auto 0px",
                                  }}
                                  onClick={() => {
                                    this.props.history.push(
                                      `/pages/dashboard/ship-for-me/my-request/${
                                        booking.bookingId
                                      }`
                                    );
                                  }}
                                >
                                  more
                                </div>

                                {booking.status == "Pending" &&
                                  !booking.shippingChargeCustomer && (
                                    <div
                                      style={{
                                        color: "white",
                                        padding: "3px 7px",
                                        border: "2px solid gainsboro",
                                        borderRadius: 8,
                                        backgroundColor: "#18768f",
                                        cursor: "pointer",
                                        fontSize: 8,
                                        textAlign: "center",
                                        marginLeft: 5,
                                        fontWeight: "bold",
                                      }}
                                      data-toggle="modal"
                                      data-target="#editShipmentModal"
                                      onClick={() => {
                                        this.setState({
                                          productObj: booking,
                                          imageUrl: booking.imageUrl
                                            ? booking.imageUrl
                                            : man,
                                          bookingId: booking.bookingId,
                                          shipFrom: booking.shipFrom,
                                          method: booking.method,
                                          productType: booking.productType,
                                          submit_loader: false,
                                          submit_loader2: false,
                                          result: booking.result,
                                          weight: booking.weight,
                                          date: booking.date,
                                          ctnQuantity: booking.ctnQuantity,
                                          totalCbm: booking.totalCbm,
                                          productContains:
                                            booking.productContains,
                                          productBrand: booking.productBrand,
                                          productsTotalCost:
                                            booking.productsTotalCost,
                                          shipTo: booking.shipTo,
                                          shipTo2: booking.shipTo2,
                                          validTo: booking.validTo,
                                          shipmentMethod:
                                            booking.shipmentMethod,
                                          productQuantity:
                                            booking.productQuantity,
                                          othersProductName:
                                            booking.othersProductName,
                                          productUrl: booking.productUrl,
                                          productVariant:
                                            booking.productVariant,
                                          image_loader: false,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-pencil" /> Edit
                                    </div>
                                  )}
                                {booking.status === "Rates Provided" &&
                                !booking.shipping ? (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "3px 7px",
                                      border: "2px solid gainsboro",
                                      borderRadius: 8,
                                      backgroundColor: "#18768f",
                                      cursor: "pointer",
                                      fontSize: 8,
                                      textAlign: "center",
                                      marginLeft: 5,
                                      fontWeight: "bold",
                                    }}
                                    data-toggle="modal"
                                    data-target="#shippingInfoModal"
                                    onClick={() => {
                                      this.setState({
                                        productObj: booking,
                                      });
                                    }}
                                  >
                                    Start shipping
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              {booking.shippingChargeCustomer ? (
                                <Link
                                  to={`${
                                    process.env.PUBLIC_URL
                                  }/pages/dashboard/ship-for/invoice/${
                                    booking.bookingId
                                  }`}
                                  target="_blank"
                                >
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "0px 7px",
                                      border: "2px solid gainsboro",
                                      borderRadius: 7,
                                      backgroundColor: "#a3422b",
                                      cursor: "pointer",
                                      fontSize: 10,
                                      textAlign: "center",
                                      marginRight: 0,
                                      fontWeight: "bold",
                                      height: 20,
                                    }}
                                    onClick={() => {
                                      // modal er moddhe invoice dekhabo and download korar option dibo
                                    }}
                                  >
                                    <i
                                      className="icofont-dollar"
                                      style={{ fontWeight: "bold" }}
                                    />{" "}
                                    invoice
                                  </div>
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="main-order-section">
                    <div className="col-sm-12">
                      <div>
                        <div className="col-sm-12 empty-cart-cls text-center">
                          <img
                            style={{ maxWidth: "50%", marginTop: 20 }}
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/empty-search.jpg`}
                            className="img-fluid mb-4"
                            alt=""
                          />

                          <h5>No shipment requests</h5>
                        </div>
                      </div>
                      <div style={{ paddingBottom: 40, textAlign: "center" }}>
                        <Link to="/pages/ship-for-me">
                          <button
                            className="btn btn-solid"
                            style={{
                              padding: 6,
                              fontSize: 10,
                              borderRadius: 5,
                            }}
                          >
                            Do a shipment request
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#18768f",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              {productObj && productObj.status == "Rates Provided" && (
                <>
                  {productObj && productObj.validTo && this.state.days > -1 ? (
                    <div
                      style={{
                        color: "white",
                        position: "absolute",
                        right: 50,
                        top: "-3px",
                      }}
                    >
                      <span
                        style={{
                          background: "white",
                          padding: 5,
                          borderRadius: 10,
                          color: "black",
                          border: "2px solid #ec345b",
                        }}
                      >
                        {this.state.days}days
                      </span>{" "}
                      :{" "}
                      <span
                        style={{
                          background: "white",
                          padding: 5,
                          borderRadius: 10,
                          color: "black",
                          border: "2px solid #ec345b",
                        }}
                      >
                        {this.state.hours}hrs
                      </span>{" "}
                      :{" "}
                      <span
                        style={{
                          background: "white",
                          padding: 5,
                          borderRadius: 10,
                          color: "black",
                          border: "2px solid #ec345b",
                        }}
                      >
                        {this.state.minutes}mins
                      </span>{" "}
                      :{" "}
                      <span
                        style={{
                          background: "white",
                          padding: 5,
                          borderRadius: 10,
                          color: "black",
                          border: "2px solid #ec345b",
                        }}
                      >
                        {this.state.seconds}secs
                      </span>
                    </div>
                  ) : (
                    productObj &&
                    productObj.validTo && (
                      <div
                        style={{
                          color: "white",
                          position: "absolute",
                          right: 50,
                          top: "-3px",
                        }}
                      >
                        <span
                          style={{
                            background: "white",
                            padding: 5,
                            borderRadius: 10,
                            color: "red",
                            border: "2px solid #ec345b",
                          }}
                        >
                          00days
                        </span>{" "}
                        :{" "}
                        <span
                          style={{
                            background: "white",
                            padding: 5,
                            borderRadius: 10,
                            color: "red",
                            border: "2px solid #ec345b",
                          }}
                        >
                          00hrs
                        </span>{" "}
                        :{" "}
                        <span
                          style={{
                            background: "white",
                            padding: 5,
                            borderRadius: 10,
                            color: "red",
                            border: "2px solid #ec345b",
                          }}
                        >
                          00mins
                        </span>{" "}
                        :{" "}
                        <span
                          style={{
                            background: "white",
                            padding: 5,
                            borderRadius: 10,
                            color: "red",
                            border: "2px solid #ec345b",
                          }}
                        >
                          00secs
                        </span>
                      </div>
                    )
                  )}
                </>
              )}
              <div className="modal-body">
                <div style={{ padding: "0px 10px", marginTop: 10 }}>
                  <div style={{ fontWeight: "bold" }}>
                    {productObj && productObj.othersProductName}
                  </div>
                  {productObj && productObj.warehouse && (
                    <div style={{ fontSize: 11 }}>
                      Warehouse Address:{" "}
                      <span style={{ color: "gray", fontSize: 11 }}>
                        {productObj && productObj.warehouse}
                      </span>
                    </div>
                  )}
                  {productObj && productObj.productUrl && (
                    <div style={{ marginBottom: 8, fontSize: 11 }}>
                      Product Link:{" "}
                      <a
                        href={productObj.productUrl}
                        target="_blank"
                        style={{ color: "#ff8084" }}
                      >
                        {productObj && productObj.productUrl}
                      </a>
                    </div>
                  )}
                </div>
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Product Image</td>
                        <td style={{ fontWeight: "bold" }}>Product Category</td>
                        <td style={{ fontWeight: "bold" }}>Total Quantity</td>
                        <td style={{ fontWeight: "bold" }}>Total Weight</td>
                        <td style={{ fontWeight: "bold" }}>Total Price</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {productObj && (
                            <img
                              style={{
                                height: 70,
                                width: 70,
                                border: "1px solid gainsboro",
                                borderRadius: 5,
                              }}
                              src={
                                productObj.imageUrl ? productObj.imageUrl : man
                              }
                            />
                          )}
                        </td>
                        <td>{productObj && productObj.productType}</td>
                        <td>{productObj && productObj.productQuantity}pcs </td>
                        <td>{productObj && productObj.weight}Kg</td>
                        <td>{productObj && productObj.productsTotalCost}Tk</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="row"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <div className="col" style={{ marginLeft: 5 }}>
                    <div
                      style={{
                        paddingLeft: 7,
                        color: "#18768f",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      Product Information
                    </div>
                    <div className="category-list-shipping">
                      Origin Country:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.shipFrom}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Ship By:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.shipBy}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Ship to:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.shipTo}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Valid to:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.validTo}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Brand/Non-brand:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {productObj && productObj.productBrand}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Product Contains:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {productObj && productObj.productContains}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Carton Quantity:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {productObj && productObj.ctnQuantity}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Total CBM:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {productObj && productObj.totalCbm}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Product details:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {productObj && productObj.productVariant}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Status:{" "}
                      <span
                        style={{
                          fontWeight: "bold",

                          color: "white",
                          padding: "1px 5px",
                          borderRadius: 5,

                          backgroundColor: productObj
                            ? productObj.status == "Pending"
                              ? "gainsboro"
                              : productObj.status == "Rates Provided"
                              ? "gray"
                              : productObj.status == "Received in Warehouse"
                              ? "orange"
                              : productObj.status == "Ready for fly"
                              ? "darkorange"
                              : productObj.status == "Bangladesh customs"
                              ? "chocolate"
                              : productObj.status == "Paicart warehouse"
                              ? "green"
                              : productObj.status == "Bangladesh warehouse"
                              ? "#00a3a3"
                              : productObj.status == "Delivered"
                              ? "darkgreen"
                              : "red"
                            : "white",
                          textAlign: "center",
                          border: "2px solid gainsboro",
                          fontSize: 10,
                        }}
                      >
                        {productObj && productObj.status}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Payment Status:{" "}
                      <span
                        style={{
                          padding: "1px 6px",
                          fontWeight: "bold",
                          color: "white",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.paymentStatus == "Partially Paid"
                              ? "darkorange"
                              : productObj.paymentStatus == "Not Paid"
                              ? "#596fa8"
                              : productObj.paymentStatus == "Paid"
                              ? "green"
                              : "#596fa8"
                            : "#596fa8",

                          textAlign: "center",
                          border: "2px solid gainsboro",
                          fontSize: 10,
                        }}
                      >
                        {(productObj && productObj.paymentStatus) || "Not Paid"}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Received weight:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.receivedWeight &&
                          `${productObj.receivedWeight}Kg`}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Rate/kg:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.customerRate &&
                          `${productObj.customerRate}Tk`}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Packaging & other cost:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.otherCost &&
                          `${productObj.otherCost}Tk`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Discount:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.discount &&
                          `${productObj.discount}Tk`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Refund amount:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.refund &&
                          `${productObj.refund}Tk`}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Total shipping charge:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.shippingChargeCustomer &&
                          `${productObj.shippingChargeCustomer}Tk`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Total Paid:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && this.getTotal(productObj)}Tk
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Refund note:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.refundNote}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Waybill Image: <br />
                      {productObj && (
                        <img
                          style={{
                            height: 50,
                            width: 50,
                            border: "1px solid gainsboro",
                            borderRadius: 5,
                          }}
                          src={
                            productObj.waybillImage
                              ? productObj.waybillImage
                              : man
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{
                        paddingLeft: 7,
                        color: "#18768f",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      Shipping Status
                    </div>
                    <div className="category-list-shipping">
                      Booking Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.date}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Rates Provided:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Rates ProvidedDate"]}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Abroad Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Received in WarehouseDate"]}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Ready for fly:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Ready for flyDate"]}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Bangladesh customs:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj
                          ? productObj["Bangladesh customsDate"]
                            ? productObj["Bangladesh customsDate"]
                            : productObj["Bangladesh warehouseDate"]
                            ? productObj["Bangladesh warehouseDate"]
                            : ""
                          : ""}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Custom released:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Bangladesh warehouseDate"]}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Paicart Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Paicart warehouseDate"]}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Delivered:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["DeliveredDate"]}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Delivered by:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.deliveredBy}
                      </span>
                    </div>
                    <div
                      style={{
                        paddingLeft: 7,
                        color: "#18768f",
                        fontWeight: "bold",
                        marginBottom: 5,
                        marginTop: 30,
                      }}
                    >
                      Shipping Information
                    </div>
                    <div className="category-list-shipping">
                      Customer Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.displayName}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Shipping mark:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.shippingMark}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Tracking No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.trackingNo}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Cargo company:{" "}
                      <span style={{ fontWeight: "lighter" }}>AR shipper</span>
                    </div>
                    <div className="category-list-shipping">
                      Lot Number:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.lotNo &&
                          productObj.lotNo.toString().replace("CB", "AR")}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Carton number:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.ctnNo &&
                          productObj.ctnNo.toString().replace("CB", "AR")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  style={{
                    background: "#f48110",
                    borderRadius: 5,
                    color: "white",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="shippingInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#18768f",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Freight company instruction
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div
                className="modal-body"
                style={{ marginLeft: 10, marginTop: 10 }}
              >
                <div style={{ color: "#666" }}>
                  1. অনুগ্রহ করে আপনার সকল পার্সেল বা কার্টুন সবুজ বোনা ব্যাগে
                  প্যাকেজিং করুন। অন্যথায় আমাদের ওয়্যারহাউজ কর্তৃক সবুজ বোনা
                  ব্যাগে প্যাকেজিং করলে রি প্যাকিং খরচ যুক্ত হবে।
                </div>
                <div style={{ color: "#666", marginTop: 5 }}>
                  2. ⁠প্রতিটা বক্সের প্যাকেজিং সম্পূর্ণ করার পর প্রতিটি বক্সের
                  উপর আপনার শিপিং মার্ক, পণ্যের নাম, প্যাকেজিং সহ পণ্যের সঠিক
                  ওজন, পণ্যের পরিমান সম্পর্কিত তালিকা লিখে দিন। অন্যথায়
                  ওয়্যারহাউজ কর্তৃক পণ্য শিপমেন্ট হবে না।
                </div>
                <div style={{ color: "#666", marginTop: 5 }}>
                  3. ⁠লিকুইড, পাউডার, মেশিন ও কাঁচ যাতীয় ভঙ্গুর পণ্য অবশ্যই
                  কাঠের বক্সে পাঠাতে হবে।
                </div>
                <div style={{ color: "#666", marginTop: 5 }}>
                  4. ⁠ভুল পণ্য ডিক্লারেশন করলে পণ্যের জরিমানা ও যাবতীয় খরচ
                  Paicart কর্তৃপক্ষ বহন করবে না।
                </div>
                <div style={{ color: "#666", marginTop: 5 }}>
                  5. যথা সময়ে আপনার পার্সেলের ট্রাকিং নাম্বার যোগ করুন। ট্রাকিং
                  নাম্বার ছাড়া আপনার পণ্য শিপমেন্ট করা হবে না।
                </div>
                <div style={{ color: "#666", marginTop: 5, paddingBottom: 15 }}>
                  6. পণ্য শিপমেন্ট সময়কালীন ফ্রেইট ও ট্যাক্স বৃদ্ধি পেলে শিপিং
                  খরচ বৃদ্ধি পাবে।
                </div>
              </div>

              <div
                style={{
                  marginLeft: 25,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={this.state.checked}
                  onChange={(e) => {
                    this.setState({
                      checked: !this.state.checked,
                    });
                  }}
                />{" "}
                I have read and agreed to the{" "}
                <a target="_blank" href="/pages/terms-and-coditions">
                  <span style={{ color: "#ff8084" }}>Terms and Conditions</span>
                </a>
                .
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  style={{
                    background: "#f48110",
                    borderRadius: 5,
                    color: "white",
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  style={{
                    background: "#18768f",
                    borderRadius: 5,
                    color: "white",
                  }}
                  onClick={() => {
                    this.props.updateShipmentRequestRedux({
                      ...this.state.productObj,
                      shipping: true,
                      readTermsAndConditions: this.state.checked,
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="editShipmentModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Edit Shipment Request
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <form
                    onSubmit={this.handleSubmit}
                    className="rounded-field mt-2"
                  >
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          METHOD (Sea/Air)
                        </label>

                        <select
                          className="custom-select"
                          name="method"
                          value={this.state.method}
                          onChange={this.handleChange}
                        >
                          <option value="">Choose Method</option>
                          <option value="sea">Sea</option>
                          <option value="air">Air</option>
                        </select>
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          ORIGIN OF SHIPMENT
                        </label>

                        <select
                          className="custom-select"
                          name="shipFrom"
                          value={this.state.shipFrom}
                          onChange={this.handleChange}
                        >
                          <option value="">Select Country</option>
                          <option value="china">China</option>
                          <option value="uk">UK</option>
                          <option value="usa">Usa</option>
                          <option value="hongkong">Hongkong</option>
                          <option value="thailand">Thailand</option>
                          {this.state.method === "air" && (
                            <>
                              <option value="india">India</option>
                            </>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          TOTAL WEIGHT
                        </label>

                        <input
                          type="number"
                          name="weight"
                          value={this.state.weight}
                          onChange={(event) => {
                            this.setState({
                              weight: event.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Enter Weight"
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT TYPE
                        </label>

                        <input
                          type="text"
                          value={this.state.productType}
                          className="form-control"
                          placeholder="Product Type"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT LINK (optional)
                        </label>
                        <textarea
                          type="text"
                          name="productUrl"
                          value={this.state.productUrl}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Enter product Url here"
                        />
                      </div>
                    </div>
                    <div
                      className="form-row"
                      style={{
                        color: "#555",
                        marginTop: 15,
                        marginBottom: 10,
                        borderBottom: "1px solid gray",
                      }}
                    >
                      Shipping Information
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          CARTON QUANTITY
                        </label>

                        <input
                          type="text"
                          name="ctnQuantity"
                          value={this.state.ctnQuantity}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Carton Quantity"
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT QUANTITY
                        </label>

                        <input
                          type="text"
                          name="productQuantity"
                          value={this.state.productQuantity}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Product Quantity"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT TOTAL COST
                        </label>

                        <input
                          type="text"
                          name="productsTotalCost"
                          value={this.state.productsTotalCost}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Products total cost"
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT NAME
                        </label>

                        <input
                          type="text"
                          name="othersProductName"
                          value={this.state.othersProductName}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Product Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT CONTAINS
                        </label>

                        <select
                          className="custom-select"
                          name="productContains"
                          value={this.state.productContains}
                          onChange={this.handleChangeBooking}
                          required
                        >
                          <option value="liquid">liquid</option>
                          <option value="powder">powder</option>
                          <option value="battery">battery</option>
                          <option value="none">none</option>
                        </select>
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          BRAND/NON-BRAND
                        </label>

                        <select
                          className="custom-select"
                          name="productBrand"
                          value={this.state.productBrand}
                          onChange={this.handleChangeBooking}
                          required
                        >
                          <option value="Brand">Brand</option>
                          <option value="Non Brand">Non Brand</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          Total CBM (in cm3)
                        </label>
                        <input
                          type="text"
                          name="totalCbm"
                          value={this.state.totalCbm}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Enter total CBM (height x width x length) in cm"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          SHIP TO
                        </label>
                        <input
                          type="text"
                          name="shipTo"
                          value={this.state.shipTo}
                          className="form-control"
                          required
                          readOnly
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          VALID TO
                        </label>

                        <input
                          type="text"
                          name="validTo"
                          value={this.state.validTo}
                          className="form-control"
                          placeholder="Shipping Deadline"
                          required
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          ADDITIONAL INFORMATION
                        </label>

                        <textarea
                          style={{
                            borderRadius: 5,
                            height: 80,
                          }}
                          type="text"
                          name="productVariant"
                          value={this.state.productVariant}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Product description (color,size,variant,quanity etc)"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          UPLOAD IMAGE
                        </label>

                        <div
                          className="box-input-file"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {this.state.image_loader ? (
                            <div
                              className="spinner-border"
                              role="status"
                              style={{
                                color: "purple",
                                paddingTop: "25px",
                                backgroundColor: "white",
                                width: 150,
                                height: 150,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "1px solid gainsboro",
                                borderRadius: 8,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 30,
                                  padding: 0,
                                  marginRight: 20,
                                }}
                              >
                                <CircleLoader
                                  loading={this.state.image_loader}
                                  color="blue"
                                  size={35}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <img
                                className="img-100 lazyloaded blur-up"
                                src={this.state.imageUrl}
                                alt="#"
                                style={{
                                  zIndex: 6,
                                  cursor: "pointer",
                                  maxWidth: 150,
                                  minWidth: 150,
                                  minHeight: 150,
                                  maxHeight: 150,
                                  border: "1px solid gainsboro",
                                  borderRadius: 8,
                                }}
                                onClick={() => {
                                  document
                                    .getElementById("upload-image-input2")
                                    .click();
                                }}
                              />
                              <input
                                id="upload-image-input2"
                                className="upload"
                                type="file"
                                style={{
                                  position: "absolute",
                                  zIndex: 5,
                                  maxWidth: "50px",
                                  marginTop: "20px",
                                }}
                                onChange={(e) => this._handleImgChange(e, 0)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleBookNowSubmit();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  bookingsArray: state.bookingRequests.shipForMeOfSingleUser,
  orderTracking: state.orders.orderTrackingResult,
  d2dRates: state.bookingRequests.d2dRates,
});

export default connect(
  mapStateToProps,
  {
    getAllShipForMeOfSingleUserRedux,
    getAllD2DRatesRedux,
    getOrderTrackingResultRedux,
    updateShipmentRequestRedux,
    updateShipForMeRedux,
  }
)(MyRequests);
