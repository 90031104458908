import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  getTopCollection,
  getTrendingCollection,
  getTopCollectionItems,
} from "../../../services";
import { Product5 } from "../../../services/script";
import { addToCart, addToWishlist } from "../../../actions";
import ProductItem from "./product-item";
import {
  auth,
  addCartItemTofirestore,
  addWishlistTofirestore,
} from "../../../firebase/firebase.utils";
import "./collection2.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
class CollectionTwo extends Component {
  componentDidMount() {
    // console.log(this.props)
  }

  addToReduxAndFirestoreCart = (product, qty) => {
    const { addToCart } = this.props;
    auth.onAuthStateChanged(
      async (userAuth) => await addCartItemTofirestore(userAuth, product, qty)
    );
    addToCart(product, qty);
  };

  addToReduxAndFirestoreWishlist = async (product) => {
    const { addToWishlist, currentUser } = this.props;
    console.log(this.props);
    if (currentUser && currentUser.uid) {
      await addWishlistTofirestore(currentUser, product);
      toast.success("Item addded to wishilist");
    } else {
      alert("You must login first.");
    }
  };

  render() {
    const {
      items,
      topCollectionItems,
      symbol,

      title,
      subtitle,
    } = this.props;
    // console.log(this.props)
    let renderableItems = items.filter(
      (item) => item.categoryId == this.props.categoryId
    );
    return (
      <div className="each-home-category-container">
        {/*Paragraph*/}
        <section className="section-b-space j-box pets-box ratio_square  products-container-section">
          <div className="container">
            <div className="row">
              <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className="title1 title5">
                  {subtitle ? (
                    <h4
                      style={{
                        cursor: "pointer",
                        color: "#18768f",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        this.props.history.push("/collection/in-stock");
                      }}
                    >
                      {subtitle}
                    </h4>
                  ) : (
                    ""
                  )}
                  <h2
                    className="title-inner1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.props.history.push("/collection/in-stock");
                    }}
                  >
                    {title}
                  </h2>
                  <hr role="tournament6" className="tournament-line" />
                </div>
                <Slider {...Product5} className="product-5 product-m no-arrow">
                  {renderableItems.slice(0, 6).map((product, index) => (
                    <div key={index}>
                      <ProductItem
                        product={product}
                        symbol={symbol}
                        onAddToWishlistClicked={() =>
                          this.addToReduxAndFirestoreWishlist(product)
                        }
                        onAddToCartClicked={() =>
                          this.addToReduxAndFirestoreCart(product, 1)
                        }
                        key={index}
                      />
                    </div>
                  ))}
                </Slider>
                <Slider {...Product5} className="product-5 product-m no-arrow">
                  {renderableItems.slice(6, 12).map((product, index) => (
                    <div key={index}>
                      <ProductItem
                        product={product}
                        symbol={symbol}
                        onAddToWishlistClicked={() =>
                          this.addToReduxAndFirestoreWishlist(product)
                        }
                        onAddToCartClicked={() =>
                          this.addToReduxAndFirestoreCart(product, 1)
                        }
                        key={index}
                      />
                    </div>
                  ))}
                </Slider>
                <Slider {...Product5} className="product-5 product-m no-arrow">
                  {renderableItems.slice(12, 20).map((product, index) => (
                    <div key={index}>
                      <ProductItem
                        product={product}
                        symbol={symbol}
                        onAddToWishlistClicked={() =>
                          this.addToReduxAndFirestoreWishlist(product)
                        }
                        onAddToCartClicked={() =>
                          this.addToReduxAndFirestoreCart(product, 1)
                        }
                        key={index}
                      />
                    </div>
                  ))}
                </Slider>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    color: "#f48110",
                    marginRight: 12,
                    cursor: "pointer",
                  }}
                >
                  <a
                    target="_blank"
                    href={`/collection/${this.props.categoryId}?category_id=${
                      this.props.categoryId
                    }`}
                    style={{ color: "#f48110" }}
                  >
                    View all ►►
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: state.data.products,
  topCollectionItems: state.data.products
    .filter((item) => item.availability == ownProps.status)
    .slice(12, 30),
  symbol: state.data.symbol,
  cartItems: state.cartList,
  currentUser: state.user.currentUser,
});

export default withRouter(
  connect(
    mapStateToProps,
    { addToWishlist, addToCart }
  )(CollectionTwo)
);
