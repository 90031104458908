import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./logisticsDetails.css";
class LogisticsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
    };
  }

  render() {
    const { currentUser } = this.props;
    console.log(currentUser);
    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    style={{
                      fontSize: 9,
                      color: "darkorange",
                      paddingBottom: 10,
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 50,
                        color: "darkorange",
                        fontSize: 9,
                      }}
                    >
                      Logistic no:1
                    </div>
                    <div style={{ color: "gray", fontSize: 9, marginLeft: 40 }}>
                      Logistic no:2
                    </div>
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    padding: 13,
                    backgroundColor: "#fdedda",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <i
                      className="icofont-exclamation-circle"
                      style={{ fontSize: 22, color: "darkorange" }}
                    />
                    &nbsp;
                    <div
                      style={{
                        marginTop: 5,
                        color: "rgb(212, 117, 0)",
                        fontSize: 9,
                      }}
                    >
                      Self delivery in transit
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: 13,
                    backgroundColor: "white",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 15,
                        color: "#464646",
                        fontSize: 10,
                      }}
                    >
                      Tracking No. 758364523458736
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        marginTop: 15,
                        color: "black",
                      }}
                    >
                      Logistics Company: ZTO
                    </div>
                    <div
                      style={{ fontSize: 11, marginTop: 15, color: "#464646" }}
                    >
                      Delivery time: 2021-11-20 5:43:00
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: 13,
                    backgroundColor: "white",
                    justifyContent: "space-between",
                    marginTop: 20,
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div
                    style={{
                      marginTop: 10,
                      color: "#464646",
                      fontSize: 9,
                    }}
                  >
                    Logistics <br /> record
                  </div>
                </div>
                <div className="row">
                  <div className="section-containing-container">
                    <div className="flex-options">
                      <div style={{ backgroundColor: "white", padding: 3 }}>
                        <div style={{ padding: 5 }}>
                          <table className="table table-logistics-details">
                            <tbody>
                              <tr>
                                <td colSpan="2" style={{ fontWeight: "bold" }}>
                                  Lot No
                                </td>
                                <td>CB_136</td>
                              </tr>
                              <tr>
                                <td colSpan="2">Country</td>
                                <td> China to Bangladesh</td>
                              </tr>
                              <tr>
                                <td colSpan="2">Shipment Method</td>
                                <td> D2D-Air</td>
                              </tr>
                              <tr>
                                <td colSpan="2">Shipping Line</td>
                                <td>Maersk</td>
                              </tr>
                              <tr>
                                <td colSpan="2">Carton No</td>
                                <td>41,42,43,44,45,46,47,48</td>
                              </tr>
                              <tr>
                                <td colSpan="2">Delivery Date (approx)</td>
                                <td>11-12-2021</td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            style={{
                              fontSize: 9,
                              marginBottom: 20,
                              marginTop: 10,
                            }}
                          >
                            * উপরোল্লেখিত ডেলিভারি তারিখ একটি সম্ভাব্য তারিখ।
                            এয়ারপোর্ট, কাস্টমজনিত সুবিধা অসুবিধার কারণে পণ্য
                            ডেলিভারি পেতে কিছুসময় দেরী অথবা দ্রুত পাওয়া যেতে
                            পারে। অতিরিক্ত দেরী হলে আমাদের কাস্টমার সার্ভিসতে
                            যোগাযোগ করুন ।
                          </div>
                          <div style={{ fontSize: 9, marginBottom: 10 }}>
                            *আপনার প্রেরিত পণ্যের প্রতিটা বক্সের উপরে আপনার
                            শিপিং মার্ক/নাম, পণ্যের বিবরণ (পণ্যের নাম, কত পিস)
                            অবশ্যই অবশ্যই লেখা থাকতে হবে।
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        padding: 15,
                        backgroundColor: "white",
                        borderBottom: "1px solid gainsboro",
                        fontSize: 9,
                        marginLeft: 0,
                        paddingLeft: 10,

                        marginTop: 20,
                      }}
                    >
                      Order <br />
                      status
                    </div>
                    <div
                      className="flex-options"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                        }}
                      >
                        <div>
                          <div className="px-3 m-4">
                            <div className="row">
                              <div className="col order-tracking-bar additional-styles-for">
                                {true ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-industries"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                          color: "white",
                                          border: "1px soild rgb(1, 70, 109)",
                                        }}
                                      />
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                        }}
                                      />
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(1, 70, 109)",
                                      }}
                                    >
                                      Abroad Warehouse <br />
                                      11-11-2021
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-industries" />
                                      <div className="progress-bar-line" />
                                    </div>
                                    &nbsp; <p>Abroad Warehouse</p>
                                  </span>
                                )}
                                {false ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-police"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                          color: "white",
                                          border: "1px soild rgb(1, 70, 109)",
                                        }}
                                      />
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                        }}
                                      />
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(1, 70, 109)",
                                      }}
                                    >
                                      Abroad Customs
                                      <br />
                                      11-11-2021
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-police" />
                                      <div className="progress-bar-line" />
                                    </div>
                                    &nbsp; <p>Abroad Customs</p>
                                  </span>
                                )}
                                {false ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-airplane-alt"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                          color: "white",
                                          border: "1px soild rgb(1, 70, 109)",
                                        }}
                                      />
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                        }}
                                      />
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(1, 70, 109)",
                                      }}
                                    >
                                      Ready to Fly
                                      <br />
                                      11-11-2021
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-airplane-alt" />
                                      <div className="progress-bar-line" />
                                    </div>
                                    &nbsp; <p>Ready to Fly</p>
                                  </span>
                                )}
                                {false ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-police"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                          color: "white",
                                          border: "1px soild rgb(1, 70, 109)",
                                        }}
                                      />
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                        }}
                                      />
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(1, 70, 109)",
                                      }}
                                    >
                                      Bangladesh Customs
                                      <br />
                                      11-11-2021
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-police" />
                                      <div className="progress-bar-line" />
                                    </div>
                                    &nbsp; <p>Bangladesh Customs</p>
                                  </span>
                                )}
                                {false ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-home"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                          color: "white",
                                          border: "1px soild rgb(1, 70, 109)",
                                        }}
                                      />
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                        }}
                                      />
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(1, 70, 109)",
                                      }}
                                    >
                                      Local Warehouse
                                      <br />
                                      11-11-2021
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-home" />
                                      <div className="progress-bar-line" />
                                    </div>
                                    &nbsp; <p>Local Warehouse</p>
                                  </span>
                                )}
                                {false ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-tick-mark"
                                        style={{
                                          backgroundColor: "rgb(1, 70, 109)",
                                          color: "white",
                                          border: "1px soild rgb(1, 70, 109)",
                                        }}
                                      />
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(1, 70, 109)",
                                      }}
                                    >
                                      Delivered
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        marginTop: -20,
                                      }}
                                    >
                                      <i className="icofont-tick-mark" />
                                    </div>
                                    &nbsp; <p>Delivered</p>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {false ? (
                            <>
                              <div className="row mt-3">
                                <div className="col">
                                  <div
                                    className="center-head"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <span
                                      className="bg-light-gray"
                                      style={{
                                        textTransform: "none",
                                        color: "purple",
                                      }}
                                    >
                                      শিপমেন্ট সংক্রান্ত নোটিশ
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  color: "gray",
                                  fontSize: "80%",
                                  padding: "10px",
                                  paddingTop: "5px",
                                  paddingBottom: "15px",
                                }}
                              >
                                Notice
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(LogisticsDetails);
