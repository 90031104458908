import {
  FETCH_SINGLE_PRODUCT,
  CHANGE_CURRENCY,
  RECEIVE_PRODUCTS,
} from "../constants/ActionTypes";

const initialState = {
  products: [],
  symbol: "Tk",
  product_details: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.products };
    case "FETCH_ALL_PRODUCTS_FROM_FIRESTORE":
      let products = action.payload.map((product) => {
        if (product.price_range.length > 0) {
          product.price_range = JSON.parse(product.price_range);
          return product;
        } else {
          return product;
        }
      });
      return { ...state, products };
    case FETCH_SINGLE_PRODUCT:
      let product = {
        ...action.payload,
        price_range:
          action.payload.price_range.length > 0
            ? JSON.parse(action.payload.price_range)
            : [],
      };

      return { ...state, products: [...state.products, product] };

    case CHANGE_CURRENCY:
      return { ...state, symbol: action.symbol };

    // default:
    //     return {...state};
  }
  return state;
};
export default productReducer;
