import React, { Component } from "react";
import Slider from "react-slick";
import "./small-image.css";

class SmallImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav2: this.slider2,
    };
  }
  componentDidMount() {
    this.setState({
      nav2: this.slider2,
    });
  }

  render() {
    const { item, settings } = this.props;

    // slick ta muloto accurate kaj kore na because of maxwidth deya ja small image te tar jonno.
    // r reload neyaite hobe live kaj kore na media query
    var productsnav = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      swipeToSlide: true,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      edgeFriction: 0,
      responsive: [
        { breakpoint: 375, settings: { slidesToShow: 4 } },
        { breakpoint: 450, settings: { slidesToShow: 5 } },
        { breakpoint: 1024, settings: { slidesToShow: 5 } },
        { breakpoint: 1500, settings: { slidesToShow: 5 } },
        { breakpoint: 1600, settings: { slidesToShow: 6 } },
        { breakpoint: 25000, settings: { slidesToShow: 7 } },
        { breakpoint: 100000, settings: "unslick" },
      ],
    };

    return (
      <div className="small-image-container-div">
        <Slider
          {...productsnav}
          asNavFor={this.props.navTwo}
          ref={(slider) => (this.slider2 = slider)}
          className="slider-nav"
        >
          {item.pictures.map((vari, index) => (
            <div
              key={index}
              onClick={() => this.props.clickOnColorVariant(vari)}
              style={{
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              <img
                src={`${vari}`}
                key={index}
                alt=""
                className="img-fluid"
                style={{
                  maxHeight: 60,
                  minHeight: 60,
                  maxWidth: 60,
                  minWidth: 60,
                  border: "2px solid gainsboro",
                  borderRadius: 5,
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default SmallImages;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "gainsboro",
        minHeight: "94%",
        maxHeight: "94%",
        marginTop: "2px",
        borderRadius: "3px",
        border: "2px solid gainsboro",
        color: "black",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "gainsboro",
        minHeight: "94%",
        maxHeight: "94%",
        marginTop: "2px",
        borderRadius: "3px",
        border: "2px solid gainsboro",
        color: "black",
      }}
      onClick={onClick}
    />
  );
}
