import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import man from "./assets/plus image.jpeg";
import "./request-product.css";
import "./ship-for-me.css";
import {
  getAllD2DRatesRedux,
  setBookingRequestRedux,
  setProductRequestRedux,
} from "../../actions";
import { connect } from "react-redux";
import { HashLoader, CircleLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { uploadImageProduct } from "../../firebase/firebase.utils";
import BuyAndShipForMe from "../layouts/homePage/buyAndShipForMe.png";
class RequestProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: man,
      date: new Date().toLocaleDateString("en-GB"),
      productQuantity: "",
      productUrl: "",
      productVariant: "",
      productType: "",
      country: "",
      store: "",
      image_loader: false,
      submit_loader: false,
    };
  }
  componentDidMount = async () => {};

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      console.log(name);
      if (name == "country") {
        console.log(name);
        this.props.getAllD2DRatesRedux("air", this.state.country);
      }
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    // if (this.props.currentUser.displayName === "") {
    //   alert("Please log in to your account to request a booking");
    //   return;
    // }

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ image_loader: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageProduct(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ image_loader: false });
    }
  };

  handleBookNowSubmit = async (event) => {
    event.preventDefault();
    if (!this.props.currentUser.id) {
      alert("You must login first");
      return;
    }
    if (this.state.image_loader) {
      alert("Wait for the image to be uploaded!");
      return;
    }
    if (!this.state.productType || !this.state.country) {
      alert("Please select a product type.");
      return;
    }
    this.setState({ submit_loader: true });
    let date = new Date();
    const [productType, shippingRate] = this.state.productType.split("-");
    console.log(this.state.productType);
    console.log(productType);
    console.log(shippingRate);
    await this.props.setProductRequestRedux({
      ...this.state,
      date: date.toLocaleDateString("en-GB"),
      time: date.getTime(),
      userId: this.props.currentUser.id,
      displayName: this.props.currentUser.displayName,
      shippingRate,
      productType,
      shipmentStatusScore: 1,
      status: "Pending",
      orderStatus: "Pending",
    });
    this.setState({
      submit_loader: false,
    });
    toast.success(
      "Your booking is successful! Our agent will contact you soon"
    );

    this.setState({
      imageUrl: man,
      date: new Date().toLocaleDateString("en-GB"),
      productQuantity: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
      submit_loader: false,
    });
  };
  render() {
    return (
      <div style={{ background: "#f7f7f7" }}>
        <ToastContainer />
        <Helmet>
          <title>Paicart | Request for a Product </title>
        </Helmet>
        <Breadcrumb title={"Tax and Shipping"} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container main-container">
            <div className="row header-text-1" style={{ paddingTop: 20 }}>
              Request for Product
            </div>

            <div className="result-container form-container-request-product">
              <div
                className="row"
                style={{
                  color: "#18768f",
                  fontWeight: "bold",
                  justifyContent: "center",
                  padding: 10,
                  fontSize: 25,
                }}
              >
                Product Information
              </div>
              <div
                className="row"
                style={{ height: 1, backgroundColor: "#18768f" }}
              />
              <div className="section-containing-container mt-2">
                <div className="flex-options">
                  <form
                    style={{ marginBottom: 20 }}
                    onSubmit={this.handleBookNowSubmit}
                  >
                    <div className="form-row mb-2 product-link">
                      <div className="col">
                        <label className="label-title">PRODUCT STORE</label>
                        <select
                          className="custom-select"
                          name="store"
                          value={this.state.store}
                          onChange={this.handleChange}
                          required
                          style={{ height: 50, color: "gray" }}
                        >
                          <option value="">Select Store</option>
                          <option value="AliExpress">AliExpress</option>
                          <option value="Gearbest">Gearbest</option>
                          <option value="Taobao">Taobao</option>
                          <option value="Flipkart">Flipkart</option>
                          <option value="Amazon (India)">Amazon (India)</option>
                          <option value="Amazon (USA)">Amazon (USA)</option>
                          <option value="Amazon (UAE)">Amazon (UAE)</option>
                          <option value="Best Buy">Best Buy</option>
                          <option value="Alibaba">Alibaba</option>
                          <option value="Pingduoduo">Pingduoduo</option>
                          <option value="Taobao (Direct)">
                            Taobao (Direct)
                          </option>
                          <option value="Walmart">Walmart</option>
                          <option value="eBay">eBay</option>
                          <option value="JLCPCB">JLCPCB</option>
                          <option value="Noon">Noon</option>
                          <option value="Myntra">Myntra</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row mb-2 product-link">
                      <div className="col">
                        <label className="label-title">ORIGIN COUNTRY</label>
                        <select
                          className="custom-select"
                          name="country"
                          value={this.state.country}
                          onChange={this.handleChange}
                          required
                          style={{ height: 50, color: "gray" }}
                        >
                          <option value="">Select Country</option>
                          <option value="china">China</option>
                          <option value="uk">UK</option>
                          <option value="usa">Usa</option>
                          <option value="hongkong">Hongkong</option>
                          <option value="thailand">Thailand</option>
                          <option value="india">India</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row mb-2 product-link">
                      <div className="col">
                        <label className="label-title">PRODUCT TYPE</label>
                        <select
                          className="custom-select"
                          name="productType"
                          value={this.state.productType}
                          onChange={this.handleChange}
                          required
                          style={{ height: 50, color: "gray" }}
                        >
                          <option value="">
                            Select Product Type (shipping rate for 5kg+)
                          </option>
                          {this.props.d2dRates.map((rate) => (
                            <option
                              value={`${rate.id}-${rate.eleven}`}
                              key={rate.id}
                            >
                              {rate.id}-{rate.eleven}Tk/Kg
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label className="label-title">PRODUCT LINK</label>
                        <input
                          style={{
                            borderRadius: 5,
                            height: 50,
                          }}
                          type="text"
                          name="productUrl"
                          value={this.state.productUrl}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="Enter product Url here"
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label className="label-title">PRODUCT QUANTITY</label>
                        <input
                          style={{
                            borderRadius: 5,
                            height: 50,
                          }}
                          type="text"
                          name="productQuantity"
                          value={this.state.productQuantity}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="Total pcs"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label className="label-title">
                          PRODUCT DESCRIPTION (Color, Size, Pc)
                        </label>
                        <textarea
                          style={{
                            borderRadius: 5,
                            height: 100,
                          }}
                          type="text"
                          name="productVariant"
                          value={this.state.productVariant}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="Ex:- (color: red, size: m, pcs:20)"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label className="label-title">UPLOAD IMAGE</label>

                        <div
                          className="box-input-file"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {this.state.image_loader ? (
                            <div
                              className="spinner-border"
                              role="status"
                              style={{
                                color: "purple",
                                paddingTop: "25px",
                                backgroundcolor: "#666",
                                width: 150,
                                height: 150,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "1px solid gainsboro",
                                borderRadius: 5,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 30,
                                  padding: 0,
                                  marginRight: 20,
                                }}
                              >
                                <CircleLoader
                                  loading={this.state.image_loader}
                                  color="blue"
                                  size={35}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <img
                                className="img-100 lazyloaded blur-up"
                                src={this.state.imageUrl}
                                alt="#"
                                style={{
                                  zIndex: 10,
                                  cursor: "pointer",
                                  maxWidth: 150,
                                  minWidth: 150,
                                  minHeight: 150,
                                  maxHeight: 150,
                                  border: "1px solid gainsboro",
                                  borderRadius: 5,
                                }}
                                onClick={() => {
                                  document
                                    .getElementById("upload-image-input")
                                    .click();
                                }}
                              />
                              <input
                                id="upload-image-input"
                                className="upload"
                                type="file"
                                style={{
                                  position: "absolute",
                                  zIndex: 5,
                                  maxWidth: "50px",
                                  marginTop: "20px",
                                }}
                                onChange={(e) => this._handleImgChange(e, 0)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="row final-booknow"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <button
                        className="btn"
                        type="submit"
                        style={{
                          fontSize: 14,
                          textAlign: "center",
                          cursor: "pointer",
                          color: "white",
                          fontWeight: "bold",
                          backgroundColor: "#18768f",
                          padding: 14,
                          width: "30%",
                          borderRadius: 5,
                          marginTop: 20,
                          minHeight: 50,
                          minWidth: 100,
                        }}
                      >
                        {!this.state.submit_loader && <>Book Now </>}
                        <HashLoader
                          loading={this.state.submit_loader}
                          color="white"
                          size={25}
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid #18768f",
                }}
              >
                <div>
                  <img
                    src={BuyAndShipForMe}
                    style={{
                      width: "100%",
                      borderRadius: 10,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    d2dRates: state.bookingRequests.d2dRates,
    currentUser: state.user.currentUser,
  };
};
export default connect(
  mapStateToProps,
  { getAllD2DRatesRedux, setBookingRequestRedux, setProductRequestRedux }
)(RequestProduct);
