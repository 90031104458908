import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Modal from "react-responsive-modal";
import "./details-price.css";
import { connect } from "react-redux";
import { getAllD2DRatesRedux } from "../../../../actions/index";
class DetailsWithPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: 1,
      selectedColor: "",
      colorUrl: "",
      selectedSize: "",
      stock: "InStock",
      nav3: null,
      warningMessage: false,
      category: "",
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount = async () => {
    this.setState({
      nav3: this.slider3,
    });
    if (this.colorsArray.length > 0) {
      this.clickOnColorVariant(
        this.colorsArray[0].image,
        this.colorsArray[0].displayName
      );
      // this.setState(
      //   {
      //     selectedColor: this.colorsArray[0],
      //   },
      //   () => {
      //     if (this.sizeArray && this.sizeArray.length > 0) {
      //       this.getSizesArray(this.state.selectedColor);
      //     }
      //   }
      // );
    }
  };

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    this.setState({ quantity: this.state.quantity + 1 });
  };

  changeQty = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state);
  };

  clickOnColorVariant = (image, selectedColor) => {
    if (image) {
      this.props.clickOnColorVariant(image);
      this.setState({ colorUrl: image });
    }

    console.log(selectedColor);
    this.setState({ selectedColor });
  };

  clickOnSizeVariant = (sizeName, sizeCm) => {
    this.setState({ selectedSize: `${sizeName}(${sizeCm})` });
  };

  BuynowClicked = (item) => {
    const { BuynowClicked, history, currentUser } = this.props;
    if (!currentUser) {
      history.push("/pages/login", {
        from: `/product/${this.props.match.params.id}`,
      });
      return;
    }
    const cartItemObj = {
      id: item.id,
      name: item.name,
      price: item.price,
      salePrice: item.salePrice,
      availability: item.availability,
      pictures: item.pictures,
      variants: false,
    };
    if (!item.variants) {
      BuynowClicked(cartItemObj, this.state.quantity);
      this.setState({
        open: false,
        quantity: 1,
        selectedColor: "",
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
      });
      history.push(`${process.env.PUBLIC_URL}/checkout`);
    } else {
      if (item.variants.options[0] && item.variants.options[1]) {
        if (this.state.selectedColor === "" || this.state.selectedSize === "") {
          this.setState({ warningMessage: true });
        } else {
          BuynowClicked(
            {
              ...cartItemObj,
              variants: true,
              color: this.state.selectedColor,
              colorUrl: this.state.colorUrl,
              sizeOrShipsFrom: this.state.selectedSize,
            },
            this.state.quantity
          );
          this.setState({
            open: false,
            quantity: 1,
            selectedColor: "",
            selectedSize: "",
            stock: "InStock",
            nav3: null,
            warningMessage: false,
            colorUrl: "",
          });
          history.push(`${process.env.PUBLIC_URL}/checkout`);
        }
      } else if (item.variants.options[0]) {
        if (this.state.selectedColor === "") {
          this.setState({ warningMessage: true });
        } else {
          BuynowClicked(
            {
              ...cartItemObj,
              variants: true,
              color: this.state.selectedColor,
              colorUrl: this.state.colorUrl,
            },
            this.state.quantity
          );
          this.setState({
            open: false,
            quantity: 1,
            selectedColor: "",
            selectedSize: "",
            stock: "InStock",
            nav3: null,
            warningMessage: false,
            colorUrl: "",
          });
          history.push(`${process.env.PUBLIC_URL}/checkout`);
        }
      }
    }
  };

  addToCartClicked = (item) => {
    const {
      currentUser,
      addToCartClicked,
      BuynowClicked,
      history,
    } = this.props;
    if (!currentUser) {
      history.push("/pages/login", {
        from: `/product/${this.props.match.params.id}`,
      });
      return;
    }
    const cartItemObj = {
      id: item.id,
      name: item.name,
      price: item.price,
      salePrice: item.salePrice,
      availability: item.availability,
      pictures: item.pictures,
      variants: false,
    };
    if (!item.variants) {
      addToCartClicked(cartItemObj, this.state.quantity);
      this.setState({
        open: false,
        quantity: 1,
        selectedColor: "",
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
      });
    } else {
      if (item.variants.options[0] && item.variants.options[1]) {
        if (this.state.selectedColor === "" || this.state.selectedSize === "") {
          this.setState({ warningMessage: true });
        } else {
          addToCartClicked(
            {
              ...cartItemObj,
              variants: true,
              color: this.state.selectedColor,
              colorUrl: this.state.colorUrl,
              sizeOrShipsFrom: this.state.selectedSize,
            },
            this.state.quantity
          );
          this.setState({
            open: false,
            quantity: 1,
            selectedColor: "",
            selectedSize: "",
            stock: "InStock",
            nav3: null,
            warningMessage: false,
            colorUrl: "",
          });
        }
      } else if (item.variants.options[0]) {
        if (this.state.selectedColor === "") {
          this.setState({ warningMessage: true });
        } else {
          addToCartClicked(
            {
              ...cartItemObj,
              variants: true,
              color: this.state.selectedColor,
              colorUrl: this.state.colorUrl,
            },
            this.state.quantity
          );
          this.setState({
            open: false,
            quantity: 1,
            selectedColor: "",
            selectedSize: "",
            stock: "InStock",
            nav3: null,
            warningMessage: false,
            colorUrl: "",
          });
        }
      }
    }
  };

  render() {
    const {
      symbol,
      item,
      BuynowClicked,
      addToWishlistClicked,
      currentUser,
      d2dRates,
    } = this.props;

    var colorsnav = {
      slidesToShow: 8,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };

    this.colorsArray = [];
    if (item.variants && item.variants.options[0]) {
      this.colorsArray = item.variants.options[0].values;
    }

    return (
      <div className="col-lg-6 rtl-text">
        <div className="product-right">
          <h6
            style={{
              color: "black",
              fontSize: "100%",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              marginBottom: "0px",
            }}
          >
            {window.innerWidth > 600
              ? item.name
              : `${item.name.slice(0, 60)}....`}
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingBottom: 10,
            }}
          >
            <p>within 30 days</p>
            <p style={{ paddingLeft: 30 }}>
              The purchase of this product is a commercial trade activity
            </p>
          </div>
          <div>
            <h6 style={{ marginBottom: "0px" }}>orders: {item.orders}</h6>
            <h6>available: {item.totalAvailableQuantity}</h6>
          </div>
          <h3 style={{ color: "#f54c3c" }}>
            {symbol}
            {item.salePrice}
            <span>
              <del>
                {symbol}
                {item.price}
              </del>
            </span>
          </h3>

          {item.variants ? (
            <div>
              <h6 className="product-title size-text">
                {item.variants.options[0].name}:{" "}
              </h6>
              <div className="row" style={{ marginBottom: 20 }}>
                {item.variants.options[0].values.map((vari, i) => {
                  return (
                    <div
                      style={{ padding: 5, cursor: "pointer" }}
                      className={
                        this.state.selectedColor == vari.displayName
                          ? "border-red"
                          : ""
                      }
                    >
                      <div
                        key={i}
                        style={{
                          maxWidth: "50px",
                          minHeight: "52px",
                          maxHeight: "52px",
                          padding: "0px",
                        }}
                        onClick={() =>
                          this.clickOnColorVariant(vari.image, vari.displayName)
                        }
                      >
                        <img
                          src={`${vari.image}`}
                          key={i}
                          alt={vari.displayName}
                          className="img-fluid color-variant-image"
                          style={{
                            maxWidth: "50px",
                            maxHeight: "50px",
                            minHeight: "50px",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="product-description border-product">
            {item ? (
              <div>
                {item.variants ? (
                  <>
                    {item.variants.options[1] ? (
                      <h6 className="product-title size-text">
                        {item.variants.options[1].name}
                      </h6>
                    ) : (
                      ""
                    )}{" "}
                  </>
                ) : (
                  ""
                )}

                <div className="size-box">
                  {item.variants ? (
                    <div className="size-category">
                      {item.variants.options[1]
                        ? item.variants.options[1].values.map((size, i) => {
                            return (
                              <div
                                className="row"
                                style={{
                                  padding: "20px 10px",
                                  display: "flex",
                                  flexDirection: "row",
                                  borderBottom: "1px dotted gainsboro",
                                }}
                                // onClick={() =>
                                //   this.clickOnSizeVariant(
                                //     size.name,
                                //     size.displayName
                                //   )
                                // }
                                key={i}
                              >
                                <div className="col-4 size-price">{`${
                                  size.name
                                }(${size.displayName})`}</div>
                                <div
                                  className="col size-price"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {item.salePrice}Tk &nbsp; &nbsp;
                                </div>
                                <div className="col-4">
                                  <div
                                    className="qty-box"
                                    style={{ marginTop: 0 }}
                                  >
                                    <div className="input-group">
                                      <span
                                        className="input-group-prepend"
                                        style={{ height: 20 }}
                                      >
                                        <button
                                          type="button"
                                          className="btn quantity-left-minus"
                                          onClick={this.minusQty}
                                          data-type="minus"
                                          data-field=""
                                        >
                                          <i className="fa fa-angle-left" />
                                        </button>
                                      </span>
                                      <input
                                        type="text"
                                        name="quantity"
                                        value={this.state.quantity}
                                        onChange={this.changeQty}
                                        className="form-control input-number"
                                        style={{ height: 20 }}
                                      />
                                      <span
                                        className="input-group-prepend"
                                        style={{ height: 20 }}
                                      >
                                        <button
                                          type="button"
                                          className="btn quantity-right-plus"
                                          onClick={this.plusQty}
                                          data-type="plus"
                                          data-field=""
                                        >
                                          <i className="fa fa-angle-right" />
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <span className="instock-cls" style={{ color: "darkgreen" }}>
              {item.availability}
            </span>
            <div className="form" style={{ marginBottom: 10, marginTop: 5 }}>
              <div className="form-group row shipping-method">
                <label style={{ marginBottom: 5, marginLeft: 13 }}>
                  Know Your Shipping cost (approx):
                </label>
                <div className="col-xl-9 col-sm-7">
                  <select
                    className="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="category"
                    value={this.state.category}
                    onChange={this.handleChange}
                    onClick={() => {
                      this.props.getAllD2DRatesRedux("air", "china");
                    }}
                  >
                    <option>Choose your product category</option>
                    {d2dRates.map((rate) => (
                      <option value={rate.id} key={rate.id}>
                        {rate.id} - {rate.ten}Tk/Kg
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <p className="service-list">
              <span>&#10003;</span> 24/7 online services for our customers via
              wechat,whatsapp, hotline and facebook. <br />
              <span>&#10003;</span> The best wholesale prices and a vast variety
              of goods.
              <br />
              <span>&#10003;</span> live tracking your product 24/7.
              <br />
              <span>&#10003;</span> 100% Secure payment with us. <br />
              <span>&#10003;</span> Fastest product delivery(around 15-20
              working days). <br />
              <span>&#10003;</span> Cheapest shipping charges around the
              country.
              <br />
              <span>&#10003;</span> Claiming 100% refund facility.{" "}
              <Link
                to={`${process.env.PUBLIC_URL}/pages/refund-policy`}
                style={{ fontSize: "80%", color: "orange" }}
              >
                see refund policy
              </Link>
              <br />{" "}
            </p>
          </div>

          {this.state.warningMessage ? (
            <div
              class="alert alert-danger"
              role="alert"
              style={{
                color: "white",
                backgroundColor: "orange",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              Please fill the missing information first
            </div>
          ) : (
            ""
          )}
          {!currentUser ? (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                color: "white",
                backgroundColor: "orange",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              You must login first
            </div>
          ) : (
            ""
          )}
          <div className="product-buttons">
            {currentUser ? (
              <>
                <a
                  className="order-now-button"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.BuynowClicked(item)}
                >
                  <i className="fa fa-shopping-bag" />
                  &nbsp;Order Now
                </a>
                <a
                  className="wishlist-btn"
                  style={{
                    padding: "9px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.addToCartClicked(item)}
                >
                  <i className="fa fa-shopping-cart" />
                  &nbsp;Add To Cart
                </a>

                <button
                  className="wishlist-btn"
                  style={{
                    padding: "7px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() => addToWishlistClicked(item)}
                >
                  <i className="fa fa-heart" />
                  <span className="title-font">&nbsp;Add To WishList</span>
                </button>
              </>
            ) : (
              <a
                className="btn btn-solid"
                onClick={() =>
                  this.props.history.push("/pages/login", {
                    from: this.props.match.url,
                  })
                }
              >
                Login
              </a>
            )}
          </div>

          <div className="border-product">
            <h6 className="product-title">product details</h6>
            {item.shortDetails ? <p>{item.shortDetails}</p> : ""}
          </div>
          <div className="border-product" style={{ paddingBottom: "30px" }}>
            <h6 className="product-title">share it</h6>
            <div className="product-icon">
              <ul className="product-social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://plus.google.com/discover" target="_blank">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
              <button
                className="wishlist-btn"
                onClick={() => addToWishlistClicked(item)}
              >
                <i className="fa fa-heart" />
                <span className="title-font">Add To WishList</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser.displayName,
  d2dRates: state.bookingRequests.d2dRates,
});
export default connect(
  mapStateToProps,
  { getAllD2DRatesRedux }
)(DetailsWithPrice);
