import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { getSingleNotice } from "../../../../firebase/firebase.utils";
class Content extends Component {
  state = { noticeObj: null };

  componentDidMount = async () => {
    console.log(this.props.match.params.noticeId);
    if (this.props.allNotices && this.props.allNotices.length > 0) {
      const noticeObj = this.props.allNotices.find(
        (notice) => notice.id == this.props.match.params.noticeId
      );

      this.setState({ noticeObj });
    } else {
      let notice = await getSingleNotice(this.props.match.params.noticeId);
      console.log(notice);
      this.setState({ noticeObj: notice });
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allNotices && nextProps.allNotices.length > 0) {
      const noticeObj = nextProps.allNotices.find(
        (notice) => notice.id == nextProps.match.params.noticeId
      );
      this.setState({ noticeObj });
    }
  };

  render() {
    const { noticeObj } = this.state;
    return (
      <>
        <main id="body-content">
          <section className="wide-tb-100">
            <div className="container pos-rel">
              <div className="row" style={{ justifyContent: "center" }}>
                <div
                  className="notice-board-container"
                  style={{
                    minHeight: "300px",
                    border: "1px solid gainsboro",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="img-icon mt-4 d-flex align-items-center"
                    style={{
                      border: "1px solid gainsboro",
                      borderRadius: "20px",
                      padding: "8px 20px",
                      background: "#18768f",
                    }}
                  >
                    <h5
                      style={{
                        margin: "auto",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      className="notice-header-text"
                    >
                      {noticeObj && noticeObj.noticeTitle}
                    </h5>
                  </div>
                  <span
                    style={{
                      float: "right",
                      color: "gray",
                      marginTop: 5,
                      fontSize: 12,
                    }}
                  >
                    Published At: {noticeObj && noticeObj.createdAt}
                  </span>
                  <div
                    style={{
                      marginTop: "40px",
                    }}
                  >
                    <pre style={{ color: "black" }}>
                      {noticeObj && noticeObj.noticeBody}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allNotices: state.notices.notices,
  };
};
export default withRouter(connect(mapStateToProps)(Content));
