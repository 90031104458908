import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./logisticsDetails.css";
import Success from "./success.png";
class OrderSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
    };
  }

  render() {
    const { currentUser } = this.props;
    console.log(currentUser);
    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}

        {/* mobile view  */}
        <section
          className="section-b-space"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div
              className="row"
              style={{ padding: 10, justifyContent: "center" }}
            >
              <img style={{ height: 100, width: 100 }} src={Success} />
            </div>
            <div
              className="row"
              style={{
                padding: 10,
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Your order is successfull!
            </div>
            <div
              className="row"
              style={{
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: 22,
              }}
            >
              {/* Order Id: {this.props.match.params.orderId} */}
            </div>
            <div
              className="row"
              style={{
                padding: 10,
                justifyContent: "center",
                color: "darkorange",
                cursor: "pointer",
              }}
              onClick={() =>
                this.props.history.push(
                  "/pages/dashboard/buy-and-ship-for-me/my-orders"
                )
              }
            >
              View all your order
              <i className="icofont-arrow-right" style={{ marginTop: 6 }} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(OrderSuccessPage);
