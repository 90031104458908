import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../../../firebase/firebase.utils";
import "./topbar.css";
import { setCurrentUser } from "../../../../actions";
import { toast } from "react-toastify";

class TopBar extends Component {
  onSignOutClick = async () => {
    await auth.signOut();
    this.props.setCurrentUser({ displayName: "", email: "", password: "" });
    toast.warn("Logged out!");
  };

  render() {
    const { currentUser } = this.props;
    return (
      <div
        className="top-header"
        style={{
          maxHeight: "3rem",
          backgroundImage: "linear-gradient(90deg,#15819e,#18768f)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="header-contact">
                <ul>
                  <li>welcome to our store Paicart</li>
                  <li>
                    <i className="fa fa-phone" aria-hidden="true" />
                    call_us: +88-01979653008
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="header-dropdown">
                <li className="mobile-wishlist">
                  <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                    <i className="fa fa-heart" aria-hidden="true" />
                    wishlist
                  </Link>
                </li>

                {currentUser.id ? (
                  <li className="onhover-dropdown mobile-account mobile-account2">
                    <i className="fa fa-user" aria-hidden="true" />
                    {(currentUser.displayName &&
                      currentUser.displayName.slice(0, 10)) ||
                      currentUser.userId}
                    <ul
                      className="profile-info-container onhover-show-div"
                      style={{ zIndex: 1000000000 }}
                    >
                      <div className="square-box" />
                      <div className="welcome-greatings">
                        Welcome to Paicart
                      </div>
                      <li style={{ marginTop: 15 }}>
                        <Link
                          to={`${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-orders`}
                          data-lng="en"
                        >
                          My Orders
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-request`}
                          data-lng="en"
                        >
                          Buy & Ship For Me
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-request`}
                          data-lng="en"
                        >
                          Ship For Me
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/information`}
                          data-lng="en"
                        >
                          Profile
                        </Link>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          backgroundColor: "#18768f",
                          borderRadius: 5,
                          marginTop: "20px",
                          marginBottom: 5,
                        }}
                      >
                        <div
                          className="sign-out-button"
                          onClick={this.onSignOutClick}
                          data-lng="en"
                          style={{
                            color: "white",
                            padding: 8,
                          }}
                        >
                          Sign out
                        </div>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="onhover-dropdown mobile-account mobile-account2">
                    <i className="fa fa-user" aria-hidden="true" /> my_account
                    <ul
                      className="profile-info-container onhover-show-div"
                      style={{ zIndex: 1000000000 }}
                    >
                      <div className="square-box" />
                      <div className="welcome-greatings">
                        Welcome to Paicart
                      </div>
                      <li
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          backgroundColor: "#18768f",
                          borderRadius: 5,
                          marginTop: "40px",
                        }}
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/login`}
                          data-lng="en"
                          style={{
                            color: "white",
                            padding: 5,
                          }}
                        >
                          Sign in
                        </Link>
                      </li>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ color: "black", fontSize: 15 }}>
                          New User?
                        </div>
                        <div>
                          <Link
                            style={{
                              color: "#18768f",
                              borderBottom: "1px solid #18768f",
                              fontWeight: "bold",
                              fontSize: 15,
                            }}
                            to={`${process.env.PUBLIC_URL}/pages/login`}
                          >
                            Sign up
                          </Link>
                        </div>
                      </div>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(
  mapStateToProps,
  { setCurrentUser }
)(TopBar);
