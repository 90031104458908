import React, { Component } from "react";
import { Link } from "react-router-dom";

import { SlideUpDown } from "../../../services/script";
import LogoImage from "../headers/common/logo";
import "./footer-one.css";
import AppStore from "./images/appStore.png";
import GooglePlay from "./images/googlePlay.png";
class FooterOne extends Component {
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  render() {
    return (
      <footer className="footer-light" style={{ marginTop: 50 }}>
        <section className="section-b-space light-layout">
          <div className="container" style={{ paddingTop: 20 }}>
            <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4 style={{ textTransform: "none" }}>About</h4>
                </div>
                <div className="footer-contant">
                  <div
                    className="footer-logo"
                    style={{ maxWidth: "70%", margin: "20px auto" }}
                  >
                    <LogoImage logo={this.props.logoName} />
                  </div>
                  <p>
                    শপিং করুন বিশ্বের যে কোন ই-কমার্স প্লাটফর্ম থেকে খুব সহজে
                    Paicart এর সাথে। আমরাই দিচ্ছি সবচেয়ে দ্রুত গতিতে পন্য
                    ডেলিভারি দেওয়ার প্রতিশ্রুতি। ফেসবুক ফ্যান পেজে লাইক দিয়ে
                    আমাদের সাথে থাকুন।
                  </p>
                  <div className="footer-social">
                    <ul>
                      <li>
                        {" "}
                        <a
                          href="https://www.facebook.com/paicart"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://youtube.com/@paicart?si=OnNIyOLuBiCTJKcA"
                          target="_blank"
                        >
                          <i className="fa fa-youtube" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://x.com/PaicartBD?t=QhryMjA31XT_9TVULYGXbQ&s=09
"
                          target="_blank"
                        >
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/paicart.com.bd?igsh=MTBzdmQxNmNubDJ6Ng==
"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://pin.it/7AhsuIiEt" target="_blank">
                          <i className="fa fa-pinterest" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col offset-xl-1">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4 style={{ textTransform: "none" }}>Why Choose us</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/privacy-policy`}
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/refund-policy`}
                        >
                          Return & Refunds
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${
                            process.env.PUBLIC_URL
                          }/pages/terms-and-coditions`}
                        >
                          Terms & conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4 style={{ textTransform: "none" }}>Help Center</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/notices`}>
                          Notices
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/faq`}>
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/how-to-order`}
                        >
                          How to Order
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${
                            process.env.PUBLIC_URL
                          }/pages/tax-and-shipping`}
                        >
                          Tax & Shipping
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4 style={{ textTransform: "none" }}>store information</h4>
                  </div>
                  <div className="footer-contant">
                    <ul className="contact-list">
                      <li>
                        <i className="fa fa-map-marker" />
                        Paicart.com, <br />
                        Suite#F2, (2nd floor) , <br />
                        Trapical Razia Shopping Complex, , <br />
                        26/1 Chamelibagh, <br />
                        Shantinagar, Dhaka 1217
                      </li>
                      <li>
                        <i className="fa fa-phone" />
                        Call Us: +88-01979653008
                      </li>
                      <li>
                        <i className="fa fa-envelope-o " />
                        Email Us:{" "}
                        <a href="#" className="style-email">
                          support@paicart.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4 style={{ textTransform: "none" }}>Download App</h4>
                  </div>
                  <div className="footer-contant">
                    <ul className="contact-list">
                      <li>
                        <a href="https://play.google.com" target="_blank">
                          <img src={GooglePlay} style={{ width: "80%" }} />
                        </a>
                      </li>
                      <li>
                        <a href="https://apps.apple.com/us" target="_blank">
                          <img src={AppStore} style={{ width: "80%" }} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer ">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="footer-end">
                  <p>
                    <i className="fa fa-copyright" aria-hidden="true" />{" "}
                    2024-2026 Paicart.com. All rights reserved.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="payment-card-bottom">
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/visa.png`}
                          alt="visacard"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/mastercard.png`}
                          alt="mastercard"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/paypal.png`}
                          alt="paypal"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/american-express.png`}
                          alt="amex"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/discover.png`}
                          alt="discover"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {" "}
              <div className="col">
                <div
                  className="footer-end"
                  style={{ paddingBottom: 20, marginTop: -13 }}
                >
                  <a
                    href="https://otcommerce.com"
                    target="_blank"
                    style={{ color: "rgb(244, 129, 16)", fontSize: 12 }}
                  >
                    <i className="fa fa-copyright" aria-hidden="true" /> Powered
                    by © OT Commerce
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterOne;
