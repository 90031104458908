import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../common/index.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllProductsFirestore,
  setSearchedProductsArray,
} from "../../../actions";
import axios from "axios";
import https from "http";
import request from "request";

// Import custom components
import Collection from "./collection";

import BlogSection from "../common/blogsection";
import HeaderThree from "../../common/headers/header-three";
import FooterTwo from "../../common/footers/footer-two";
import ThemeSettings from "../../common/theme-settings";
import CollectionTwo from "./collection2";
import {
  getAllFirestoreProducts,
  getAllFirestoreAliProductsList,
} from "../../../firebase/firebase.utils";
import "./main.css";

class Pets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarValue: "",
      shippingValue: "Ship for me",
    };
  }
  componentDidMount = async () => {
    const productsArray = await getAllFirestoreProducts();
    const aliProductsArray = await getAllFirestoreAliProductsList();
    this.props.getAllProductsFirestore([...productsArray, ...aliProductsArray]);
  };
  componentWillUnmount = () => {
    this.setState({ searchBarValue: "" });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleSearchBarSubmit = (event) => {
    event.preventDefault();
    if (this.state.searchBarValue.length < 35) {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/collection/${this.state.searchBarValue}`
      );
    } else {
      this.props.setSearchedProductsArray([]);
      if (this.state.searchBarValue.includes("1688")) {
        let productId = this.state.searchBarValue.split("/")[4].split(".")[0];
        console.log(productId);
        this.props.history.push(`${process.env.PUBLIC_URL}/1688/${productId}`);
      } else {
        let taobaoUrl = new URL(this.state.searchBarValue);
        let params = taobaoUrl.search;
        const urlParams = new URLSearchParams(params);
        const id = urlParams.get("id");
        this.props.history.push(`${process.env.PUBLIC_URL}/taobao/${id}`);
      }
    }
    this.setState({ searchBarValue: "" });
  };

  handleNextClick = () => {
    if (this.state.shippingValue == "Ship for me") {
      this.props.history.push(`${process.env.PUBLIC_URL}/pages/ship-for-me`);
    } else if (this.state.shippingValue === "Buy and ship for me") {
      this.props.history.push(`${process.env.PUBLIC_URL}/pages/buy-for-me`);
    } else {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/pages/request-for-product`
      );
    }
  };

  _handleImgChange = async (e) => {
    // let reader = new FileReader();
    // const { pictures } = this.state;
    e.preventDefault();
    let file = e.target.files[0];

    // reader.onloadend = () => {
    //   pictures[i] = reader.result;
    //   this.setState({
    //     file: file,
    //     pictures,
    //   });
    // };
    if (file) {
      // reader.readAsDataURL(file);
      // const imgUrl = await uploadImage(file);
      const _EXTERNAL_URL = `/uploadImage`;

      let data = new FormData();
      data.append("productImage", file);

      // let config = {
      //   header: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      try {
        const imgUrl = await axios.post(_EXTERNAL_URL, data);
        console.log(imgUrl.data);
        this.props.history.push(
          `${process.env.PUBLIC_URL}/collection/${imgUrl.data}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>
            GlobalbuyBD | A cross border shopping platform for Bangladeshi Smart
            Shoppers
          </title>
        </Helmet>
        {/* <HeaderThree logoName={'logo/14.png'} /> */}
        <div className="slider-inquiry-bar">
          <section className="p-0 small-slider home-page-main-slider">
            <Slider
              className="slide-1 home-slider"
              autoplay={true}
              autoplaySpeed={400}
              dots={true}
              infinite={true}
              speed={1000}
              slidesToScroll={1}
              arrows={true}
              slidesToShow={1}
            >
              <div>
                <div className="home home46">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="slider-contain" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="home home45">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="slider-contain" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </section>
          <div className="enquiry-box">
            <div className="enquiry-box-container">
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: 21,
                  fontWeight: "bold",
                  color: "#341d00",
                }}
              >
                Tell us
              </p>
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: 21,
                  fontWeight: "bold",
                  color: "#341d00",
                  marginTop: -10,
                }}
              >
                What do you need
              </p>
              <div>
                <select
                  name="shippingValue"
                  value={this.state.shippingValue}
                  onChange={this.handleChange}
                  required
                  className="custom-select select-outline-style"
                >
                  <option value="Ship for me">Ship for me</option>
                  <option value="Buy and ship for me">
                    Buy and ship for me
                  </option>
                  <option value="request a product">Request a Prouduct</option>
                </select>
                <div className="button-container-div">
                  <button
                    type="submit"
                    className="btn btn-solid"
                    style={{
                      width: "100%",
                      borderRadius: 5,
                      textAlign: "center",
                      paddingTop: 8,
                      paddingBottom: 8,
                    }}
                    onClick={() => {
                      this.handleNextClick();
                    }}
                  >
                    Next
                    <i
                      class="fa fa-arrow-right"
                      style={{ paddingLeft: 5, marginTop: -2 }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Product Section*/}
        <CollectionTwo
          type={"others"}
          status={"in-stock"}
          title="NEW COLLECTION"
          subtitle="In Stock"
        />
        {/*Product Section End*/}

        {/*Banner Section*/}
        <section
          className="pt-0 banner-6 ratio2_1"
          style={{ marginBottom: "3rem" }}
        >
          <div className="container">
            <div className="row partition3">
              <div className="col-md-4">
                <Link
                  to={`${process.env.PUBLIC_URL}/collection/womens fashion`}
                >
                  <div className="collection-banner p-left">
                    <div className="img-part">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/pets/banner/1.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        <h2>fashion</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link
                  to={`${process.env.PUBLIC_URL}/collection/mobile accessories`}
                >
                  <div className="collection-banner p-right text-right">
                    <div className="img-part">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/pets/banner/2.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        <h2>Accessorries</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to={`${process.env.PUBLIC_URL}/collection/kids fashion`}>
                  <div className="collection-banner p-left">
                    <div className="img-part">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/pets/banner/3.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        <h2>kids</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row partition3 banner-top-cls">
              <div className="col-md-4">
                <Link to={`${process.env.PUBLIC_URL}/collection/womens beauty`}>
                  <div className="collection-banner p-right">
                    <div className="img-part">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/pets/banner/4.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        <h2>beauty</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to={`${process.env.PUBLIC_URL}/collection/womens shoes`}>
                  <div className="collection-banner p-left text-left">
                    <div className="img-part">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/pets/banner/5.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        <h2>shoes</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link
                  to={`${
                    process.env.PUBLIC_URL
                  }/collection/womens & mens watches`}
                >
                  <div className="collection-banner p-left">
                    <div className="img-part">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/pets/banner/6.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        <h2>watches</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/*Banner Section End*/}

        {/*Parallax banner*/}
        <section className="p-0 pet-parallax">
          <div className="full-banner parallax parallax-banner19  text-center p-center">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="banner-contain">
                    <h4>choose our cargos</h4>
                    <h3>get upto 30% off</h3>
                    <p>
                      for bulk order of any product we also provide our customer
                      an impressive service with ALG. if you are interseted to
                      enlarge your business and want to import product from
                      outside bangladeh. we are the most safe,secure and
                      reliable cargos in Bangladesh.{" "}
                    </p>
                    <a
                      target="_blank"
                      href="http://algcargos.com"
                      className="btn btn-solid black-btn"
                      tabIndex="0"
                    >
                      visit us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Parallax banner end*/}

        {/*Product Slider*/}
        <CollectionTwo
          type={"Kids"}
          status={"pre-order"}
          title="SAVE AND EXTRA"
          subtitle="Pre Order"
        />
        {/*Product Slider End*/}
        {/* <ThemeSettings/> */}
        {/* <FooterTwo logoName={'logo/14.png'}/> */}
      </div>
    );
  }
}

export default connect(
  null,
  { getAllProductsFirestore, setSearchedProductsArray }
)(Pets);
