import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Product5 } from "../../../../services/script";
import { addToCart, addToCompare, addToWishlist } from "../../../../actions";
import ProductItem2 from "../../../layouts/homePage/product-item2";
import {
  auth,
  addCartItemTofirestore,
  addWishlistTofirestore,
} from "../../../../firebase/firebase.utils";
import "./relatedProduct.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
class RelatedProduct extends Component {
  componentDidMount() {}

  addToReduxAndFirestoreCart = (product, qty) => {
    const { addToCart } = this.props;
    auth.onAuthStateChanged(
      async (userAuth) => await addCartItemTofirestore(userAuth, product, qty)
    );
    addToCart(product, qty);
  };

  addToReduxAndFirestoreWishlist = async (product) => {
    const { addToWishlist, currentUser } = this.props;
    console.log(this.props);
    if (currentUser && currentUser.uid) {
      await addWishlistTofirestore(currentUser, product);
      toast.success("Item addded to wishilist");
    } else {
      alert("You must login first.");
    }
  };

  render() {
    const {
      items,
      topCollectionItems,
      symbol,
      addToCompare,
      title,
      subtitle,
      item,
    } = this.props;
    // console.log(this.props)
    console.log(item.relatedGroups);
    return (
      <div>
        {/*Paragraph*/}
        <section
          className="section-b-space j-box pets-box ratio_square related-group-container"
          style={{ paddingBottom: 10 }}
        >
          <div className="container">
            <div className="row">
              <div className="col similar-products-container">
                <div className="title1 title5">
                  <h2
                    className="title-inner1"
                    style={{
                      cursor: "pointer",
                      color: "#18768f",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      this.props.history.push("/collection/in-stock");
                    }}
                  >
                    {title}
                  </h2>
                  <hr role="tournament6" />
                </div>
                <Slider {...Product5} className="product-5 product-m no-arrow">
                  {item &&
                    item.relatedGroups &&
                    item.relatedGroups.length > 0 &&
                    item.relatedGroups[0].Items.slice(0, 6).map(
                      (product, index) => (
                        <div key={index}>
                          <ProductItem2
                            product={product}
                            symbol={symbol}
                            onAddToCompareClicked={() => addToCompare(product)}
                            onAddToWishlistClicked={() =>
                              this.addToReduxAndFirestoreWishlist(product)
                            }
                            onAddToCartClicked={() =>
                              this.addToReduxAndFirestoreCart(product, 1)
                            }
                            key={index}
                          />
                        </div>
                      )
                    )}
                </Slider>
                {item &&
                  item.relatedGroups &&
                  item.relatedGroups.length > 0 &&
                  item.relatedGroups[0].Items.length > 12 && (
                    <Slider
                      {...Product5}
                      className="product-5 product-m no-arrow"
                    >
                      {item.relatedGroups[0].Items.slice(6, 12).map(
                        (product, index) => (
                          <div key={index}>
                            <ProductItem2
                              product={product}
                              symbol={symbol}
                              onAddToCompareClicked={() =>
                                addToCompare(product)
                              }
                              onAddToWishlistClicked={() =>
                                this.addToReduxAndFirestoreWishlist(product)
                              }
                              onAddToCartClicked={() =>
                                this.addToReduxAndFirestoreCart(product, 1)
                              }
                              key={index}
                            />
                          </div>
                        )
                      )}
                    </Slider>
                  )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    color: "#f48110",
                  }}
                >
                  <a
                    target="_blank"
                    href={`/collection/${item.categoryId}?category_id=${
                      item.categoryId
                    }`}
                    style={{ color: "#f48110" }}
                  >
                    View all ►►
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: state.data.products
    .filter((item) => item.availability == ownProps.status)
    .slice(0, 12),
  topCollectionItems: state.data.products
    .filter((item) => item.availability == ownProps.status)
    .slice(12, 30),
  symbol: state.data.symbol,
  cartItems: state.cartList,
  currentUser: state.user.currentUser,
});

export default withRouter(
  connect(
    mapStateToProps,
    { addToWishlist, addToCart, addToCompare }
  )(RelatedProduct)
);
