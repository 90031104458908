import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getTotal, getCartProducts } from "../../../reducers";
import {
  addToCart,
  addToWishlist,
  getAllProductsFirestore,
  setSearchedProductsArray,
  emptySearchedProductsArray,
  setImgUrl,
} from "../../../actions";
import { SyncLoader, CircleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

import { getVisibleproducts } from "../../../services";
import ProductListItem from "./product-list-item";
import {
  auth,
  addCartItemTofirestore,
  addWishlistTofirestore,
  getAllFirestoreProducts,
  getAllFirestoreAliProductsList,
} from "../../../firebase/firebase.utils";
import axios from "axios";
import { Helmet } from "react-helmet";
import themeSettings from "../../common/theme-settings";
import { withRouter } from "react-router-dom";
import {
  batchSearchItemsFrameByCategoryId,
  batchSearchItemsFrameByTitle,
  batchSearchItemsFrameByVendorId,
  batchSearchItemsByImage,
  SearchRatingListItems,
} from "../../../actions/get1688Data";
import "./product-listing.css";
import { toast } from "react-toastify";
class ProductListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMoreItems: true,
      page: 0,
      notFound: false,
      products: 0,
      imageUrl: "",
    };
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const categoryId = query.get("category_id");
    const shop_name = query.get("shop_name");
    let imageUrl = query.get("imageUrl");
    console.log(imageUrl);
    imageUrl = imageUrl
      ? imageUrl
          .replace("rechargeRequests/", "rechargeRequests%2F")
          .replaceAll(" ", "%20")
      : "";
    this.setState({
      imageUrl,
    });
    console.log(categoryId);
    console.log(imageUrl);
    if (imageUrl) {
      console.log("image search.");
      try {
        const response = await batchSearchItemsByImage(
          imageUrl,
          this.state.page
        );
        console.log(response);
        if (response.length > 0) {
          this.setState({ products: response.length });
          this.props.setSearchedProductsArray(20, response);
        } else {
          // this.props.emptySearchedProductsArray();
          setTimeout(() => {
            this.setState({ notFound: true });
          }, 10000);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (shop_name) {
      const response = await batchSearchItemsFrameByVendorId(
        this.props.match.params.id,
        this.state.page
      );
      console.log(response);
      if (response.length > 0) {
        this.setState({ products: response.length });
        this.props.setSearchedProductsArray(20, response);
      } else {
        // this.props.emptySearchedProductsArray();
        setTimeout(() => {
          this.setState({ notFound: true });
        }, 10000);
      }
    } else if (categoryId) {
      if (categoryId == "Last" || categoryId == "Popular") {
        const response = await SearchRatingListItems(
          categoryId,
          this.state.page
        );
        console.log(response);
        if (response.length > 0) {
          this.setState({ products: response.length });
          this.props.setSearchedProductsArray(20, response);
        } else {
          // this.props.emptySearchedProductsArray();
          setTimeout(() => {
            this.setState({ notFound: true });
          }, 10000);
        }
      } else {
        const response = await batchSearchItemsFrameByCategoryId(
          this.props.match.params.id,
          this.state.page
        );
        console.log(response);
        if (response.length > 0) {
          this.setState({ products: response.length });
          this.props.setSearchedProductsArray(20, response);
        } else {
          // this.props.emptySearchedProductsArray();
          setTimeout(() => {
            this.setState({ notFound: true });
          }, 10000);
        }
      }
    } else {
      console.log(this.props.shop);
      console.log(this.props.match.params.id);
      const response = await batchSearchItemsFrameByTitle(
        this.props.match.params.id,
        this.state.page
      );
      console.log(response);
      if (response && response.length > 0) {
        this.setState({ products: response.length });
        this.props.setSearchedProductsArray(20, response);
      } else {
        // this.props.emptySearchedProductsArray();
        setTimeout(() => {
          this.setState({ notFound: true });
        }, 10000);
      }
    }
  };
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    console.log(nextProps.filterByCategory);

    if (nextProps.imageUrl && nextProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        hasMoreItems: true,
        page: 0,
        notFound: false,
        products: 0,
      });
      this.props.setSearchedProductsArray(0, []);

      this.props.setLoader(true);
      const query = new URLSearchParams(nextProps.location.search);
      const categoryId = query.get("category_id");
      const shop_name = query.get("shop_name");
      let imageUrl = query.get("imageUrl");
      imageUrl = imageUrl
        ? imageUrl
            .replace("rechargeRequests/", "rechargeRequests%2F")
            .replaceAll(" ", "%20")
        : "";
      this.setState({
        imageUrl,
      });
      console.log(categoryId);
      console.log(imageUrl);
      if (imageUrl) {
        console.log("image search.");
        try {
          const response = await batchSearchItemsByImage(
            imageUrl,
            this.state.page
          );
          console.log(response);
          if (response.length > 0) {
            this.setState({ products: response.length });
            this.props.emptySearchedProductsArray();
            this.props.setSearchedProductsArray(20, response);
          } else {
            setTimeout(() => {
              this.setState({ notFound: true });
            }, 10000);
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.props.setLoader(false);
    }

    if (
      nextProps.filterByCategory &&
      this.props.filterByCategory !== nextProps.filterByCategory
    ) {
      this.setState({
        hasMoreItems: true,
        page: 0,
        notFound: false,
        products: 0,
      });
      this.props.setSearchedProductsArray(0, []);

      this.props.setLoader(true);
      const query = new URLSearchParams(nextProps.location.search);
      const categoryId = query.get("category_id");
      const shop_name = query.get("shop_name");
      let imageUrl = query.get("imageUrl");
      imageUrl = imageUrl
        ? imageUrl
            .replace("rechargeRequests/", "rechargeRequests%2F")
            .replaceAll(" ", "%20")
        : "";
      this.setState({
        imageUrl,
      });
      console.log(categoryId);
      console.log(imageUrl);
      if (imageUrl) {
        console.log("image search.");
        try {
          const response = await batchSearchItemsByImage(
            imageUrl,
            this.state.page,
            nextProps.filterByCategory
          );
          console.log(response);
          if (response.length > 0) {
            this.setState({ products: response.length });
            this.props.emptySearchedProductsArray();
            this.props.setSearchedProductsArray(20, response);
          } else {
            setTimeout(() => {
              this.setState({ notFound: true });
            }, 10000);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (shop_name) {
        const response = await batchSearchItemsFrameByVendorId(
          this.props.match.params.id,
          this.state.page,
          nextProps.filterByCategory
        );
        console.log(response);
        if (response.length > 0) {
          this.setState({ products: response.length });
          this.props.emptySearchedProductsArray();
          this.props.setSearchedProductsArray(20, response);
        } else {
          setTimeout(() => {
            this.setState({ notFound: true });
          }, 10000);
        }
      } else if (categoryId) {
        if (categoryId == "Last" || categoryId == "Popular") {
          const response = await SearchRatingListItems(
            categoryId,
            this.state.page
          );
          console.log(response);
          if (response.length > 0) {
            this.setState({ products: response.length });
            this.props.emptySearchedProductsArray();
            this.props.setSearchedProductsArray(20, response);
          } else {
            setTimeout(() => {
              this.setState({ notFound: true });
            }, 10000);
          }
        } else {
          const response = await batchSearchItemsFrameByCategoryId(
            this.props.match.params.id,
            this.state.page,
            nextProps.filterByCategory
          );
          console.log(response);
          if (response.length > 0) {
            this.setState({ products: response.length });
            this.props.emptySearchedProductsArray();
            this.props.setSearchedProductsArray(20, response);
          } else {
            setTimeout(() => {
              this.setState({ notFound: true });
            }, 10000);
          }
        }
      } else {
        console.log(this.props.shop);
        console.log(this.props.match.params.id);
        const response = await batchSearchItemsFrameByTitle(
          this.props.match.params.id,
          this.state.page,
          nextProps.filterByCategory
        );
        console.log(response);
        if (response && response.length > 0) {
          this.setState({ products: response.length });
          this.props.emptySearchedProductsArray();
          this.props.setSearchedProductsArray(20, response);
        } else {
          setTimeout(() => {
            this.setState({ notFound: true });
          }, 10000);
        }
      }
      this.props.setLoader(false);
    }
  };

  getProducts = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const categoryId = query.get("category_id");
    const shop_name = query.get("shop_name");
    if (this.state.imageUrl) {
      try {
        const response = await batchSearchItemsByImage(
          this.state.imageUrl,
          this.state.page,
          this.props.filterByCategory ? this.props.filterByCategory : null
        );
        console.log(response);
        if (response.length > 0) {
          this.setState({ products: response.length });
          this.props.setSearchedProductsArray(
            parseInt(this.props.totalResults) + response.length,
            response
          );
        } else {
          // this.props.emptySearchedProductsArray();
          setTimeout(() => {
            this.setState({ notFound: true });
          }, 10000);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (shop_name) {
      const response = await batchSearchItemsFrameByVendorId(
        this.props.match.params.id,
        this.state.page,
        this.props.filterByCategory ? this.props.filterByCategory : null
      );
      console.log(response);
      if (response.length > 0) {
        this.setState({ products: response.length });
        this.props.setSearchedProductsArray(
          parseInt(this.props.totalResults) + response.length,
          response
        );
      } else {
        // this.props.emptySearchedProductsArray();
        setTimeout(() => {
          this.setState({ hasMoreItems: false });
        }, 10000);
      }
    } else if (categoryId) {
      if (categoryId == "Last" || categoryId == "Popular") {
        const response = await SearchRatingListItems(
          categoryId,
          this.state.page
        );
        console.log(response);
        if (response.length > 0) {
          this.setState({ products: response.length });
          this.props.setSearchedProductsArray(
            parseInt(this.props.totalResults) + response.length,
            response
          );
        } else {
          // this.props.emptySearchedProductsArray();
          setTimeout(() => {
            this.setState({ hasMoreItems: false });
          }, 10000);
        }
      } else {
        const response = await batchSearchItemsFrameByCategoryId(
          this.props.match.params.id,
          this.state.page,
          this.props.filterByCategory ? this.props.filterByCategory : null
        );
        console.log(response);
        if (response.length > 0) {
          this.setState({ products: response.length });
          this.props.setSearchedProductsArray(
            parseInt(this.props.totalResults) + response.length,
            response
          );
        } else {
          // this.props.emptySearchedProductsArray();
          setTimeout(() => {
            this.setState({ hasMoreItems: false });
          }, 10000);
        }
      }
    } else {
      console.log(this.props.shop);
      console.log(this.props.match.params.id);
      const response = await batchSearchItemsFrameByTitle(
        this.props.match.params.id,
        this.state.page,
        this.props.filterByCategory ? this.props.filterByCategory : null
      );
      console.log(response);
      if (response.length > 0) {
        this.setState({ products: response.length });
        this.props.setSearchedProductsArray(
          parseInt(this.props.totalResults) + response.length,
          response
        );
      } else {
        // this.props.emptySearchedProductsArray();
        setTimeout(() => {
          this.setState({ hasMoreItems: false });
        }, 10000);
      }
    }
  };

  addToReduxAndFirestoreCart = (product, qty) => {
    const { addToCart } = this.props;
    auth.onAuthStateChanged(
      async (userAuth) => await addCartItemTofirestore(userAuth, product, qty)
    );
    addToCart(product, qty);
  };

  addToReduxAndFirestoreWishlist = async (product) => {
    const { addToWishlist, currentUser } = this.props;
    console.log(this.props);
    if (currentUser && currentUser.uid) {
      await addWishlistTofirestore(currentUser, product);
      toast.success("Item addded to wishilist");
    } else {
      alert("You must login first.");
    }
  };

  getData = async () => {
    if (this.props.totalResults < 300) {
      await this.getProducts();
    } else {
      this.setState({
        hasMoreItems: false,
      });
    }
  };

  fetchData = () => {
    const { products, totalResults } = this.props;
    console.log("fetch data is called");
    if (this.state.hasMoreItems) {
      this.setState({ page: parseInt(this.state.page) + 50 }, () => {
        console.log("fetch data is called");
        this.getData();
      });
    } else {
      this.setState({ hasMoreItems: false });
    }
  };

  render() {
    const { products, symbol } = this.props;
    console.log(products);

    let renderableProducts = [];
    if (products.length > 0) {
      renderableProducts = products;
    }
    return (
      <div style={{ paddingLeft: 5, paddingRight: 5 }}>
        <Helmet>
          <title>{this.props.match.params.id}</title>
        </Helmet>

        <div className="product-wrapper-grid">
          <div className="container-fluid">
            {!this.state.notFound ? (
              <InfiniteScroll
                className="row"
                dataLength={renderableProducts.length} //This is important field to render the next data
                next={this.fetchData}
                hasMore={this.state.hasMoreItems}
                loader={
                  <div className="sync-loader-container">
                    <SyncLoader loading={true} color="#18768f" size={25} />
                  </div>
                }
                endMessage={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <b>Yay! You have seen it all</b>
                  </div>
                }
              >
                {renderableProducts.map((product, index) => (
                  <div
                    className={`${
                      this.props.colSize === 3
                        ? "col-xl-3 col-md-6 col-grid-box"
                        : "col-lg-" + this.props.colSize
                    } product-container-col`}
                    key={index}
                  >
                    <ProductListItem
                      product={product}
                      symbol={symbol}
                      onAddToWishlistClicked={() =>
                        this.addToReduxAndFirestoreWishlist(product)
                      }
                      onAddToCartClicked={() =>
                        this.addToReduxAndFirestoreCart(product, 1)
                      }
                      key={index}
                      shop={this.props.shop}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/empty-search.jpg`}
                    className="img-fluid mb-4"
                  />
                  <h3>
                    Sorry! Couldn't find the product you were looking For!!!{" "}
                  </h3>
                  <p>
                    Please check if you have misspelt something or try searching
                    with other words.
                  </p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-solid"
                  >
                    continue shopping
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    products: state.searchedProducts.products,
    symbol: state.data.symbol,
    totalResults: state.searchedProducts.totalResults,
    currentUser: state.user.currentUser,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      addToCart,
      addToWishlist,

      getAllProductsFirestore,
      setSearchedProductsArray,
      emptySearchedProductsArray,
      setImgUrl,
    }
  )(ProductListing)
);
