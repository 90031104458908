import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  updateShipmentRequestRedux,
  setShipForMeRedux,
} from "../../../../actions/index";
import "./makePaymentModal.css";
import { uploadImageRechargeRequest } from "../../../../firebase/firebase.utils";
import { CircleLoader } from "react-spinners";
import man from "./assets/plus image.jpeg";
import axios from "axios";
import "./paymentModal.css";

class AddTrackingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingObj: null,
      method: "",
      submit_loader: false,
      loading: false,
      file: "",
      imageUrl: man,
      trackingNo: "",
      trackingFields: [{ trackingNo: "" }],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.bookingObj) {
      this.setState({
        bookingObj: nextProps.bookingObj,
        trackingNo: nextProps.bookingObj.trackingNo,
      });
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    console.log(file);
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    console.log(this.state);
    if (this.state.loading) {
      alert("Wait let your waybill image upload to finish.");
      return;
    }
    if (this.state.trackingFields.length == 1) {
      await this.props.updateShipmentRequestRedux({
        ...this.props.bookingObj,
        trackingNo: this.state.trackingFields[0].trackingNo,
        waybillImage: this.state.imageUrl == man ? "" : this.state.imageUrl,
      });
    } else {
      this.state.trackingFields.forEach(async (trackingField, index) => {
        if (index == 0) {
          await this.props.updateShipmentRequestRedux({
            ...this.props.bookingObj,
            trackingNo: trackingField.trackingNo,
            waybillImage: this.state.imageUrl == man ? "" : this.state.imageUrl,
          });
        } else {
          await this.props.setShipForMeRedux({
            ...this.props.bookingObj,
            bookingId: this.props.bookingObj.bookingId + index + 1,
            trackingNo: trackingField.trackingNo,
            waybillImage: this.state.imageUrl == man ? "" : this.state.imageUrl,
          });
        }
      });
    }

    toast.success("successfully updated tracking no");

    this.setState({
      bookingObj: null,
      method: "",
      submit_loader: false,
      loading: false,
      trackingNo: "",
      imageUrl: man,
    });
    this.props.startTrackingToggleModal(null);
  };

  render() {
    const { email, password, bookingObj } = this.state;

    return (
      <>
        <div
          className={
            this.props.toggleTrackingModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div
              className="modal-content final-payment-modal"
              style={{
                backgroundColor: "rgb(1, 70, 109)",
                overflowY: "scroll",
                maxHeight: 600,
              }}
            >
              <div className="modal-body p-0">
                <section
                  className="pos-rel bg-light-gray"
                  style={{ paddingTop: 0 }}
                >
                  <div
                    className="container-fluid make-payment-container"
                    style={{ backgroundColor: "rgb(1, 70, 109)" }}
                  >
                    <a
                      id="modal-close-icon-payment-modal-3"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.props.startTrackingToggleModal(null);
                        this.setState({
                          trackingFields: [{ trackingNo: "" }],
                        });
                      }}
                    >
                      <i
                        className="icofont-close-line"
                        style={{
                          color: "white",
                          fontWeight: "bolder",
                          paddingRight: 10,
                        }}
                      />
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      {/* <div className="col bg-fixed bg-img-7 request_pag_img">
                        &nbsp;
                      </div> */}

                      <div className="col">
                        <div className="m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontSize: "200%",
                              borderBottom: "2px dotted white",
                            }}
                          >
                            Booking Id: {bookingObj && bookingObj.bookingId}
                          </h2>
                          <form
                            onSubmit={this.handleSubmit}
                            className="rounded-field mt-4"
                          >
                            <div
                              style={{
                                color: "white",
                                marginBottom: 0,
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              Tracking No
                            </div>
                            {this.state.trackingFields.map(
                              (trackingField, index) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    maxWidth: "60%",
                                    marginTop: 5,
                                  }}
                                  key={index}
                                >
                                  <input
                                    type="text"
                                    name={trackingField.trackingNo}
                                    className="form-control"
                                    placeholder={`Enter tracking No.${index +
                                      1}`}
                                    style={{
                                      fontSize: ".8rem",
                                      padding: 10,
                                      borderRadius: 5,
                                      border: "2px solid gainsboro",
                                    }}
                                    onChange={(e) => {
                                      const { value, name } = e.target;
                                      this.setState({
                                        trackingFields: this.state.trackingFields.map(
                                          (trackin, i) => {
                                            if (i == index) {
                                              return { trackingNo: value };
                                            } else {
                                              return trackin;
                                            }
                                          }
                                        ),
                                      });
                                    }}
                                    value={trackingField.trackingNo}
                                  />
                                  {index + 1 ==
                                  this.state.trackingFields.length ? (
                                    <span
                                      style={{
                                        padding: 10,
                                        fontSize: ".8rem",
                                        borderRadius: 5,
                                        background: "white",
                                        color: "gray",
                                        fontWeight: "bold",
                                        marginLeft: 6,
                                        border: "2px solid gainsboro",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          trackingFields: [
                                            ...this.state.trackingFields,
                                            { trackingNo: "" },
                                          ],
                                        });
                                      }}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        padding: 10,
                                        fontSize: ".8rem",
                                        borderRadius: 5,
                                        background: "rgb(1, 70, 109)",
                                        color: "rgb(1, 70, 109)",
                                        fontWeight: "bold",
                                        marginLeft: 6,
                                        border: "2px solid rgb(1, 70, 109)",
                                      }}
                                    >
                                      +
                                    </span>
                                  )}
                                </div>
                              )
                            )}

                            <div
                              className="form-row mb-1"
                              style={{
                                fontSize: "130%",
                                color: "white",
                                fontWeight: "bold",
                                backgroundColor: "transparent",
                                marginTop: 10,
                              }}
                            >
                              <div>
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: 0,
                                    fontSize: 16,
                                  }}
                                >
                                  Upload waybill Image (optional)
                                </label>
                                <div
                                  className="box-input-file"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  {this.state.loading ? (
                                    <div
                                      className="spinner-border"
                                      role="status"
                                      style={{
                                        color: "purple",
                                        paddingTop: "25px",
                                        backgroundColor: "white",
                                        width: 150,
                                        height: 150,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        border: "1px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginTop: 30,
                                          padding: 0,
                                          marginRight: 20,
                                        }}
                                      >
                                        <CircleLoader
                                          loading={this.state.loading}
                                          color="blue"
                                          size={35}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        className="img-100 lazyloaded blur-up"
                                        src={this.state.imageUrl}
                                        alt="#"
                                        style={{
                                          zIndex: 6,
                                          cursor: "pointer",
                                          maxWidth: 150,
                                          minWidth: 150,
                                          minHeight: 150,
                                          maxHeight: 150,
                                          border: "1px solid gainsboro",
                                          borderRadius: 5,
                                        }}
                                        onClick={() => {
                                          document
                                            .getElementById(
                                              "upload-image-input-waybill"
                                            )
                                            .click();
                                        }}
                                      />
                                      <input
                                        id="upload-image-input-waybill"
                                        className="upload"
                                        type="file"
                                        style={{
                                          position: "absolute",
                                          zIndex: 5,
                                          maxWidth: "50px",
                                          marginTop: "20px",
                                        }}
                                        onChange={(e) =>
                                          this._handleImgChange(e, 0)
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-row">
                              <div
                                className="col pt-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  type="submit"
                                  className="btn"
                                  style={{
                                    backgroundColor: "#ff4c3b",
                                    padding: 10,
                                    borderRadius: 4,
                                    color: "white",
                                  }}
                                >
                                  Update
                                  <i className="icofont-rounded-right" />
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.user.currentUser,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {
      updateShipmentRequestRedux,
      setShipForMeRedux,
    }
  )(AddTrackingModal)
);
