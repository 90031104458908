import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addToOrdersApiRedux } from "../../actions/index";
import {
  uploadImageRechargeRequest,
  addToOrdersApi,
  getAllPartials,
} from "../../firebase/firebase.utils";

import sslCommerze from "./user-profile/buy-and-ship-for-me/assets/ssl commerze.png";
import "./user-profile/buy-and-ship-for-me/makePaymentModal.css";
import { CircleLoader } from "react-spinners";
import man from "./user-profile/buy-and-ship-for-me/assets/plus image.jpeg";
import axios from "axios";
import "./paymentModal.css";
import "./discountModal.css";
class PaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      method: "",
      submit_loader: false,
      file: "",
      loading: false,
      imageUrl: man,
      offer: "3",
    };
  }

  componentDidMount = () => {
    const { partials } = this.props;

    if (partials.length > 0) {
      this.setState({
        offer: `${partials[0].payNow100}%`,
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { partials } = nextProps;
    if (nextProps.pendingOrders && nextProps.pendingOrders.length > 0) {
      const invoiceArray = nextProps.pendingOrders;
      this.setState({
        invoiceArray,
      });
    }
    if (partials.length > 0 && this.props.partials.length != partials.length)
      this.setState({
        offer: `${partials[0].payNow100}%`,
      });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  readyForSslCheckout = async (total) => {
    const { currentUser } = this.props;
    this.setState({
      submit_loader: true,
    });
    const data = {
      name: `${currentUser.userId}-${currentUser.displayName}`,
      amount: Math.round(total),
      from:
        window.location.pathname == "/user-my-invoice-express"
          ? "express"
          : "d2d",
    };
    const response = await axios.post(
      "http://localhost:5000/init-sslCommerz",
      data
    );
    if (response.data && response.data.length > 30) {
      window.location.replace(response.data);
    } else {
      alert("checkout failed. Try again later.");
    }

    this.setState({ submit_loader: false });
  };

  render() {
    const { email, password, invoiceArray } = this.state;
    const { currency, pendingOrders, couponAmount, partials } = this.props;
    const invoicesToPay = invoiceArray;
    let total = 0;
    pendingOrders.map((shop) =>
      shop.items.map((item) =>
        item.skus.map((sku) => {
          total += parseInt(
            parseFloat(sku.price) * parseInt(sku.totalQuantity)
          );
        })
      )
    );
    total = total - couponAmount;
    console.log(pendingOrders);
    console.log(total);
    let partial = null;
    if (partials.length > 0) {
      partial = partials[0];
    }
    return (
      <>
        {partial && (
          <div
            className={
              this.props.toggleModal2
                ? "modal fade show"
                : "modal fade show visible-modal"
            }
            id="request_payment_popup"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered request_popup"
              role="document"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <div
                className="modal-content final-payment-modal"
                style={{
                  backgroundColor: "white",
                  overflowY: "scroll",
                  maxHeight: 600,
                }}
              >
                <div className="modal-body p-0">
                  <section
                    className="pos-rel bg-light-gray"
                    style={{ paddingTop: 0 }}
                  >
                    <div className="container-fluid make-payment-container">
                      <a
                        id="modal-close-icon-payment-modal-2"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          this.props.startToggleModal2(null);
                        }}
                      >
                        <i
                          className="icofont-close-line"
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            paddingRight: 5,
                          }}
                        />
                      </a>
                      <div className="d-lg-flex justify-content-center no-gutters mb-spacer-md">
                        <div className="col">
                          <div className="px-3" style={{ paddingRight: 0 }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="logo-image-offer"
                                src={"/assets/images/icon/logo/14.png"}
                              />
                            </div>

                            <div className="offer-title">
                              যত বেশী পেমেন্ট,তত বেশী অফার
                            </div>
                            <div
                              style={{
                                borderBottom: "1px solid gainsboro",
                                paddingBottom: 20,
                              }}
                            >
                              <div className="offer-pay-now">
                                Total Tk{" "}
                                {Math.round(
                                  total -
                                    (total * this.state.offer.split("%")[0]) /
                                      100
                                )}{" "}
                                <span>{this.state.offer} discount added</span>
                              </div>
                              <div className="offer-pay-now">
                                Pay Now (
                                {this.state.offer == `${partial.payNow100}%`
                                  ? "100%"
                                  : this.state.offer == `${partial.payNow80}%`
                                  ? "80%"
                                  : "60%"}
                                ) Tk{" "}
                                {Math.round(
                                  this.state.offer == `${partial.payNow100}%`
                                    ? total -
                                        total *
                                          (this.state.offer.split("%")[0] / 100)
                                    : this.state.offer == `${partial.payNow80}%`
                                    ? total * (80 / 100)
                                    : total * (60 / 100)
                                )}{" "}
                              </div>
                              <div className="offer-range-text">
                                নিচের রেঞ্জ থেকে আপনার অগ্রীম পেমেন্ট পার্সেন্ট
                                সিলেক্ট করুন
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  margin: "20px 0px",
                                  marginTop: 5,
                                }}
                              >
                                <div
                                  style={{
                                    padding: 15,
                                    borderRadius: 10,
                                    marginRight: 10,
                                    background:
                                      this.state.offer == `${partial.payNow60}%`
                                        ? "#18768f"
                                        : "#f1f1f1",
                                    color:
                                      this.state.offer == `${partial.payNow60}%`
                                        ? "white"
                                        : "#555",
                                    cursor: "pointer",
                                  }}
                                  className="offer-box-text"
                                  onClick={() => {
                                    this.setState({
                                      offer: `${partial.payNow60}%`,
                                    });
                                  }}
                                >
                                  Pay Now 60% <br />
                                  {partial.payNow60}% OFF
                                </div>
                                <div
                                  style={{
                                    padding: 15,
                                    borderRadius: 10,
                                    background:
                                      this.state.offer == `${partial.payNow80}%`
                                        ? "#18768f"
                                        : "#f1f1f1",
                                    color:
                                      this.state.offer == `${partial.payNow80}%`
                                        ? "white"
                                        : "#555",
                                    cursor: "pointer",
                                  }}
                                  className="offer-box-text"
                                  onClick={() => {
                                    this.setState({
                                      offer: `${partial.payNow80}%`,
                                    });
                                  }}
                                >
                                  Pay Now 80% <br />
                                  {partial.payNow80}% OFF
                                </div>
                                <div
                                  style={{
                                    padding: 15,
                                    borderRadius: 10,
                                    marginLeft: 10,
                                    background:
                                      this.state.offer ==
                                      `${partial.payNow100}%`
                                        ? "#18768f"
                                        : "#f1f1f1",
                                    color:
                                      this.state.offer ==
                                      `${partial.payNow100}%`
                                        ? "white"
                                        : "#555",
                                    cursor: "pointer",
                                  }}
                                  className="offer-box-text"
                                  onClick={() => {
                                    this.setState({
                                      offer: `${partial.payNow100}%`,
                                    });
                                  }}
                                >
                                  Pay Now 100% <br />
                                  {partial.payNow100}% OFF
                                </div>
                              </div>
                              <div style={{ marginTop: 50 }}>
                                <button
                                  className="btn"
                                  style={{
                                    margin: "auto",
                                    display: "flex",
                                    backgroundColor: "#f54c3c",
                                    color: "white",
                                    padding: 10,
                                    borderRadius: 5,
                                  }}
                                  onClick={async () => {
                                    this.props.startToggleModal2("proceed");
                                    this.props.startToggleModal(
                                      pendingOrders,
                                      Math.round(
                                        total -
                                          total *
                                            (this.state.offer.split("%")[0] /
                                              100)
                                      ),
                                      Math.round(
                                        this.state.offer ==
                                          `${partial.payNow100}%`
                                          ? total -
                                              total *
                                                (this.state.offer.split(
                                                  "%"
                                                )[0] /
                                                  100)
                                          : this.state.offer ==
                                            `${partial.payNow80}%`
                                          ? total * (80 / 100)
                                          : total * (60 / 100)
                                      ),
                                      this.state.offer,
                                      this.state.offer ==
                                        `${partial.payNow100}%`
                                        ? "100%"
                                        : this.state.offer ==
                                          `${partial.payNow80}%`
                                        ? "80%"
                                        : "60%"
                                    );
                                  }}
                                >
                                  Proceed to Pay
                                </button>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: 20,
                              }}
                            >
                              <img
                                src={sslCommerze}
                                alt=""
                                style={{
                                  maxWidth: "70%",
                                }}
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.user.currentUser,
    currency: state.user.currency,
    // pendingOrders: state.cartList.pendingOrders,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {
      addToOrdersApiRedux,
    }
  )(PaymentModal)
);
