import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class IntroModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="request_intro_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal bg-fixed request-pag-img"
              style={{
                boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                height: window.screen.width > 700 ? "700px" : "500px",
                width: window.screen.width > 700 ? "600px" : "400px",
                backgroundImage: `url(${this.props.introModal})`,
                margin: "auto",
                backgroundSize: "cover",
              }}
            >
              <div className="modal-body p-0">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="modal-close-icon-login"
                >
                  <i
                    className="icofont-close-line"
                    style={{ color: "white" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    introModal: state.user.introModal,
  };
};
export default withRouter(connect(mapStateToProps)(IntroModal));
