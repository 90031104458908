import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../myOrders.css";
class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
    };
  }

  render() {
    const { currentUser } = this.props;
    console.log(currentUser);
    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section
          className="section-b-space main-container-cart"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col-3 left-list-column">
                <div className="left-list-container">
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Buy & Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-request`
                          );
                        }}
                      >
                        My Request Products
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-orders`
                          );
                        }}
                      >
                        My Orders
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-request`
                          );
                        }}
                      >
                        My Booking
                      </p>

                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Profile
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/information`
                          );
                        }}
                      >
                        Information
                      </p>
                      <p
                        className="each-option"
                        style={{ color: "#f54c3c" }}
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/security`
                          );
                        }}
                      >
                        Security
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col right-detail-column">
                {/* when no order is found  */}
                <div className="main-order-section">
                  <div className="col-sm-12">
                    <div>
                      <div
                        className="col-sm-12 empty-cart-cls text-center"
                        style={{ marginTop: 50 }}
                      >
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/empty-search.jpg`}
                          className="img-fluid mb-4"
                          alt=""
                        />
                        <h3 style={{ paddingBottom: 50 }}>
                          <strong>Our team is working on this.</strong>
                        </h3>
                      </div>
                    </div>
                    <div style={{ paddingBottom: 40, textAlign: "center" }}>
                      <button
                        className="btn btn-solid"
                        style={{ padding: 6, fontSize: 10, borderRadius: 5 }}
                      >
                        Coming soon
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div>
              <div className="d-flex flex-row main-navigator-option">
                <div className="buy-ship-for-me-mobile">
                  <div>Buy & Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-request`
                        );
                      }}
                    >
                      My Request Products
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-orders`
                        );
                      }}
                    >
                      My Orders
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 160 }}>
                  <div>Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-request`
                        );
                      }}
                    >
                      My Booking
                    </div>

                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div
                  style={{ minWidth: 100, borderBottom: "2px solid #f54c3c" }}
                >
                  <div>Profile</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/information`
                        );
                      }}
                    >
                      Information
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        paddingBottom: 10,
                        color: "#f54c3c",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/security`
                        );
                      }}
                    >
                      Security
                    </div>
                  </div>
                </div>
                <div />
                <div />
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row search-bar-row-1" />
                {/* when no order is found  */}
                <div className="main-order-section">
                  <div className="col-sm-12">
                    <div>
                      <div
                        className="col-sm-12 empty-cart-cls text-center"
                        style={{ marginTop: 50 }}
                      >
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/empty-search.jpg`}
                          className="img-fluid mb-4"
                          alt=""
                        />
                        <h3 style={{ paddingBottom: 50 }}>
                          <strong>Our team is working on this. </strong>
                        </h3>
                      </div>
                    </div>
                    <div style={{ paddingBottom: 40, textAlign: "center" }}>
                      <button
                        className="btn btn-solid"
                        style={{ padding: 6, fontSize: 10, borderRadius: 5 }}
                      >
                        Coming soon
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(Security);
