import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./myOrders.css";
class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
    };
  }

  render() {
    const { currentUser } = this.props;
    console.log(currentUser);
    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    style={{
                      fontSize: 9,
                      color: "darkorange",
                      maxWidth: 60,
                      paddingBottom: 8,
                      fontWeight: "bold",
                    }}
                  >
                    Goods to be received
                  </div>
                </div>
                <div
                  className="user-info"
                  style={{
                    backgroundImage: `url("assets/images/place-order-header.jpg")`,
                  }}
                >
                  <div style={{ color: "gray", fontSize: 10 }}>
                    shipping address:{" "}
                  </div>
                  <div style={{ paddingLeft: 20 }}>
                    <div style={{ fontSize: 10 }}>
                      <i
                        className="icofont-user-male"
                        style={{ color: "gainsboro" }}
                      />{" "}
                      MSBINT
                    </div>
                    <div style={{ fontSize: 10 }}>
                      <i
                        className="icofont-location-pin"
                        style={{ color: "gainsboro" }}
                      />{" "}
                      suit 6/a, 37/2 Pritom-Zaman Tower, 10th Floor, suite 6A,
                      Culvert Road, Dhaka-1000. Bangladesh
                    </div>
                    <div style={{ fontSize: 10 }}>
                      <>
                        <i
                          className="icofont-telephone"
                          style={{ color: "gainsboro" }}
                        />{" "}
                        510000
                      </>
                      <>
                        <i
                          className="icofont-smart-phone"
                          style={{ color: "gainsboro", marginLeft: 10 }}
                        />{" "}
                        +8801641103558
                      </>
                    </div>
                    <div style={{ fontSize: 10, marginTop: 3, color: "gray" }}>
                      change the &nbsp; &nbsp; &nbsp; use of temporary address
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: 13,
                    backgroundColor: "white",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <i
                      className="icofont-truck-alt"
                      style={{ fontSize: 30, color: "gray" }}
                    />
                    &nbsp;{" "}
                    <div style={{ marginTop: 5, color: "black" }}>
                      Order shipment status
                    </div>
                  </div>
                  <div style={{ marginTop: 3 }}>
                    <i className="icofont-arrow-right" />
                  </div>
                </div>

                {/* when no order is found  */}
                {/* <div className="main-order-section">
                   <div className="col-sm-12">
                    <div>
                      <div
                        className="col-sm-12 empty-cart-cls text-center"
                        style={{ marginTop: 50 }}
                      >
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/empty-search.jpg`}
                          className="img-fluid mb-4"
                          alt=""
                        />
                        <h3 style={{ paddingBottom: 50 }}>
                          <strong>You haven't make any Orders yet!</strong>
                        </h3>
                      </div>
                    </div>
                    <div style={{ paddingBottom: 40, textAlign: "center" }}>
                      <Link to="/">
                        <button
                          className="btn btn-solid"
                          style={{ padding: 6, fontSize: 10, borderRadius: 5 }}
                        >
                          Continue Shopping
                        </button>
                      </Link>
                    </div>
                </div>
                  </div> */}
                <div style={{ marginTop: 20 }} />
                <div style={{ borderRadius: 10, marginBottom: 20 }}>
                  <div className="each-order-container-1">
                    <div
                      className="order-header-1"
                      style={{ borderBottom: "1px solid gainsboro" }}
                    >
                      <div style={{ fontSize: 10 }}>
                        Quanzhong Zanchilong Trading has...{" "}
                      </div>
                    </div>
                  </div>
                  <div
                    className="products-box"
                    style={{
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <div>
                      <div
                        style={{
                          padding: "10px 5px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "1px solid gainsboro",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                          <div
                            style={{
                              backgroundColor: "gainsboro",
                              color: "gray",
                              padding: 7,
                              fontSize: 7,
                              marginTop: 20,
                              borderRadius: 5,
                            }}
                          >
                            <i className="icofont-eye" />
                            track
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "10px 5px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "1px solid gainsboro",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                          <div
                            style={{
                              backgroundColor: "gainsboro",
                              color: "gray",
                              padding: 7,
                              fontSize: 7,
                              marginTop: 20,
                              borderRadius: 5,
                            }}
                          >
                            <i className="icofont-eye" />
                            track
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "10px 5px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "1px solid gainsboro",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                          <div
                            style={{
                              backgroundColor: "gainsboro",
                              color: "gray",
                              padding: 7,
                              fontSize: 7,
                              marginTop: 20,
                              borderRadius: 5,
                            }}
                          >
                            <i className="icofont-eye" />
                            track
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginBottom: 5,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 7,
                          color: "gray",
                          maxWidth: 300,
                          padding: 6,
                          paddingBottom: 0,
                        }}
                      >
                        Goodes commitment order promises to deliver goods within
                        a limited time, and claims can be applied for in case of
                        any delay
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="each-order-container-1"
                  style={{
                    borderRadius: 10,
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 6,
                    }}
                  >
                    <div style={{ fontSize: 8, color: "gray" }}>
                      Total price <br /> of goods
                    </div>
                    <div style={{ fontSize: 10, color: "gray" }}>32.81</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 6,
                    }}
                  >
                    <div style={{ fontSize: 8, color: "gray" }}>
                      CN local courier charge
                    </div>
                    <div style={{ fontSize: 10, color: "gray" }}>Tk 4.00</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 6,
                    }}
                  >
                    <div
                      style={{ fontSize: 8, color: "gray", fontWeight: "bold" }}
                    >
                      Actual payment
                    </div>
                    <div style={{ fontSize: 10, color: "darkorange" }}>
                      36.81
                    </div>
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    justifyContent: "space-between",
                    marginTop: 15,
                    backgroundColor: "white",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div
                    style={{
                      fontSize: 8,
                      fontWeight: "bold",
                      color: "black",
                      marginTop: 15,
                      marginLeft: 15,
                    }}
                  >
                    Actual payment (including shipping charge): 4860Tk
                  </div>
                  <div
                    style={{
                      padding: "12px 20px",
                      fontSize: 10,
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: "darkorange",
                    }}
                  >
                    proceed to pay
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(OrderDetails);
