import React, { Component } from "react";

import "../../common/index.scss";
import axios from "axios";
import { connect } from "react-redux";
import "./main.css";

import OnHoverCategory from "./onHoverCategory";
class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarValue: "",
      shippingValue: "Ship for me",
    };
  }
  componentDidMount = async () => {};
  componentWillUnmount = () => {
    this.setState({ searchBarValue: "" });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleSearchBarSubmit = (event) => {
    event.preventDefault();
    if (this.state.searchBarValue.length < 35) {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/collection/${this.state.searchBarValue}`
      );
    } else {
      this.props.setSearchedProductsArray([]);
      if (this.state.searchBarValue.includes("1688")) {
        let productId = this.state.searchBarValue.split("/")[4].split(".")[0];
        console.log(productId);
        this.props.history.push(`${process.env.PUBLIC_URL}/1688/${productId}`);
      } else {
        let taobaoUrl = new URL(this.state.searchBarValue);
        let params = taobaoUrl.search;
        const urlParams = new URLSearchParams(params);
        const id = urlParams.get("id");
        this.props.history.push(`${process.env.PUBLIC_URL}/taobao/${id}`);
      }
    }
    this.setState({ searchBarValue: "" });
  };

  handleNextClick = () => {
    if (this.state.shippingValue == "Ship for me") {
      this.props.history.push(`${process.env.PUBLIC_URL}/pages/ship-for-me`);
    } else if (this.state.shippingValue === "Buy and ship for me") {
      this.props.history.push(`${process.env.PUBLIC_URL}/pages/buy-for-me`);
    } else {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/pages/request-for-product`
      );
    }
  };

  _handleImgChange = async (e) => {
    // let reader = new FileReader();
    // const { pictures } = this.state;
    e.preventDefault();
    let file = e.target.files[0];

    // reader.onloadend = () => {
    //   pictures[i] = reader.result;
    //   this.setState({
    //     file: file,
    //     pictures,
    //   });
    // };
    if (file) {
      // reader.readAsDataURL(file);
      // const imgUrl = await uploadImage(file);
      const _EXTERNAL_URL = `/uploadImage`;

      let data = new FormData();
      data.append("productImage", file);

      // let config = {
      //   header: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      try {
        const imgUrl = await axios.post(_EXTERNAL_URL, data);
        console.log(imgUrl.data);
        this.props.history.push(
          `${process.env.PUBLIC_URL}/collection/${imgUrl.data}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    return (
      <div className="category-box">
        <li style={{ fontWeight: "bold", marginBottom: 15, fontSize: 18 }}>
          <i
            className="icofont-navigation-menu"
            style={{ paddingRight: 5, fontSize: 20 }}
          />{" "}
          Category Market{" "}
        </li>
        <div className="option-bg">
          <i
            className="icofont-karate"
            style={{
              paddingRight: 5,
              fontSize: 17,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Dress & clothing
            <OnHoverCategory category={"Dress & clothing"} />
          </li>
        </div>
        <div className="option-bg">
          <i
            className="icofont-boot"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Accessories Shoes
            <OnHoverCategory category={"Accessories Shoes"} />
          </li>
        </div>
        <div className="option-bg">
          <i
            className="icofont-wallet"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Handbags Wallets
            <OnHoverCategory category={"Handbags Wallets"} />
          </li>
        </div>
        <div className="option-bg">
          <i
            className="icofont-toy-ball"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Sports Apparel
            <OnHoverCategory category={"Sports Apparel"} />
          </li>
        </div>
        <div className="option-bg">
          <i
            className="icofont-baby-cloth"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Children's clothing
            <OnHoverCategory category={"Children's clothing"} />
          </li>
        </div>
        <div className="option-bg">
          <i
            className="icofont-home"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Home Furnishings
            <OnHoverCategory category={"Home Furnishings"} />
          </li>
        </div>

        <div className="option-bg">
          <i
            className="icofont-ui-home"
            style={{
              paddingRight: 5,
              fontSize: 19,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Home imporovement
            <OnHoverCategory category={"Home imporovement"} />
          </li>
        </div>

        <div className="option-bg">
          <i
            className="icofont-dog"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Office Culture/Pet
            <OnHoverCategory category={"Office Culture/Pet"} />
          </li>
        </div>

        <div className="option-bg">
          <i
            className="icofont-marker-alt-2"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Beauty & Makeup
            <OnHoverCategory category={"Beauty & Makeup"} />
          </li>
        </div>
        <div className="option-bg">
          <i
            className="icofont-camera"
            style={{
              paddingRight: 5,
              fontSize: 18,
              fontWeight: "light",
            }}
          />{" "}
          <li className="onhover-dropdown onhover-dropdown-2 mobile-account">
            Digital
            <OnHoverCategory category={"Digital"} />
          </li>
        </div>
      </div>
    );
  }
}

export default connect(null)(Shop);
