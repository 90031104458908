import React, { useEffect } from "react";
import Content from "./Content";
import { connect } from "react-redux";
import { getAllNoticesRedux } from "../../../actions";
const AllNotices = (props) => {
  useEffect(() => {
    props.getAllNoticesRedux();
    window.scrollTo(0, 0);
  }, []);
  return <Content props={props} />;
};
const mapStateToProps = (state) => {
  return {};
};
export default connect(
  mapStateToProps,
  { getAllNoticesRedux }
)(AllNotices);
