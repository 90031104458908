export const allThanas = [
  {
    name: "Dhaka",
    thana: [
      "ADABOR",
      "BADDA",
      "BANANI",
      "BANGSHAL",
      "BHASAN TEK",
      "BHATARA",
      "BIMAN BANDAR",
      "CANTONMENT",
      "CHAK BAZAR",
      "DAKSHINKHAN",
      "DARUS SALAM",
      "DEMRA",
      "DHAMRAI",
      "DHANMONDI",
      "DOHAR",
      "GENDARIA",
      "GULSHAN",
      "HAZARIBAGH",
      "JATRABARI",
      "KADAMTALI",
      "KAFRUL",
      "KALABAGAN",
      "KAMRANGIR CHAR",
      "KERANIGANJ",
      "KHILGAON",
      "KHILKHET",
      "KOTWALI",
      "LALBAGH",
      "MIRPUR",
      "MOHAMMADPUR",
      "MOTIJHEEL",
      "MUGDA PARA",
      "NAWABGANJ",
      "NEW MARKET",
      "PALLABI",
      "PALTAN",
      "RAMNA",
      "RAMPURA",
      "RUPNAGAR",
      "SABUJBAGH",
      "SAVAR",
      "SHAH ALI",
      "SHAHBAGH",
      "SHAHJAHANPUR",
      "SHER-E-BANGLA NAGAR",
      "SHYAMPUR",
      "SUTRAPUR",
      "TEJGAON",
      "TEJGAON IND. AREA",
      "TURAG",
      "UTTAR KHAN",
      "UTTARA PASCHIM",
      "UTTARA PURBA",
      "WARI",
    ],
  },
  {
    name: "Chattogram",
    thana: [
      "ANOWARA",
      "BAKALIA",
      "BANSHKHALI",
      "BAYEJID BOSTAMI",
      "BOALKHALI",
      "CHANDANAISH",
      "CHANDGAON",
      "CHITTAGONG PORT",
      "DOUBLE MOORING",
      "FATIKCHHARI",
      "HALISHAHAR",
      "HATHAZARI",
      "KHULSHI",
      "KOTWALI",
      "LOHAGARA",
      "MIRSHARAI",
      "PAHARTALI",
      "PANCHLAISH",
      "PATENGA",
      "PATIYA",
      "RANGUNIA",
      "RAOZAN",
      "SANDWIP",
      "SATKANIA",
      "SITAKUNDA",
    ],
  },
  {
    name: "Khulna",
    thana: [
      "BATIAGHATA",
      "DACOPE",
      "DAULATPUR",
      "DIGHALIA",
      "DUMURIA",
      "KHALISHPUR",
      "KHAN JAHAN ALI",
      "KHULNA SADAR",
      "KOYRA",
      "PAIKGACHHA",
      "PHULTALA",
      "RUPSA",
      "SONADANGA",
      "TEROKHADA",
    ],
  },
  {
    name: "Rajshahi",
    thana: [
      "BAGHA",
      "BAGHMARA",
      "BOALIA",
      "CHARGHAT",
      "DURGAPUR",
      "GODAGARI",
      "MATIHAR",
      "MOHANPUR",
      "PABA",
      "PUTHIA",
      "RAJPARA",
      "SHAH MAKHDUM",
      "TANORE",
    ],
  },
];
