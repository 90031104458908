import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import { HashLoader } from "react-spinners";
import { getOrderTrackingResultRedux } from "../../actions";
import "./ship-for-me.css";
import "./track-order.css";
import { connect } from "react-redux";
import { getOrderOrShipmentRequest } from "../../firebase/firebase.utils";
class TrackOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      visual: "INPUT",
      trackingError: false,
      trackingNo: "",
      submit_loader: false,
    };
  }
  componentDidMount = async () => {};

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { orderTrackingResult } = this.props;
    console.log(orderTrackingResult);
    return (
      <div>
        <Helmet>
          <title>Paicart | Track your order</title>
        </Helmet>
        <Breadcrumb title={"Track Order"} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container track-order-container">
            {this.state.visual === "INPUT" && (
              <div
                style={{
                  backgroundColor: "#11677e",
                  borderRadius: 5,
                  padding: 30,
                  paddingTop: 10,
                }}
              >
                <div className="row track-your-order-txt">Track Your Order</div>
                <form style={{ marginBottom: 20 }}>
                  <div className="form-row">
                    <div className="input-container-div">
                      <input
                        style={{
                          borderRadius: 5,
                          height: 50,
                        }}
                        type="text"
                        name="trackingNo"
                        value={this.state.trackingNo}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="Enter your Tracking No/Order Id"
                        required
                      />
                    </div>
                  </div>
                  {this.state.trackingError && (
                    <div className="form-row tracking-error-div">
                      Please input Tracking No/Order Id
                    </div>
                  )}
                  <div className="form-row m-2">
                    <div
                      className="track-order-button-div"
                      onClick={async () => {
                        console.log(this.state.trackingNo);
                        if (this.state.trackingNo) {
                          this.setState({
                            submit_loader: true,
                            trackingError: false,
                          });

                          await this.props.getOrderTrackingResultRedux(
                            this.state.trackingNo
                          );

                          this.setState({
                            visual: "RESULT",
                            submit_loader: false,
                          });
                        } else {
                          this.setState({
                            trackingError: true,
                          });
                        }
                      }}
                    >
                      {!this.state.submit_loader && (
                        <i
                          className="fa fa-search"
                          style={{
                            fontSize: 15,
                            textAlign: "center",
                            cursor: "pointer",
                            color: "white",
                            fontWeight: "bold",
                            paddingLeft: 5,
                          }}
                        >
                          Track Order
                        </i>
                      )}
                      <HashLoader
                        loading={this.state.submit_loader}
                        color="white"
                        size={25}
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {this.state.visual === "RESULT" && (
              <div className="result-container">
                <div
                  className="d-flex flex-row cross-icon"
                  style={{
                    justifyContent: "flex-end",
                    padding: 10,
                    marginTop: 10,
                  }}
                >
                  <i
                    onClick={() => {
                      this.setState({
                        visual: "INPUT",
                        trackingNo: "",
                      });
                    }}
                    className="fa fa-times"
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      cursor: "pointer",
                      color: "#555",
                      fontWeight: "bold",
                      paddingLeft: 5,
                    }}
                  />
                </div>
                {orderTrackingResult ? (
                  <>
                    <div
                      className="row tracking-no-header"
                      style={{
                        color: "#555",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      Order Id :{" "}
                      {orderTrackingResult.orderId ||
                        orderTrackingResult.bookingId}
                    </div>
                    <div
                      className="section-containing-container"
                      style={{
                        marginLeft: -15,
                        marginRight: -15,
                      }}
                    >
                      <div
                        className="flex-options"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          background: "#18768f",
                          paddingBottom: 20,
                          borderRadius: 5,
                        }}
                      >
                        <div
                          style={{
                            padding: 60,
                            background: "#fff4e2",
                            borderRadius: 10,
                            border: "2px solid gainsboro",
                            marginTop: 10,
                          }}
                        >
                          <div className="row">
                            <div className="col order-tracking-bar">
                              {orderTrackingResult.shipmentStatusScore >= 1 ? (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="icofont-ui-timer"
                                      style={{
                                        backgroundColor: "#18768f",
                                        color: "white",
                                        border: "2px soild gainsboro",
                                      }}
                                    />
                                    <div
                                      className="progress-bar-line"
                                      style={{
                                        backgroundColor: "#18768f",
                                      }}
                                    />
                                  </div>
                                  &nbsp;
                                  <p
                                    style={{
                                      color: "#18768f",
                                    }}
                                  >
                                    Pending <br />
                                    {orderTrackingResult.date ||
                                      orderTrackingResult.orderedDate}
                                  </p>
                                </span>
                              ) : (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i className="icofont-industries" />
                                    <div className="progress-bar-line" />
                                  </div>
                                  &nbsp; <p>Pending</p>
                                </span>
                              )}
                              {orderTrackingResult.shipmentStatusScore >= 2 ? (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="icofont-thumbs-up"
                                      style={{
                                        backgroundColor: "#18768f",
                                        color: "white",
                                        border: "2px soild gainsboro",
                                      }}
                                    />
                                    <div
                                      className="progress-bar-line"
                                      style={{
                                        backgroundColor: "#18768f",
                                      }}
                                    />
                                  </div>
                                  &nbsp;
                                  <p
                                    style={{
                                      color: "#18768f",
                                    }}
                                  >
                                    Approved
                                    <br />
                                    {orderTrackingResult[
                                      "Rates ProvidedDate"
                                    ] || orderTrackingResult["PurchasedDate"]}
                                  </p>
                                </span>
                              ) : (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i className="icofont-thumbs-up" />
                                    <div className="progress-bar-line" />
                                  </div>
                                  &nbsp; <p>Approved</p>
                                </span>
                              )}
                              {orderTrackingResult.shipmentStatusScore >= 3 ? (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="icofont-building-alt"
                                      style={{
                                        backgroundColor: "#18768f",
                                        color: "white",
                                        border: "2px soild gainsboro",
                                      }}
                                    />
                                    <div
                                      className="progress-bar-line"
                                      style={{
                                        backgroundColor: "#18768f",
                                      }}
                                    />
                                  </div>
                                  &nbsp;
                                  <p
                                    style={{
                                      color: "#18768f",
                                    }}
                                  >
                                    Abroad Warehouse
                                    <br />
                                    {
                                      orderTrackingResult[
                                        "Received in WarehouseDate"
                                      ]
                                    }
                                  </p>
                                </span>
                              ) : (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i className="icofont-building-alt" />
                                    <div className="progress-bar-line" />
                                  </div>
                                  &nbsp; <p>Abroad Warehouse</p>
                                </span>
                              )}
                              {orderTrackingResult.shipmentStatusScore >= 4 ? (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="icofont-airplane-alt"
                                      style={{
                                        backgroundColor: "#18768f",
                                        color: "white",
                                        border: "2px soild gainsboro",
                                      }}
                                    />
                                    <div
                                      className="progress-bar-line"
                                      style={{
                                        backgroundColor: "#18768f",
                                      }}
                                    />
                                  </div>
                                  &nbsp;
                                  <p
                                    style={{
                                      color: "#18768f",
                                    }}
                                  >
                                    Ready To Fly
                                    <br />
                                    {orderTrackingResult["Ready for flyDate"]}
                                  </p>
                                </span>
                              ) : (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i className="icofont-airplane-alt" />
                                    <div className="progress-bar-line" />
                                  </div>
                                  &nbsp; <p>Ready for fly</p>
                                </span>
                              )}
                              {orderTrackingResult.shipmentStatusScore >= 5 ? (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="icofont-police"
                                      style={{
                                        backgroundColor: "#18768f",
                                        color: "white",
                                        border: "2px soild gainsboro",
                                      }}
                                    />
                                    <div
                                      className="progress-bar-line"
                                      style={{
                                        backgroundColor: "#18768f",
                                      }}
                                    />
                                  </div>
                                  &nbsp;
                                  <p
                                    style={{
                                      color: "#18768f",
                                    }}
                                  >
                                    Bangladesh Customs
                                    <br />
                                    {
                                      orderTrackingResult[
                                        "Bangladesh customsDate"
                                      ]
                                    }
                                  </p>
                                </span>
                              ) : (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i className="icofont-police" />
                                    <div className="progress-bar-line" />
                                  </div>
                                  &nbsp; <p>Bangladesh Customs</p>
                                </span>
                              )}
                              {orderTrackingResult.shipmentStatusScore >= 6 ? (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="icofont-home"
                                      style={{
                                        backgroundColor: "#18768f",
                                        color: "white",
                                        border: "2px soild gainsboro",
                                      }}
                                    />
                                    <div
                                      className="progress-bar-line"
                                      style={{
                                        backgroundColor: "#18768f",
                                      }}
                                    />
                                  </div>
                                  &nbsp;
                                  <p
                                    style={{
                                      color: "#18768f",
                                    }}
                                  >
                                    Paicart Warehouse
                                    <br />
                                    {orderTrackingResult[
                                      "Paicart WarehouseDate"
                                    ] ||
                                      orderTrackingResult[
                                        "Paicart warehouseDate"
                                      ]}
                                  </p>
                                </span>
                              ) : (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i className="icofont-home" />
                                    <div className="progress-bar-line" />
                                  </div>
                                  &nbsp; <p>Paicart Warehouse</p>
                                </span>
                              )}

                              {orderTrackingResult.shipmentStatusScore >= 7 ? (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="icofont-tick-mark"
                                      style={{
                                        backgroundColor: "#18768f",
                                        color: "white",
                                        border: "2px soild gainsboro",
                                      }}
                                    />
                                  </div>
                                  &nbsp;
                                  <p
                                    style={{
                                      color: "#18768f",
                                    }}
                                  >
                                    Delivered
                                    <br />
                                    {orderTrackingResult["DeliveredDate"]}
                                  </p>
                                </span>
                              ) : (
                                <span className="d-flex flex-row">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i className="icofont-tick-mark" />
                                  </div>
                                  &nbsp; <p>Delivered</p>
                                </span>
                              )}
                            </div>
                            {false ? (
                              <>
                                <div className="row mt-3">
                                  <div className="col">
                                    <div
                                      className="center-head"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      <span
                                        className="bg-light-gray"
                                        style={{
                                          textTransform: "none",
                                          color: "purple",
                                        }}
                                      >
                                        শিপমেন্ট সংক্রান্ত নোটিশ
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: "gray",
                                    fontSize: "80%",
                                    padding: "10px",
                                    paddingTop: "5px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  Notice
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="row"
                      style={{
                        color: "#18768f",
                        fontWeight: "bold",
                        justifyContent: "center",
                        padding: 10,
                        fontSize: 30,
                      }}
                    >
                      Order not Shipped yet!
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderTrackingResult: state.orders.orderTrackingResult,
  };
};
export default connect(
  mapStateToProps,
  { getOrderTrackingResultRedux }
)(TrackOrder);
