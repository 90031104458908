import React, { Component, useRef } from "react";
// Custom Components
import HeaderThree from "./common/headers/header-three";
import { ToastContainer, toast } from "react-toastify";
import FooterOne from "./common/footers/footer-one";
import {
  auth,
  createUserProfileDocument,
  firestore,
} from "../firebase/firebase.utils";
import { connect } from "react-redux";
import {
  setCurrentUser,
  setReduxCart,
  setReduxWishlist,
  getAllProductsFirestore,
  getCurrencyRedux,
} from "../actions";
// ThemeSettings
import ThemeSettings from "./common/theme-settings";

class App extends Component {
  unsubscribeFromAuth = null;

  componentDidMount = async () => {
    this.props.getCurrencyRedux();
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);
        if (userRef) {
          userRef.onSnapshot((snapShot) => {
            this.props.setCurrentUser({ id: snapShot.id, ...snapShot.data() });
          });
          const cartRef = firestore.doc(`carts/${userAuth.uid}`);
          cartRef.onSnapshot((snapShot) => {
            if (snapShot.exists) {
              this.props.setReduxCart(snapShot.data().cart);
            }
          });
          const wishlistRef = firestore.doc(`wishlists/${userAuth.uid}`);
          wishlistRef.onSnapshot((snapShot) => {
            if (snapShot.exists) {
              this.props.setReduxWishlist(snapShot.data().wishlist);
            }
          });
        } else {
          this.props.setCurrentUser({ displayName: "", email: "" });
          this.props.setReduxCart([]);
          this.props.setReduxWishlist([]);
        }
      } else {
        this.props.setCurrentUser({ displayName: "", email: "" });
        this.props.setReduxCart([]);
        this.props.setReduxWishlist([]);
      }
    });
  };

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <ToastContainer />
        <HeaderThree logoName={"logo/14.png"} />
        {this.props.children}
        <FooterOne logoName={"logo/14.png"} />

        <ThemeSettings />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    cartItems: state.cartList,
  };
};
export default connect(
  mapStateToProps,
  {
    setCurrentUser,
    setReduxCart,
    setReduxWishlist,
    getAllProductsFirestore,
    getCurrencyRedux,
  }
)(App);
