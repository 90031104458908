import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
class Refund extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Paicart | refund policy</title>
        </Helmet>
        <Breadcrumb title={"Return and Refund Policy"} />
        {/*about section*/}
        <section
          className="about-page  section-b-space"
          style={{ background: "#fafafa" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12" style={{ marginBottom: "3rem" }}>
                <h2 style={{ marginTop: "3rem", textTransform: "none" }}>
                  Return and Refund Policy{" "}
                </h2>
                <div style={{ marginBottom: "1rem" }}>
                  আপনাদের অর্ডারকৃত পণ্যগুলোর রেডিস্টক আমাদের কাছে থাকে না।
                  আপনারা অর্ডার করার পর সরাসরি সেলার থেকে আপনাদের দরজায় আমরা
                  পণ্যগুলো পৌঁছে দেই আর তাই খুব সীমিত কিছু পরিস্থিতি ছাড়া আমাদের
                  পক্ষে রিফান্ড অথবা রিটার্ন দেয়া সম্ভব হয় না।
                </div>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  কোন কোন ক্ষেত্রে রিফান্ড প্রযোজ্য হবেঃ
                </h3>
                <p>
                  ০১। অর্ডার করা পণ্য আমাদের কাছ থেকে ডেলিভারি পাওয়ার পর পণ্যে
                  যদি কোনো ত্রুটি থাকে, ড্যামেজ থাকে অথবা আপনাকে যদি ভুল পণ্য
                  কিংবা অসম্পূর্ণ পণ্য পাঠানো হয় শুধুমাত্র সেই ক্ষেত্রে আমাদের
                  ওয়েবসাইটের রিফান্ড অপশনে যেয়ে ২৪ ঘণ্টার মধ্যে উপযুক্ত প্রমান
                  সহ রিফান্ডের জন্য আবেদন করতে হবে। এর পরে রিফান্ডের জন্য আবেদন
                  করলে তা গ্রহণ করা হবে না।
                </p>
                <p>
                  ০২। সেলারের কাছে আপনার অর্ডার করা পণ্য না থাকলে আপনি রিফান্ডের
                  জন্য আবেদন করতে পারবেন।
                </p>
                <p>
                  ০৩। অনেকগুলো পণ্য একসাথে অর্ডার করার ফলে কোনো কোনো সময় সেলার
                  কিছু পণ্য পাঠাতে দেরি করে। এই ক্ষেত্রে যে পণ্যগুলো আমাদের
                  অফিসে চলে আসে আমরা সেগুলো আপনাদের ঠিকানায় পাঠিয়ে দেই। বাকি
                  পণ্যগুলো পেতে কিছুদিন অপেক্ষা করুন। আপনি চাইলে paicart.com
                  থেকে আপনার অর্ডারটি ট্র্যাক করতে পারেন।
                </p>
                <p>
                  ০৪। ৪৫ কার্যদিবস(সকল প্রকার ছুটি ব্যতীত) মধ্যে আপনার অর্ডার
                  করা পণ্য কোনো কারণে না আসলে রিফান্ডের জন্য আবেদন করা যাবে।
                </p>
                <p>০৫। আমাদের এখানে পণ্য এক্সচেঞ্জ করার সিস্টেম নেই।</p>
                <p>
                  ০৬। নিচের কারন গুলির কারনে যদি অর্ডার ক্যান্সেল করা হয় তাহলে
                  পারশিয়াল পেমেন্ট এর ২.৫% কেটে রাখা হবেঃ <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;★প্রোডাক্ট ভুল সিলেক্ট করে অর্ডার
                  করলে <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;★প্রোডাক্ট অর্ডার কনফার্ম করার পরে
                  চায়না কুরিয়ার চার্জ বা অন্য কোন কারনে অর্ডার ক্যান্সেল করতে
                  হলে।
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;★নিষিদ্ধ পণ্য অর্ডার করলে।
                </p>
                <br />
                <p>
                  ০৭। সাধারণ পনের ক্ষেত্রে কোন পণ্য নষ্ট হয়ে গেলে গ্রাহক শুধু
                  পণ্যের পরিশোধিত সমুদয় মূল্য ফেরত পাবে
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  মিসিং বা হারিয়ে যাওয়া প্রোডাক্ট এর রিফান্ড এর ক্ষেত্রে
                  Paicart.com এর কিছু দিক নির্দেশনাঃ
                </h3>
                <p>
                  ০১। গ্রাহক যখন তার ডেলিভারিকৃত প্রোডাক্ট গুলো আনবক্সিং করবে
                  ,তখন তার একটি ভিডিও ফুটেজ সংরক্ষণ করতে হবে।
                </p>
                <p>
                  ০২। ডেলিভারিকৃত প্রত্যেকটি প্রোডাক্টের বক্স এর উপরে একটি
                  চাইনিজ ইনভয়েস/ স্টিকার থাকে সেটির একটি ছবি এবং প্রোডাক্টের
                  বক্সের ভেতরে যদি একটি চাইনিজ ইনভয়েস থাকে উভয়ের ছবি তুলে
                  সংরক্ষণ করতে হবে।
                </p>
                <p>
                  ০৩। কাস্টমার যে ওয়েট ম্যাশিন /পরিমাপকের মাধ্যমে ওজন করবেন, সেই
                  পরিমাপকের উপরে বক্স সহ সবগুলো প্রোডাক্ট রেখে ওজনকৃত অবস্থায়
                  ছবি তুলে অথবা সংক্ষিপ্ত ভিডিও করে রাখতে হবে।
                </p>
                <p>
                  ০৪। মিসিং প্রোডাক্টের ক্ষেত্রে ডেলিভারিকৃত প্রোডাক্টের যথাযথ
                  সাইজ /কালার উল্লেখ করে তার মধ্যে থেকে মিসিং প্রোডাক্টের
                  সংখ্যাসহ বিস্তারিত লিখিত এবং ছবিসহ আমাদের জানাতে হবে।
                </p>
                <p>
                  ০৫। কোন কারনে যদি উপরে উল্লেখিত পদ্ধতিতে মিসিং বা হারিয়ে যাওয়া
                  প্রোডাক্টের সনাক্তকরণ সম্ভব না হয় সেক্ষেত্রে অবশ্যই গ্রাহককে
                  সকল প্রোডাক্ট ওয়্যারহাউজে ফেরত পাঠাতে হবে।
                </p>
                <p>০৬। Paicart এর শিপিং চার্জ/ওয়েট চার্জ অ-ফেরতযোগ্য।</p>
                <p>
                  ০৭। উপরোক্ত কাজগুলো গ্রাহককে অবশ্যই ডেলিভারি হওয়ার ২৪ ঘণ্টা
                  সময়ের মধ্যে সম্পন্ন করে যথাযথ কাস্টমার সাপোর্টে অভিযোগ জানাতে
                  হবে।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  রিফান্ড করার সময়সীমাঃ
                </h3>
                <p>
                  ০১। আপনি সবগুলো শর্ত মেনে রিফান্ডের জন্য আবেদন করার পর আমাদের
                  টিম আপনার সাথে যোগাযোগ করবে। আপনার আবেদনটি যদি আমাদের রিফান্ড
                  পলিসির মধ্যে পরে সেই ক্ষেত্রে আপনার অর্ডার করা পণ্যের মূল্য
                  ১০-১৫ কর্ম দিবসের মধ্যে রিফান্ড করা হবে। ক্ষেত্র বিশেষে, আপনার
                  পেমেন্ট মেথডের ওপর ভিত্তি করে রিফান্ড টাইম কম বা বেশি হতে
                  পারে।
                </p>
                <p>
                  ০২। যদি কোনো অপ্রত্যাশিত পরিস্থিতি বা আল্লাহ প্রদত্ত কারণে
                  চালান বিলম্বিত হয় (যেমন: কোভিডের কারণে লকডাউন) তাহলে রিফান্ড
                  প্রক্রিয়া সম্পূর্ণরূপে সেলারের চূড়ান্ত সিদ্ধান্তের উপর
                  নির্ভর করবে। যদি রিফান্ডের নির্ধারিত সময় শেষ হয়ে যায় এবং
                  বিক্রেতা রিফান্ড না করার সিদ্ধান্ত নেন তাহলে Paicart ও দায়ী
                  থাকবে না।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  শুধু মাত্র আমাদের শিপিং সার্ভিসর যে সকল ক্ষেত্রে রিটার্ন
                  প্রযোজ্য হবেঃ
                </h3>
                <p>
                  ০১। “শিপ ফর মি” রিফান্ড পলিসিঃ আপনি যদি শুধু মাত্র আমাদের
                  শিপিং সার্ভিস “ Pacart ship for me” নেন তবে কোন পন্য হারিয়ে
                  গেলে বা কম পেলে চায়না ওয়্যাহাউজে রিসিবকৃত ওজন ও বাংলাদেশে
                  রিসিবকৃত ওজনের সাথে ব্যপক তারতম্য হলে যে সেলার এর নিকট হতে
                  পন্য ক্র‌য় করা হয়েছে তার প্রকৃত ইনভয়েস , প্যাকিং লিস্ট ও
                  পেমেন্ট স্লিপ যাচােই করে রিফান্ড প্রদান করা হবে।
                </p>
                <p>
                  ০২। “শিপ ফর মি” রিফান্ড পলিসিঃ আপনি যদি শুধু মাত্র আমাদের
                  শিপিং সার্ভিস “ Pacart ship for me” নেন তবে আপনি আংশিক রিফান্ড
                  ক্লেইম করতে পারবেন কোন অনাকাঙ্ক্ষিত ঘটনা ঘটলে। সেই ক্ষেত্রে
                  আপনার ইনভয়েসের টোটাল থেকে ২০% গণনা করে সর্বোচ্চ ২০০ ডলার
                  পর্যন্ত রিফান্ড পেতে পারেন।
                </p>
                <p>
                  ০৩। লিকুইড, পাউডার, মেশিন ও যন্ত্রপাতির কাঠের বক্স থাকা
                  বাধ্যতামূলক। কাঠের বক্স ব্যতিত কোন পন্য শিপমেন্টের সময়
                  ক্ষতিগ্রস্থ হলে Paicart.com দায়ী থাকবে না।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  কোন কোন ক্ষেত্রে রিটার্ন প্রযোজ্য হবেনাঃ
                </h3>
                <p>
                  ০১। পণ্য রিসিভ করার ২৪ ঘন্টার মধ্যে আমাদেরকে অবগত না করলে।
                </p>
                <p>
                  ০২। কম্পিউটার অথবা ফোনের ডিসপ্লে কালারের কারণে ওয়েবসাইটে দেয়া
                  পণ্যের ছবির সাথে আসল পণ্যের কালারের তারতম্য হলে।
                </p>
                <p>০৩। সাইজের ক্ষেত্রে ১-৪ ইঞ্চি তারতম্য হলে।</p>
                <p>০৪। পণ্যের কোয়ালিটি নিয়ে সন্তুষ্ট না হলে।</p>
                <p>০৫। পণ্যটি ব্যবহার করা হলে।</p>
                <p>
                  ০৬। সেলারের প্রদত্ত কোন পণ্যের রিটার্ন সিস্টেম বা ওয়ারেন্টি
                  প্রদান করলে তা কার্যকর হবে না।
                </p>
                <p>
                  ০৭। মাছ ধরার টুল কিটের মতো অথবা অত্যাধিক ভঙ্গুর পণ্য ফেরতযোগ্য
                  নয়। আপনি যদি এই জাতীয় সরঞ্জামগুলি অর্ডার করতে চান তবে নিজের
                  দায়িত্বে করুন। শিপিংয়ের সময় এই ধরনের পণ্যের জন্য
                  Paicart.com দায়ী থাকবে না।
                </p>
                <p>
                  ০৮। অর্ডারকৃত কোন পন্য বাংলাদেশে আসার পর সর্বোচ্চ ৩০ কার্য
                </p>
                <p>
                  ০৯। দিবসের মধ্যে রিসিভ না করলে উক্ত পণ্য হারিয়ে গেলে
                  Paicart.com দায়ী থাকবে না।
                </p>
                <p>
                  ১০। অর্ডারকৃত কোন পন্য বাংলাদেশে আসার পর সর্বোচ্চ ৪৫
                  কার্যদিবসের মধ্যে ক্রেতা রিসিভ না করলে উক্ত পণ্যের শিপমেন্ট
                  খরচ বাবদ Paicart.com উক্ত পণ্য অন্যত্র বিক্রয় করা বা নিলাম
                  করার অধিকার রাখে। উক্ত ক্ষেত্রে শিপমেন্ট বিল বাবদ টাকা কর্তন
                  করে অবশিষ্ট অর্থ থাকলে তা ফেরত দেয়া হবে।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  রিটার্ন করার শর্তসমূহঃ
                </h3>
                <p>
                  ০১। রিটার্ন করা পণ্যটি অবশ্যই অব্যবহৃত হতে হবে, কোনো ভাবেই
                  পণ্যটি ধৌত করা যাবেনা, ভাজ ফেলা কিংবা ধোঁয়া যাবে না।
                </p>
                <p>
                  ০২। পণ্যটি অবশ্যই সাথে দেয়া সাপ্লায়ারের অরিজিনাল প্যাকেট বা
                  বক্সে সতর্কতার সাথে ভরে রিটার্ন করতে হবে। আর পণ্যটি যদি
                  Paicart.com এর প্যাকেট কিংবা বক্সে ভরে ডেলিভারি দেয়া হয়ে থাকে
                  সেই ক্ষেত্রে সেইম প্যাকেট বা বক্সে ভরে শিপিং লেভেল সহ পণ্যটি
                  রিটার্ন করতে হবে। কোনো ভাবেই সাপ্লাইয়ারের দেয়া বক্সে টেইপ বা
                  স্টিকার ব্যবহার করা যাবে না।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  কিভাবে রিফান্ড/রিটার্নের জন্য আবেদন করবেন?
                </h3>
                <p>
                  ০১। প্রথমে আপনাকে আপনার একাউন্টে লগিন করুন। তারপর সেখান থেকে
                  My Order অপশনটিতে ক্লিক করুন।
                </p>
                <p>
                  ০২। সেখান থেকে আপনি যেই অর্ডারটি রিফান্ড/রিটার্ন করতে চান সেটি
                  সিলেক্ট করুন এবং Manage Order বাটনটিতে ক্লিক করুন।
                </p>
                <p>
                  ০৩। রিটার্নের ক্ষেত্রে আপনি যে পণ্যটি রিটার্ন করতে চাচ্ছেন সেই
                  পণ্যটি সতর্কতার সাথে সাপ্লায়ারের দেয়া অরিজিনাল এবং ভালো
                  প্যাকেটে ভরে প্যাকেট করুন।
                </p>
                <p>
                  ০৪। আপনার ঠিকানা যদি ঢাকার মধ্যে হয় সেই ক্ষেত্রে আপনি চাইলে
                  নিজে এসে আমাদের অফিসে পণ্যটি রিটার্ন করে দিতে পারবেন অথবা
                  আমাদের ঠিকানায় কুরিয়ার করে পাঠাতে পারবেন। অন্যদিকে, আপনার
                  ঠিকানা যদি ঢাকার বাইরে হয় সেই ক্ষেত্রে পণ্যটি কুরিয়ার করে
                  আমাদের ঠিকানায় পাঠাতে পারবেন।
                </p>
                <p>
                  ০৫। ঢাকা কিংবা ঢাকার বাইরে থেকে পণ্য পাঠানোর আগে অবশ্যই
                  আমাদেরকে আগে ফোনে অথবা ইমেইল করে জানাতে হবে। উভয় ক্ষেত্রে
                  আপনাকে নিজ খরচে ও নিজ দায়িত্বে পণ্যটি কুরিয়ার করতে হবে।
                  কুরিয়ারে পণ্য হারালে বা নষ্ট হলে তা আমাদের দায়বদ্ধতার বাইরে।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  গোপনীয়তা নীতিমালা পরিবর্তনঃ
                </h3>
                <p>
                  এই গোপনীয়তা নীতিমালাটি Paicart.com যেকোন সময়ে পরিবর্তন করার
                  অধিকার রাখে।
                  <br />
                  আমাদের গোপনীয়তা নীতিমালায় যদি কোন পরিবর্তন আনা হয়, সেই
                  ক্ষেত্রে পরিবর্তিত গোপনীয়তা নীতিমালাটি আমরা এখানে রাখবো।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  কুরিয়ার করার ঠিকানাঃ
                </h3>
                <p>
                  Sundarban Courier service <br />
                  Paicart.com
                  <br />
                  Moubon Super Market
                  <br />
                  2nd floor, New Kakrail Road, Ramna, Dhaka-1217
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Refund;
