import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
class TermsAndCondition extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Paicart | terms & conditions</title>
        </Helmet>
        <Breadcrumb title={"Terms and Conditions"} />
        {/*about section*/}
        <section
          className="about-page  section-b-space"
          style={{ background: "#fafafa" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12" style={{ marginBottom: "3rem" }}>
                <h2 style={{ marginTop: "3rem", textTransform: "none" }}>
                  Terms & Conditions
                </h2>
                <p
                  style={{
                    marginBottom: "1rem",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  আমাদের থেকে পন্য ক্রয়ের আগে নিম্নোক্ত বিষয়গুলো বুঝে পন্য ক্রয়
                  করবেন যাতে বিক্রয় পরবর্তী কোন ঝামেলা না আসে। আমাদের সাইটে
                  অর্ডার করার আগে অবশ্যই আমাদের শপিং ও শিপিং পলিসি ও চার্জ সমূহ
                  ভালোভাবে জেনে বুঝে পেমেন্ট করবেন। আমাদের উদ্দেশ্য গ্রাহকদের
                  সন্তুষ্টি অর্জনের সাথে ব্যবসা করা।আমরা চাই আমাদের কাস্টমার
                  আমাদের সার্ভিস সম্পর্কে ১০০% জেনে সিদ্ধান্ত গ্রহন করুন। আমাদের
                  কোন বিষয়ে যদি কোন রকম দ্বিধা থাকে তা আমাদেরকে +8801979653008
                  কল করে অথবা Facebook পেজ ইনবক্সে জেনে নিন।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>1.</span>{" "}
                  আমরা কোন পন্যের রেডিষ্টক রাখিনা। শুধুমাত্র আপনার অর্ডারকৃত
                  পন্যই চায়না সাপ্লাইয়ার থেকে ক্রয় করা হয়। তাই কখনো ২/৩ দিনের
                  মধ্যে পণ্য ডেলিভেরী সম্ভব না।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>2.</span>{" "}
                  পন্যের মিনিমাম ৫০% অগ্রিম পেমেন্ট ছাড়া কোন অর্ডার নেয়া
                  হয়না।পন্যের বাকি পেমেন্ট চায়না টু বাংলাদেশ শিপিং চার্জ
                  ডেলিভেরীর সময় প্রদান করতে হবে। বিস্তারিত জানতে, +8801979653008
                  অথবা সোশ্যাল লিঙ্কগুলোতে ম্যাসেজ দিয়ে জেনে নিন।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>3.</span>{" "}
                  আমাদের সাইটে উল্লেখিত পণ্যের ওজন সেলার এর দেয়া তথ্যের উপর
                  ভিত্তি করে প্রদর্শিত হচ্ছে যা ১০০% সঠিক নয়। তাই এখানে উল্লেখিত
                  ওজন দেখে বিভ্রান্ত হবেন না। পণ্য আসার পর প্রকৃত ওজনের উপর
                  ওয়েবসাইটে উল্লেখিত রেট অনুযায়ী শিপিং ও কাস্টমস চার্জ আপনাকে
                  প্রদান করতে হবে। মনে রাখবেন শিপিং ও কাস্টমস চার্জ পণ্য মুল্যের
                  সাথে দেখানো হয়না। পণ্য আসার পরই শুধুমাত্র শিপিং ও কাস্টমস
                  চার্জ হিসাব করা হয়ে থাকে। অর্ডার করার সময় যে রেট উল্লেখ থাকবে
                  সেটাই ডেলিভেরী নেওয়ার সময় ধরা হবে।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>4.</span>{" "}
                  বানিজ্যিক পন্য বাই এয়ারে আসতে আমাদের ওয়্যার হাউস পৌছনোর দিন
                  থেকে ১৫ থেকে ২০ কার্য দিবস (সকল প্রকার ছুটি ব্যতীত) এবং বাই
                  শিপে ওয়্যার হাউস পৌছনোর দিন থেকে ৪৫ থেকে ৬০ কার্য দিবস (সকল
                  প্রকার ছুটি ব্যতীত) লাগে।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>5.</span>{" "}
                  পন্য মুল্যের মধ্যে চায়না লোকাল কুরিয়ার/ ট্রান্সপোর্ট চার্জ
                  অন্তর্ভুক্ত নয়। পন্য ডেলিভারী নেওয়ার সময় পরিশোধ করতে হবে।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>6.</span>{" "}
                  পণ্য মুল্যের সাথে বাংলাদেশের লোকাল কুরিয়ার খরচ অন্তর্ভুক্ত নয়।
                  পণ্য আসার পর এই খরচ যোগ হবে। ঢাকার মধ্যে পাঠাও, স্টেডফাস্ট
                  কুরিয়ার ও ঢাকার বাইরে সুন্দরবন কুরিয়ারে পণ্য পাঠানো হবে।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>7.</span>{" "}
                  পন্য কেনার আগে অবশ্যই রিটার্ন এন্ড রিফান্ড পলিসি জেনে নিন। বাই
                  এয়ারের পণ্য যদি ৪৫ কার্য দিবস (সকল প্রকার ছুটি ব্যতীত)
                  অতিবাহিত হয়ে যায় এবং বাই শিপের পণ্য ৯০ কার্য দিবস (সকল প্রকার
                  ছুটি ব্যতীত) অতিবাহিত হয়ে যায় তাহলে শর্ত সাপেক্ষে রিফান্ড করা
                  হবে। এই ৪০/৯০ কার্য দিবসের আগে রিফান্ড এপ্লাই গ্রহণ করা হবেনা।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>8.</span>{" "}
                  কাষ্টমস কর্তৃক আমদানী নিষিদ্ধ পন্য অর্ডার করা যাবেনা। যদি কেউ
                  সরাসরি অর্ডার করেন তাহলে অর্ডার ক্যান্সেল হবে এবং আপনার
                  পেমেন্টকৃত টাকা থেকে গেটওয়ে চার্জ ২.৫০% কেটে বাকি টাকা রিফান্ড
                  করা হবে। নিষিদ্ধ পন্যের ডিটেইল জানতে (এখানে ক্লিক করুন)।
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>9.</span>{" "}
                  অর্ডারকৃত পন্য একাধিক সাপ্লাইয়ার ও আইটেম হলে সব আইটেম এক সাথে
                  নাও আসতে পারে। সেক্ষেত্রে আমাদের দেওয়া সময়সীমার আগে কেউ আংশিক
                  ডেলিভেরি নিতে চান তাহলে আপনাকে উক্ত অর্ডারের পুরো বকেয়া পরিশোধ
                  করতে হবে। বাকি পন্য আসার পর শুধু কুরিয়ার /কাষ্টমস/
                  ট্রান্সপোর্ট চার্জ পেমেন্ট করে পন্য নিতে পারবেন।
                </p>

                <p
                  style={{
                    marginBottom: "1rem",
                    marginTop: 80,
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  Before purchasing the product from us, understand the
                  following points and purchase the product so that there is no
                  problem after the sale. Be sure to read and understand our
                  shopping and shipping policies and charges before placing an
                  order on our site. Our aim is to do business with customer
                  satisfaction. We want our customers to make a 100% informed
                  decision about our services. Call us on +8801979653008 or
                  Facebook page inbox if you have any doubts about anything.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>1.</span>{" "}
                  We do not reserve any products. Only the products you order
                  are purchased from China suppliers. So product delivery is not
                  possible within 2/3 days.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>2.</span>{" "}
                  No order is accepted without 50% advance payment of the
                  product. The remaining payment of the product China to
                  Bangladesh shipping charge should be paid at the time of
                  delivery. For details, message +8801979653008 or social links.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>3.</span>{" "}
                  Product weight shown on our site is based on information
                  provided by the seller which is not 100% accurate. So don't
                  get confused by the weight mentioned here. You will have to
                  pay the shipping and customs charges as per the rates
                  mentioned on the website based on the actual weight of the
                  product upon arrival. Note that shipping and customs charges
                  are not included in the product price. Only shipping and
                  customs charges are calculated upon product arrival. The rate
                  mentioned at the time of ordering will be applicable at the
                  time of delivery.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>4.</span>{" "}
                  Commercial goods take 15 to 20 working days (excluding all
                  holidays) from the date of arrival at our warehouse by air and
                  45 to 60 working days (excluding all holidays) from the date
                  of arrival at the warehouse by ship.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>5.</span>{" "}
                  Product price does not include China local courier/transport
                  charges. Payment to be made on delivery of goods.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>6.</span>{" "}
                  Product price does not include Bangladesh local courier
                  charges. This cost will be added after the product arrives.
                  Products will be shipped by Pathao, Steadfast Courier within
                  Dhaka and Sundarban Courier outside Dhaka.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>7.</span>{" "}
                  Be sure to know the return and refund policy before purchasing
                  the product. Conditional refunds will be made if Buy Air
                  products are overdue by 45 working days (excluding all
                  holidays) and Buy Ship products are overdue by 90 working days
                  (excluding all holidays). Refund applications will not be
                  accepted before these 40/90 working days.
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>8.</span>{" "}
                  Goods prohibited for importation by customs cannot be ordered.
                  If someone orders directly, the order will be cancelled and
                  the remaining amount will be refunded after deducting the
                  gateway charge of 2.50% from your paid amount. For details of
                  prohibited goods (click here).
                </p>
                <p>
                  <span style={{ fontWeight: "bold", color: "black" }}>9.</span>{" "}
                  If the product ordered is from multiple suppliers and items
                  may not all arrive together. In case someone wants to take
                  partial delivery before the deadline given by us, then you
                  will have to pay the full balance of the order. After the rest
                  of the goods arrive, you can only pay the
                  courier/customs/transport charges and take the goods.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TermsAndCondition;
