import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import "./collection2.css";
import { getRelatedItems } from "../../../services";
import icon from "./G-icon.png";
class ProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      cartModalopen: false,
      stock: "InStock",
      quantity: 1,
      image: "",
    };
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenCartModal = () => {
    this.setState({ cartModalopen: true });
    this.props.onAddToCartClicked();
  };
  onCloseCartModal = () => {
    this.setState({ cartModalopen: false });
  };

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked,
      relatedItems,
      currency,
    } = this.props;

    let RatingStars = [];
    for (var i = 0; i < 5; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }

    return (
      <div>
        <div className="product-box product-box-homepage">
          <div
            className="img-wrapper img-wrapper-homepage"
            style={{
              backgroundImage: `url(${icon})`,
              backgroundColor: "white",
              backgroundSize: "130px 130px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="lable-block">
              {product.sale == true ? (
                <span className="lable4">on sale</span>
              ) : (
                ""
              )}
              {product.new == true ? <span className="lable3">new</span> : ""}
            </div>
            <div className="front">
              <Link to={`/product/${product.Id.replace("abb-", "")}`}>
                <img
                  src={`${
                    product.Image && product.Image.Url
                      ? product.Image.Url
                      : `${process.env.PUBLIC_URL}/assets/images/icon/icon.png`
                  }`}
                  className="img-fluid lazyload bg-img product-only-image"
                  style={{ minWidth: "100%" }}
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div
            className="product-detail"
            style={{ padding: "5px", paddingBottom: "80px", paddingTop: "0px" }}
          >
            <div>
              <div className="rating" style={{ minHeight: "35px" }}>
                {RatingStars.length > 0 ? (
                  RatingStars
                ) : (
                  <p
                    style={{
                      color: "gainsboro",
                      fontSize: "90%",
                    }}
                  >
                    No rating
                  </p>
                )}
              </div>
              <Link to={`/product/${product.Id.replace("abb-", "")}`}>
                <h6
                  style={{ fontSize: "80%", paddingBottom: "0px" }}
                  className="product-name"
                >
                  {product.Name.slice(0, 25)}
                </h6>
              </Link>
              <h6
                style={{
                  color: "#18768f",

                  fontWeight: "bold",
                }}
                className="product-price"
              >
                {symbol}
                {Math.round(
                  parseFloat(product.Price.MarginPrice) * parseFloat(taka)
                )}{" "}
                <br />
                {product.Price.OriginalPrice &&
                product.Price.OriginalPrice !== product.Price.MarginPrice ? (
                  <del style={{ fontSize: "70%" }}>
                    <span className="money">
                      {symbol}
                      {product.Price.OriginalPrice * parseFloat(taka)}
                    </span>
                  </del>
                ) : (
                  ""
                )}
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  relatedItems: getRelatedItems(state.data.products, ownProps.product.category),
  symbol: state.data.symbol,
  currency: state.user.currency,
});

export default connect(mapStateToProps)(ProductItem);
