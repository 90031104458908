import firebase from "firebase/app";
// import firebase2 from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import collection from "../components/layouts/pets/collection";
import { getCartTotal } from "../services";

// paicart firebase connect
const firebaseConfig = {
  apiKey: "AIzaSyDOUWPHR3NANUmQ6IDrehAe-Gx9hwyJ2Bc",
  authDomain: "paicart.firebaseapp.com",
  projectId: "paicart",
  storageBucket: "paicart.appspot.com",
  messagingSenderId: "702286683439",
  appId: "1:702286683439:web:1e188b457e5cdec99ed5da",
  measurementId: "G-2HHC4V5M6Y",
};

firebase.initializeApp(firebaseConfig);

export var googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
export var facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.setCustomParameters({
  prompt: "select_account",
  display: "popup",
});

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);
export const singInWithFacebook = () => auth.signInWithPopup(facebookProvider);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;
  if (userAuth && userAuth.isAnonymous) return;
  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const adminRef = firestore.doc(`admins/${userAuth.uid}`);
  const adminSnapShot = await adminRef.get();
  if (adminSnapShot.exists) return;

  const snapShot = await userRef.get();
  if (!snapShot.exists) {
    console.log(userAuth);
    const { email, displayName } = userAuth;
    const createdAt = new Date();
    try {
      console.log("creating snapshot");

      const userCount = await incrementUserCountByOne();
      const userCollectionRef = firestore
        .collection("users")
        .where("userId", "==", `${userCount}`);
      const user = await userCollectionRef.get();
      const usersArray = [];
      user.forEach((snapShot) => {
        if (snapShot.exists) {
          usersArray.push(snapShot.data());
        }
      });

      if (usersArray.length > 0) {
        await createUserProfileDocument(userAuth, additionalData);
      } else {
        await userRef.set({
          userId: userCount < 10 ? `0${userCount}` : `${userCount}`,
          uid: userAuth.uid,
          id: userAuth.uid,
          ...(displayName ? { displayName } : {}),
          email,
          createdAt,
          ...additionalData,
          myWallet: 0,
          addresss: "",
          company: "",
          status: "Customer",
          totalRecharge: 0,
        });
      }
    } catch (error) {
      console.log("error creating user", error.message);
    }
  }
  return userRef;
};

const incrementUserCountByOne = async () => {
  const countRef = firestore.doc(`userCount/count`);
  const snapShot = await countRef.get();
  if (!snapShot.exists) {
    try {
      countRef.set({
        userCount: 1,
      });
    } catch (error) {
      alert(error);
    }
  } else {
    try {
      countRef.update({
        userCount: snapShot.data().userCount + 1,
      });
    } catch (error) {
      alert(error);
    }
  }
  const updatedSnapShot = await countRef.get();
  return updatedSnapShot.data().userCount;
};

export const removeItemFromCart = async (userAuth, item) => {
  console.log(userAuth);
  if (!userAuth.id) {
    return;
  }
  const cartRef = firestore.doc(`carts/${userAuth.id}`);
  const snapShot = await cartRef.get();
  console.log(item);
  const firestoreCart = snapShot.data().cart;
  let skusArray = item.skus.map((sku) => sku.sku_id);
  console.log(skusArray);
  let updatedCart = firestoreCart.map((shop) => {
    if (shop.items[0].id == item.id) {
      if (shop.items[0].skus.length == item.skus.length) {
        // jodi shobgula sku select kore tokhon oi item tai bad jabe
        return null;
      } else {
        // jod ordhek sku order kore tokhon ordhek bad jabe jokhon sku milbe
        let newSkusArray = [];
        shop.items[0].skus.map((sku) => {
          console.log(sku.sku_id);
          if (skusArray.includes(`${shop.items[0].id}-${sku.sku_id}`)) {
            console.log("milse sku bad jabe");
          } else {
            newSkusArray.push(sku);
          }
        });

        return { ...shop, items: [{ ...shop.items[0], skus: newSkusArray }] };
      }
    } else {
      return shop;
    }
  });
  console.log(updatedCart);
  await cartRef.update({
    cart: updatedCart.filter((shop) => shop),
  });
  let updatedCartArr = await cartRef.get();
  return updatedCartArr.data().cart;
};

const getPrice = (item, totalQuantity, taka) => {
  let price2 = 0;
  if (item.price_range) {
    let price_range = JSON.parse(item.price_range);
    if (price_range && price_range.length > 0) {
      console.log(totalQuantity);
      console.log(price_range);
      price_range.map((price, index2, arr) => {
        const stateQ = parseInt(totalQuantity);
        const dataQs = arr.map((p, i) => (i === 0 ? 0 : parseInt(p[0])));
        const selectedIndex = dataQs.findIndex((q, i, arr) => {
          return stateQ >= q && stateQ < (arr[i + 1] || stateQ + 1);
        });
        console.log(selectedIndex);
        const selected = selectedIndex === index2;
        console.log(selected);
        if (selected) {
          price2 = Math.round(parseFloat(price[1]) * parseFloat(taka));
        }
      });
    }
  }
  return price2;
};

export const addCartItemTofirestore = async (userAuth, product) => {
  if (!userAuth.id) {
    return;
  }
  const cartRef = firestore.doc(`carts/${userAuth.id}`);
  const snapShot = await cartRef.get();
  if (!snapShot.exists) {
    try {
      await cartRef.set({
        cart: [product],
      });
      const updatedSnapshot = await cartRef.get();
      return updatedSnapshot.data().cart;
    } catch (error) {
      console.log("error creating cartProduct", error.message);
      return [];
    }
  } else {
    let firestoreCart = snapShot.data().cart;
    const shopAlreadyAddedArray = firestoreCart.filter(
      (cartItem) => cartItem.shopId == product.shopId
    );
    let shopAlreadyAdded = null;
    if (shopAlreadyAddedArray.length == 1) {
      shopAlreadyAdded = shopAlreadyAddedArray[0];
    } else if (shopAlreadyAddedArray.length > 1) {
      for (var i = 0; i < shopAlreadyAddedArray.length; i++) {
        let shop = shopAlreadyAddedArray[i];
        if (shop.items.find((item) => item.id == product.items[0].id)) {
          shopAlreadyAdded = shop;
          break;
        } else {
          shopAlreadyAdded = shop;
        }
      }
    }

    if (shopAlreadyAdded) {
      const itemAlreadyAdded = shopAlreadyAdded.items.find(
        (item) => item.id == product.items[0].id
      );
      if (itemAlreadyAdded) {
        console.log("shop added, item added");
        console.log(product.items[0].skus.length);
        console.log(product.items[0]);
        let totalQuantity = 0;
        let mergedArray = [...product.items[0].skus];
        itemAlreadyAdded.skus.map((sku) => {
          if (
            !product.items[0].skus.find((sku1) => sku1.sku_id == sku.sku_id)
          ) {
            mergedArray.push(sku);
          } else {
            mergedArray = mergedArray.map((sku2) => {
              if (sku2.sku_id == sku.sku_id) {
                return {
                  ...sku2,
                  totalQuantity:
                    parseInt(sku.totalQuantity) + parseInt(sku2.totalQuantity),
                };
              } else {
                return {
                  ...sku2,
                };
              }
            });
          }
        });
        mergedArray.map((sku) => {
          totalQuantity += parseInt(sku.totalQuantity);
        });

        let price = getPrice(product.items[0], totalQuantity, product.currency);
        mergedArray = mergedArray.map((sku) => {
          return {
            ...sku,
            price: product.items[0].price_range ? price : sku.price,
          };
        });
        console.log(totalQuantity);
        console.log(mergedArray);
        console.log(price);
        let cartArray = [];

        for (let i = 0; i < product.items[0].skus.length; i++) {
          if (
            itemAlreadyAdded.skus.find(
              (sku) => sku.sku_id == product.items[0].skus[i].sku_id
            )
          ) {
            console.log("shop added, item added, sku added");

            // protibare last er ta miltese baki duita sku ager tai thaktese thats why 1 tar beshi sku dile only last er ta update hoitese
            let newCart = snapShot.data().cart.map((shop) => {
              if (shop.shopId == product.shopId) {
                let newShopItems = shop.items.map((item) => {
                  if (item.id == itemAlreadyAdded.id) {
                    return { ...itemAlreadyAdded, skus: mergedArray };
                  } else {
                    return item;
                  }
                });
                return { ...shop, items: newShopItems };
              } else {
                return shop;
              }
            });
            try {
              await cartRef.update({
                cart: newCart,
              });
              const updatedSnapshot = await cartRef.get();
              cartArray = updatedSnapshot.data().cart;
            } catch (error) {
              console.log("error creating cartProduct", error.message);
            }
          } else {
            console.log("shop added, item added", "sku not added");
            itemAlreadyAdded.skus.push(product.items[0].skus[i]);
            let newCart = snapShot.data().cart.map((shop) => {
              if (shop.shopId == product.shopId) {
                let newShopItems = shop.items.map((item) => {
                  if (item.id == itemAlreadyAdded.id) {
                    return {
                      ...itemAlreadyAdded,
                      skus: mergedArray,
                    };
                  } else {
                    return item;
                  }
                });
                return { ...shop, items: newShopItems };
              } else {
                return shop;
              }
            });
            try {
              await cartRef.update({
                cart: newCart,
              });
              const updatedSnapshot = await cartRef.get();
              cartArray = updatedSnapshot.data().cart;
            } catch (error) {
              console.log("error creating cartProduct", error.message);
            }
          }
        }
        return cartArray;
      } else {
        console.log("shop added, item not added");
        // eta commnet out korle eki shop er vhinno item ekta order hishebe gonno hobe
        // let newCart = snapShot.data().cart.map((shop) => {
        //   if (shop.shopId === shopAlreadyAdded.shopId) {
        //     let newItemsArray = [...product.items, ...shopAlreadyAdded.items];
        //     let newShopObj = { ...shopAlreadyAdded, items: newItemsArray };
        //     return newShopObj;
        //   } else {
        //     return shop;
        //   }
        // });
        try {
          await cartRef.update({
            // ekhane eki shop er duita vhinno item alada alada order hishebe count hocche
            cart: [...snapShot.data().cart, product],
          });
          const updatedSnapshot = await cartRef.get();
          return updatedSnapshot.data().cart;
        } catch (error) {
          console.log("error creating cartProduct", error.message);
          return [];
        }
      }
    } else {
      try {
        await cartRef.update({
          cart: [...snapShot.data().cart, product],
        });
        const updatedSnapshot = await cartRef.get();
        return updatedSnapshot.data().cart;
      } catch (error) {
        console.log("error creating cartProduct", error.message);
        return [];
      }
    }
  }
};

export const getSingleCoupon = async (couponName) => {
  const couponsCollectionRef = firestore
    .collection(`coupons`)
    .where("name", "==", couponName);

  const couponsCollection = await couponsCollectionRef.get();

  let coupons = [];
  couponsCollection.forEach((doc) => {
    coupons.push(doc.data());
  });
  if (coupons.length > 0) {
    return coupons[0];
  } else {
    return null;
  }
};
export const getSingleNotice = async (noticeId) => {
  const noticeRef = firestore.doc(`notices/${noticeId}`);

  const snapShot = await noticeRef.get();
  return snapShot.data();
};
export const getAllPartials = async () => {
  const productsCollectionRef = firestore.collection("partial-payments");

  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
    return [];
  }
};
export const addToOrdersApi = async (userAuth, orders) => {
  if (!userAuth.id) {
    return;
  }
  let ordersArray = [];
  for (let i = 0; i < orders.length; i++) {
    const shop = orders[i];
    const orderRef = firestore.doc(`ordersApi/${shop.orderId}`);
    const snapShot = await orderRef.get();
    if (!snapShot.exists) {
      try {
        await orderRef.set({
          ...shop,
          userId: userAuth.id,
          displayName: userAuth.displayName,
        });
        const updatedSnapshot = await orderRef.get();
        ordersArray.push(updatedSnapshot.data());
      } catch (error) {
        console.log("error creating orders", error.message);
        return;
      }
    } else {
      alert("this orderId was already added. try again");
    }
  }
  return ordersArray.sort((a, b) => b.time - a.time);
};
export const updateOrdersApi = async (userAuth, orders) => {
  if (!userAuth.id) {
    return;
  }

  for (let i = 0; i < orders.length; i++) {
    const shop = orders[i];
    const orderRef = firestore.doc(`ordersApi/${shop.orderId}`);
    const snapShot = await orderRef.get();
    if (snapShot.exists) {
      try {
        await orderRef.update({ ...shop, userId: userAuth.id });
      } catch (error) {
        console.log("error creating orders", error.message);
        return;
      }
    } else {
      alert("No order was found to be updated");
    }
  }
  let ordersArray = await getAllOrdersApi(userAuth.id);
  return ordersArray.sort((a, b) => b.time - a.time);
};

export const decrementCartItemFromFirestore = async (userAuth, product) => {
  if (!userAuth) return;
  const cartRef = firestore.doc(`carts/${userAuth.uid}`);
  const snapShot = await cartRef.get();
  if (!snapShot.exists) {
    return;
  } else {
    var sameVariant = snapShot.data().cart.filter((item) => {
      if (
        item.id === product.id &&
        (item.sizeOrShipsFrom
          ? item.sizeOrShipsFrom === product.sizeOrShipsFrom
          : true)
      ) {
        if (item.color ? item.color === product.color : true) {
          return item;
        }
      }
    });
    if (
      snapShot.data().cart.findIndex((item) => item.id === product.id) !== -1 &&
      sameVariant.length > 0
    ) {
      const cart = snapShot.data().cart.reduce((cartAcc, item) => {
        if (item.id === product.id && item.qty >= 1) {
          if (
            (item.color ? item.color === product.color : true) &&
            (item.sizeOrShipsFrom
              ? item.sizeOrShipsFrom === product.sizeOrShipsFrom
              : true)
          ) {
            cartAcc.push({
              ...item,
              qty: item.qty - 1,
              sum:
                (typeof item.salePrice == "string"
                  ? item.salePrice.includes("-")
                    ? item.salePrice.split("-")[1]
                    : parseInt(item.salePrice)
                  : item.salePrice) *
                (parseInt(item.qty) - 1),
            });
          } else {
            cartAcc.push(item);
          }
        } else {
          cartAcc.push(item);
        }
        return cartAcc;
      }, []);
      const newCart = cart.filter((item) => item.qty !== 0);
      try {
        await cartRef.update({
          cart: newCart,
        });
      } catch (error) {
        alert(error);
      }
    } else {
      try {
        await cartRef.update({
          cart: [
            ...snapShot.data().cart,
            {
              ...product,
              qty: 1,
              sum:
                typeof product.salePrice == "string"
                  ? product.salePrice.includes("-")
                    ? product.salePrice.split("-")[1]
                    : parseInt(product.salePrice)
                  : product.salePrice * 1,
            },
          ],
        });
      } catch (error) {
        alert(error, "error in the else block");
      }
    }
    sameVariant = [];
  }
};

export const removeCartItemFromFirestore = async (userAuth, product) => {
  if (!userAuth) return;
  const cartRef = firestore.doc(`carts/${userAuth.uid}`);
  const snapShot = await cartRef.get();
  if (!snapShot.exists) {
    return;
  } else {
    var cart = [];
    snapShot.data().cart.forEach((cartItem) => {
      if (cartItem.id !== product.id) {
        cart.push(cartItem);
      } else {
        if (cartItem.color !== product.color) {
          cart.push(cartItem);
        } else {
          if (cartItem.sizeOrShipsFrom !== product.sizeOrShipsFrom) {
            cart.push(cartItem);
          }
        }
      }
    });
    try {
      await cartRef.update({ cart });
    } catch (error) {
      alert(error);
    }
  }
};

export const removeAllCartItemFromFirestore = async (userAuth, cartItem) => {
  if (!userAuth) return;
  const cartRef = firestore.doc(`carts/${userAuth.uid}`);
  cartRef.delete();
};
// add item to firestore cart finished

// add item to firestore wishlist

export const addWishlistTofirestore = async (userAuth, product) => {
  console.log(product);
  if (!userAuth) return;
  const wishlistRef = firestore.doc(`wishlists/${userAuth.uid}`);
  const snapShot = await wishlistRef.get();
  if (!snapShot.exists) {
    wishlistRef.set({
      wishlist: [{ ...product, price_range: "", videos: "" }],
    });
  } else {
    if (
      snapShot.data().wishlist.findIndex((item) => item.id === product.id) !==
      -1
    ) {
      return;
    } else {
      wishlistRef.update({
        wishlist: [
          ...snapShot.data().wishlist,
          { ...product, price_range: "", videos: "" },
        ],
      });
    }
  }
};

export const removeFromWishlistFirestore = async (userAuth, product) => {
  if (!userAuth) return;
  const wishlistRef = firestore.doc(`wishlists/${userAuth.uid}`);
  const snapShot = await wishlistRef.get();
  const wishlist = snapShot
    .data()
    .wishlist.filter((item) => item.id !== product.id);
  wishlistRef.update({ wishlist });
};

export const addToCartAndRemoveWishlistFirestore = async (
  userAuth,
  product,
  qty
) => {
  await addCartItemTofirestore(userAuth, product, qty);
  // await removeFromWishlistFirestore(userAuth,product)
};

// wishlist ended

// orders start

const GenerateUniqueID = () => {
  return ("0000" + (Math.random() * (100000 - 101) + 101)).slice(-5);
};

export const addCartItemsToOrdersFirestore = async (
  userAuth,
  ordersArray,
  billingAddress
) => {
  if (!userAuth) return;
  const sum = getCartTotal(ordersArray);
  const paid = 0;
  const uniqueId = "gbb" + GenerateUniqueID();
  const orderRef = firestore.doc(`orders/${uniqueId}`);
  try {
    await orderRef.set({
      userId: userAuth.uid,
      otherInformation: billingAddress,
      order: ordersArray,
      sum,
      status: "order_pending",
      orderedAt: new Date(),
      paymentStatus: {
        paid,
        due: parseInt(sum) - parseInt(paid),
        total: sum,
      },
    });
    const snapShot = await orderRef.get();
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const userSnapShot = await userRef.get();
    const previousOrdersArray = userSnapShot.data().ordersArray
      ? userSnapShot.data().ordersArray
      : [];
    try {
      await userRef.update({
        ordersArray: [
          ...previousOrdersArray,
          { ...snapShot.data(), orderId: uniqueId },
        ],
        shippingAddress: billingAddress,
      });
    } catch (error) {
      alert("error creating order. try again later", error);
    }

    console.log(snapShot.data());
    return { ...snapShot.data(), orderId: uniqueId };
  } catch (error) {
    alert("error creating order. please try again", error);
  }
};

// getting all products from firestore
export const getAllFirestoreProducts = async () => {
  const productsCollectionRef = firestore.collection("products");
  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data())
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
  }
};
export const getCurrency = async () => {
  const currencyRef = firestore.doc("Currency/taka");
  try {
    const currency = await currencyRef.get();
    return currency.data();
  } catch (error) {
    alert(error);
  }
};

export const getSingleProductRequest = async (id) => {
  const orderRef = firestore.doc(`bookingRequest/${id}`);
  try {
    const order = await orderRef.get();
    return order.data();
  } catch (error) {
    alert(error);
  }
};
export const getOrderOrShipmentRequest = async (orderId) => {
  // shurute order Id hishebe check korbo
  const orderRef = firestore.doc(`ordersApi/${orderId}`);
  const shipmentRequestRef = firestore.doc(`shipForMe/${orderId}`);
  const productRequestRef = firestore.doc(`bookingRequest/${orderId}`);
  const orderSnapShot = await orderRef.get();
  const shipmentSnapShot = await shipmentRequestRef.get();
  const bookingSnapShot = await productRequestRef.get();
  console.log(orderSnapShot.data());
  if (orderSnapShot.exists) {
    // order thakle order return korbo
    return orderSnapShot.data();
  } else if (shipmentSnapShot.exists) {
    // ship for me thakle shipfor me return korbo
    return shipmentSnapShot.data();
  } else if (bookingSnapShot.exists) {
    return bookingSnapShot.data();
  } else {
    const ordersCollectionRef = firestore
      .collection("ordersApi")
      .where("trackingNo", "==", orderId);
    const shipmentCollectionRef = firestore
      .collection("shipForMe")
      .where("trackingNo", "==", orderId);
    const productRequestCollectionRef = firestore
      .collection("bookingRequest")
      .where("trackingNo", "==", orderId);

    const orders = await ordersCollectionRef.get();
    const shipments = await shipmentCollectionRef.get();
    const productRequests = await productRequestCollectionRef.get();

    if (!orders.empty) {
      const ordersArray = [];
      orders.forEach((doc) => {
        ordersArray.push(doc.data());
      });
      return ordersArray[0];
    } else if (!shipments.empty) {
      const shipmentsArray = [];
      shipments.forEach((doc) => {
        shipmentsArray.push(doc.data());
      });
      return shipmentsArray[0];
    } else if (!productRequests.empty) {
      const productReqeustsArray = [];
      productRequests.forEach((doc) => {
        productReqeustsArray.push(doc.data());
      });
      return productReqeustsArray[0];
    } else {
      return null;
    }
  }
};
export const getAllFirestoreAliProductsList = async () => {
  const aliProductsCollectionRef = firestore.collection("aliproducts");
  try {
    const products = await aliProductsCollectionRef.get();
    const aliProductsArray = [];
    products.forEach((doc) => {
      aliProductsArray.push(doc.data());
    });
    return aliProductsArray;
  } catch (error) {
    alert(error);
  }
};

// getting single product from firestore
export const getSingleProduct = async (id) => {
  try {
    const aliProductRef = firestore.doc(`aliproducts/${id}`);
    try {
      const aliProduct = await aliProductRef.get();

      return aliProduct.data();
    } catch (error) {
      alert(error);
    }
  } catch (error) {
    alert(error);
  }
};
export const getSingleShipForMe = async (bookingId) => {
  try {
    const shipForMeRef = firestore.doc(`shipForMe/${bookingId}`);
    try {
      const shipForMe = await shipForMeRef.get();

      return shipForMe.data();
    } catch (error) {
      alert(error);
    }
  } catch (error) {
    alert(error);
  }
};

export const getAllHomeCategory = async () => {
  const productsCollectionRef = firestore.collection("homeCategories");

  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
  }
};
//  upload image of bank slip or bkash slip transaction
export const uploadImage = async (file) => {
  const imageRef = storage.ref(`payments/${GenerateUniqueID()}`);

  await imageRef.put(file);
  var imgUrl = [];
  await imageRef.getDownloadURL().then((url) => {
    imgUrl.push(url);
  });
  return imgUrl[0];
};
// upload payment with image of the slip
export const uploadPayment = async (payment, user) => {
  const paymentRef = firestore.doc(`payments/${payment.orderId}`);
  try {
    const snapShot = await paymentRef.get();
    if (snapShot.exists) {
      const previousPayments = await snapShot.data().payments;
      console.log(snapShot.data());
      paymentRef.update({
        payments: [...previousPayments, payment],
      });
    } else {
      paymentRef.set({
        payments: [payment],
      });
    }
    const userRef = firestore.doc(`users/${user.id}`);
    const userSnapShot = await userRef.get();
    userRef.update({
      paymentsArray: userSnapShot.data().paymentsArray
        ? [...userSnapShot.data().paymentsArray, payment]
        : [payment],
    });
  } catch (error) {
    alert(error);
  }
};
export const updateUser = async (user) => {
  const userRef = firestore.doc(`users/${user.id}`);
  try {
    const userSnapShot = await userRef.get();
    userRef.update({
      ...user,
    });
  } catch (error) {
    alert(error);
  }
};

export default firebase;

export const getAllProductsTax = async () => {
  const productsCollectionRef = firestore.collection("taxes");
  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
  }
};
export const uploadImageProduct = async (file) => {
  const imageRef = storage.ref(`d2dExpressProduct/${file.name}`);
  try {
    await imageRef.put(file);
    var imgUrl = [];
    await imageRef.getDownloadURL().then((url) => {
      console.log(url);
      imgUrl.push(url);
    });

    return imgUrl[0];
  } catch (error) {
    return null;
  }
};
export const setProductRequest = async (bookingObj) => {
  const bookingId = `${Math.round(Math.random() * 1000000 - 1)}`;
  const bookingRef = firestore.doc(`bookingRequest/${bookingId}`);
  delete bookingObj.file;
  const snapShot = await bookingRef.get();
  if (!snapShot.exists) {
    try {
      await bookingRef.set({
        bookingId,
        ...bookingObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await bookingRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a booking with similar uid, please try again later"
    );
  }
};
export const setShipForMe = async (bookingObj) => {
  const bookingRef = firestore.doc(`shipForMe/${bookingObj.bookingId}`);
  delete bookingObj.file;
  const snapShot = await bookingRef.get();
  if (!snapShot.exists) {
    try {
      await bookingRef.set({
        ...bookingObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await bookingRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a booking with similar uid, please try again later"
    );
  }
};
export const updateShipForMe = async (bookingObj) => {
  const bookingRef = firestore.doc(`shipForMe/${bookingObj.bookingId}`);
  delete bookingObj.file;
  const snapShot = await bookingRef.get();

  try {
    await bookingRef.update({
      ...snapShot.data(),
      ...bookingObj,
    });
    console.log(snapShot.data());
    const uploadedSnapShot = await bookingRef.get();
    return uploadedSnapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const uploadShipForMeList = async (bookingObj) => {
  const bookingId = `${Math.round(Math.random() * 1000000 - 1)}`;
  const bookingRef = firestore.doc(`shipForMeList/${bookingId}`);
  delete bookingObj.file;
  const snapShot = await bookingRef.get();
  if (!snapShot.exists) {
    try {
      await bookingRef.set({
        bookingId,
        ...bookingObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await bookingRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a booking with similar uid, please try again later"
    );
  }
};
export const updateShipForMeList = async (bookingObj) => {
  const bookingRef = firestore.doc(`shipForMeList/${bookingObj.bookingId}`);
  delete bookingObj.bookingObj;
  const snapShot = await bookingRef.get();
  if (snapShot.exists) {
    try {
      await bookingRef.update({
        ...bookingObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await bookingRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a booking with similar uid, please try again later"
    );
  }
};

export const getAllBookingsOfSingleUser = async (userId) => {
  const bookingsCollectionRef = firestore
    .collection("bookingRequest")
    .where("userId", "==", userId);
  try {
    const bookings = await bookingsCollectionRef.get();

    const bookingsArray = [];
    bookings.forEach((doc) => {
      bookingsArray.push(doc.data());
    });
    return bookingsArray.sort((a, b) => b.time - a.time);
  } catch (error) {
    alert(error);
  }
};

export const deleteShipForMeList = async (bookingObj) => {
  const productTypeRef = firestore.doc(`shipForMeList/${bookingObj.bookingId}`);
  const snapShot = await productTypeRef.get();
  console.log(snapShot.data());
  try {
    await productTypeRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const getAllShipForMeOfSingleUser = async (userId) => {
  const bookingsCollectionRef = firestore
    .collection("shipForMe")
    .where("userId", "==", userId);
  try {
    const bookings = await bookingsCollectionRef.get();
    const bookingsArray = [];
    bookings.forEach((doc) => {
      bookingsArray.push(doc.data());
    });
    return bookingsArray.sort((a, b) => b.time - a.time);
  } catch (error) {
    alert(error);
  }
};

export const updateShipmentRequest = async (requestObj) => {
  const reqeustRef = firestore.doc(`shipForMe/${requestObj.bookingId}`);
  try {
    await reqeustRef.update({
      ...requestObj,
    });
    const request = await reqeustRef.get();
    return request.data();
  } catch (error) {
    alert(error);
  }
};

export const getAllOrdersApi = async (userId) => {
  if (!userId) {
    return;
  }
  const ordersCollectionRef = firestore
    .collection("ordersApi")
    .where("userId", "==", userId);
  try {
    const orders = await ordersCollectionRef.get();
    const ordersArray = [];
    orders.forEach((doc) => {
      ordersArray.push(doc.data());
    });
    return ordersArray.sort((a, b) => b.time - a.time);
  } catch (error) {
    alert(error);
  }
};

export const updateMultipleProductRequest = async (productReqArray) => {
  try {
    let bookingArray = [];
    for (let booking of productReqArray) {
      const bookingRef = firestore.doc(`bookingRequest/${booking.bookingId}`);
      await bookingRef.update({
        paymentStatus: "pending",
      });
      const bookingData = await bookingRef.get();
      bookingArray.push(bookingData);
    }
    return bookingArray;
  } catch (error) {
    alert(error);
    return [];
  }
};
export const uploadImageRechargeRequest = async (file) => {
  const imageRef = storage.ref(`rechargeRequests/${file.name}`);

  try {
    await imageRef.put(file);
    var imgUrl = [];
    await imageRef.getDownloadURL().then((url) => {
      console.log(url);
      imgUrl.push(url);
    });
    return imgUrl[0];
  } catch (error) {
    return null;
  }
};

export const getSelectedIntroModal = async () => {
  const introCollectionRef = firestore
    .collection(`intros`)
    .where("selected", "==", true);
  const intros = await introCollectionRef.get();
  let imgUrl = [];
  intros.forEach((intro) => {
    imgUrl.push(intro.data());
  });
  if (imgUrl.length > 0) {
    return imgUrl[0].imageUrl;
  } else {
    return "";
  }
};

export const uploadPaymentRequest = async (paymentObj) => {
  const paymentRequestRef = firestore.doc(
    `paymentRequest/${paymentObj.paymentId}`
  );
  delete paymentObj.file;
  const snapShot = await paymentRequestRef.get();
  try {
    if (!snapShot.exists) {
      await paymentRequestRef.set({ ...paymentObj });

      const ordersArray = [];
      await paymentObj.productRequestArray.map(async (booking) => {
        const bookingsCollectionRef = firestore
          .collection("bookingRequest")
          .where("bookingId", "==", booking.bookingId);
        const bookings = await bookingsCollectionRef.get();
        bookings.forEach(async (doc) => {
          const bookingRef = firestore.doc(
            `bookingRequest/${doc.data().bookingId}`
          );
          await bookingRef.update({
            paymentStatus: "pending",
          });
          const orderSnapshot = await bookingRef.get();
          ordersArray.push(orderSnapshot.data());
        });
      });
      return paymentObj;
    } else {
      alert("an error occurred. please try again");
    }
  } catch (error) {
    alert(error);
  }
};

export const uploadPaymentRequest2 = async (paymentObj) => {
  const paymentRequestRef = firestore.doc(
    `paymentRequestApi/${paymentObj.paymentId}`
  );
  delete paymentObj.file;
  const snapShot = await paymentRequestRef.get();
  try {
    if (!snapShot.exists) {
      await paymentRequestRef.set({ ...paymentObj, productRequest: true });
      const ordersArray = [];
      await paymentObj.productRequestArray.map(async (booking) => {
        const bookingsCollectionRef = firestore
          .collection("bookingRequest")
          .where("bookingId", "==", booking.bookingId);
        const bookings = await bookingsCollectionRef.get();
        bookings.forEach(async (doc) => {
          const bookingRef = firestore.doc(
            `bookingRequest/${doc.data().bookingId}`
          );
          await bookingRef.update({
            paymentStatus: "pending",
          });
          const orderSnapshot = await bookingRef.get();
          ordersArray.push(orderSnapshot.data());
        });
      });
      return paymentObj;
    } else {
      alert("an error occurred. please try again");
    }
  } catch (error) {
    alert(error);
  }
};
export const uploadRefundApply = async (refundObj) => {
  const refundRequestRef = firestore.doc(`refundRequest/${refundObj.refundId}`);

  const snapShot = await refundRequestRef.get();
  try {
    if (!snapShot.exists) {
      await refundRequestRef.set({
        ...refundObj,
        status: "pending",
        time: refundObj.refundId,
      });
      if (refundObj.productRequest) {
        const orderRef = firestore.doc(
          `bookingRequest/${refundObj.order.bookingId}`
        );
        await orderRef.update({
          refundStatus: "requested",
        });
        const orderSnapshot = await orderRef.get();
        return orderSnapshot.data();
      } else {
        const orderRef = firestore.doc(`ordersApi/${refundObj.order.orderId}`);
        await orderRef.update({
          refundStatus: "requested",
        });
        const orderSnapshot = await orderRef.get();
        return orderSnapshot.data();
      }
    } else {
      alert("an error occurred. please try again");
    }
  } catch (error) {
    alert(error);
  }
};
export const uploadShipmentPaymentRequest = async (paymentObj) => {
  const paymentRequestRef = firestore.doc(
    `paymentRequest/${paymentObj.paymentId}`
  );
  delete paymentObj.file;
  const snapShot = await paymentRequestRef.get();
  try {
    if (!snapShot.exists) {
      await paymentRequestRef.set({ ...paymentObj });

      const ordersArray = [];
      await paymentObj.productRequestArray.map(async (booking) => {
        const bookingsCollectionRef = firestore
          .collection("shipForMe")
          .where("bookingId", "==", booking.bookingId);
        const bookings = await bookingsCollectionRef.get();
        bookings.forEach(async (doc) => {
          const bookingRef = firestore.doc(`shipForMe/${doc.data().bookingId}`);
          await bookingRef.update({
            paymentRequested: true,
          });
          const orderSnapshot = await bookingRef.get();
          ordersArray.push(orderSnapshot.data());
        });
      });
      return paymentObj;
    } else {
      alert("an error occurred. please try again");
    }
  } catch (error) {
    alert(error);
  }
};
export const uploadPaymentRequestApi = async (paymentObj) => {
  const paymentRequestRef = firestore.doc(
    `paymentRequestApi/${paymentObj.paymentId}`
  );
  delete paymentObj.file;
  const snapShot = await paymentRequestRef.get();
  try {
    if (!snapShot.exists) {
      await paymentRequestRef.set({ ...paymentObj });

      const ordersArray = [];
      console.log(paymentObj.pendingOrders);
      await paymentObj.pendingOrders.map(async (shop) => {
        const ordersApiCollectionRef = firestore
          .collection("ordersApi")
          .where("orderId", "==", shop.orderId);
        const orders = await ordersApiCollectionRef.get();
        orders.forEach(async (doc) => {
          console.log(doc.data());
          console.log(doc.data().orderId);
          const orderRef = firestore.doc(`ordersApi/${doc.data().orderId}`);
          const snapShot = await orderRef.get();
          if (snapShot.exists) {
            await orderRef.update({
              ...shop,
              orderTotal:
                doc.data().orderTotal -
                doc.data().orderTotal * (paymentObj.offer.split("%")[0] / 100),
            });
          }
          const orderSnapshot = await orderRef.get();
          ordersArray.push(orderSnapshot.data());
        });
      });
      return paymentObj;
    } else {
      alert("an error occurred. please try again");
    }
  } catch (error) {
    alert(error);
  }
};
export const uploadPaymentRequestApi2 = async (paymentObj) => {
  const paymentRequestRef = firestore.doc(
    `paymentRequestApi/${paymentObj.paymentId}`
  );
  delete paymentObj.file;
  const snapShot = await paymentRequestRef.get();
  try {
    if (!snapShot.exists) {
      await paymentRequestRef.set({ ...paymentObj });

      const ordersArray = [];
      console.log(paymentObj.pendingOrders);
      await paymentObj.pendingOrders.map(async (shop) => {
        const ordersApiCollectionRef = firestore
          .collection("ordersApi")
          .where("orderId", "==", shop.orderId);
        const orders = await ordersApiCollectionRef.get();
        orders.forEach(async (doc) => {
          console.log(doc.data());
          console.log(doc.data().orderId);
          const orderRef = firestore.doc(`ordersApi/${doc.data().orderId}`);
          const snapShot = await orderRef.get();
          if (snapShot.exists) {
            await orderRef.update({
              paymentStatus: "pending",
            });
          }
          const orderSnapshot = await orderRef.get();
          ordersArray.push(orderSnapshot.data());
        });
      });
      return paymentObj;
    } else {
      alert("an error occurred. please try again");
    }
  } catch (error) {
    alert(error);
  }
};
export const getSingleOrderApi = async (id) => {
  const orderRef = firestore.doc(`ordersApi/${id}`);
  try {
    const order = await orderRef.get();
    return order.data();
  } catch (error) {
    alert(error);
  }
};

export const getSingleUser = async (id) => {
  const userRef = firestore.doc(`users/${id}`);
  try {
    const user = await userRef.get();
    return user.data();
  } catch (error) {
    alert(error);
  }
};
export const getAllD2DRates = async (freightType, country) => {
  const d2dRatesCollectionRef = firestore.collection(
    `d2d-rates-${freightType}-${country}`
  );
  try {
    const d2dRates = await d2dRatesCollectionRef.get();
    const d2dRatesArray = [];
    d2dRates.forEach((doc) => {
      d2dRatesArray.push(doc.data());
    });
    return d2dRatesArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllNotices = async () => {
  const noticesCollectionRef = firestore.collection("notices");
  try {
    const notices = await noticesCollectionRef.get();
    let noticesArray = [];
    notices.forEach((doc) => {
      noticesArray.push(doc.data());
    });

    return noticesArray.sort((a, b) => b.time - a.time);
  } catch (error) {
    alert(error);
  }
};
export const getAllShipForMeList = async (userId) => {
  const listCollectionRef = firestore
    .collection(`shipForMeList`)
    .where("userId", "==", userId);
  try {
    const list = await listCollectionRef.get();
    const listsArray = [];
    list.forEach((doc) => {
      listsArray.push(doc.data());
    });
    return listsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllBanners = async () => {
  const bannersCollectionRef = firestore
    .collection("banners")
    .where("visible", "==", true);

  try {
    const bannersCollections = await bannersCollectionRef.get();
    const bannersCollectionsArray = [];
    bannersCollections.forEach((doc) => {
      bannersCollectionsArray.push(doc.data());
    });
    return bannersCollectionsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllCampaigns = async () => {
  const bannersCollectionRef = firestore
    .collection("campaigns")
    .where("visible", "==", true);

  try {
    const bannersCollections = await bannersCollectionRef.get();
    const bannersCollectionsArray = [];
    bannersCollections.forEach((doc) => {
      bannersCollectionsArray.push(doc.data());
    });
    return bannersCollectionsArray;
  } catch (error) {
    alert(error);
  }
};

export const verifyOtp = async (number, otp) => {
  const otpRef = firestore.doc(`otpSms/${number}`);
  const snapShot = await otpRef.get();
  try {
    if (!snapShot.exists) {
      alert("Your OTP is expired.Try again.");
      return {
        displayName: "",
        email: "",
      };
    } else {
      if (snapShot.data().otp == otp) {
        const userAuth = {
          uid: number,
          id: number,
          email: "",
          displayName: number,
        };
        const userRef = await createUserProfileDocument(userAuth, {
          mobileNo: `${number}`,
        });
        await auth.signInAnonymously();
        const userObj = await userRef.get();
        return userObj.data();
      }
    }
  } catch (error) {
    alert(error);
  }
};

export const sendOtp = async (number, otp) => {
  const otpRef = firestore.doc(`otpSms/${number}`);

  const snapShot = await otpRef.get();
  try {
    if (!snapShot.exists) {
      await otpRef.set({ number, otp });
      setTimeout(async () => {
        await otpRef.delete();
      }, 60000);
    } else {
      await otpRef.update({ number, otp });
      setTimeout(async () => {
        await otpRef.delete();
      }, 60000);
    }
  } catch (error) {
    alert(error);
  }
};
