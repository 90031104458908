import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import {
  getAllProductsTax,
  uploadImageRechargeRequest,
} from "../../firebase/firebase.utils";
import LogoBlock from "../layouts/common/logo-block";
import {
  changeCurrency,
  setSearchedProductsArray,
  setSearchedProductDetail,
} from "../../actions";
import { connect } from "react-redux";
import "./buy-for-me.css";
import buyForMeImg from "./buyformeimg.png";
import man from "./user-profile/buy-and-ship-for-me/assets/plus image.jpeg";
import { HashLoader, CircleLoader } from "react-spinners";
class BuyForMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
      searchBarValue: "",
      imageUrl: man,
      width: 0,
      height: 0,
    };
  }
  componentDidMount = async () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSearchBarSubmit = (event) => {
    event.preventDefault();
    if (this.state.searchBarValue.length < 35) {
      var win = window.open(
        `${process.env.PUBLIC_URL}/collection/${this.state.searchBarValue}`,
        "_blank"
      );
      console.log(win);
      win.focus();
    } else {
      this.props.setSearchedProductsArray(0, []);
      if (this.state.searchBarValue.includes("1688")) {
        let productId = this.state.searchBarValue.split("/")[4].split(".")[0];
        console.log(productId);

        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${productId}`,
          "_blank"
        );
        win.focus();
      } else {
        let taobaoUrl = new URL(this.state.searchBarValue);
        let params = taobaoUrl.search;
        const urlParams = new URLSearchParams(params);
        const id = urlParams.get("id");
        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${id}`,
          "_blank"
        );
        win.focus();
      }
    }
    this.setState({ searchBarValue: "" });
  };

  handleImgChange = async (e, i) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ isLoading: false });
      this.openPage(imgUrl);
    }
  };
  openPage = (imgUrl) => {
    var win = window.open(
      `${process.env.PUBLIC_URL}/collection/img_uploads?imageUrl=${imgUrl}`,
      "_blank"
    );
    win.focus();
  };

  render() {
    return (
      <>
        {this.state.isLoading && (
          <div
            style={{
              backgroundColor: "#00000024",
              width: "100%",
              height: this.state.height,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              position: "absolute",
              top: "0%",
              left: "0%",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircleLoader
                loading={this.state.isLoading}
                color="#18768f"
                size={55}
              />
            </div>
          </div>
        )}
        <div>
          <Helmet>
            <title>Paicart | Buy and ship for me</title>
          </Helmet>
          <Breadcrumb title={"Tax and Shipping"} />

          <section
            className="about-page  section-b-space"
            style={{ paddingBottom: 0 }}
          >
            <div className="container">
              <div className="row header-text-1">
                You will find it,we will buy it.
              </div>
              <div className="row header-text-2">
                Did you find your products? Enter the URL of the products page
                below and hit enter!
              </div>
              <div className="search-bar-logo">
                <section className="section-b-space j-box pets-box ratio_square header-text-box">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="title1 title5">
                          <h4
                            className="title-inner1 taobao-support"
                            style={{ color: "#18768f" }}
                          >
                            Search any china wholesale product by name or
                            picture
                          </h4>
                          <hr role="tournament6" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="top-searchbar-container"
                  style={{ marginBottom: 40 }}
                >
                  <form
                    className="form_search search-form-taobao"
                    role="form"
                    onSubmit={this.handleSearchBarSubmit}
                    style={{
                      borderRadius: "0px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      id="query search-autocomplete"
                      type="search"
                      placeholder="Search 100+ millions of products"
                      value={this.state.searchBarValue}
                      onChange={this.handleChange}
                      name="searchBarValue"
                      className="nav-search nav-search-field"
                      aria-expanded="true"
                      style={{ color: "#555" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        className="btn-search"
                        style={{ width: "40px", position: "relative" }}
                      >
                        <i
                          className="fa fa-camera"
                          style={{
                            color: "#11677e",
                            marginTop: 10,
                            fontSize: "150%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.document
                              .getElementById("upload-image-input10")
                              .click();
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        style={{
                          position: "relative",
                          backgroundColor: "#11677e",
                          minHeight: "100%",
                          marginTop: -5,
                          marginRight: -5,
                          borderRadius: 0,
                        }}
                        onClick={this.handleSearchBarSubmit}
                      >
                        <i
                          className="fa fa-search"
                          style={{
                            color: "white",
                            margin: "auto",
                            padding: "5px 13px",
                            fontSize: "120%",
                          }}
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <input
                id="upload-image-input10"
                className="upload"
                type="file"
                style={{
                  position: "absolute",
                  zIndex: 5,
                  maxWidth: "50px",
                  marginTop: "10px",
                  display: "none",
                }}
                onChange={(e) => this.handleImgChange(e)}
              />
            </div>
          </section>
          <section style={{ paddingTop: 0, marginTop: -20, paddingBottom: 50 }}>
            <div>
              <img src={buyForMeImg} style={{ width: "100%" }} />
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default connect(
  null,
  { setSearchedProductDetail, setSearchedProductsArray }
)(BuyForMe);
