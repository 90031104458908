import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { uploadPaymentRequestRedux2 } from "../../../../actions/index";
import { uploadImageRechargeRequest } from "../../../../firebase/firebase.utils";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import city from "../../../../assets/images/portfolio/payment-logo/city.png";
import bkash from "../../../../assets/images/portfolio/payment-logo/bkash.png";
import rocket from "../../../../assets/images/portfolio/payment-logo/rocket.png";
import nagad from "../../../../assets/images/portfolio/payment-logo/nagad.png";
import sslCommerze from "./assets/ssl commerze.png";
import "./makePaymentModal.css";
import { CircleLoader } from "react-spinners";
import man from "./assets/plus image.jpeg";
import axios from "axios";
import "./paymentModal.css";
import PayWithBkash from "./assets/bkash-wt.png";
class PaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      method: "City",
      submit_loader: false,
      file: "",
      loading: false,
      imageUrl: man,
      payableTotal: 0,
    };
  }

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    if (this.state.payableTotal == 0) {
      alert("Amount to pay now can't be 0. Please update your amount first.");
      return;
    }
    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event, payNowPercent, orderTotal, totalPaid) => {
    event.preventDefault();
    const { currentUser, productRequestArray, pendingOrders } = this.props;
    const { payableTotal } = this.state;

    if (this.state.payableTotal == 0) {
      alert("Amount to pay now can't be 0");
      return;
    }
    if (this.state.loading) {
      alert("please wait for the image to be uploded.");
      return;
    }
    if (this.state.imageUrl === man) {
      alert("Please upload your transactions copy.");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }
    if (this.state.payableTotal > orderTotal - totalPaid) {
      alert("Amount to pay now can't be more than due amount.");
      return;
    }

    if (totalPaid == 0 && this.state.payableTotal < (orderTotal * 60) / 100) {
      alert(`Payable amount can't be less than 60% of the due amount`);
      return;
    }
    if (!this.state.method) {
      alert("Please choose payment method.");
      return;
    }
    const paymentId = Math.floor(Math.random() * Date.now());

    this.setState({ submit_loader: true });

    let date = new Date();
    let actualTotal = 0;
    pendingOrders.map((shop) => (actualTotal += parseInt(shop.orderTotal)));

    await this.props.uploadPaymentRequestRedux2({
      status: "pending",
      date: date.toLocaleDateString("en-GB"),
      paymentId,
      amount: payableTotal,
      payableTotal,
      actualTotal,
      actualTotalAfterCouponAdd: actualTotal,
      offer: "",
      payNowPercent,
      total: actualTotal,
      payableTotalWithGatewayCharge:
        this.state.method == "City"
          ? payableTotal
          : parseInt(
              parseInt(payableTotal * (1.5 / 100) + parseInt(payableTotal))
            ),
      imageUrl: this.state.imageUrl,
      method: this.state.method,
      productRequestArray: this.props.pendingOrders,
      pendingOrders: this.props.pendingOrders,
      userId: currentUser.id,
      displayName: currentUser.displayName,
      time: date.getTime(),
    });

    toast.success(
      "Your payment Request is submitted! Our team member will confirm it soon"
    );
    this.setState({
      amount: "",
      imageUrl: man,
      method: "",
      file: "",
      loading: false,
      submit_loader: false,
    });

    document.getElementById("modal-close-icon-payment-modal-2").click();
  };

  handleBkashPayment = async (payNowPercent, orderTotal, totalPaid) => {
    const { currentUser, currency, total, offer, couponAmount } = this.props;
    let { pendingOrders } = this.props;
    const { payableTotal } = this.state;

    if (this.state.payableTotal == 0) {
      alert("Amount to pay now can't be 0");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }
    if (this.state.payableTotal > orderTotal - totalPaid) {
      alert("Amount to pay now can't be more than due amount.");
      return;
    }

    if (totalPaid == 0 && this.state.payableTotal < (orderTotal * 60) / 100) {
      alert(`Payable amount can't be less than 60% of the due amount`);
      return;
    }
    if (!this.state.method) {
      alert("Please choose payment method.");
      return;
    }
    const paymentId = Math.floor(Math.random() * Date.now());

    this.setState({ submit_loader: true });

    let date = new Date();
    let actualTotal = 0;
    pendingOrders.map((shop) => (actualTotal += parseInt(shop.orderTotal)));
    try {
      let { data } = await axios.post(
        "https://paicart.com/api/v1/bkash/create-payment",
        {
          status: "pending",
          date: date.toLocaleDateString("en-GB"),
          paymentId,
          amount: payableTotal,
          payableTotal,
          actualTotal,
          actualTotalAfterCouponAdd: actualTotal,
          offer: "",
          payNowPercent,
          total: actualTotal,
          payableTotalWithGatewayCharge:
            this.state.method == "City"
              ? payableTotal
              : parseInt(
                  parseInt(payableTotal * (1.5 / 100) + parseInt(payableTotal))
                ),

          method: this.state.method,
          productRequestArray: this.props.pendingOrders,
          pendingOrders: this.props.pendingOrders,
          userId: currentUser.id,
          displayName: currentUser.displayName,
          time: date.getTime(),
          productRequest: true,
          url: window.location.href,
        }
      );
      window.open(data.bkashURL, "_self");
      console.log(data);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred!Please try again later.");
    }

    this.setState({
      amount: "",
      imageUrl: man,
      method: "Bkash",
      file: "",
      loading: false,
      submit_loader: false,
      payableTotal: 0,
    });
  };

  readyForSslCheckout = async (total) => {
    const { currentUser } = this.props;
    this.setState({
      submit_loader: true,
    });
    const data = {
      name: `${currentUser.userId}-${currentUser.displayName}`,
      amount: Math.round(total),
      from:
        window.location.pathname == "/user-my-invoice-express"
          ? "express"
          : "d2d",
    };
    const response = await axios.post(
      "http://localhost:5000/init-sslCommerz",
      data
    );
    if (response.data && response.data.length > 30) {
      window.location.replace(response.data);
    } else {
      alert("checkout failed. Try again later.");
    }

    this.setState({ submit_loader: false });
  };

  render() {
    const { email, password, invoiceArray, payableTotal } = this.state;
    const { currency, pendingOrders, total, offer, couponAmount } = this.props;

    let actualtotal = 0;
    pendingOrders.map((shop) => {
      if (shop.shippingChargeCustomer && shop.shippingChargeCustomer !== 0) {
        actualtotal +=
          parseInt(shop.orderTotal) +
          parseInt(shop.shippingChargeCustomer) +
          parseInt(shop.localShipping ? shop.localShipping : 0) +
          parseInt(shop.otherCost ? shop.otherCost : 0) -
          parseInt(shop.discount ? shop.discount : 0);
      } else {
        actualtotal +=
          parseInt(shop.orderTotal) +
          (shop.localShipping ? parseInt(shop.localShipping) : 0) +
          (shop.otherCost ? parseInt(shop.otherCost) : 0) -
          (shop.discount ? parseInt(shop.discount) : 0);
      }
    });
    let totalPaid = 0;
    pendingOrders.map((shop) => {
      if (shop.payments && shop.payments.length > 0) {
        shop.payments.map((payment) => {
          totalPaid += parseInt(payment.amount);
        });
      }
    });
    console.log(pendingOrders);
    console.log(actualtotal);
    let payNowPercent = 0;
    if (payableTotal && payableTotal !== 0) {
      payNowPercent = `${parseFloat(
        (payableTotal / (actualtotal - totalPaid)) * 100
      ).toFixed(2)}%`;
    }
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div
              className="modal-content final-payment-modal"
              style={{
                backgroundColor: "white",
                overflowY: "scroll",
                maxHeight: 700,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <div className="modal-body p-0">
                <section
                  className="pos-rel bg-light-gray"
                  style={{ paddingTop: 0 }}
                >
                  <div className="container-fluid make-payment-container">
                    <a
                      id="modal-close-icon-payment-modal-2"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.props.startToggleModal(null);
                      }}
                    >
                      <i
                        className="icofont-close-line"
                        style={{
                          color: "black",
                          fontWeight: "bolder",
                          paddingRight: 5,
                        }}
                      />
                    </a>
                    <div className="d-lg-flex justify-content-center no-gutters mb-spacer-md">
                      <div className="col">
                        <div
                          className="px-3 padding-right-disable"
                          style={{ paddingRight: 0, paddingLeft: 0 }}
                        >
                          <h2 className="mb-1 fw-6 make-payment-header">
                            Make Payment
                          </h2>

                          <div
                            style={{
                              color: "black",

                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              marginBottom: "25px",
                            }}
                          >
                            <div>
                              <div>Total Amount: {actualtotal}Tk</div>
                              <div>Total Paid: {totalPaid}Tk</div>
                            </div>
                          </div>
                          <Tabs style={{ marginBottom: "40px" }}>
                            <TabList
                              className="nav nav-tabs tab-coupon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "15px",
                              }}
                            >
                              <Tab className="nav-link each-option">
                                Direct Deposite
                              </Tab>
                              <Tab
                                className="nav-link each-option"
                                onClick={() => {
                                  this.setState({ method: "Bkash" });
                                }}
                              >
                                Mobile Banking
                              </Tab>
                              <Tab
                                disabled={true}
                                className="nav-link each-option"
                                onClick={() => {
                                  alert(
                                    "Sorry. We are currently working on it. Please do Direct deposite now"
                                  );
                                }}
                              >
                                Credit/Debit Card
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <div>
                                <div
                                  style={{ fontWeight: "bold", color: "#333" }}
                                >
                                  Choose payment method:
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginTop: 6,
                                  }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.setState({
                                        method: "City",
                                      });
                                    }}
                                  >
                                    <img
                                      src={city}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        widhth: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.method == "City"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.method == "City"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      City Bank
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 12,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        method: "Bkash",
                                      });
                                    }}
                                  >
                                    <img
                                      src={bkash}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        widhth: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.method == "Bkash"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.method == "Bkash"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      Bkash
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: 12,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        method: "Rocket",
                                      });
                                    }}
                                  >
                                    <img
                                      src={rocket}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        widhth: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.method == "Rocket"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.method == "Rocket"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      Rocket
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: 12,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        method: "Nagad",
                                      });
                                    }}
                                  >
                                    <img
                                      src={nagad}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        widhth: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.method == "Nagad"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.method == "Nagad"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      Nagad
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    color: "#333",
                                    marginTop: 10,
                                  }}
                                >
                                  Amount to Pay now:
                                </div>
                                <input
                                  type="number"
                                  name=""
                                  className="form-control"
                                  placeholder="Enter the amount you want to pay now"
                                  value={this.state.payableTotal}
                                  onChange={(e) => {
                                    if (
                                      e.target.value >
                                      actualtotal - totalPaid
                                    ) {
                                      alert(
                                        `Payable amount can't be more than the due amount`
                                      );
                                      return;
                                    }

                                    this.setState({
                                      payableTotal: e.target.value,
                                    });
                                  }}
                                  style={{ marginBottom: 20 }}
                                />

                                <div
                                  style={{
                                    color: "white",
                                    padding: "10px",
                                    backgroundColor: "rgb(1, 70, 109)",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: 20,
                                  }}
                                >
                                  নিন্মোক্ত একাউন্টতে জমা/ট্রান্সফার করুন
                                </div>
                                <div className="table-responsive-md">
                                  <table className="table">
                                    <thead>
                                      <tr className="table-light">
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          Bank/Mobile Banking
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          AC Name
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          AC No.
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          {this.state.method == "City"
                                            ? "Branch"
                                            : "AC Type"}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.method == "City" && (
                                        <tr
                                          className="table-light"
                                          key="City Bank"
                                          style={{
                                            borderBottom: "2px solid #ebeef4",
                                          }}
                                        >
                                          <th scope="row">
                                            <img
                                              src={city}
                                              style={{
                                                height: 40,
                                                width: 40,
                                                maxHeight: 40,
                                                maxWidth: 40,
                                                border: "2px solid gainsboro",
                                                borderRadius: 5,
                                              }}
                                            />{" "}
                                            City Bank
                                          </th>
                                          <td>MD MOSTAFA SHEIKH</td>
                                          <td>
                                            2303116295001 <br />
                                            <span style={{ fontSize: 11 }}>
                                              Routing:225670228{" "}
                                            </span>
                                          </td>
                                          <td>Bhulta, Narayanganj</td>
                                        </tr>
                                      )}
                                      {this.state.method == "Bkash" && (
                                        <tr
                                          className="table-light"
                                          key="Bkash"
                                          style={{
                                            borderBottom: "2px solid #ebeef4",
                                          }}
                                        >
                                          <th scope="row">
                                            <img
                                              src={bkash}
                                              style={{
                                                height: 40,
                                                width: 40,
                                                maxHeight: 40,
                                                maxWidth: 40,
                                                border: "2px solid gainsboro",
                                                borderRadius: 5,
                                              }}
                                            />{" "}
                                            Bkash
                                          </th>
                                          <td>MD MOSTAFA SHEIKH </td>
                                          <td>01303‑427088</td>
                                          <td>Personal</td>
                                        </tr>
                                      )}
                                      {this.state.method == "Rocket" && (
                                        <tr
                                          className="table-light"
                                          key="Rocket"
                                          style={{
                                            borderBottom: "2px solid #ebeef4",
                                          }}
                                        >
                                          <th scope="row">
                                            <img
                                              src={rocket}
                                              style={{
                                                height: 40,
                                                width: 40,
                                                maxHeight: 40,
                                                maxWidth: 40,
                                                border: "2px solid gainsboro",
                                                borderRadius: 5,
                                              }}
                                            />{" "}
                                            Rocket
                                          </th>
                                          <td>MD MOSTAFA SHEIKH </td>
                                          <td>01303-4270888</td>
                                          <td>Personal</td>
                                        </tr>
                                      )}
                                      {this.state.method == "Nagad" && (
                                        <tr
                                          className="table-light"
                                          key="Nagad"
                                          style={{
                                            borderBottom: "2px solid #ebeef4",
                                          }}
                                        >
                                          <th scope="row">
                                            <img
                                              src={nagad}
                                              style={{
                                                height: 40,
                                                width: 40,
                                                maxHeight: 40,
                                                maxWidth: 40,
                                                border: "2px solid gainsboro",
                                                borderRadius: 5,
                                              }}
                                            />{" "}
                                            Nagad
                                          </th>
                                          <td>MD MOSTAFA SHEIKH </td>
                                          <td>01303‑427088</td>
                                          <td>Personal</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  className="table-responsive-md"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <table className="table">
                                    <thead>
                                      <tr
                                        className="table-light"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="col">Total Due Amount </th>
                                        <th scope="col">
                                          {parseInt(actualtotal) -
                                            parseInt(totalPaid)}
                                          Tk{" "}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        className="table-light"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="col">
                                          Pay Now ({payNowPercent})
                                        </th>
                                        <th scope="col">
                                          {payableTotal ? payableTotal : 0}Tk
                                        </th>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="City Bank"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">
                                          Fee
                                          {this.state.method == "City"
                                            ? "(0%)"
                                            : "(1.5%)"}
                                        </th>
                                        <td>
                                          {this.state.method == "City"
                                            ? 0
                                            : parseInt(
                                                payableTotal * (1.5 / 100)
                                              )}
                                          Tk
                                        </td>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="City Bank-1"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th
                                          scope="row"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Amount to Pay
                                        </th>
                                        <td style={{ fontWeight: "bold" }}>
                                          {payableTotal
                                            ? this.state.method == "City"
                                              ? parseInt(payableTotal)
                                              : parseInt(
                                                  parseInt(payableTotal) *
                                                    (1.5 / 100) +
                                                    parseInt(payableTotal)
                                                )
                                            : 0}
                                          Tk
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div
                                style={{
                                  maxWidth: "500px",
                                  borderRadius: "10px",
                                  background: "rgb(1, 70, 109)",
                                  padding: "40px",
                                  paddingBottom: "0px",
                                  margin: "auto",
                                }}
                              >
                                <form
                                  onSubmit={(e) =>
                                    this.handleSubmit(
                                      e,
                                      payNowPercent,
                                      actualtotal,
                                      totalPaid
                                    )
                                  }
                                >
                                  <div className="form-row mb-4 upload-title">
                                    জমার ছবি/স্ক্রিনশট আপলোড করুন
                                  </div>

                                  <div
                                    className="form-row mb-1"
                                    style={{
                                      fontSize: "130%",
                                      color: "white",
                                      fontWeight: "bold",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    Upload Image
                                  </div>
                                  <div
                                    className="form-row mb-2"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div
                                      className="box-input-file"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      {this.state.loading ? (
                                        <div
                                          className="spinner-border"
                                          role="status"
                                          style={{
                                            color: "purple",
                                            paddingTop: "25px",
                                            backgroundColor: "white",
                                            width: 150,
                                            height: 150,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            border: "1px solid gainsboro",
                                            borderRadius: 5,
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginTop: 30,
                                              padding: 0,
                                              marginRight: 20,
                                            }}
                                          >
                                            <CircleLoader
                                              loading={this.state.loading}
                                              color="blue"
                                              size={35}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <img
                                            className="img-100 lazyloaded blur-up"
                                            src={this.state.imageUrl}
                                            alt="#"
                                            style={{
                                              zIndex: 6,
                                              cursor: "pointer",
                                              maxWidth: 150,
                                              minWidth: 150,
                                              minHeight: 150,
                                              maxHeight: 150,
                                              border: "1px solid gainsboro",
                                              borderRadius: 5,
                                            }}
                                            onClick={() => {
                                              document
                                                .getElementById(
                                                  "upload-image-input"
                                                )
                                                .click();
                                            }}
                                          />

                                          <input
                                            id="upload-image-input"
                                            className="upload"
                                            type="file"
                                            style={{
                                              position: "absolute",
                                              zIndex: 5,
                                              maxWidth: "50px",
                                              marginTop: "10px",
                                            }}
                                            onChange={(e) =>
                                              this._handleImgChange(e, 0)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    className="form-row"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div
                                      className="col pt-2"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        style={{
                                          minWidth: "100px",
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          minHeight: "30px",
                                          backgroundColor: "#f54c3c",
                                          border: "1px solid #f54c3c",
                                          borderRadius: 5,
                                          padding: 10,
                                        }}
                                      >
                                        {!this.state.submit_loader && (
                                          <>Upload</>
                                        )}

                                        <CircleLoader
                                          loading={this.state.submit_loader}
                                          color="white"
                                          size={15}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </form>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    fontSize: "14px",
                                    color: "white",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  আপনার ব্যংক জমা স্লিপ অথবা ট্রান্সফার এর ছবিটি
                                  উপরে আপলোড করে পেমেন্ট সম্পন্ন করুন।
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div>
                                <div
                                  style={{ fontWeight: "bold", color: "#333" }}
                                >
                                  Choose payment method:
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginTop: 6,
                                  }}
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 12,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        method: "Bkash",
                                      });
                                    }}
                                  >
                                    <img
                                      src={bkash}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        width: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.method == "Bkash"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.method == "Bkash"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      Bkash
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    color: "#333",
                                    marginTop: 10,
                                  }}
                                >
                                  Amount to Pay now:
                                </div>
                                <input
                                  type="number"
                                  name=""
                                  className="form-control"
                                  placeholder="Enter the amount you want to pay now"
                                  value={this.state.payableTotal}
                                  onChange={(e) => {
                                    if (
                                      e.target.value >
                                      actualtotal - totalPaid
                                    ) {
                                      alert(
                                        `Payable amount can't be more than the due amount`
                                      );
                                      return;
                                    }

                                    this.setState({
                                      payableTotal: e.target.value,
                                    });
                                  }}
                                />
                                <div
                                  style={{
                                    color: "gray",
                                  }}
                                >
                                  {totalPaid == 0
                                    ? "***অর্ডার কনফার্ম এর জন্য নূন্যতম ৬০% পেমেন্ট করুন"
                                    : "***নিজের পছন্দমত এমাউন্ট পেমেন্ট করুন"}
                                </div>
                                <div
                                  className="table-responsive-md"
                                  style={{
                                    marginTop: "10px",
                                  }}
                                >
                                  <table
                                    className="table"
                                    style={{ marginBottom: 0 }}
                                  >
                                    <thead>
                                      <tr
                                        className="table-light"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="col">Total Due Amount </th>
                                        <th scope="col">
                                          {parseInt(actualtotal) -
                                            parseInt(totalPaid)}
                                          Tk{" "}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        className="table-light"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="col">
                                          Pay Now ({payNowPercent})
                                        </th>
                                        <th scope="col">
                                          {payableTotal ? payableTotal : 0}Tk
                                        </th>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="City Bank"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">
                                          Fee
                                          {this.state.method == "City"
                                            ? "(0%)"
                                            : "(1.5%)"}
                                        </th>
                                        <td>
                                          {this.state.method == "City"
                                            ? 0
                                            : parseInt(
                                                payableTotal * (1.5 / 100)
                                              )}
                                          Tk
                                        </td>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="City Bank-1"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th
                                          scope="row"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Amount to Pay
                                        </th>
                                        <td style={{ fontWeight: "bold" }}>
                                          {payableTotal
                                            ? this.state.method == "City"
                                              ? parseInt(payableTotal)
                                              : parseInt(
                                                  parseInt(payableTotal) *
                                                    (1.5 / 100) +
                                                    parseInt(payableTotal)
                                                )
                                            : 0}
                                          Tk
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#d62267",
                                    paddingTop: 5,
                                    paddingBottom: 10,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    className="pay-with-bkash"
                                    onClick={() => {
                                      this.handleBkashPayment(
                                        payNowPercent,
                                        actualtotal,
                                        totalPaid
                                      );
                                    }}
                                    src={PayWithBkash}
                                  />
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div
                                className="table-responsive-md"
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "30px",
                                }}
                              >
                                <table className="table">
                                  <thead>
                                    <tr
                                      className="table-light"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="col">Total</th>
                                      <th scope="col">{actualtotal}Tk</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      className="table-light"
                                      key="City Bank-2"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="row">Fee(2.5%)</th>
                                      <td>
                                        {parseInt(actualtotal) * (2.5 / 100)}
                                        Tk
                                      </td>
                                    </tr>
                                    <tr
                                      className="table-light"
                                      key="City Bank-3"
                                    >
                                      <th
                                        scope="row"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Amount to Pay
                                      </th>
                                      <td style={{ fontWeight: "bold" }}>
                                        {parseInt(
                                          parseInt(actualtotal) * (2.5 / 100) +
                                            actualtotal
                                        )}
                                        Tk
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <button
                                className="btn"
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  backgroundColor: "#f54c3c",
                                  color: "white",
                                  padding: 10,
                                  borderRadius: 5,
                                }}
                                onClick={async () => {
                                  this.readyForSslCheckout(
                                    parseInt(actualtotal) * (2.5 / 100)
                                  );
                                }}
                              >
                                {!this.state.submit_loader && (
                                  <>Proceed to Pay</>
                                )}

                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </TabPanel>
                          </Tabs>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={sslCommerze}
                              alt=""
                              style={{
                                maxWidth: "70%",
                              }}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.user.currentUser,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {
      uploadPaymentRequestRedux2,
    }
  )(PaymentModal)
);
