import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";

// import custom Components
import ProductListing from "./common/product-listing";
import Breadcrumb from "../common/breadcrumb";
import FilterBar from "./common/filter-bar";
import "./collection-no-sidebar.css";
import { connect } from "react-redux";
import {
  emptySearchedProductsArray,
  setSearchedProductsArray,
} from "../../actions/index";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import { SyncLoader, CircleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
class CollectionNoSideBar extends Component {
  state = {
    layoutColumns: 2,
    shop: "1688",
    shop_name: "",
    categoryId: "",
    searchBarValue: "",
    imageUrl: "",
    filterByCategory: "",
    active: false,
  };

  componentDidMount = () => {
    const query = new URLSearchParams(this.props.location.search);
    const shop_name = query.get("shop_name");
    const categoryId = query.get("category_id");
    const imageUrl = query.get("imageUrl");
    console.log(shop_name);
    this.setState({
      shop_name,
      categoryId,
      imageUrl: imageUrl
        ? imageUrl
            .replace("rechargeRequests/", "rechargeRequests%2F")
            .replaceAll(" ", "%20")
        : "",
    });
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const query = new URLSearchParams(nextProps.location.search);
    const shop_name = query.get("shop_name");
    const categoryId = query.get("category_id");
    const imageUrl = query.get("imageUrl");
    console.log(shop_name);
    this.setState({
      shop_name,
      categoryId,
      imageUrl: imageUrl
        ? imageUrl
            .replace("rechargeRequests/", "rechargeRequests%2F")
            .replaceAll(" ", "%20")
        : "",
      filterByCategory: "",
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleChange2 = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (this.state.filterByCategory && this.state.filterByCategory !== "") {
      } else {
      }
    });
  };
  handleSearchBarSubmit = (event) => {
    event.preventDefault();
    if (this.state.searchBarValue.length < 35) {
      var win = window.open(
        `${process.env.PUBLIC_URL}/collection/${this.state.searchBarValue}`,
        "_blank"
      );
      win.focus();
    } else {
      this.props.setSearchedProductsArray(0, []);
      if (this.state.searchBarValue.includes("1688")) {
        let productId = this.state.searchBarValue.split("/")[4].split(".")[0];
        console.log(productId);

        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${productId}`,
          "_blank"
        );
        win.focus();
      } else {
        let taobaoUrl = new URL(this.state.searchBarValue);
        let params = taobaoUrl.search;
        const urlParams = new URLSearchParams(params);
        const id = urlParams.get("id");
        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${id}`,
          "_blank"
        );
        win.focus();
      }
    }
    this.setState({ searchBarValue: "" });
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  componentWillUnmount = () => {
    // this.props.emptySearchedProductsArray();
  };

  handleImgChange = async (e, i) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ isLoading: false });
      this.openPage(imgUrl);
    }
  };
  openPage = (imgUrl) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/collection/img_uploads?imageUrl=${imgUrl}`
    );
  };

  render() {
    const { id } = this.props.match.params;
    console.log(this.state.imageUrl);
    console.log(id);
    return (
      <LoadingOverlay
        active={this.state.active}
        spinner={<CircleLoader color="#18768f" size={50} />}
      >
        <div>
          <Breadcrumb title={id} />

          {/*Section Start*/}
          <section className="section-b-space">
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="collection-content col remove-padding-mobie-view">
                    <div className="page-main-content">
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col-sm-12"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            <div className="collection-product-wrapper">
                              <div className="product-top-filter">
                                <div className="container-fluid p-0" />
                              </div>
                              <div
                                className="top-searchbar-parent"
                                style={{ background: "white" }}
                              >
                                <div className="top-searchbar-container-2">
                                  <form
                                    className="form_search top-form form-search2"
                                    role="form"
                                    onSubmit={this.handleSearchBarSubmit}
                                    style={{
                                      borderRadius: "0px",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <input
                                      id="query search-autocomplete"
                                      type="search"
                                      placeholder="Search 100+ millions of products"
                                      value={this.state.searchBarValue}
                                      onChange={this.handleChange}
                                      name="searchBarValue"
                                      className="nav-search nav-search-field"
                                      aria-expanded="true"
                                      style={{ color: "black" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <div
                                        className="btn-search"
                                        style={{
                                          width: "40px",
                                          position: "relative",
                                        }}
                                      >
                                        <i
                                          className="fa fa-camera"
                                          style={{
                                            color: "#11677e",
                                            marginTop: 12,
                                            fontSize: "150%",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            window.document
                                              .getElementById(
                                                "upload-image-input10"
                                              )
                                              .click();
                                          }}
                                        />
                                      </div>
                                      <button
                                        type="submit"
                                        style={{
                                          position: "relative",
                                          backgroundColor: "#11677e",
                                          minHeight: "100%",
                                          marginTop: -5,
                                          marginRight: -5,
                                          borderRadius: 0,
                                        }}
                                        onClick={this.handleSearchBarSubmit}
                                      >
                                        <i
                                          className="fa fa-search"
                                          style={{
                                            color: "white",
                                            margin: "auto",
                                            padding: "5px 13px",
                                            fontSize: "120%",
                                          }}
                                        />
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>

                              {this.state.imageUrl ? (
                                <div
                                  className="row"
                                  style={{
                                    paddingTop: 0,
                                    justifyContent: "center",
                                    marginLeft: 20,
                                    marginTop: 10,
                                    marginRight: 15,
                                  }}
                                >
                                  <div
                                    style={{
                                      padding: "5px 10px",
                                      fontWeight: "bold",
                                      fontSize: 14,
                                      color: "#18768f",
                                      paddingLeft: 0,
                                    }}
                                  >
                                    Showing Result for:{" "}
                                    <img
                                      src={this.state.imageUrl}
                                      style={{
                                        minHeight: 100,
                                        maxHeight: 100,
                                        minWidth: 100,
                                        maxWidth: 100,
                                        border: "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : this.props.match.params.id.includes(
                                  "abb-"
                                ) ? (
                                <div
                                  className="row"
                                  style={{
                                    paddingTop: 0,
                                    justifyContent: "center",
                                    marginLeft: 20,
                                    marginTop: 10,
                                    marginRight: 15,
                                  }}
                                >
                                  <div
                                    style={{
                                      padding: "5px 10px",
                                      fontWeight: "bold",
                                      fontSize: 14,
                                      color: "#18768f",
                                      paddingLeft: 0,
                                    }}
                                  >
                                    Showing Result for:{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        color: "black",
                                      }}
                                    >
                                      Shop Name: {this.state.shop_name}
                                    </span>
                                  </div>
                                </div>
                              ) : this.state.categoryId ? null : (
                                <div
                                  className="row"
                                  style={{
                                    paddingTop: 0,
                                    justifyContent: "center",
                                    marginLeft: 20,
                                    marginTop: 10,
                                    marginRight: 15,
                                  }}
                                >
                                  <div
                                    style={{
                                      padding: "5px 10px",
                                      fontWeight: "bold",
                                      fontSize: 14,
                                      color: "#18768f",
                                      paddingLeft: 0,
                                    }}
                                  >
                                    Showing result for:{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        color: "black",
                                      }}
                                    >
                                      {this.props.match.params.id}{" "}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {this.state.categoryId == "Last" ||
                              this.state.categoryId == "Popular" ||
                              this.state.shop_name ? null : (
                                <div
                                  className="filter-container"
                                  style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    marginTop: this.state.categoryId ? 10 : 0,
                                  }}
                                >
                                  <select
                                    title="Filter by category"
                                    required
                                    name="filterByCategory"
                                    className="custom-select"
                                    aria-required="true"
                                    aria-invalid="false"
                                    onChange={this.handleChange2}
                                    value={this.state.filterByCategory}
                                  >
                                    <option value="">Filter Products </option>
                                    <option value="Default">Default</option>
                                    <option value="Volume%3ADesc">
                                      Best Selling
                                    </option>
                                    <option value="Price%3AAsc">
                                      Low To High (price)
                                    </option>
                                    <option value="Price%3ADesc">
                                      High To Low (price)
                                    </option>
                                  </select>
                                </div>
                              )}

                              <div
                                className="product-wrapper-grid"
                                style={{ marginTop: 10 }}
                              >
                                <div className="container-fluid">
                                  <div className="row">
                                    <ProductListing
                                      colSize={this.state.layoutColumns}
                                      {...this.props}
                                      shop={this.state.shop}
                                      imageUrl={this.state.imageUrl}
                                      filterByCategory={
                                        this.state.filterByCategory
                                      }
                                      setLoader={(value) => {
                                        this.setState({
                                          active: value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Section End*/}
          <input
            id="upload-image-input10"
            className="upload"
            type="file"
            style={{
              position: "absolute",
              zIndex: 5,
              maxWidth: "50px",
              marginTop: "10px",
              display: "none",
            }}
            onChange={(e) => this.handleImgChange(e)}
          />
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(
  null,
  { emptySearchedProductsArray, setSearchedProductsArray }
)(CollectionNoSideBar);
