import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
class Privacy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Paicart | Privacy policy</title>
        </Helmet>
        <Breadcrumb title={"Privacy Policy"} />
        {/*about section*/}
        <section
          className="about-page  section-b-space"
          style={{ background: "#fafafa" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12" style={{ marginBottom: "3rem" }}>
                <h2
                  style={{
                    marginTop: "3rem",
                    marginBottom: "3rem",
                    textTransform: "none",
                  }}
                >
                  Privacy Policy
                </h2>
                <p style={{ marginBottom: "1rem" }}>
                  Paicart মার্কেট প্লেসে আপনাকে স্বাগতম। আমরা আমাদের ওয়েবসাইট
                  ব্যবহারকারীদের গোপনীয়তা রক্ষায় সদা তৎপর, তাই আপনাদের তথ্যের
                  গোপনীয়তা রক্ষায় আমরা একটি গোপনীয়তা নীতিমালায় প্রকাশ করছি
                  যেখানে আমরা কিভাবে আপনাদের গোপনীয়তা রক্ষা করবো তার বিস্তারিত
                  ভাবে বর্ণণা করা হয়েছে।
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  আপনার ব্যক্তিগত তথ্যসমূহ কিভাবে আমরা সংগ্রহ, ব্যবহার ও প্রকাশ
                  করি (বিশেষ শর্তাবলীর মাধ্যমে), সেটা এই গোপনীয়তার নীতিমালা খুব
                  ভালভাবে ব্যাখ্যা করে। এই গোপনীয়তার নীতিমালা সেসব পদক্ষেপকেও
                  ব্যাখ্যা করে, যার মাধ্যমে আমরা আপনার ব্যক্তিগত তথ্যসমূহকে
                  সুরক্ষিত রেখেছি। সবশেষে, এই গোপনীয়তার নীতিমালা আপনার ব্যক্তিগত
                  তথ্যের সংগ্রহ, ব্যবহার এবং প্রকাশ সম্পর্কে সুস্পষ্ট ব্যাখ্যা
                  প্রদান করে। এই সাইটে সরাসরি অথবা অন্য মাধ্যমে প্রবেশের মাধ্যমে
                  আপনি নীতিমালাটি মেনে নিলেন।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  ব্যক্তিগত তথ্যঃ
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  আমরা আপনার ব্যক্তিগত তথ্য অন্য কোনো ব্যক্তি বা প্রতিষ্ঠান কে
                  প্রদান করি না। শুধুমাত্র আপনার অর্ডারকৃত পণ্য কুরিয়ার করার
                  ক্ষেত্রে কুরিয়ার সার্ভিস কোম্পানিকে দেয়া হয়। সেইক্ষেত্রে
                  কুরিয়ার সার্ভিস কোম্পানি তাদের ব্যবসায়িক প্রমোশন বা অন্য কাজে
                  এই তথ্য ব্যবহার করলে তার দায়ভার সম্পূর্ণ আমাদের দায়বদ্ধতার
                  বাইরে। তবে দেশের আইন প্রযোগকারী কোন সংস্থা যদি দেশের কাজে
                  তাদের কোন তদন্তের প্রয়োজনে তথ্য চায় সেই ক্ষেত্রে আমরা দিতে
                  বাধ্য থাকবো।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  তথ্য সরবরাহঃ
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  আপনি যখন আমাদের ওয়েবসাইটে রেজিস্ট্রেশন করেন তখন আপনার ফোন
                  নাম্বার নেয়া হয়। পরবর্তীতে আপনি যখন অর্ডার দিতে যান তখন আপনার
                  নাম, ফোন নাম্বার, ইমেইল এড্রেস, ঠিকানা সংগ্রহ করি। আপনার
                  যাবতীয় তথ্য আমরা নিরাপদ ও সুরক্ষিত সার্ভারে সংগ্রহ করি এবং
                  সর্বোচ্চ নিরাপত্তা নিশ্চিত করে থাকি।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  ডেটা সংগ্রহ সম্পর্কিত তথ্যঃ
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  আপনার অর্ডার প্রসেস করতে আপনার দেওয়া তথ্য আমরা ব্যবহার করবো
                  এবং আপনার অনুরোধের ভিত্তিতেই আমাদের ওয়েবসাইটে প্রয়োজনীয়
                  সার্ভিস ও তথ্য দিয়ে আপনাকে সহায়তা করবো। পরবর্তীতে, আপনার
                  অ্যাকাউন্ট পরিচালনার জন্য আপনার দেওয়া তথ্য আমরা ব্যবহার করবো
                  এবং আপনার পেমেন্টের কাজে ব্যবহৃত সকল অর্থনৈতিক লেনদেন যাচাই
                  এবং সম্পন্ন করব। আমাদের ওয়েবসাইট থেকে ডাউনলোড হওয়া সকল ডেটা
                  অডিট করা হয়ে থাকে এবং আমাদের ব্যবহারকারীর ডেমোগ্রাফীর উপর
                  রিসার্চ করতে পারি। আমাদের থেকে যদি কোন বিজ্ঞাপন সংক্রান্ত তথ্য
                  পেতে না চান, তাহলে অপশনটি দেখা থেকে বিরত থাকতে পারেন।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  গোপনীয়তা নীতিমালা পরিবর্তনঃ
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  এই গোপনীয়তা নীতিমালাটি paicart.com এর কর্তৃপক্ষ যেকোন সময়ে
                  পরিবর্তন করার অধিকার রাখে। আমাদের গোপনীয়তা নীতিমালায় যদি কোন
                  পরিবর্তন আনা হয়, সেই ক্ষেত্রে পরিবর্তিত গোপনীয়তা নীতিমালাটি
                  আমরা এখানে রাখবো।
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  আপনার সম্মতিঃ
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  আমাদের ওয়েবসাইট ব্যবহার করার মাধ্যমে আপনি এই গোপনীয়তা
                  নীতিমালায় সম্মতি প্রকাশ করছেন। আমাদের গোপনীয়তা নীতিমালা নিয়ে
                  আপনার যদি কোন প্রশ্ন বা জিজ্ঞাসা থেকে থাকে তাহলে আমাদের সাথে
                  যোগাযোগ করার অনুরোধ করা হলো।
                </p>
                <p style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  Data protection is a matter of trust and your privacy is
                  important to us. We shall therefore only use your name and
                  other information which relates to you in the manner set out
                  in this Privacy Policy. We will only collect information where
                  it is necessary for us to do so and we will only collect
                  information if it is relevant to our dealings with you.
                </p>
                <p style={{ maringBottom: "1rem" }}>
                  We will only keep your information for as long as we are
                  either required to by law or as is relevant for the purposes
                  for which it was collected.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  You can visit the Site and browse without having to provide
                  personal details. During your visit to the Site you remain
                  anonymous and at no time can we identify you unless you have
                  an account on the Site and log on with your user name and
                  password.
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  1. Data that we collect
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  We may collect various pieces of information if you seek to
                  place an order for a product with us on the Site.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  We collect, store and process your data for processing your
                  purchase on the Site and any possible later claims, and to
                  provide you with our services. We may collect personal
                  information including, but not limited to, your title, name,
                  gender, date of birth, email address, postal address, delivery
                  address (if different), telephone number, mobile number, fax
                  number, payment details, payment card details or bank account
                  details.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  We will use the information you provide to enable us to
                  process your orders and to provide you with the services and
                  information offered through our website and which you request.
                  Further, we will use the information you provide to administer
                  your account with us; verify and carry out financial
                  transactions in relation to payments you make; audit the
                  downloading of data from our website; improve the layout
                  and/or content of the pages of our website and customize them
                  for users; identify visitors on our website; carry out
                  research on our users’ demographics; send you information we
                  think you may find useful or which you have requested from us,
                  including information about our products and services,
                  provided you have indicated that you have not objected to
                  being contacted for these purposes. Subject to obtaining your
                  consent we may contact you by email with details of other
                  products and services. If you prefer not to receive any
                  marketing communications from us, you can opt out at any time.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  We may pass your name and address on to a third party in order
                  to make delivery of the product to you (for example to our
                  courier or supplier). You must only submit to us the Site
                  information which is accurate and not misleading and you must
                  keep it up to date and inform us of changes.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  Your actual order details may be stored with us but for
                  security reasons cannot be retrieved directly by us. However,
                  you may access this information by logging into your account
                  on the Site. Here you can view the details of your orders that
                  have been completed, those which are open and those which are
                  shortly to be dispatched and administer your address details,
                  bank details ( for refund purposes) and any newsletter to
                  which you may have subscribed. You undertake to treat the
                  personal access data confidentially and not make it available
                  to unauthorized third parties. We cannot assume any liability
                  for misuse of passwords unless this misuse is our fault.
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  Other uses of your Personal Information
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  We may use your personal information for opinion and market
                  research. Your details are anonymous and will only be used for
                  statistical purposes. You can choose to opt out of this at any
                  time. Any answers to surveys or opinion polls we may ask you
                  to complete will not be forwarded on to third parties.
                  Disclosing your email address is only necessary if you would
                  like to take part in competitions. We save the answers to our
                  surveys separately from your email address.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  We may also send you other information about us, the Site, our
                  other websites, our products, sales promotions, our
                  newsletters, anything relating to other companies in our group
                  or our business partners. If you would prefer not to receive
                  any of this additional information as detailed in this
                  paragraph (or any part of it) please click the ‘unsubscribe’
                  link in any email that we send to you. Within 7 working days
                  (days which are neither (i) a Sunday, nor (ii) a public
                  holiday anywhere in Bangladesh) of receipt of your instruction
                  we will cease to send you information as requested. If your
                  instruction is unclear we will contact you for clarification.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  We may further anonymize data about users of the Site
                  generally and use it for various purposes, including
                  ascertaining the general location of the users and usage of
                  certain aspects of the Site or a link contained in an email to
                  those registered to receive them, and supplying that
                  anonymized data to third parties such as publishers. However,
                  that anonymized data will not be capable of identifying you
                  personally.
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  Competitions
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  For any competition we use the data to notify winners and
                  advertise our offers. You can find more details where
                  applicable in our participation terms for the respective
                  competition.
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  {" "}
                  Third Parties and Links
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  We may pass your details to other companies in our group. We
                  may also pass your details to our agents and subcontractors to
                  help us with any of our uses of your data set out in our
                  Privacy Policy. For example, we may use third parties to
                  assist us with delivering products to you, to help us to
                  collect payments from you, to analyze data and to provide us
                  with marketing or customer service assistance.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  We may exchange information with third parties for the
                  purposes of fraud protection and credit risk reduction. We may
                  transfer our databases containing your personal information if
                  we sell our business or part of it. Other than as set out in
                  this Privacy Policy, we shall NOT sell or disclose your
                  personal data to third parties without obtaining your prior
                  consent unless this is necessary for the purposes set out in
                  this Privacy Policy or unless we are required to do so by law.
                  The Site may contain advertising of third parties and links to
                  other sites or frames of other sites. Please be aware that we
                  are not responsible for the privacy practices or content of
                  those third parties or other sites, nor for any third party to
                  whom we transfer your data in accordance with our Privacy
                  Policy.
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  2. Cookies
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  The acceptance of cookies is not a requirement for visiting
                  the Site. However we would like to point out that the use of
                  the ‘basket’ functionality on the Site and ordering is only
                  possible with the activation of cookies. Cookies are tiny text
                  files which identify your computer to our server as a unique
                  user when you visit certain pages on the Site and they are
                  stored by your Internet browser on your computer’s hard drive.
                  Cookies can be used to recognize your Internet Protocol
                  address, saving you time while you are on, or want to enter,
                  the Site. We only use cookies for your convenience in using
                  the Site (for example to remember who you are when you want to
                  amend your shopping cart without having to re-enter your email
                  address) and not for obtaining or using any other information
                  about you (for example targeted advertising). Your browser can
                  be set to not accept cookies, but this would restrict your use
                  of the Site. Please accept our assurance that our use of
                  cookies does not contain any personal or private details and
                  are free from viruses. If you want to find out more
                  information about cookies, go to
                  http://www.allaboutcookies.org or to find out about removing
                  them from your browser, go to
                  http://www.allaboutcookies.org/manage-cookies/index.html.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  This website uses Google Analytics, a web analytics service
                  provided by Google, Inc. (“Google”). Google Analytics uses
                  cookies, which are text files placed on your computer, to help
                  the website analyze how users use the site. The information
                  generated by the cookie about your use of the website
                  (including your IP address) will be transmitted to and stored
                  by Google on servers in the United States. Google will use
                  this information for the purpose of evaluating your use of the
                  website, compiling reports on website activity for website
                  operators and providing other services relating to website
                  activity and internet usage. Google may also transfer this
                  information to third parties where required to do so by law,
                  or where such third parties process the information on
                  Google’s behalf. Google will not associate your IP address
                  with any other data held by Google. You may refuse the use of
                  cookies by selecting the appropriate settings on your browser,
                  however please note that if you do this you may not be able to
                  use the full functionality of this website. By using this
                  website, you consent to the processing of data about you by
                  Google in the manner and for the purposes set out above.
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  3. Security
                </h3>
                <p style={{ marginBottom: "1rem" }}>
                  We have in place appropriate technical and security measures
                  to prevent unauthorized or unlawful access to or accidental
                  loss of or destruction or damage to your information. When we
                  collect data through the Site, we collect your personal
                  details on a secure server. We use firewalls on our servers.
                  Our security procedures mean that we may occasionally request
                  proof of identity before we disclose personal information to
                  you. You are responsible for protecting against unauthorized
                  access to your password and to your computer.
                </p>
                <h3 style={{ marginTop: "3rem", color: "black" }}>
                  4. Your rights
                </h3>
                <p style={{ marginBottom: "3rem" }}>
                  If you are concerned about your data you have the right to
                  request access to the personal data which we may hold or
                  process about you. You have the right to require us to correct
                  any inaccuracies in your data free of charge. At any stage you
                  also have the right to ask us to stop using your personal data
                  for direct marketing purposes.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Privacy;
