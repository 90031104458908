import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
class Contact extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Paicart | Contact us</title>
        </Helmet>
        <Breadcrumb title={"Paicart | Contact Us"} />

        {/*Forget Password section*/}
        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row section-b-space">
              <div className="col-lg-7 map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.144104937872!2d90.41012947607189!3d23.742240089088064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b861d40306a1%3A0xab9176cf68a61e7b!2sTropical%20Razia%20Shopping%20Complex!5e0!3m2!1sen!2sbd!4v1716773488997!5m2!1sen!2sbd"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </div>
              <div className="col-lg-5">
                <div className="contact-right">
                  <ul>
                    <li>
                      <div className="contact-icon">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/phone.png`}
                          alt="Generic placeholder image"
                        />
                        <h6>Contact Us</h6>
                      </div>
                      <div className="media-body">
                        <p>+88-01979653008</p>
                        <p>+88-01979653008</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        <h6>Address</h6>
                      </div>
                      <div className="media-body">
                        <p>
                          Suite#F2, (2nd floor) Trapical Razia Shopping Complex,
                        </p>
                        <p>26/1 Chamelibagh, Shantinagar, Dhaka 1217</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/email.png`}
                          alt="Generic placeholder image"
                        />
                        <h6>Email</h6>
                      </div>
                      <div className="media-body">
                        <p>support@paicart.com</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <i className="fa fa-fax" aria-hidden="true" />
                        <h6>Fax</h6>
                      </div>
                      <div className="media-body">
                        <p>info@paicart.com</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
