import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItem1: true,
      navItem2: false,
      navItem3: false,
      navItem4: false,
      navItem5: false,
      navItem6: false,
    };
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>FAQ - frequently asked question</title>
        </Helmet>
        <Breadcrumb title={"Faq"} />

        {/*Dashboard section*/}
        <section className="faq-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="accordion theme-accordion" id="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          কিভাবে অর্ডার করতে হবে ?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          {" "}
                          থেকে পণ্য কেনা অন্য সব ই-কমার্স সাইটের মতোই সহজ।
                          আমাদের ডেভেলপারগন চেষ্টা করেছেন ইউজার ফ্রেন্ডলি ও
                          মোবাইল রেসপন্সিভ সাইট ডেভেলপ করার। আমাদের সাইট আপনি
                          মোবাইল ব্রাউজারে ব্যবহারের সময় আপনাকে এ্যাপ্স এর
                          এক্সপেরিয়েন্স দিবে। তাছাড়াও আমাদের সাইটে থাকছে
                          আর্টিফিসিয়াল ইন্টেলিজেন্স ইমেজ সার্চ অপশন যার মাধ্যমে
                          আপনি যেকোন ছবি সার্চ করেও আপনার কাংখিত পণ্য পেতে
                          পারেন।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          আপনাদের অফিস কোথায় ?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          {" "}
                          ১২৩/১০ মৌবন সুপার মার্কেট, নতুন কাকরাইল রোড, রমনা,
                          ঢাকা।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          কেন আপনাদের বিশ্বাস করবো?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          আমাদের রয়েছে কর্পোরেট অফিস, আউটলেট এবং ওয়্যার হাউজ।
                          যার ঠিকানা আমাদের ওয়েবসাইট এবং ফেসবুক পেজে দেওয়া
                          হয়েছে, আপনি চাইলে অর্ডার করার পূর্বে আমাদের অফিসে
                          আসতে পারেন।
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          আপনাদের পেমেন্ট সিস্টেম কি?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          {" "}
                          আমাদের সাইটে এখন শুধুমাত্র বিকাশ পেমেন্ট সিস্টেম চালু
                          করা হয়েছে। সকল কার্ড ও মোবাইল ব্যাংকের প্রসেসিং চলছে।
                          অতি দ্রুত চালু হবে। তাছাড়াও আপনি আমাদের সিটি ব্যাংকে
                          পেমেন্ট করতে পারেন। ১০০০০ টাকার নিচে অর্ডার করলে ৮০%
                          ১০০০১ থেকে ১০০০০০ টাকার করলে ৬০% ১০০০০০ টাকার উপরে
                          অর্ডার করলে ৫০% অগ্রিম পেমেন্ট করতে হবে।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          ক্যাশ অন ডেলিভারী হবে কিনা?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          আমরা আন্তরিক ভাবে দুঃখিত যে, আমরা পণ্যের সম্পূর্ন
                          মূল্য ক্যাশ অন ডেলিভেরী করিনা। কারন আমাদের স্টকে কোন
                          পণ্য থাকেনা। আপনার অর্ডার পাওয়ার পর আমরা চায়না
                          সাপ্লাইয়ারকে ফুল পেমেন্ট করে পণ্য অর্ডার করতে হয়।
                          কিন্ত আপনার সুবিধার্থে আমরা ৫০% অগ্রিম নিয়ে অর্ডার
                          প্রসেস করে থাকি। পণ্য আসার পর শিপিং চার্জ ও পণ্যের
                          বাকি ৫০% টাকা ক্যাশ অন ডেলিভেরী করা হয়ে থাকে।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          অর্ডার করা পণ্য হাতে পাবার সময়কাল
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          এটি নির্ভর করে আপনি কি একি সাপ্লাইয়ার এর কাছে অর্ডার
                          দিচ্ছেন নাকি আলাদা আলাদা সাপ্লাইয়ার এর কাছ থেকে
                          নিচ্ছেন। যদি সাপ্লাইয়ার আলাদা হয় এবং পণ্য চায়না
                          ওয়্যারহাউসে আসার সময়কাল ভিন্ন হয় তাহলে একাধিক
                          শিপমেন্টে বাংলাদেশে পণ্য আসবে। তবে আমরা অগ্রাধিকার
                          ভিত্তিতে শিপমেন্ট করে থাকি । যে পণ্যটি চায়না
                          ওয়্যারহাউসে আগে আসে সেটি আমরা চলতি শিপমেন্টে পাঠিয়ে
                          দেই। আমাদের সার্ভিসের বিশেষত্ব হচ্ছে যে আপনি প্রতিটি
                          ধাপে SMS এবং মেইল এর মাধ্যমে পণ্যের অবস্থান সম্পর্কে
                          জানতে পারবেন । আপনি চাইলে আগে আসা পণ্য আগেই ডেলিভেরী
                          নিতে পারবেন অথবা সব পণ্য দেশে আসার পর ডেলিভারি নিতে
                          পারেন। ঢাকার ভিতরে ডেলিভারি টাইম ৪৮ ঘণ্টার মধ্যে এবং
                          ঢাকার বাইরে ডেলিভারির সময় সর্বোচ্চ ৫-১০ দিনের মধ্যে।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          সাইটে পণ্য ক্রয়মূল্য বাদে কি অতিরিক্ত কোনো খরচ আছে ?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          জ্বি আরো দুই ধরনের খরচ যোগ হবে পণ্য আসার পর শিপিং ও
                          কাস্টমস চার্জ যা পণ্য আসার পর ওজন হিসাবে যোগ হবে। পণ্য
                          অর্ডার করার সময় শিপিং প্রতি কেজির শিপিং রেট দেয়া থাকে।
                          উক্ত রেট অনুযায়ী পণ্য আসার পর শিপিং চার্জ বাবদ যোগ হয়।
                          ডেলিভেরী চার্জঃ আমাদের অফিস থেকে সরাসরি পণ্য রিসিভ
                          করলে এই চার্জ হবেনা। কুরিয়ারে পণ্য রিসিভ করলে কুরিয়ার
                          খরচ ‍যুক্ত হবে।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          কুরিয়ার চার্জ / আমাদের ঠিকানায় ডেলিভেরী চার্জ কত ?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseEight"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          ঢাকার ভিতর নিম্নোক্ত হারে চার্জ আসবেঃ প্রথম কেজি ১২০
                          টাকা ও পরবর্তী কেজি ৩০ টাকা হারে কুরিয়ার চার্জ আসবে।
                          মনে করুন আপনার পণ্যের ওজন ৩কেজি ২০০ গ্রাম । আপনার
                          ডেলিভেরী চার্জ আসবে প্রথম কেজির জন্য ১২০ টাকা ও
                          পরবর্তী ২কেজি ২০০ গ্রাম কে ৩ কেজি হিসাবে ৯০ টাকা সহ
                          ২১০ টাকা আসবে। ঢাকার বাইরে নিম্নোক্ত হারে চার্জ আসবেঃ
                          প্রথম কেজি ১৫০ টাকা ও পরবর্তী কেজি ৪০ টাকা হারে
                          কুরিয়ার চার্জ আসবে। মনে করুন আপনার পণ্যের ওজন ৩কেজি
                          ২০০ গ্রাম । আপনার ডেলিভেরী চার্জ আসবে প্রথম কেজির জন্য
                          ১৫০ টাকা ও পরবর্তী ২কেজি ২০০ গ্রাম কে ৩ কেজি হিসাবে
                          ১২০ টাকা সহ ২৭০ টাকা আসবে
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          যেকোন পণ্য কি অর্ডার করতে পারবো?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseNine"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          বাংলাদেশ কাষ্টমস এর রুলস অনুযায়ী কিছু পন্যের ক্ষেত্রে
                          সরকারের নিষেধাজ্ঞা রয়েছে যেগুলো আমদানী করা অবৈধ ও
                          শাস্তিযোগ্য অপরাধ। এ ধরণের পণ্য অর্ডার করা থেকে বিরত
                          থাকুন। আমাদের সাইটের সকল সেলার যেহেতু চাইনিজ বেইজ তাই
                          এসব পণ্য সার্চ করলে পেতে পারেন। কিন্তু এগুলো অর্ডার
                          করবেন না। আমরা অনেক কিওয়ার্ড ব্লক করে দিয়েছি তারপরও
                          যদি এসব পণ্য দেখায় আমাদের জানাবেন। আমরা ব্লক করে দিবো।
                          আমদানী নিষিদ্ধ পণ্য অর্ডার করলে আপনার পেমেন্টকৃত টাকা
                          থেকে পেমেন্ট গেটওয়ে খরচ ২.৫% কেটে রেখে আপনাকে রিফান্ড
                          দেয়া হবে। রিফান্ড পেতে ১২ কর্মদিবস সময় লাগবে।
                        </p>
                        <h3 style={{ marginTop: "1rem" }}>
                          আমদানী নিষিদ্ধ পন্য নিচে দেয়া হলো
                        </h3>
                        <p>
                          ❌ সেক্স টয় <br />
                          ❌ SKD (আমদানী নিষিদ্ধ পণ্যের অংশ বিশেষ)
                          <br />
                          ❌ গোল্ড <br />
                          ❌ সিরিঞ্জ
                          <br />
                          ❌ পাকিস্তানি ক্রিম <br />
                          ❌ সিকিউরিটি পণ্যহাতকড়া/পিস্তল/বন্দুক বা সাদৃশ্য কোন
                          পন্য
                          <br />
                          ❌ সিগারেট <br />
                          ❌ মোবাইল ফোন
                          <br />
                          ❌ এলকোহলজাত পানীয়
                          <br />
                          ❌ ড্রোন/ ড্রোন এর পার্টস
                          <br />
                          ❌ ওয়াকিটকি
                          <br />
                          ❌ পুরাতন পন্য
                          <br />
                          ❌ মটর সাইকেল,কার (বাচ্চাদের খেলনা হলে আনা যাবে)
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          পণ্য যদি ভাঙ্গা, নষ্ট ও অমিল হয় তাহলে কি রিফান্ড
                          ক্লেইম করতে পারবেন?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTen"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          অবশ্যই পারবেন। এ বিষয়ে বিস্তারিত জানতে আমাদের রিটার্ন
                          এন্ড রিফান্ড পলিসি দেখুন।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseEleven"
                          aria-expanded="false"
                          aria-controls="collapseEleven"
                        >
                          শিপিং রেট কত ও কিভাবে হিসাব হয় ?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseEleven"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <h3 style={{ marginTop: "1rem" }}>এয়ার শিপিং:</h3>
                        <p>
                          শিপিং চার্জ পণ্যের ক্যাটাগরি, ওজন, সিবিএম ইত্যাদির উপর
                          নির্ভর করে। আপনাদের সুবিধার্থে আমরা এখন প্রত্যেক
                          পণ্যের শিপিং রেট আলাদা করে দিয়েছি। পণ্য অর্ডার করার
                          সময় আপনি নিজেই পণ্যের নাম/ক্যাটাগরি সিলেক্ট করে অর্ডার
                          প্লেস করতে পারবেন। তাই কোনো কনফিউশান ছাড়াই আপনি অর্ডার
                          করতে পারছেন। তাই পণ্য আসার পর শিপিং রেট নিয়ে কোনো
                          দ্বিধা থাকবে না। মনে রাখবেন শিপিং রেট সেট করার জন্য
                          সঠিক ক্যাটাগরি সিলেক্ট করা জরুরী। আপনি ক্যাটাগরি
                          সিলেকশান ভুল করলে শিপিং রেট ভুল আসবে। যদি আপনি আপনার
                          পণ্যের নাম/ক্যাটাগরি আমাদের শিপিং লিষ্টে না পান তাহলে
                          আমাদের কাস্টমার কেয়ারে 01979653008 নাম্বারে কল করে
                          জেনে নিন। তবে আপনি যদি ক্যাটাগরি সিলেকশান ভুল করেন
                          তাহলে চায়না এক্সপ্রেস তা সংশোধন করার অধিকার সংরক্ষন
                          করে।
                        </p>
                        <h3 style={{ marginTop: "1rem" }}>সি-শিপিং:</h3>
                        <p>
                          সি-শিপিং একটি জটিল ও সময় সাপেক্ষ বিষয়। সি-শিপিং এর
                          জন্য আপনাকে ন্যুনতম ৫০০ কেজি পণ্যের অর্ডার করতে হবে।
                          আপনাদের সুবিধার্থে আমরা সর্বোচ্চ ২টি লিংক থেকে ৫০০
                          কেজি হলেও অর্ডার নিচ্ছি তবে একক কোনো পণ্য ২৫০ কেজির কম
                          নয়। অনেক সময় পিস বা সংখ্যায় সি-শিপিং এর রেট নির্ধারন
                          হয়ে থাকে। যেমন জুতা, স্যান্ডেল, ব্যাগ ইত্যাদি।
                          সি-শিপিং এর রেট ওয়েব সাইটে দেয়া নেই। কারন সি-শিপিং এর
                          রেট প্রতিনিয়ত চেঞ্জ হয়ে থাকে। তাই সি-শিপিং এ অর্ডার
                          করার আগে অবশ্যই আমাদের কাস্টমার কেয়ার প্রতিনিধির সাথে
                          কথা বলে শিপিং রেট জেনে অর্ডার প্লেস করবেন।
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Faq;
