import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { verifyOtpRedux } from "../../actions/index";
import { CircleLoader } from "react-spinners";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

const OtpModal = (props) => {
  const [otp, setOtp] = useState("");
  const [time, setTime] = useState(60);
  const [submit_loader, set_submit_loader] = useState(false);

  useEffect(() => {
    if (props.number && time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOtp(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (submit_loader) {
      return;
    }
    set_submit_loader(true);
    console.log(props.number);
    console.log(otp);
    await props.verifyOtpRedux(props.number, otp);
    set_submit_loader(false);
    window.document.getElementById("close_otp_modal").click();
    toast.success("Successfully logged in!");
    props.history.goBack();
  };

  return (
    <>
      <div
        className="modal fade"
        id="request_otp_popup"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered request_popup"
          role="document"
        >
          <div
            className="modal-content otp-modal"
            style={{ background: "black" }}
          >
            <div className="modal-body p-0">
              <section className="pos-rel bg-light-gray">
                <div className="container-fluid p-0">
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close_otp_modal"
                  >
                    <i className="icofont-close-line" />
                  </a>
                  <div
                    style={{
                      boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 25,
                      }}
                    >
                      <div>
                        <h2
                          className="h2-xl fw-6"
                          style={{
                            color: "orange",
                            fontSize: "140%",
                            fontWeight: "bolder",
                          }}
                        >
                          Enter your OTP
                        </h2>
                        <form
                          onSubmit={handleSubmit}
                          noValidate="novalidate"
                          className="rounded-field"
                        >
                          <div className="form-row mb-1">
                            <div className="col">
                              <input
                                type="text"
                                name="otp"
                                className="form-control"
                                placeholder="Enter your OTP here"
                                value={otp}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {time > 0 ? (
                            <div
                              className="form-row mb-2"
                              style={{ color: "white", marginLeft: 2 }}
                            >
                              OTP expires in {time}s
                            </div>
                          ) : (
                            <div
                              className="form-row mb-2"
                              style={{ color: "red", marginLeft: 2 }}
                            >
                              OTP expired.Try again!
                            </div>
                          )}
                          <div className="form-row">
                            <div className="col pt-2">
                              <button
                                type="submit"
                                className="form-btn btn-theme bg-orange"
                                style={{
                                  minWidth: "130px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  border: "none",
                                  background: "#18768f",
                                  padding: "7px",
                                  borderRadius: "7px",
                                  float: "right",
                                }}
                              >
                                {!submit_loader && (
                                  <>
                                    Proceed
                                    <i
                                      className="icofont-rounded-right"
                                      style={{ marginTop: 5 }}
                                    />
                                  </>
                                )}

                                <CircleLoader
                                  loading={submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    number: state.user.otpNumber,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    { verifyOtpRedux }
  )(OtpModal)
);
