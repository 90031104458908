import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./myOrders.css";
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
    };
  }

  render() {
    const { currentUser } = this.props;
    console.log(currentUser);
    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section
          className="section-b-space main-container-cart"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col-3 left-list-column">
                <div className="left-list-container">
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Buy & Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p className="each-option">My Request</p>
                      <p className="each-option">My Orders</p>
                      <p className="each-option">My Forwarding Parcel</p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p className="each-option">My Request</p>
                      <p className="each-option">My Orders</p>
                      <p className="each-option">My Forwarding Parcel</p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Profile
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        style={{ color: "#f54c3c" }}
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/information`
                          );
                        }}
                      >
                        Information
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/security`
                          );
                        }}
                      >
                        Security
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div className="col select-option">Approved (0)</div>
                  <div
                    className="col select-option"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      paddingBottom: 10,
                      borderBottom: "2px solid #f54c3c",
                    }}
                  >
                    Pending (0)
                  </div>
                  <div className="col select-option">Rejected (0)</div>
                </div>
                <div
                  className="row search-bar-row"
                  style={{
                    padding: "20px",
                    paddingRight: "0px",
                    justifyContent: "flex-end",
                  }}
                >
                  <form className="form-inline subscribe-form">
                    <div className="form-group mx-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Search Order"
                        style={{
                          padding: 8,
                          width: 250,
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-solid"
                      style={{ padding: 8, marginLeft: -50 }}
                    >
                      <i
                        className="icofont-search"
                        style={{ fontWeight: "bold" }}
                      />
                    </button>
                  </form>
                </div>
                {/* when no order is found  */}
                {/* <div className="main-order-section">
                   <div className="col-sm-12">
                    <div>
                      <div
                        className="col-sm-12 empty-cart-cls text-center"
                        style={{ marginTop: 50 }}
                      >
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/empty-search.jpg`}
                          className="img-fluid mb-4"
                          alt=""
                        />
                        <h3 style={{ paddingBottom: 50 }}>
                          <strong>You haven't make any Orders yet!</strong>
                        </h3>
                      </div>
                    </div>
                    <div style={{ paddingBottom: 40, textAlign: "center" }}>
                      <Link to="/">
                        <button
                          className="btn btn-solid"
                          style={{ padding: 6, fontSize: 10, borderRadius: 5 }}
                        >
                          Continue Shopping
                        </button>
                      </Link>
                    </div>
                </div>
                  </div> */}
                <div className="row title-header">
                  <div className="col-4">Goods</div>
                  <div className="col">Unit Price (taka)</div>
                  <div className="col">Quantity</div>
                  <div className="col">Total Amount</div>
                  <div className="col">Order Status</div>
                </div>
                <div>
                  <div className="row each-order-container">
                    <div className="row order-header">
                      <div style={{ fontSize: 11, marginTop: 2 }}>
                        <i
                          className="icofont-brand-natgeo"
                          style={{ color: "black" }}
                        />
                        &nbsp;Order Number: 22571303567382929227 &nbsp; &nbsp;
                      </div>
                      <div
                        style={{ fontSize: 11, color: "gray", marginTop: 2 }}
                      >
                        2021-11-16 &nbsp; 16:44:29 &nbsp; &nbsp;
                      </div>
                      <div>
                        <i
                          className="icofont-smart-phone"
                          style={{ color: "darkorange", fontSize: 20 }}
                        />
                        &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11 }}>
                        <i
                          className="icofont-brand-china-unicom"
                          style={{ color: "orange", fontSize: 17 }}
                        />
                        &nbsp;Quanzhong Zanchilong Trading has... &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11 }}>
                        <i
                          className="icofont-bomb"
                          style={{ color: "blue", fontSize: 17 }}
                        />
                        &nbsp;Zanchilong footwear &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11, marginTop: 2 }}>
                        view all his orders &nbsp; &nbsp;
                      </div>
                      <div>
                        <i
                          className="icofont-book-mark"
                          style={{ color: "black" }}
                        />
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 13,
                        color: "gray",
                        padding: 10,
                        paddingLeft: 30,
                        justifyContent: "flex-start",
                      }}
                    >
                      Order account number:{" "}
                      <span style={{ color: "darkgray" }}>tb3731455678</span>
                    </div>
                  </div>
                  <div className="row products-box">
                    <div className="col-8">
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 12,
                            }}
                          >
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div style={{ color: "gray", fontSize: 12 }}>
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 12,
                            }}
                          >
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{
                        paddingTop: 5,
                        borderTop: "1px solid gainsboro",
                      }}
                    >
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <del>175.00</del>
                          </div>
                          <div style={{ fontWeight: "bold", fontSize: 16 }}>
                            174.88
                          </div>
                          <div style={{ fontSize: 13, color: "gray" }}>
                            Including shipping cost 11.00
                          </div>
                        </div>
                        <div
                          className="col-6"
                          style={{ fontSize: 13, color: "gray" }}
                        >
                          waiting for the seller to ship order details check
                          logistics
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row each-order-container">
                    <div className="row order-header">
                      <div style={{ fontSize: 11, marginTop: 2 }}>
                        <i
                          className="icofont-brand-natgeo"
                          style={{ color: "black" }}
                        />
                        &nbsp;Order Number: 22571303567382929227 &nbsp; &nbsp;
                      </div>
                      <div
                        style={{ fontSize: 11, color: "gray", marginTop: 2 }}
                      >
                        2021-11-16 &nbsp; 16:44:29 &nbsp; &nbsp;
                      </div>
                      <div>
                        <i
                          className="icofont-smart-phone"
                          style={{ color: "darkorange", fontSize: 20 }}
                        />
                        &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11 }}>
                        <i
                          className="icofont-brand-china-unicom"
                          style={{ color: "orange", fontSize: 17 }}
                        />
                        &nbsp;Quanzhong Zanchilong Trading has... &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11 }}>
                        <i
                          className="icofont-bomb"
                          style={{ color: "blue", fontSize: 17 }}
                        />
                        &nbsp;Zanchilong footwear &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11, marginTop: 2 }}>
                        view all his orders &nbsp; &nbsp;
                      </div>
                      <div>
                        <i
                          className="icofont-book-mark"
                          style={{ color: "black" }}
                        />
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 13,
                        color: "gray",
                        padding: 10,
                        paddingLeft: 30,
                        justifyContent: "flex-start",
                      }}
                    >
                      Order account number:{" "}
                      <span style={{ color: "darkgray" }}>tb3731455678</span>
                    </div>
                  </div>
                  <div className="row products-box">
                    <div className="col-8">
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 12,
                            }}
                          >
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 12,
                            }}
                          >
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div style={{ color: "gray", fontSize: 12 }}>
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{
                        paddingTop: 5,
                        borderTop: "1px solid gainsboro",
                      }}
                    >
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <del>175.00</del>
                          </div>
                          <div style={{ fontWeight: "bold", fontSize: 16 }}>
                            174.88
                          </div>
                          <div style={{ fontSize: 13, color: "gray" }}>
                            Including shipping cost 11.00
                          </div>
                        </div>
                        <div
                          className="col-6"
                          style={{ fontSize: 13, color: "gray" }}
                        >
                          waiting for the seller to ship order details check
                          logistics
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row each-order-container">
                    <div className="row order-header">
                      <div style={{ fontSize: 11, marginTop: 2 }}>
                        <i
                          className="icofont-brand-natgeo"
                          style={{ color: "black" }}
                        />
                        &nbsp;Order Number: 22571303567382929227 &nbsp; &nbsp;
                      </div>
                      <div
                        style={{ fontSize: 11, color: "gray", marginTop: 2 }}
                      >
                        2021-11-16 &nbsp; 16:44:29 &nbsp; &nbsp;
                      </div>
                      <div>
                        <i
                          className="icofont-smart-phone"
                          style={{ color: "darkorange", fontSize: 20 }}
                        />
                        &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11 }}>
                        <i
                          className="icofont-brand-china-unicom"
                          style={{ color: "orange", fontSize: 17 }}
                        />
                        &nbsp;Quanzhong Zanchilong Trading has... &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11 }}>
                        <i
                          className="icofont-bomb"
                          style={{ color: "blue", fontSize: 17 }}
                        />
                        &nbsp;Zanchilong footwear &nbsp; &nbsp;
                      </div>
                      <div style={{ fontSize: 11, marginTop: 2 }}>
                        view all his orders &nbsp; &nbsp;
                      </div>
                      <div>
                        <i
                          className="icofont-book-mark"
                          style={{ color: "black" }}
                        />
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 13,
                        color: "gray",
                        padding: 10,
                        paddingLeft: 30,
                        justifyContent: "flex-start",
                      }}
                    >
                      Order account number:{" "}
                      <span style={{ color: "darkgray" }}>tb3731455678</span>
                    </div>
                  </div>
                  <div className="row products-box">
                    <div className="col-8">
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div style={{ color: "gray", fontSize: 12 }}>
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div style={{ color: "gray", fontSize: 12 }}>
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          padding: 5,
                          borderTop: "1px solid gainsboro",
                          borderRight: "1px solid gainsboro",
                          paddingBottom: 25,
                        }}
                      >
                        <div className="col-2" style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <div style={{ color: "#4a4a4a", fontSize: 12 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div style={{ color: "gray", fontSize: 12 }}>
                            Color: Gray 8856, Size: 44
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginTop: 5 }}
                          >
                            <div className="info-box">48h delivery</div>
                            <div className="info-box" style={{ marginLeft: 5 }}>
                              Fast refund
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          41.00
                        </div>
                        <div className="col" style={{ color: "rgb(98 98 98)" }}>
                          2
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{
                        paddingTop: 5,
                        borderTop: "1px solid gainsboro",
                      }}
                    >
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <del>175.00</del>
                          </div>
                          <div style={{ fontWeight: "bold", fontSize: 16 }}>
                            174.88
                          </div>
                          <div style={{ fontSize: 13, color: "gray" }}>
                            Including shipping cost 11.00
                          </div>
                        </div>
                        <div
                          className="col-6"
                          style={{ fontSize: 13, color: "gray" }}
                        >
                          waiting for the seller to ship order details check
                          logistics
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div>
              <div className="row main-navigator-option">
                <div
                  className="buy-ship-for-me-mobile"
                  style={{ borderBottom: "2px solid #f54c3c" }}
                >
                  <div>Buy & Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 9,
                        padding: "10px 0px",
                        color: "#f54c3c",
                      }}
                    >
                      My Requests
                    </div>
                    <div
                      style={{ fontSize: 9, paddingBottom: 10, color: "gray" }}
                    >
                      My Orders
                    </div>
                    <div
                      style={{ fontSize: 9, paddingBottom: 10, color: "gray" }}
                    >
                      My Forwarding Parcel
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 160 }}>
                  <div>Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 9,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                    >
                      My Requests
                    </div>
                    <div
                      style={{ fontSize: 9, paddingBottom: 10, color: "gray" }}
                    >
                      My Orders
                    </div>
                    <div
                      style={{ fontSize: 9, paddingBottom: 10, color: "gray" }}
                    >
                      My Forwarding Parcel
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 100 }}>
                  <div>Profile</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 9,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                    >
                      Information
                    </div>
                    <div
                      style={{ fontSize: 9, paddingBottom: 10, color: "gray" }}
                    >
                      Security
                    </div>
                  </div>
                </div>
                <div />
                <div />
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div className="col select-option">Approved (0)</div>
                  <div
                    className="col select-option"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      paddingBottom: 10,
                      borderBottom: "2px solid #f54c3c",
                    }}
                  >
                    Pending (0)
                  </div>
                  <div className="col select-option">Rejected (0)</div>
                </div>
                <div className="row search-bar-row-1">
                  <form className="form-inline subscribe-form">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Search Order"
                      style={{
                        padding: 8,
                        width: 250,
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />

                    <button
                      type="submit"
                      className="btn btn-solid"
                      style={{ padding: 8, marginLeft: -50 }}
                    >
                      <i
                        className="icofont-search"
                        style={{ fontWeight: "bold" }}
                      />
                    </button>
                  </form>
                </div>
                {/* when no order is found  */}
                {/* <div className="main-order-section">
                   <div className="col-sm-12">
                    <div>
                      <div
                        className="col-sm-12 empty-cart-cls text-center"
                        style={{ marginTop: 50 }}
                      >
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/empty-search.jpg`}
                          className="img-fluid mb-4"
                          alt=""
                        />
                        <h3 style={{ paddingBottom: 50 }}>
                          <strong>You haven't make any Orders yet!</strong>
                        </h3>
                      </div>
                    </div>
                    <div style={{ paddingBottom: 40, textAlign: "center" }}>
                      <Link to="/">
                        <button
                          className="btn btn-solid"
                          style={{ padding: 6, fontSize: 10, borderRadius: 5 }}
                        >
                          Continue Shopping
                        </button>
                      </Link>
                    </div>
                </div>
                  </div> */}

                <div style={{ borderRadius: 10, marginBottom: 20 }}>
                  <div className="each-order-container-1">
                    <div className="order-header-1">
                      <div style={{ fontSize: 10 }}>
                        Quanzhong Zanchilong Trading has...{" "}
                        <i
                          className="icofont-arrow-right"
                          style={{ color: "black" }}
                        />
                      </div>

                      <div
                        style={{
                          color: "darkorange",
                          fontSize: 7,
                          maxWidth: 50,
                          fontWeight: "bold",
                        }}
                      >
                        Goods to be received
                      </div>
                    </div>
                  </div>
                  <div
                    className="products-box"
                    style={{
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 5,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 7,
                          color: "gray",
                          maxWidth: 50,
                          paddingLeft: 10,
                        }}
                      >
                        3 kinds of goods
                      </div>
                      <div style={{ fontSize: 7, color: "gray" }}>
                        Including shipping charge Tk 110.00
                      </div>

                      <div style={{ fontSize: 8, color: "black" }}>
                        Total Amount Tk 6500
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "0px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 11,
                          color: "gray",
                          margin: "auto 0px",
                        }}
                      >
                        more
                      </div>

                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid gainsboro",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "gray",
                          marginLeft: 45,
                        }}
                      >
                        extended receipt
                      </div>
                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid darkorange",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "darkorange",
                        }}
                      >
                        View Logistics
                      </div>
                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid gainsboro",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "gray",
                        }}
                      >
                        confirm receipt
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ borderRadius: 10, marginBottom: 20 }}>
                  <div className="each-order-container-1">
                    <div className="order-header-1">
                      <div style={{ fontSize: 10 }}>
                        Quanzhong Zanchilong Trading has...{" "}
                        <i
                          className="icofont-arrow-right"
                          style={{ color: "black" }}
                        />
                      </div>

                      <div
                        style={{
                          color: "darkorange",
                          fontSize: 7,
                          maxWidth: 50,
                          fontWeight: "bold",
                        }}
                      >
                        Goods to be received
                      </div>
                    </div>
                  </div>
                  <div
                    className="products-box"
                    style={{
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 5,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 7,
                          color: "gray",
                          maxWidth: 50,
                          paddingLeft: 10,
                        }}
                      >
                        3 kinds of goods
                      </div>
                      <div style={{ fontSize: 7, color: "gray" }}>
                        Including shipping charge Tk 110.00
                      </div>

                      <div style={{ fontSize: 8, color: "black" }}>
                        Total Amount Tk 6500
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "0px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 11,
                          color: "gray",
                          margin: "auto 0px",
                        }}
                      >
                        more
                      </div>

                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid gainsboro",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "gray",
                          marginLeft: 45,
                        }}
                      >
                        extended receipt
                      </div>
                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid darkorange",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "darkorange",
                        }}
                      >
                        View Logistics
                      </div>
                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid gainsboro",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "gray",
                        }}
                      >
                        confirm receipt
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ borderRadius: 10, marginBottom: 20 }}>
                  <div className="each-order-container-1">
                    <div className="order-header-1">
                      <div style={{ fontSize: 10 }}>
                        Quanzhong Zanchilong Trading has...{" "}
                        <i
                          className="icofont-arrow-right"
                          style={{ color: "black" }}
                        />
                      </div>

                      <div
                        style={{
                          color: "darkorange",
                          fontSize: 7,
                          maxWidth: 50,
                          fontWeight: "bold",
                        }}
                      >
                        Goods to be received
                      </div>
                    </div>
                  </div>
                  <div
                    className="products-box"
                    style={{
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: 5,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "auto" }}>
                          <div
                            style={{
                              height: 60,
                              width: 60,
                              overflow: "hidden",
                              backgroundImage: `url("assets/images/demo.jpg")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                            Men's summer breathable shoes 2021 new trend all
                            match sport casual shoes youth net shoes spring
                            shoes [Product Snapshot]
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Color: Gray 8856ß
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            Size: 44
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ color: "rgb(98 98 98)", fontSize: 11 }}>
                            Tk41.00
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginLeft: 20,
                            }}
                          >
                            X2
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 5,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 7,
                          color: "gray",
                          maxWidth: 50,
                          paddingLeft: 10,
                        }}
                      >
                        3 kinds of goods
                      </div>
                      <div style={{ fontSize: 7, color: "gray" }}>
                        Including shipping charge Tk 110.00
                      </div>

                      <div style={{ fontSize: 8, color: "black" }}>
                        Total Amount Tk 6500
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "0px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 11,
                          color: "gray",
                          margin: "auto 0px",
                        }}
                      >
                        more
                      </div>

                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid gainsboro",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "gray",
                          marginLeft: 45,
                        }}
                      >
                        extended receipt
                      </div>
                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid darkorange",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "darkorange",
                        }}
                      >
                        View Logistics
                      </div>
                      <div
                        style={{
                          padding: "10px 6px",
                          border: "1px solid gainsboro",
                          borderRadius: 20,
                          fontSize: 8,
                          color: "gray",
                        }}
                      >
                        confirm receipt
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(Orders);
