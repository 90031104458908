import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./index.css";

import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import { removeFromCart, incrementQty, decrementQty } from "../../actions";
import {
  addCartItemTofirestore,
  decrementCartItemFromFirestore,
  removeCartItemFromFirestore,
  auth,
} from "../../firebase/firebase.utils";

class cartComponent extends Component {
  constructor(props) {
    super(props);
  }

  removeFromReduxAndFirestoreCart = (item) => {
    auth.onAuthStateChanged(
      async (userAuth) => await removeCartItemFromFirestore(userAuth, item)
    );
    this.props.removeFromCart(item);
  };

  decrementReduxAndFirestoreQty = (item) => {
    auth.onAuthStateChanged(
      async (userAuth) => await decrementCartItemFromFirestore(userAuth, item)
    );
    // this.props.decrementQty(item);
  };

  incrementReduxAndFirestoreQty = (item, qty) => {
    auth.onAuthStateChanged(
      async (userAuth) => await addCartItemTofirestore(userAuth, item, qty)
    );
    // this.props.incrementQty(item, qty);
  };

  render() {
    const { cartItems, symbol, total } = this.props;
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Globalbuybd | Cart items</title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Cart Page"} />

        {cartItems.length > 0 ? (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table cart-table table-responsive-xs">
                    <thead>
                      <tr className="table-head">
                        <th scope="col">image</th>
                        <th scope="col">product name</th>
                        <th scope="col">variant</th>
                        <th scope="col">price</th>
                        <th scope="col">quantity</th>
                        <th scope="col">action</th>
                        <th scope="col">total</th>
                      </tr>
                    </thead>
                    {cartItems.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <Link
                                to={
                                  item.availability === "in-stock" ||
                                  item.availability === "pre-order"
                                    ? `${process.env.PUBLIC_URL}/product/${
                                        item.id
                                      }`
                                    : `${process.env.PUBLIC_URL}/${
                                        item.availability
                                      }/${item.id}`
                                }
                              >
                                <img
                                  src={
                                    item.colorUrl
                                      ? item.colorUrl
                                      : item.pictures[0]
                                  }
                                  alt=""
                                />
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  item.availability === "in-stock" ||
                                  item.availability === "pre-order"
                                    ? `${process.env.PUBLIC_URL}/product/${
                                        item.id
                                      }`
                                    : `${process.env.PUBLIC_URL}/${
                                        item.availability
                                      }/${item.id}`
                                }
                              >
                                {item.name}
                              </Link>
                              <div className="mobile-cart-content row">
                                <div className="col-xs-3">
                                  <div className="qty-box">
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        name="quantity"
                                        readOnly={true}
                                        className="form-control input-number"
                                        defaultValue={item.qty}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    {symbol}
                                    {item.salePrice}
                                  </h2>
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    <div
                                      className="icon"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.removeFromReduxAndFirestoreCart(
                                          item
                                        )
                                      }
                                    >
                                      <i className="icon-close" />
                                    </div>
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="product-variant">
                                <p style={{ marginTop: "1rem" }}>
                                  {item.color ? `color: ${item.color}` : ""}
                                </p>
                                <p style={{ marginTop: "1rem" }}>
                                  {item.sizeOrShipsFrom
                                    ? ` ${item.sizeOrShipsFrom}`
                                    : ""}
                                </p>
                              </div>
                            </td>
                            <td>
                              <h2>
                                {symbol}
                                {Math.round(parseInt(item.salePrice))}
                              </h2>
                            </td>
                            <td>
                              <div className="qty-box">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-left-minus"
                                      onClick={() =>
                                        this.decrementReduxAndFirestoreQty(item)
                                      }
                                      data-type="minus"
                                    >
                                      <i className="fa fa-angle-left" />
                                    </button>
                                  </span>
                                  <input
                                    type="text"
                                    name="quantity"
                                    value={item.qty}
                                    readOnly={true}
                                    className="form-control input-number"
                                  />

                                  <span className="input-group-prepend">
                                    <button
                                      className="btn quantity-right-plus"
                                      onClick={() =>
                                        this.incrementReduxAndFirestoreQty(
                                          item,
                                          1
                                        )
                                      }
                                      data-type="plus"
                                      disabled={
                                        item.qty >= item.stock ? true : false
                                      }
                                    >
                                      <i className="fa fa-angle-right" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              {item.qty >= item.stock ? "out of Stock" : ""}
                            </td>
                            <td>
                              <div
                                className="icon"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.removeFromReduxAndFirestoreCart(item)
                                }
                              >
                                <i className="fa fa-times" />
                              </div>
                            </td>
                            <td>
                              <h2 className="td-color">
                                {symbol}
                                {item.sum}
                              </h2>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                  <table className="table cart-table table-responsive-md">
                    <tfoot>
                      <tr>
                        <td>total price :</td>
                        <td className="cart-total-black">
                          <h2>
                            {symbol} {total}{" "}
                          </h2>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="row cart-buttons">
                <div className="col-6">
                  <Link
                    to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}
                    className="btn btn-solid"
                  >
                    continue shopping
                  </Link>
                </div>
                <div className="col-6">
                  <Link
                    to={`${process.env.PUBLIC_URL}/checkout`}
                    className="btn btn-solid"
                  >
                    check out
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icon-empty-cart.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <h3>
                        <strong>Your Cart is Empty</strong>
                      </h3>
                      <h4>Explore more shortlist some items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
});

export default connect(
  mapStateToProps,
  { removeFromCart, incrementQty, decrementQty }
)(cartComponent);
