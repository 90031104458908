const INITIAL_STATE = {
  shipForMeList: [],
};

const shipForMeListReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_SHIP_FOR_ME_LIST":
      return { ...state, shipForMeList: [...action.payload] };

    case "UPLOAD_SHIP_FOR_ME_LIST":
      return {
        ...state,
        shipForMeList: [action.payload, ...state.shipForMeList],
      };
    case "UPDATE_SHIP_FOR_ME_LIST":
      const filteredProductsArray = state.shipForMeList.filter(
        (shipForMe) => shipForMe.bookingId !== action.payload.bookingId
      );

      return {
        ...state,
        shipForMeList: [action.payload, ...filteredProductsArray],
      };

    case "DELETE_SHIP_FOR_ME_LIST":
      const updatedProductsArray = state.shipForMeList.filter(
        (product) => product.bookingId !== action.payload.bookingId
      );
      return {
        ...state,
        shipForMeList: [...updatedProductsArray],
      };
    default:
      return { ...state };
  }
};
export default shipForMeListReducers;
