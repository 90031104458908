import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./wishlist.css";
import Breadcrumb from "../common/breadcrumb";
import { addToCartAndRemoveWishlist, removeFromWishlist } from "../../actions";
import {
  addToCartAndRemoveWishlistFirestore,
  removeFromWishlistFirestore,
  auth,
} from "../../firebase/firebase.utils";

class wishList extends Component {
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  removeFromReduxAndFirestoreWishlist = (item) => {
    auth.onAuthStateChanged(
      async (userAuth) => await removeFromWishlistFirestore(userAuth, item)
    );
    this.props.removeFromWishlist(item);
  };

  addToCartAndRemoveWishlistFromReduxAndFirestore = (item, qty) => {
    const { history } = this.props;
    // auth.onAuthStateChanged(async userAuth=>addToCartAndRemoveWishlistFirestore(userAuth,item,qty))
    // this.props.addToCartAndRemoveWishlist(item,qty)
    if (item.availability === "in-stock" || item.availability === "pre-order") {
      history.push(`/product/${item.id}`);
    } else {
      history.push(`/${item.availability}/${item.id}`);
    }
  };

  render() {
    const { Items, symbol } = this.props;
    console.log(this.props);

    return (
      <div>
        <Breadcrumb title={"Wishlist"} />
        {Items.length > 0 ? (
          <section className="wishlist-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table cart-table table-responsive-xs">
                    <thead>
                      <tr className="table-head">
                        <th scope="col">image</th>
                        <th scope="col">product name</th>
                        <th scope="col">price</th>

                        <th scope="col">action</th>
                      </tr>
                    </thead>
                    {Items.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <Link
                                to={`${process.env.PUBLIC_URL}/product/${
                                  item.id
                                }`}
                              >
                                <img
                                  src={item.pictures[0]}
                                  alt=""
                                  style={{
                                    minHeight: 70,
                                    maxHeight: 70,
                                    minWidth: 70,
                                    maxWidth: 70,
                                    borderRadius: 5,
                                    border: "2px solid gainsboro",
                                  }}
                                />
                              </Link>
                            </td>
                            <td className="wishlist-item-name">
                              <Link
                                to={`${process.env.PUBLIC_URL}/product/${
                                  item.id
                                }`}
                              >
                                {item.name}
                              </Link>
                            </td>

                            <td>
                              <h2>
                                {symbol}
                                {parseInt(
                                  item.salePrice *
                                    (this.props.currency
                                      ? this.props.currency.taka
                                      : 1)
                                )}
                                <del>
                                  <span className="money">
                                    {symbol}
                                    {parseInt(
                                      item.price *
                                        (this.props.currency
                                          ? this.props.currency.taka
                                          : 1)
                                    )}
                                  </span>
                                </del>
                              </h2>
                            </td>

                            <td>
                              <a
                                href="javascript:void(0)"
                                className="icon"
                                onClick={() =>
                                  this.removeFromReduxAndFirestoreWishlist(item)
                                }
                              >
                                <i
                                  className="fa fa-times"
                                  style={{ color: "red" }}
                                />
                              </a>
                              <a
                                href="javascript:void(0)"
                                className="cart"
                                onClick={() => {
                                  this.props.history.push(
                                    `/product/${item.id}`
                                  );
                                }}
                                style={{ marginLeft: 10 }}
                              >
                                <i
                                  className="fa fa-shopping-cart"
                                  style={{ color: "#18768f" }}
                                />
                              </a>
                            </td>
                            <div className="mobile-cart-content">
                              <div
                                className="row"
                                style={{ marginTop: "10px" }}
                              >
                                <div className="col-xs-3">
                                  <h2
                                    className="td-color"
                                    style={{ fontSize: 10, color: "#555" }}
                                  >
                                    {item.name.slice(0, 50)}...
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="col-xs-3">
                                  <h2
                                    className="td-color"
                                    style={{ fontSize: 13 }}
                                  >
                                    {symbol}
                                    {parseInt(
                                      item.salePrice *
                                        (this.props.currency
                                          ? this.props.currency.taka
                                          : 1)
                                    )}
                                  </h2>
                                </div>
                                <div
                                  className="col-xs-3"
                                  style={{ marginRight: 30 }}
                                >
                                  <h2 className="td-color">
                                    <a
                                      href="javascript:void(0)"
                                      className="icon"
                                      onClick={() =>
                                        this.removeFromReduxAndFirestoreWishlist(
                                          item
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-times"
                                        style={{
                                          color: "red",
                                          fontSize: 13,
                                        }}
                                      />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      className="cart"
                                      onClick={() => {
                                        this.props.history.push(
                                          `/product/${item.id}`
                                        );
                                      }}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <i
                                        className="fa fa-shopping-cart"
                                        style={{
                                          color: "#18768f",
                                          fontSize: 15,
                                        }}
                                      />
                                    </a>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/empty-wishlist.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <h3>
                        <strong>WhishList is Empty</strong>
                      </h3>
                      <h4>Explore more shortlist some items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Items: state.wishlist.list,
  symbol: state.data.symbol,
  currency: state.user.currency,
});

export default connect(
  mapStateToProps,
  { addToCartAndRemoveWishlist, removeFromWishlist }
)(wishList);
