import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { Link } from "react-router-dom";
import "./details-top-tabs.css";
import { getItemDescription } from "../../../actions/get1688Data";
class DetailsTopTabsApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
    };
  }
  render() {
    const { item } = this.props;

    return (
      <section className="tab-product m-0">
        {item ? (
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <Tabs className="tab-content nav-material">
                <TabList className="nav nav-tabs nav-material">
                  <Tab className="nav-item">
                    <span
                      className="nav-link"
                      style={{ fontSize: "80%", margin: "auto" }}
                    >
                      <i className="icofont icofont-man-in-glasses" />
                      Specifications
                    </span>
                    <div className="material-border" />
                  </Tab>
                  <Tab
                    className="nav-item"
                    onClick={async () => {
                      let description = await getItemDescription(item.id);
                      this.setState({
                        description: description,
                      });
                    }}
                  >
                    <span
                      className="nav-link active"
                      style={{ fontSize: "80%", margin: "auto" }}
                    >
                      <i className="icofont icofont-ui-home" />
                      OverView
                    </span>
                    <div className="material-border" />
                  </Tab>

                  <Tab className="nav-item">
                    <span
                      className="nav-link"
                      style={{ fontSize: "80%", margin: "auto" }}
                    >
                      <i className="icofont icofont-contacts" />
                      Reviews(
                      {item.feedback ? item.feedback.length : ""})
                    </span>
                    <div className="material-border" />
                  </Tab>
                </TabList>
                <TabPanel className="tab-pane fade mt-4 show active">
                  <table className="table table-striped mb-0">
                    <tbody>
                      {item.specs
                        ? item.specs.map((item, i) => (
                            <tr key={i}>
                              <th>{item.name}:</th>
                              <td>{item.value}</td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel>
                  <p className="mt-4 p-0">
                    {this.state.description ? (
                      <div style={{ maxWidth: "100%" }}>
                        <div
                          className="overview-container"
                          style={{ maxWidth: "100%", overflowX: "scroll" }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.description,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </p>
                </TabPanel>

                <TabPanel>
                  <div className="mt-4 text-center">
                    {item.feedback && item.feedback.length > 0 ? (
                      item.feedback.map((feedback, index) => (
                        <div class="media" key={index}>
                          <div class="pull-left">
                            <i className="fa fa-user" />
                          </div>
                          <div class="media-body review-image">
                            <h4
                              class="media-heading"
                              style={{ textAlign: "start" }}
                            >
                              {feedback.displayName}
                            </h4>
                            <ul class="list-unstyled list-inline media-detail pull-left">
                              <li>
                                <i class="fa fa-calendar" />
                                {feedback.date}
                              </li>
                              <li>
                                <i class="fa fa-thumbs-up" /> &nbsp;
                                {feedback.country}
                              </li>
                            </ul>
                            <p style={{ marginBottom: "1rem" }}>
                              {feedback.content}
                            </p>
                            <ul class="list-unstyled list-inline media-detail pull-left">
                              {feedback.info.Color ? (
                                <li>Color:{feedback.info.Color}</li>
                              ) : (
                                ""
                              )}
                              {feedback.info.Logistics ? (
                                <li>Logistics:{feedback.info.Logistics}</li>
                              ) : (
                                ""
                              )}
                              {feedback.info.Size ? (
                                <li>Size:{feedback.info.Size}</li>
                              ) : (
                                ""
                              )}
                            </ul>
                            {feedback.photos.length > 0
                              ? feedback.photos.map((photo, index) => (
                                  <img
                                    key={index}
                                    style={{ width: "10%" }}
                                    src={photo}
                                  />
                                ))
                              : ""}
                            <ul class="list-unstyled list-inline media-detail pull-right">
                              <li class="">rating :</li>
                              <li class="">{feedback.rating}</li>
                            </ul>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h2 style={{ textAlign: "center" }}>
                        No reviews yet for this product
                      </h2>
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default DetailsTopTabsApi;
