import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Modal from "react-responsive-modal";
import "./details-price.css";
import { connect } from "react-redux";
import themeSettings from "../../../common/theme-settings";
import {
  addToWishlistUnsafe,
  addToPendingOrdersRedux,
  getAllD2DRatesRedux,
} from "../../../../actions";

class DetailsWithPriceApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: 1,
      selectedColor: null,
      colorUrl: "",
      selectedSize: "",
      selectedSizeObj: "",
      stock: "InStock",
      nav3: null,
      warningMessage: false,
      skuId: "",
      sizesArray: [],
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount = async () => {
    this.setState({
      nav3: this.slider3,
    });
    if (this.colorsArray.length > 0) {
      this.setState(
        {
          selectedColor: this.colorsArray[0],
        },
        () => {
          if (this.sizeArray && this.sizeArray.length > 0) {
            this.getSizesArray(this.state.selectedColor);
          }
        }
      );
    }
  };

  getSizesArray = (color) => {
    const { item } = this.props;
    console.log(item);
    console.log(color);
    if (color.url) {
      let sizesArray = item.variants.filter((sizesku) => {
        let colorCode = sizesku.properties
          .split(";")
          .filter((colorSizeCode) => colorSizeCode.startsWith("1"))[0];
        if (colorCode == color.properties) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        sizesArray,
      });
    } else if (color.value) {
      let sizesArray = item.variants.filter((sizesku) => {
        let colorCode = sizesku.properties
          .split(";")
          .filter((colorSizeCode) => colorSizeCode.startsWith("1"))[0];
        if (colorCode == color.properties) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        sizesArray,
      });
    } else {
      let sizesArray = item.variants.filter((sizesku) => {
        let colorCode = sizesku.properties
          .split(";")
          .filter((colorSizeCode) => colorSizeCode.startsWith("1"))[0];
        if (colorCode == Object.keys(color)[0]) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        sizesArray,
      });
    }
  };

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: parseInt(this.state.quantity) - 1 });
    }
  };

  plusQty = () => {
    this.setState({ quantity: parseInt(this.state.quantity) + 1 });
  };

  changeQty = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state);
  };

  clickOnColorVariant = (color) => {
    if (color.url) {
      this.props.clickOnColorVariant(color.url);
      this.setState({ colorUrl: color.url });
    }

    this.setState({ selectedColor: color }, () => {
      this.getSizesArray(color);
    });
  };

  getSkuId = (image, size) => {
    console.log("getSkuId is getting called");
    console.log(image, size);
    if (image && size) {
      console.log(`${Object.keys(size)[0]};${image}`);
      const skuId = this.props.item.variants.find(
        (variantObj) =>
          variantObj.properties == `${Object.keys(size)[0]};${image}`
      );
      this.setState({ skuId }, () => console.log(this.state.skuId));
      // console.log(skuId);
    }
    if (image && !size) {
      const skuId = this.props.item.variants.find(
        (variantObj) => variantObj.properties == `${image}`
      );
      this.setState({ skuId }, () => console.log(this.state.skuId));
    }
    if (size && !image) {
      const skuId = this.props.item.variants.find(
        (variantObj) => variantObj.properties == `${Object.keys(size)[0]}`
      );
      this.setState({ skuId }, () => console.log(this.state.skuId));
    }
  };

  BuynowClicked = () => {
    const {
      BuynowClicked,
      history,
      currentUser,
      addToPendingOrdersRedux,
      item,
    } = this.props;
    let skuKeyArray = Object.keys(this.state)
      .filter((key) => key.startsWith("1") || key.startsWith("2"))
      .filter((key) => this.state[key] !== "");

    if (skuKeyArray.length == 0 && this.state.quantity == 0) {
      this.setState({
        warningMessage: true,
      });
      return;
    }
    if (this.state.sizesArray.length == 0 && this.colorsArray.length == 0) {
      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: false,
            skus: [
              {
                totalQuantity: this.state.quantity,
              },
            ],
          },
        ],
      };
      console.log(cartItemObj);
      addToPendingOrdersRedux([cartItemObj]);
      this.props.history.push("/dashboard/pages/place-order");
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
      });
    } else {
      let totalQuantity = 0;
      let skus = skuKeyArray.map((key) => {
        let sku = item.variants.find((sku) => sku.properties == key);
        let color = this.getColorName(sku.properties_name);

        let size = this.getSizeName(sku.properties_name);
        console.log(color);
        console.log(size);
        totalQuantity += this.state[key];
        return {
          totalQuantity: this.state[key],
          color,
          size,
          ...sku,
        };
      });
      if (totalQuantity == 0) {
        this.setState({
          warningMessage: true,
        });
        return;
      }

      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: false,
            skus,
          },
        ],
      };
      console.log(cartItemObj);
      addToPendingOrdersRedux([cartItemObj]);
      this.props.history.push("/dashboard/pages/place-order");
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
      });
      skuKeyArray.map((key) => {
        this.setState({
          [key]: "",
        });
      });
    }
  };

  addToCartClicked = async () => {
    const {
      currentUser,
      addToCartClicked,
      BuynowClicked,
      history,
      item,
    } = this.props;

    let skuKeyArray = Object.keys(this.state)
      .filter((key) => key.startsWith("1") || key.startsWith("2"))
      .filter((key) => this.state[key] !== "");

    if (skuKeyArray.length == 0 && this.state.quantity == 0) {
      this.setState({
        warningMessage: true,
      });
      return;
    }
    if (this.state.sizesArray.length == 0 && this.colorsArray.length == 0) {
      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: false,
            skus: [
              {
                totalQuantity: this.state.quantity,
              },
            ],
          },
        ],
      };
      console.log(cartItemObj);
      addToCartClicked(cartItemObj);
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
      });
    } else {
      let totalQuantity = 0;
      let skus = skuKeyArray.map((key) => {
        let sku = item.variants.find((sku) => sku.properties == key);
        let color = this.getColorName(sku.properties_name);

        let size = this.getSizeName(sku.properties_name);
        console.log(color);
        console.log(size);
        totalQuantity += this.state[key];
        return {
          totalQuantity: this.state[key],
          color,
          size,
          ...sku,
        };
      });
      if (totalQuantity == 0) {
        this.setState({
          warningMessage: true,
        });
        return;
      }

      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: false,
            skus,
          },
        ],
      };
      console.log(cartItemObj);
      addToCartClicked(cartItemObj);
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
      });
      skuKeyArray.map((key) => {
        this.setState({
          [key]: "",
        });
      });
    }
  };

  getSizeName = (name) => {
    let array = name.split(";");
    let sizeStr = array.find((str) => str.startsWith("2"));
    if (sizeStr) {
      let sizeArry = sizeStr.split(":");
      let size = sizeArry[sizeArry.length - 1];
      return size;
    } else {
      return null;
    }
  };
  getColorName = (name) => {
    let array = name.split(";");
    let colorStr = array.find((str) => str.startsWith("1"));
    if (colorStr) {
      let colorArry = colorStr.split(":");
      let color = colorArry[colorArry.length - 1];
      return color;
    } else {
      return null;
    }
  };
  getImage = (colorSizeCode) => {
    const { item } = this.props;
    console.log(colorSizeCode);

    let colorCode = colorSizeCode
      .split(";")
      .find((color) => color.startsWith("1"));
    console.log(colorCode);
    console.log(this.colorsArray);
    const colorObj = this.colorsArray.find(
      (color) =>
        (color.properties && color.properties == colorCode) ||
        Object.keys(color)[0] == colorCode
    );
    if (colorObj) {
      if (colorObj.url) {
        return (
          <div
            style={{
              maxWidth: "40px",
              minHeight: "42px",
              maxHeight: "42px",
              padding: "0px",
              marginTop: -8,
              cursor: "pointer",
            }}
            onClick={() => {
              this.props.clickOnColorVariant(colorObj.url);
              // this.setState({
              //   selectedColor: colorObj,
              //   colorUrl: colorObj.url,
              // });
            }}
          >
            <img
              src={colorObj.url}
              alt={item.properties}
              className="img-fluid color-variant-image"
              style={{
                maxWidth: "40px",
                minHeight: "40px",
                maxHeight: "40px",
              }}
            />
          </div>
        );
      } else if (colorObj.value) {
        return (
          <div
            style={{ padding: 5, fontSize: 12, border: "1px solid gainsboro" }}
          >
            {colorObj.value}
          </div>
        );
      } else {
        return (
          <div
            style={{ padding: 5, fontSize: 12, border: "1px solid gainsboro" }}
          >
            {Object.values(colorObj)[0]}
          </div>
        );
      }
    } else {
      return null;
    }
  };
  changeSkuQty = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (String(value).startsWith("0")) {
      this.setState({ [name]: String(value).replace("0", "") }, () => {
        console.log(this.state);
      });
    } else {
      this.setState(
        {
          [name]: String(value),
        },
        () => {
          console.log(this.state);
        }
      );
    }
  };
  render() {
    const {
      symbol,
      item,
      BuynowClicked,
      addToWishlistClicked,
      currentUser,
      currency,
      d2dRates,
    } = this.props;

    var colorsnav = {
      slidesToShow: 8,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };

    var totalAvailableQuantity = 0;
    if (item.variants.length > 0) {
      item.variants.map(
        (item) => (totalAvailableQuantity += parseInt(item.quantity))
      );
    }
    this.colorsArray = [];
    if (item.props_imgs && item.props_imgs.prop_img.length > 0) {
      let colorsWithPicture = item.props_imgs.prop_img;
      let colorsWithoutPicture = Object.keys(item.props_list)
        .filter((key) => key.startsWith("1"))
        .map((key) => {
          return { properties: key, value: item.props_list[key] };
        });
      if (colorsWithPicture.length == colorsWithoutPicture.length) {
        this.colorsArray = colorsWithPicture;
      } else {
        let mergedArray = [...colorsWithPicture, ...colorsWithoutPicture];
        console.log(mergedArray);
        var resArr = [];
        mergedArray.filter((item) => {
          var i = resArr.findIndex((x) => x.properties == item.properties);
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });
        console.log(resArr);
        this.colorsArray = resArr;
      }
    } else if (item.prop_imgs && item.prop_imgs.prop_img.length > 0) {
      let colorsWithPicture = item.prop_imgs.prop_img;
      let colorsWithoutPicture = Object.keys(item.props_list)
        .filter((key) => key.startsWith("1"))
        .map((key) => {
          return { properties: key, value: item.props_list[key] };
        });
      if (colorsWithPicture.length == colorsWithoutPicture.length) {
        this.colorsArray = colorsWithPicture;
      } else {
        let mergedArray = [...colorsWithPicture, ...colorsWithoutPicture];
        console.log(mergedArray);
        var resArr = [];
        mergedArray.filter((item) => {
          var i = resArr.findIndex((x) => x.properties == item.properties);
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });
        console.log(resArr);
        this.colorsArray = resArr;
      }
    } else if (
      item.props_list &&
      Object.keys(item.props_list).filter((key) => key.startsWith("1")).length >
        0
    ) {
      this.colorsArray = Object.keys(item.props_list)
        .filter((key) => key.startsWith("1"))
        .map((key) => {
          return { properties: key, value: item.props_list[key] };
        });
    }
    console.log(this.colorsArray);

    this.sizeArray = [];
    if (item.props_list) {
      this.sizeArray = Object.keys(item.props_list)
        .filter((key) => key.startsWith("2"))
        .map((key) => {
          return { [key]: item.props_list[key] };
        });
    }
    console.log(this.sizeArray);
    console.log(this.state.sizesArray);

    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }
    return (
      <div className="col-lg-6 rtl-text">
        <div className="product-right">
          <h6 className="product-detail-title">
            {" "}
            {window.innerWidth > 600
              ? item.name
              : `${item.name.slice(0, 60)}...`}
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingBottom: 10,
            }}
          >
            <p>within 30 days</p>
            <p style={{ paddingLeft: 30 }}>
              The purchase of this product is a commercial trade activity
            </p>
          </div>
          {item.price_range.length > 0 ? (
            <div className="price-range-container">
              {item.price_range.map((price, index, arr) => {
                const stateQ = parseInt(this.state.quantity);
                const dataQs = arr.map((p, i) =>
                  i === 0 ? 0 : parseInt(p[0])
                );

                const selectedIndex = dataQs.findIndex((q, i, arr) => {
                  return stateQ >= q && stateQ < (arr[i + 1] || stateQ + 1);
                });
                const selected = selectedIndex === index;

                return (
                  <div
                    key={index}
                    className={`price-box ${selected && "selected"}`}
                  >
                    <h3 className="price">
                      Tk {Math.round(parseFloat(price[1]) * parseFloat(taka))}
                    </h3>
                    <p className="quantity">
                      {index == 0 ? "below " : ""}
                      {Math.round(parseFloat(price[0]) * parseFloat(taka))} or
                      more
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div>
            <h6 style={{ marginBottom: "0px" }}>orders: {item.orders}</h6>
            <h6>total available: {totalAvailableQuantity}</h6>
          </div>
          <h3 style={{ color: "#f54c3c" }}>
            {symbol}
            {Math.round(parseFloat(item.salePrice) * parseFloat(taka))}
            <span>
              <del>
                {symbol}
                {Math.round(parseFloat(item.price) * parseFloat(taka))}
              </del>
            </span>
          </h3>

          {this.colorsArray.length > 0 && this.state.sizesArray.length > 0 ? (
            <div>
              <h6 className="product-title size-text">color: </h6>
              <div className="row" style={{ marginBottom: 20 }}>
                {this.colorsArray.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        padding: 2,
                        cursor: "pointer",
                      }}
                      className={
                        item.url &&
                        this.state.selectedColor &&
                        this.state.selectedColor.properties == item.properties
                          ? "border-red"
                          : ""
                      }
                    >
                      {item.url ? (
                        <div
                          key={i}
                          style={{
                            maxWidth: "50px",
                            minHeight: "52px",
                            maxHeight: "52px",
                            padding: "0px",
                          }}
                          onClick={() => this.clickOnColorVariant(item)}
                        >
                          <img
                            src={`${item.url}`}
                            key={i}
                            alt={item.properties}
                            className="img-fluid color-variant-image"
                            style={{
                              maxWidth: "50px",
                              minHeight: "50px",
                              maxHeight: "50px",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          key={i}
                          style={{
                            minHeight: "32px",
                            maxHeight: "32px",
                            padding: "5px",
                            border:
                              this.state.selectedColor &&
                              this.state.selectedColor.properties ==
                                item.properties
                                ? "1px solid red"
                                : "1px solid gainsboro",
                            fontSize: 12,
                            color:
                              this.state.selectedColor &&
                              this.state.selectedColor.properties ==
                                item.properties
                                ? "black"
                                : "gray",
                          }}
                          onClick={() => {
                            console.log(item);
                            this.clickOnColorVariant(item);
                          }}
                        >
                          {" "}
                          {item.value}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            this.colorsArray.length > 0 &&
            item.variants.map((color, i) => (
              <div
                className="row"
                style={{
                  padding: "20px 10px",
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px dotted gainsboro",
                }}
                key={i}
              >
                <div className="col-2 size-price">
                  {this.getImage(color.properties)}
                </div>
                <div className="col size-price" style={{ fontWeight: "bold" }}>
                  Tk. {Math.round(parseFloat(color.price) * parseFloat(taka))}
                </div>
                <div className="col">
                  <span
                    style={{
                      color: "darkgray",
                      fontWeight: "lighter",
                      fontSize: 10,
                    }}
                  >
                    {color.quantity} available for sale
                  </span>
                </div>

                <div className="col-4">
                  <div className="qty-box" style={{ marginTop: 0 }}>
                    {color.quantity == "0" ? (
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "lighter",
                          fontSize: 12,
                          marginTop: 5,
                        }}
                      >
                        Out of Stock
                      </span>
                    ) : (
                      <div className="input-group">
                        <span
                          className="input-group-prepend"
                          style={{ height: 25 }}
                        >
                          <button
                            type="button"
                            className="btn quantity-left-minus"
                            onClick={() => {
                              if (color.properties) {
                                if (this.state[color.properties] != 0) {
                                  this.setState({
                                    [color.properties]:
                                      parseInt(this.state[color.properties]) -
                                      1,
                                  });
                                }
                              } else {
                                if (this.state[Object.keys(color)[0]] != 0) {
                                  this.setState({
                                    [Object.keys(color)[0]]:
                                      parseInt(
                                        this.state[Object.keys(color)[0]]
                                      ) - 1,
                                  });
                                }
                              }
                            }}
                            data-type="minus"
                            data-field=""
                          >
                            <i className="fa fa-angle-left" />
                          </button>
                        </span>
                        {color.properties ? (
                          <input
                            type="number"
                            name={`${color.properties}`}
                            value={
                              this.state[`${color.properties}`]
                                ? this.state[`${color.properties}`]
                                : 0
                            }
                            onChange={this.changeSkuQty}
                            className="form-control input-number"
                            style={{ height: 25 }}
                            placeholder="0"
                          />
                        ) : (
                          <input
                            type="number"
                            name={`${Object.keys(color)[0]}`}
                            value={
                              this.state[`${Object.keys(color)[0]}`]
                                ? this.state[`${Object.keys(color)[0]}`]
                                : 0
                            }
                            onChange={this.changeSkuQty}
                            className="form-control input-number"
                            style={{ height: 25 }}
                          />
                        )}
                        <span
                          className="input-group-prepend"
                          style={{ height: 25 }}
                        >
                          <button
                            type="button"
                            className="btn quantity-right-plus"
                            onClick={() => {
                              if (color.properties) {
                                if (!this.state[color.properties]) {
                                  this.setState({
                                    [color.properties]: 1,
                                  });
                                } else {
                                  this.setState({
                                    [color.properties]:
                                      parseInt(this.state[color.properties]) +
                                      1,
                                  });
                                }
                              } else {
                                if (!this.state[Object.keys(color)[0]]) {
                                  this.setState({
                                    [Object.keys(color)[0]]: 1,
                                  });
                                } else {
                                  this.setState({
                                    [Object.keys(color)[0]]:
                                      parseInt(
                                        this.state[Object.keys(color)[0]]
                                      ) + 1,
                                  });
                                }
                              }
                            }}
                            data-type="plus"
                            data-field=""
                          >
                            <i className="fa fa-angle-right" />
                          </button>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}

          <div className="product-description border-product">
            {item ? (
              <div>
                {this.state.sizesArray.length > 0 ? (
                  <h6 className="product-title size-text">size</h6>
                ) : (
                  ""
                )}

                <div className="size-box">
                  {this.state.sizesArray.length > 0 ? (
                    <div className="size-category">
                      {this.state.sizesArray.map((sizeObj, i) => {
                        return (
                          <div
                            className="row"
                            style={{
                              padding: "20px 10px",
                              display: "flex",
                              flexDirection: "row",
                              borderBottom: "1px dotted gainsboro",
                            }}
                            key={i}
                          >
                            <div className="col-2 size-price">
                              {this.getSizeName(sizeObj.properties_name)}
                            </div>
                            <div
                              className="col size-price"
                              style={{ fontWeight: "bold" }}
                            >
                              Tk.{" "}
                              {Math.round(
                                parseFloat(sizeObj.price) * parseFloat(taka)
                              )}
                            </div>
                            <div className="col">
                              <span
                                style={{
                                  color: "darkgray",
                                  fontWeight: "lighter",
                                  fontSize: 10,
                                }}
                              >
                                {sizeObj.quantity} available for sale
                              </span>
                            </div>

                            <div className="col-4">
                              <div className="qty-box" style={{ marginTop: 0 }}>
                                {sizeObj.quantity == "0" ? (
                                  <span
                                    style={{
                                      color: "gray",
                                      fontWeight: "lighter",
                                      fontSize: 12,
                                      marginTop: 5,
                                    }}
                                  >
                                    Out of Stock
                                  </span>
                                ) : (
                                  <div className="input-group">
                                    <span
                                      className="input-group-prepend"
                                      style={{ height: 25 }}
                                    >
                                      <button
                                        type="button"
                                        className="btn quantity-left-minus"
                                        onClick={() => {
                                          if (
                                            this.state[sizeObj.properties] != 0
                                          ) {
                                            this.setState({
                                              [sizeObj.properties]:
                                                parseInt(
                                                  this.state[sizeObj.properties]
                                                ) - 1,
                                            });
                                          }
                                        }}
                                        data-type="minus"
                                        data-field=""
                                      >
                                        <i className="fa fa-angle-left" />
                                      </button>
                                    </span>
                                    <input
                                      type="number"
                                      name={`${sizeObj.properties}`}
                                      value={
                                        this.state[`${sizeObj.properties}`]
                                          ? String(
                                              this.state[
                                                `${sizeObj.properties}`
                                              ]
                                            )
                                          : 0
                                      }
                                      onChange={this.changeSkuQty}
                                      className="form-control input-number"
                                      style={{ height: 25 }}
                                      placeholder={"0"}
                                    />
                                    <span
                                      className="input-group-prepend"
                                      style={{ height: 25 }}
                                    >
                                      <button
                                        type="button"
                                        className="btn quantity-right-plus"
                                        onClick={() => {
                                          if (!this.state[sizeObj.properties]) {
                                            this.setState({
                                              [sizeObj.properties]: 1,
                                            });
                                          } else {
                                            this.setState({
                                              [sizeObj.properties]:
                                                parseInt(
                                                  this.state[sizeObj.properties]
                                                ) + 1,
                                            });
                                          }
                                        }}
                                        data-type="plus"
                                        data-field=""
                                      >
                                        <i className="fa fa-angle-right" />
                                      </button>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    this.colorsArray.length == 0 &&
                    this.state.sizesArray.length == 0 && (
                      <div style={{ margin: "10px 0px" }}>
                        <div style={{ marginBottom: 5 }}>Quantity</div>
                        <div className="qty-box" style={{ marginTop: 0 }}>
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-left-minus"
                                onClick={this.minusQty}
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-angle-left" />
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.quantity}
                              onChange={this.changeQty}
                              className="form-control input-number"
                            />
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-right-plus"
                                onClick={this.plusQty}
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-angle-right" />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="form" style={{ marginBottom: 10, marginTop: 5 }}>
              <div className="form-group row shipping-method">
                <label style={{ marginBottom: 5, marginLeft: 13 }}>
                  Know Your Shipping cost (approx):
                </label>
                <div className="col-xl-9 col-sm-7">
                  <select
                    className="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="category"
                    value={this.state.category}
                    onChange={this.handleChange}
                    onClick={() => {
                      this.props.getAllD2DRatesRedux("air", "china");
                    }}
                  >
                    <option>Choose your product category</option>
                    {d2dRates.map((rate) => (
                      <option value={rate.id} key={rate.id}>
                        {rate.id} - {rate.ten}Tk/Kg
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <p className="service-list">
              <span>&#10003;</span> 24/7 online services for our customers via
              wechat,whatsapp, hotline and facebook. <br />
              <span>&#10003;</span> The best wholesale prices and a vast variety
              of goods.
              <br />
              <span>&#10003;</span> live tracking your product 24/7.
              <br />
              <span>&#10003;</span> 100% Secure payment with us. <br />
              <span>&#10003;</span> Fastest product delivery(around 15-20
              working days). <br />
              <span>&#10003;</span> Cheapest shipping charges around the
              country.
              <br />
              <span>&#10003;</span> Claiming 100% refund facility.
              <Link
                to={`${process.env.PUBLIC_URL}/pages/refund-policy`}
                style={{ fontSize: "80%", color: "orange" }}
              >
                see refund policy
              </Link>
              <br />
            </p>
          </div>

          {this.state.warningMessage ? (
            <div
              className="alert alert-danger error-message"
              role="alert"
              style={{
                color: "white",
                backgroundColor: "orange",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              Please fill the missing information first
            </div>
          ) : (
            ""
          )}
          {!currentUser ? (
            <div
              className="alert alert-danger error-message"
              role="alert"
              style={{
                color: "white",
                backgroundColor: "orange",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              You must login first
            </div>
          ) : (
            ""
          )}
          <div className="product-buttons">
            {currentUser ? (
              <>
                <a
                  className="order-now-button"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => this.BuynowClicked()}
                >
                  <i className="fa fa-shopping-bag" />
                  &nbsp;Order Now
                </a>
                <a
                  className="wishlist-btn"
                  style={{
                    padding: "9px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.addToCartClicked()}
                >
                  <i className="fa fa-shopping-cart" />
                  &nbsp;Add To Cart
                </a>

                <button
                  className="wishlist-btn"
                  onClick={() => addToWishlistClicked(item)}
                  style={{
                    padding: "7px 15px",
                  }}
                >
                  <i className="fa fa-heart" />
                  <span className="title-font">&nbsp;Add To WishList</span>
                </button>
              </>
            ) : (
              <a
                className="btn btn-solid"
                onClick={() =>
                  this.props.history.push("/pages/login", {
                    from: this.props.match.url,
                  })
                }
              >
                Login
              </a>
            )}
          </div>

          <div className="border-product">
            <h6 className="product-title">product details</h6>
            {item.shortDetails ? <p>{item.shortDetails}</p> : ""}
          </div>
          <div className="border-product" style={{ paddingBottom: "30px" }}>
            <h6 className="product-title">share it</h6>
            <div className="product-icon">
              <ul className="product-social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://plus.google.com/discover" target="_blank">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
              <button
                className="wishlist-btn"
                onClick={() => addToWishlistClicked(item)}
              >
                <i className="fa fa-heart" />
                <span className="title-font">Add To WishList</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser.displayName,
  d2dRates: state.bookingRequests.d2dRates,
  currency: state.user.currency,
});
export default connect(
  mapStateToProps,
  { addToPendingOrdersRedux, getAllD2DRatesRedux }
)(DetailsWithPriceApi);
