import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../common/index.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllProductsFirestore,
  setSearchedProductsArray,
  getAllBookingsOfSingleUserRedux,
  getAllShipForMeOfSingleUserRedux,
  getAllOrdersApiRedux,
  getAllBannersRedux,
  getAllCampaignsRedux,
  getAllHomeCategoryRedux,
  uploadAliProducts,
  setIntroModal,
} from "../../../actions";
import axios from "axios";
import CollectionTwo from "./collection2";
import CollectionThree from "./collection3";
import {
  getAllFirestoreAliProductsList,
  uploadImageRechargeRequest,
  getSelectedIntroModal,
} from "../../../firebase/firebase.utils";
import "./main.css";
import Shop from "./shop";
import BuyAndShipForMe from "./buyAndShipForMe.png";
import ShipForMe from "./shipForMe.png";
import IntroModal from "./IntroModal";
import icon from "./45.jpg";
class Pets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarValue: "",
      shippingValue: "Ship for me",
      image: BuyAndShipForMe,
      campaigns: [],
    };
  }
  componentDidMount = async () => {
    this.props.getAllHomeCategoryRedux();
    const introModal = await getSelectedIntroModal();
    this.props.setIntroModal(introModal);
    if (introModal) {
      document.getElementById("intro-modal").click();
    }

    const aliProductsArray = await getAllFirestoreAliProductsList();
    this.props.getAllBannersRedux();
    this.props.getAllCampaignsRedux();
    this.props.getAllProductsFirestore([...aliProductsArray]);
    if (this.props.currentUser.id) {
      this.props.getAllBookingsOfSingleUserRedux(this.props.currentUser.id);
      this.props.getAllShipForMeOfSingleUserRedux(this.props.currentUser.id);
      this.props.getAllOrdersApiRedux(this.props.currentUser.id);
    }
    if (this.props.campaigns.length > 0) {
      this.timerID = setInterval(() => this.tick(), 1000);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.currentUser.id &&
      nextProps.currentUser.id !== this.props.currentUser.id
    ) {
      this.props.getAllBookingsOfSingleUserRedux(nextProps.currentUser.id);
      this.props.getAllShipForMeOfSingleUserRedux(nextProps.currentUser.id);
      this.props.getAllOrdersApiRedux(nextProps.currentUser.id);
    }
    if (nextProps.campaigns.length > 0) {
      this.timerID = setInterval(() => this.tick(), 1000);
    }
  };
  componentWillUnmount = () => {
    clearInterval(this.timerID);
    this.setState({ searchBarValue: "" });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleSearchBarSubmit = (event) => {
    event.preventDefault();
    if (this.state.searchBarValue.length < 35) {
      var win = window.open(
        `${process.env.PUBLIC_URL}/collection/${this.state.searchBarValue}`,
        "_blank"
      );
      win.focus();
    } else {
      this.props.setSearchedProductsArray(0, []);
      if (this.state.searchBarValue.includes("1688")) {
        let productId = this.state.searchBarValue.split("/")[4].split(".")[0];
        console.log(productId);

        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${productId}`,
          "_blank"
        );
        win.focus();
      } else {
        let taobaoUrl = new URL(this.state.searchBarValue);
        let params = taobaoUrl.search;
        const urlParams = new URLSearchParams(params);
        const id = urlParams.get("id");
        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${id}`,
          "_blank"
        );
        win.focus();
      }
    }
    this.setState({ searchBarValue: "" });
  };

  handleNextClick = () => {
    if (this.state.shippingValue == "Ship for me") {
      this.props.history.push(`${process.env.PUBLIC_URL}/pages/ship-for-me`);
    } else if (this.state.shippingValue === "Buy and ship for me") {
      this.props.history.push(`${process.env.PUBLIC_URL}/pages/buy-for-me`);
    } else {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/pages/request-for-product`
      );
    }
  };

  _handleImgChange = async (e) => {
    // let reader = new FileReader();
    // const { pictures } = this.state;
    e.preventDefault();
    let file = e.target.files[0];

    // reader.onloadend = () => {
    //   pictures[i] = reader.result;
    //   this.setState({
    //     file: file,
    //     pictures,
    //   });
    // };
    if (file) {
      // reader.readAsDataURL(file);
      // const imgUrl = await uploadImage(file);
      const _EXTERNAL_URL = `/uploadImage`;

      let data = new FormData();
      data.append("productImage", file);

      try {
        const imgUrl = await axios.post(_EXTERNAL_URL, data);
        console.log(imgUrl.data);
        this.props.history.push(
          `${process.env.PUBLIC_URL}/collection/${imgUrl.data}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleImgChange = async (e, i) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ isLoading: false });
      this.openPage(imgUrl);
    }
  };
  openPage = (imgUrl) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/collection/img_uploads?imageUrl=${imgUrl}`
    );
  };

  tick = () => {
    let campaigns = this.props.campaigns.map((campaign) => {
      if (campaign && campaign.expiryDate) {
        const [year, month, day] = campaign.expiryDate.split("-");
        let dateFuture = new Date(
          parseInt(year),
          parseInt(month - 1),
          parseInt(day)
        );
        let dateNow = new Date();
        var seconds = Math.floor((dateFuture - dateNow) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds =
          seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        return { ...campaign, days: days + 1, hours, minutes, seconds };
      }
    });
    this.setState({ campaigns });
  };

  render() {
    const {
      bookingsArray,
      shipForMesArray,
      orders,
      banners,

      homeCategories,
      items,
    } = this.props;
    const { campaigns } = this.state;
    console.log(homeCategories);
    let totalItems = 0;
    this.props.cart.map((shop) => {
      return (totalItems += shop.items.length);
    });

    let shippingBanner = "";
    if (banners.find((banner) => banner.secondBanner)) {
      shippingBanner = banners.find((banner) => banner.secondBanner).banner;
    } else {
      setTimeout(() => {
        shippingBanner = "/assets/images/home-banner/ship_for_me.jpg";
      }, 300);
    }

    console.log(banners);
    return (
      <div>
        <Helmet>
          <title>
            Paicart | A cross border shopping platform for Bangladeshi Smart
            Shoppers
          </title>
        </Helmet>
        <Link
          id="intro-modal"
          to="#"
          role="button"
          data-toggle="modal"
          data-target="#request_intro_popup"
          style={{
            display: "none",
          }}
        >
          <i className="icofont-location-arrow" /> Intro Modal
        </Link>
        <div className="header-options">
          <li className="header-option">
            <Link to={`/pages/buy-for-me`}>Buy & Ship For Me</Link>
          </li>
          <li className="header-option">
            <Link to={`/pages/ship-for-me`}>Ship For Me</Link>
          </li>
          <li className="header-option">
            <Link to={`/pages/request-for-product`}>Request a Product</Link>
          </li>
          <li className="header-option">
            <Link to={`/pages/ship-for-me`}>Campaigns</Link>
          </li>
        </div>
        <div className="top-searchbar-parent">
          <div className="top-searchbar-container-2">
            <form
              className="form_search top-form form-search2"
              role="form"
              onSubmit={this.handleSearchBarSubmit}
              style={{
                borderRadius: "0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                id="query search-autocomplete"
                type="search"
                placeholder="Search 100+ millions of products"
                value={this.state.searchBarValue}
                onChange={this.handleChange}
                name="searchBarValue"
                className="nav-search nav-search-field"
                aria-expanded="true"
                style={{ color: "black" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="btn-search"
                  style={{ width: "40px", position: "relative" }}
                >
                  <i
                    className="fa fa-camera"
                    style={{
                      color: "#11677e",
                      marginTop: 12,
                      fontSize: "150%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.document
                        .getElementById("upload-image-input10")
                        .click();
                    }}
                  />
                </div>
                <button
                  type="submit"
                  style={{
                    position: "relative",
                    backgroundColor: "#11677e",
                    minHeight: "100%",
                    marginTop: -5,
                    marginRight: -5,
                    borderRadius: 0,
                  }}
                  onClick={this.handleSearchBarSubmit}
                >
                  <i
                    className="fa fa-search"
                    style={{
                      color: "white",
                      margin: "auto",
                      padding: "5px 13px",
                      fontSize: "120%",
                    }}
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="slider-inquiry-bar">
          <Shop />
          <section className="p-0 small-slider home-page-main-slider">
            <Slider
              autoplay={true}
              autoplaySpeed={5000}
              dots={true}
              // infinite={true}
              speed={2000}
              slidesToScroll={1}
              slidesToShow={1}
              lazyLoad={true}
              arrows={false}
              className="slider-image-container-div"
            >
              {banners
                .filter((banner) => !banner.secondBanner)
                .map((banner) => (
                  <img
                    src={banner.banner ? banner.banner : "./45.jpg"}
                    style={{
                      width: "100%",
                    }}
                  />
                ))}
            </Slider>
            <div
              style={{
                marginTop: 15,
                background: "white",
                padding: "20px 10px",
                borderRadius: 5,
                maxWidth: "100%",
              }}
            >
              <div className="how-globalbuybd-parent">
                <div className="how-globalbuybd"> HOW PAICART WORKS</div>
                <div style={{ marginTop: 10 }}>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        padding: 0,
                        paddingBottom: 10,
                        marginLeft: 20,
                        borderBottom:
                          this.state.image == BuyAndShipForMe
                            ? "2px solid #18768f"
                            : "0px",
                        color:
                          this.state.image == BuyAndShipForMe
                            ? "#18768f"
                            : "black",
                        fontWeight:
                          this.state.image == BuyAndShipForMe
                            ? "bold"
                            : "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({ image: BuyAndShipForMe });
                      }}
                    >
                      Buy and Ship For me
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        padding: 0,
                        paddingRight: 5,
                        marginLeft: 15,
                        cursor: "pointer",
                        borderBottom:
                          this.state.image == ShipForMe
                            ? "2px solid #18768f"
                            : "0px",
                        color:
                          this.state.image == ShipForMe ? "#18768f" : "black",
                        fontWeight:
                          this.state.image == ShipForMe ? "bold" : "normal",
                      }}
                      onClick={() => {
                        this.setState({ image: ShipForMe });
                      }}
                    >
                      Ship for me
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: 10, marginTop: 10, paddingRight: 10 }}>
                <img
                  src={this.state.image}
                  style={{
                    width: "100%",
                    borderRadius: 10,
                  }}
                />
              </div>
            </div>
          </section>
          <div className="enquiry-box">
            <div
              className="enquiry-box-image"
              style={{ backgroundImage: `url(${shippingBanner})` }}
              onClick={() => {
                this.props.history.push(
                  `${process.env.PUBLIC_URL}/pages/ship-for-me`
                );
              }}
            />

            <div className="enquiry-box-container-2">
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#341d00",
                }}
              >
                Tell us what do you need
              </p>

              <div>
                <select
                  name="shippingValue"
                  value={this.state.shippingValue}
                  onChange={this.handleChange}
                  required
                  className="custom-select select-outline-style"
                >
                  <option value="Ship for me">Ship for me</option>
                  <option value="Buy and ship for me">
                    Buy and ship for me
                  </option>
                  <option value="request a product">Request a Prouduct</option>
                </select>
                <div className="button-container-div">
                  <button
                    type="submit"
                    style={{
                      width: "100%",
                      borderRadius: 5,
                      textAlign: "center",
                      paddingTop: 8,
                      paddingBottom: 8,
                      backgroundColor: "#18768f",
                      color: "white",
                      border: "0px",
                    }}
                    onClick={() => {
                      this.handleNextClick();
                    }}
                  >
                    Next
                    <i
                      class="fa fa-arrow-right"
                      style={{ paddingLeft: 5, marginTop: -2 }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {campaigns.length > 0 && (
          <div
            style={{ margin: "0px auto", marginTop: 40 }}
            className="campaign-container"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#18768f",
                  fontSize: 28,
                  marginTop: -20,
                }}
              >
                Campaigns
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  color: "#f48110",
                  marginRight: 12,
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.props.history.push("/campaigns/all-campaigns");
                }}
              >
                View all ►►
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
              className="each-campaign-container"
            >
              {campaigns.map((campaign) => (
                <a
                  target="_blank"
                  href={`/collection/${campaign.categoryId}?category_id=${
                    campaign.categoryId
                  }`}
                  className="each-campaign"
                  style={{
                    position: "relative",
                    backgroundImage: `url(${campaign.banner})`,
                    backgroundSize: "cover",
                    borderRadius: 10,
                    cursor: "pointer",
                    display: "block",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      top: "-3px",
                      right: 0,
                    }}
                  >
                    <span
                      style={{
                        background: "white",
                        padding: 5,
                        borderRadius: 10,
                        color: "black",
                        border: "2px solid #ec345b",
                      }}
                    >
                      {campaign.days}days
                    </span>{" "}
                    :{" "}
                    <span
                      style={{
                        background: "white",
                        padding: 5,
                        borderRadius: 10,
                        color: "black",
                        border: "2px solid #ec345b",
                      }}
                    >
                      {campaign.hours}hrs
                    </span>{" "}
                    :{" "}
                    <span
                      style={{
                        background: "white",
                        padding: 5,
                        borderRadius: 10,
                        color: "black",
                        border: "2px solid #ec345b",
                      }}
                    >
                      {campaign.minutes}mins
                    </span>{" "}
                    :{" "}
                    <span
                      style={{
                        background: "white",
                        padding: 5,
                        borderRadius: 10,
                        color: "black",
                        border: "2px solid #ec345b",
                      }}
                    >
                      {campaign.seconds}secs
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      background: "#18768f",
                      minWidth: "100%",
                      padding: "15px",
                      fontWeight: "bold",
                      color: "white",
                      borderBottomRightRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                    className="campaign-name"
                  >
                    {campaign.name}
                  </div>
                </a>
              ))}{" "}
            </div>
          </div>
        )}
        {/*Product Section*/}

        <CollectionThree
          type={"others"}
          status={"in-stock"}
          title={"Popular"}
          subtitle=""
          categoryId={"Popular"}
        />
        <CollectionThree
          type={"others"}
          status={"in-stock"}
          title={"Recently Viewed"}
          subtitle=""
          categoryId={"Last"}
        />

        {homeCategories.length > 0 &&
          homeCategories.map((cat) => (
            <CollectionTwo
              type={"others"}
              status={"in-stock"}
              title={cat.name}
              subtitle=""
              categoryId={cat.categoryId}
            />
          ))}
        <input
          id="upload-image-input10"
          className="upload"
          type="file"
          style={{
            position: "absolute",
            zIndex: 5,
            maxWidth: "50px",
            marginTop: "10px",
            display: "none",
          }}
          onChange={(e) => this.handleImgChange(e)}
        />

        <IntroModal />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    cart: state.cartList.cart,
    bookingsArray: state.bookingRequests.bookingsArrayOfSingleUser,
    shipForMesArray: state.bookingRequests.shipForMeOfSingleUser,
    orders: state.orders.ordersApi,
    banners: state.orders.banners,
    campaigns: state.orders.campaigns,
    homeCategories: state.user.homeCategories,
    items: state.data.products,
  };
};
export default connect(
  mapStateToProps,
  {
    getAllProductsFirestore,
    setSearchedProductsArray,
    getAllBookingsOfSingleUserRedux,
    getAllShipForMeOfSingleUserRedux,
    getAllOrdersApiRedux,
    getAllBannersRedux,
    getAllCampaignsRedux,
    getAllHomeCategoryRedux,
    setIntroModal,
  }
)(Pets);
