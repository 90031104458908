import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import man from "./assets/plus image.jpeg";
import "./ship-for-me.css";
import {
  getAllD2DRatesRedux,
  setBookingRequestRedux,
  setShipForMeRedux,
  uploadShipForMeListRedux,
  updateShipForMeListRedux,
  deleteShipForMeListRedux,
  getAllShipForMeListRedux,
} from "../../actions";
import { connect } from "react-redux";
import { HashLoader, CircleLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { uploadImageProduct } from "../../firebase/firebase.utils";
import ShipForMeImage from "../layouts/homePage/shipForMe.png";
class ShipForMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: man,
      visual: "INPUT",
      shipFrom: "",
      method: "",
      productType: "",
      methodError: false,
      shipFromError: false,
      productTypeError: false,
      weightError: false,
      submit_loader: false,
      submit_loader2: false,
      result: null,
      weight: "",
      date: new Date().toLocaleDateString("en-GB"),
      ctnQuantity: "",
      totalCbm: "",
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      shipTo: "",
      shipTo2: "",
      validTo: "",
      shipmentMethod: "D2D",
      productQuantity: "",
      othersProductName: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
    };
  }
  componentDidMount = async () => {
    const { currentUser } = this.props;
    if (currentUser.uid) {
      this.props.getAllShipForMeListRedux(currentUser.uid);
    }
  };
  componentWillReceiveProps = async (nextProps) => {
    const { currentUser } = nextProps;
    if (currentUser.uid && currentUser.uid !== this.props.currentUser.uid) {
      this.props.getAllShipForMeListRedux(currentUser.uid);
      console.log("getting ship for me list");
    }
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.props.getAllD2DRatesRedux(this.state.method, this.state.shipFrom);
    });
  };
  handleChange2 = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleChangeBooking = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleChangeBooking2 = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      const day = parseInt(this.state.shipTo.split("-")[2]);
      let newDay = parseInt(this.state.shipTo.split("-")[2]) + 7;
      let month = this.state.shipTo.split("-")[1];
      let year = this.state.shipTo.split("-")[0];
      const shipTo = `${day}-${month}-${year}`;
      this.setState({
        shipTo2: shipTo,
      });
      if (newDay > 30) {
        newDay = parseInt(newDay) - 30;
        month = parseInt(month) + 1;
        if (month > 12) {
          month = 1;
          year = parseInt(year) + 1;
        }
      }
      const newDate = `${newDay}-${month}-${year}`;

      this.setState({
        validTo: newDate,
      });
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ image_loader: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageProduct(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ image_loader: false });
    }
  };

  handleDoorToDoorSubmit = (weight, productType, allD2dRates, method) => {
    const selectedproductType = allD2dRates.find(
      (pdctType) => pdctType.id == productType
    );
    if (method === "sea") {
      if (weight <= 100) {
        const result = Math.round(weight * selectedproductType["100kg"]);
        return { result, perKg: selectedproductType["100kg"] };
      }
      if (100 < weight && weight <= 1000) {
        const result = Math.round(weight * selectedproductType["below_1000kg"]);
        return { result, perKg: selectedproductType["below_1000kg"] };
      }
      if (weight > 1000) {
        const result = Math.round(weight * selectedproductType["above_1000kg"]);
        return { result, perKg: selectedproductType["above_1000kg"] };
      }
    } else {
      if (weight <= 0.3) {
        const result = selectedproductType.parcel;
        return {
          result,
          perKg: Math.round(selectedproductType.parcel * 3.333333),
        };
      }
      if (0.3 < weight && weight <= 10) {
        const result = Math.round(weight * selectedproductType.ten);
        return { result, perKg: selectedproductType.ten };
      }
      if (weight > 10) {
        const result = Math.round(weight * selectedproductType.eleven);
        return { result, perKg: selectedproductType.eleven };
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { shipFrom, weight, productType, method } = this.state;
    const { d2dRates } = this.props;
    if (shipFrom == "" && weight == "" && productType == "" && method == "") {
      this.setState({
        shipFromError: true,
        weightError: true,
        productTypeError: true,
        methodError: true,
      });
      return;
    }
    if (shipFrom == "") {
      this.setState({
        shipFromError: true,
      });
      return;
    }
    if (weight == "") {
      this.setState({
        weightError: true,
      });
      return;
    }
    if (productType == "") {
      this.setState({
        productTypeError: true,
      });
      return;
    }
    if (method == "") {
      this.setState({
        methodError: true,
      });
      return;
    }
    const result = this.handleDoorToDoorSubmit(
      weight,
      productType,
      d2dRates,
      method
    );
    this.setState({
      shipBy: method,
      shipmentMethod: "D2D",
      bookingStatus: "Pending",
      d2dSeaAir: method,
      result,
      shipFromError: false,
      methodError: false,
      productTypeError: false,
      weightError: false,
      visual: "RESULT",
    });
    window.scroll({ top: 0, behavior: "smooth" });
  };

  handleBookNowSubmit = async (bookingObj) => {
    this.setState({ submit_loader2: true });
    let date = new Date();
    await this.props.setShipForMeRedux({
      ...bookingObj,
    });
    this.setState({
      submit_loader2: false,
      visual: "INPUT",
    });
    this.props.deleteShipForMeListRedux(bookingObj);

    this.setState({
      imageUrl: man,
      visual: "INPUT",
      shipFrom: "",
      method: "",
      productType: "",
      methodError: false,
      shipFromError: false,
      productTypeError: false,
      weightError: false,
      submit_loader: false,
      submit_loader2: false,
      result: null,
      weight: "",
      date: new Date().toLocaleDateString("en-GB"),
      ctnQuantity: "",
      totalCbm: 1,
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      imageUrl: "",
      shipmentMethod: "D2D",
      productQuantity: "",
      othersProductName: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
    });
  };
  handleAddToListSubmit = async (event) => {
    event.preventDefault();
    if (!this.props.currentUser.id) {
      alert("You must login first");
      return;
    }
    if (this.state.image_loader) {
      alert("Wait for the image to be uploaded!");
      return;
    }
    this.setState({ submit_loader: true });
    let date = new Date();
    await this.props.uploadShipForMeListRedux({
      ...this.state,
      date: date.toLocaleDateString("en-GB"),
      time: date.getTime(),
      userId: this.props.currentUser.id,
      displayName: `${this.props.currentUser.userId} - ${
        this.props.currentUser.displayName
      }`,
      status: "Pending",
      shipTo: this.state.shipTo2,
      shipmentStatusScore: 1,
    });
    this.setState({
      submit_loader: false,
      visual: "INPUT",
    });
    window.scroll({ top: 400, behavior: "smooth" });
    toast.success(
      "Your Product is added to List.Now request to get rate and warehouse address"
    );

    this.setState({
      imageUrl: man,
      visual: "INPUT",
      shipFrom: "",
      method: "",
      productType: "",
      methodError: false,
      shipFromError: false,
      productTypeError: false,
      weightError: false,
      submit_loader: false,
      result: null,
      weight: "",
      date: new Date().toLocaleDateString("en-GB"),
      ctnQuantity: "",
      totalCbm: "",
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      validTo: "",
      shipTo: "",
      imageUrl: "",
      shipmentMethod: "D2D",
      productQuantity: "",

      othersProductName: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
    });
  };
  render() {
    const { shipForMeList } = this.props;
    const ind = "One";

    return (
      <div style={{ background: "#f7f7f7" }}>
        <ToastContainer />
        <Helmet>
          <title>Paicart | ship for me</title>
        </Helmet>
        <Breadcrumb title={"Tax and Shipping"} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container main-container">
            <div className="row header-text-1">Get instance Shipping rate</div>
            <div className="row header-text-2" style={{ marginBottom: 20 }}>
              First check our approximate rate for shipping. If you are
              satisfied then book!
            </div>
            {this.state.visual == "INPUT" && (
              <div className="input-container">
                <div
                  className="row booking-rate-header"
                  style={{ color: "#18768f" }}
                >
                  Get instance rate (approx)
                </div>
                <div
                  style={{
                    height: 1,
                    backgroundColor: "#18768f",
                    display: "flex",
                    flexDirection: "row",
                  }}
                />
                <form style={{ marginBottom: 20 }} onSubmit={this.handleSubmit}>
                  <div className="m-5 booking-form-container">
                    <div className="booking-input-box">
                      <label
                        style={{
                          color: "#777",
                          marginBottom: 5,
                        }}
                      >
                        METHOD (Sea/Air)
                      </label>
                      <select
                        className="custom-select"
                        name="method"
                        value={this.state.method}
                        onChange={this.handleChange}
                        style={{ height: 60 }}
                      >
                        <option value="">Choose Method</option>
                        <option value="sea">Sea</option>
                        <option value="air">Air</option>
                      </select>
                      {this.state.methodError ? (
                        <p style={{ color: "red" }}>*field required</p>
                      ) : null}
                    </div>
                    <div className="booking-input-box">
                      <label
                        style={{
                          color: "#777",
                          marginBottom: 5,
                        }}
                      >
                        ORIGIN OF SHIPMENT
                      </label>
                      <select
                        className="custom-select"
                        name="shipFrom"
                        value={this.state.shipFrom}
                        onChange={this.handleChange}
                        style={{ height: 60 }}
                      >
                        <option value="">Select Country</option>
                        <option value="china">China</option>
                        <option value="uk">UK</option>
                        <option value="usa">Usa</option>
                        <option value="hongkong">Hongkong</option>
                        <option value="thailand">Thailand</option>
                        {this.state.method === "air" && (
                          <>
                            <option value="india">India</option>
                          </>
                        )}
                      </select>
                      {this.state.shipFromError && (
                        <p style={{ color: "red" }}>*field required</p>
                      )}
                    </div>
                    <div className="booking-input-box">
                      <label
                        style={{
                          color: "#777",
                          marginBottom: 5,
                        }}
                      >
                        ORIGIN OF DESTINATION
                      </label>
                      <input
                        style={{
                          borderRadius: 5,
                          height: 60,
                        }}
                        type="text"
                        name="totalCbm"
                        value={"Dhaka,Bangladesh"}
                        onChange={this.handleChange}
                        className="form-control"
                        // placeholder="Choose Method"

                        readOnly
                      />
                    </div>
                    <div className="booking-input-box">
                      <label
                        style={{
                          color: "#777",
                          marginBottom: 5,
                        }}
                      >
                        TOTAL WEIGHT
                      </label>
                      <input
                        style={{
                          borderRadius: 5,
                          height: 60,
                        }}
                        type="number"
                        name="weight"
                        value={this.state.weight}
                        onChange={(event) => {
                          this.setState({
                            weight: event.target.value,
                          });
                        }}
                        className="form-control"
                        placeholder="Enter Weight"
                      />
                      {this.state.weightError && (
                        <p style={{ color: "red" }}>*field required</p>
                      )}
                    </div>
                    <div className="booking-input-box">
                      <label
                        style={{
                          color: "#777",
                          marginBottom: 5,
                        }}
                      >
                        PRODUCT TYPE
                      </label>
                      <select
                        className="custom-select"
                        name="productType"
                        value={this.state.productType}
                        onChange={this.handleChange}
                        style={{ height: 60 }}
                      >
                        <option value="">Select Product</option>

                        {this.props.d2dRates.map((rate) => (
                          <option value={rate.id} key={rate.id}>
                            {rate.id}
                          </option>
                        ))}
                      </select>
                      {this.state.productTypeError && (
                        <p style={{ color: "red" }}>*field required</p>
                      )}
                    </div>
                    <div className="booking-input-box">
                      <button
                        type="submit"
                        className="btn"
                        style={{
                          marginTop: 25,
                          fontSize: 20,
                          textAlign: "center",
                          cursor: "pointer",
                          color: "white",
                          fontWeight: "bold",
                          backgroundColor: "#18768f",
                          padding: 20,
                          borderRadius: 5,
                        }}
                      >
                        {!this.state.submit_loader && (
                          <i className="fa fa-search" />
                        )}
                        <HashLoader
                          loading={this.state.submit_loader}
                          color="white"
                          size={25}
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {this.state.visual === "RESULT" && (
              <div className="result-container">
                <div
                  className="row cross-icon"
                  style={{
                    justifyContent: "flex-end",
                    color: "#18768f",
                    fontWeight: "bold",
                    fontSize: 25,
                  }}
                >
                  <i
                    className="fa fa-times"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ visual: "INPUT" });
                    }}
                  />
                </div>
                <div
                  className="row"
                  style={{
                    color: "#18768f",
                    fontWeight: "bold",
                    justifyContent: "center",
                    padding: 10,
                    fontSize: 30,
                  }}
                >
                  Book Now
                </div>
                <div
                  className="row"
                  style={{ height: 1, backgroundColor: "#18768f" }}
                />
                <div className="section-containing-container mt-2">
                  <div className="flex-options">
                    <div style={{ backgroundColor: "white", padding: 5 }}>
                      <div
                        style={{
                          backgroundColor: "#d6ebf7",
                          padding: 20,
                          border: "2px dashed #9edcff",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            padding: 10,
                            paddingBottom: 20,
                          }}
                        >
                          আপনার অনুসন্ধান এর ফলাফল:{" "}
                        </div>
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td colSpan="2">Ship By</td>
                              <td>{this.state.shipBy}</td>
                            </tr>
                            <tr>
                              <td colSpan="2">Ship From</td>
                              <td>{this.state.shipFrom}</td>
                            </tr>
                            <tr>
                              <td colSpan="2">Product Type</td>
                              <td>{this.state.productType}</td>
                            </tr>
                            <tr>
                              <td colSpan="2">Per Kg</td>
                              <td>
                                {this.state.result && this.state.result.perKg}{" "}
                                Tk/Kg
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">Total Weight</td>
                              <td>{this.state.weight}Kg</td>
                            </tr>
                            <tr>
                              <td colSpan="2" style={{ fontWeight: "bold" }}>
                                Total Shipping Charge
                              </td>
                              <td style={{ fontWeight: "bold" }}>
                                {this.state.result && this.state.result.result}
                                Tk (approx)
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 0,
                          }}
                        >
                          (IF 1 CBM= 167kg)
                        </div>
                        <div
                          style={{
                            fontSize: 13,
                            marginBottom: 20,
                            marginTop: 20,
                          }}
                        >
                          *প্রোডাক্টের ধরণ এবং কোয়ান্টিটি এর উপর ভিত্তি করে
                          শিপিং চার্জ কম কিংবা বেশি হতে পারে। বুকিং প্লেস করার
                          কিছুক্ষনের মাঝেই আমাদের কাস্টমার প্রতিনিধি আপনাকে
                          বুকিং এর চুড়ান্ত শিপিং চার্জ জানিয়ে দিবে।
                        </div>
                        <div style={{ fontSize: 13 }}>
                          *আপনার প্রেরিত পণ্যের প্রতিটা বক্সের উপরে আপনার শিপিং
                          মার্ক/নাম, পণ্যের বিবরণ (পণ্যের নাম, কত পিস) অবশ্যই
                          অবশ্যই লেখা থাকতে হবে।
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-options">
                    <form
                      style={{ marginBottom: 20 }}
                      onSubmit={this.handleAddToListSubmit}
                    >
                      <div className="form-row mb-2 product-link">
                        <div className="col">
                          <label className="label-title">
                            PRODUCT LINK (optional)
                          </label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="text"
                            name="productUrl"
                            value={this.state.productUrl}
                            onChange={this.handleChangeBooking}
                            className="form-control"
                            placeholder="Enter product Url here"
                          />
                        </div>
                      </div>

                      <div className="form-row mb-2" style={{ marginTop: 20 }}>
                        <div className="col">
                          <label className="label-title">CARTON QUANTITY</label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="text"
                            name="ctnQuantity"
                            value={this.state.ctnQuantity}
                            onChange={this.handleChangeBooking}
                            className="form-control"
                            placeholder="Carton Quantity"
                          />
                        </div>
                        <div className="col">
                          <label className="label-title">
                            PRODUCT QUANTITY
                          </label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="text"
                            name="productQuantity"
                            value={this.state.productQuantity}
                            onChange={this.handleChangeBooking}
                            className="form-control"
                            placeholder="Product Quantity"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-row mb-2" style={{ marginTop: 20 }}>
                        <div className="col">
                          <label className="label-title">
                            PRODUCT TOTAL COST
                          </label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="text"
                            name="productsTotalCost"
                            value={this.state.productsTotalCost}
                            onChange={this.handleChangeBooking}
                            className="form-control"
                            placeholder="Products total cost"
                          />
                        </div>
                        <div className="col">
                          <label className="label-title">PRODUCT NAME</label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="text"
                            name="othersProductName"
                            value={this.state.othersProductName}
                            onChange={this.handleChangeBooking}
                            className="form-control"
                            placeholder="Product Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-row mb-2" style={{ marginTop: 20 }}>
                        <div className="col">
                          <label className="label-title">
                            PRODUCT CONTAINS
                          </label>
                          <select
                            className="custom-select"
                            name="productContains"
                            value={this.state.productContains}
                            onChange={this.handleChangeBooking}
                            required
                            style={{ height: 50 }}
                          >
                            <option value="liquid">liquid</option>
                            <option value="powder">powder</option>
                            <option value="battery">battery</option>
                            <option value="none">none</option>
                          </select>
                        </div>
                        <div className="col">
                          <label className="label-title">BRAND/NON-BRAND</label>
                          <select
                            className="custom-select"
                            name="productBrand"
                            value={this.state.productBrand}
                            onChange={this.handleChangeBooking}
                            required
                            style={{ height: 50 }}
                          >
                            <option value="Brand">Brand</option>
                            <option value="Non Brand">Non Brand</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-row mb-2" style={{ marginTop: 20 }}>
                        <div className="col">
                          <label className="label-title">SHIP TO</label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="date"
                            name="shipTo"
                            value={this.state.shipTo}
                            onChange={(e) => {
                              this.handleChangeBooking2(e);
                            }}
                            className="form-control"
                            required
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                        <div className="col">
                          <label className="label-title">VALID TO</label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="text"
                            name="validTo"
                            value={this.state.validTo}
                            className="form-control"
                            placeholder="Shipping Deadline"
                            required
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-row mb-2">
                        <div className="col">
                          <label className="label-title">
                            Total CBM (in cm3)
                          </label>
                          <input
                            style={{
                              borderRadius: 5,
                              height: 50,
                            }}
                            type="text"
                            name="totalCbm"
                            value={this.state.totalCbm}
                            onChange={this.handleChangeBooking}
                            className="form-control"
                            placeholder="Enter total CBM (height x width x length) in cm"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-row mb-2">
                        <div className="col">
                          <label className="label-title">
                            ADDITIONAL INFORMATION
                          </label>
                          <textarea
                            style={{
                              borderRadius: 5,
                              height: 80,
                            }}
                            type="text"
                            name="productVariant"
                            value={this.state.productVariant}
                            onChange={this.handleChangeBooking}
                            className="form-control"
                            placeholder="Product description (color,size,variant,quanity etc)"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-row mb-2" style={{ marginTop: 20 }}>
                        <div className="col">
                          <label className="label-title">UPLOAD IMAGE</label>

                          <div
                            className="box-input-file"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            {this.state.image_loader ? (
                              <div
                                className="spinner-border"
                                role="status"
                                style={{
                                  color: "purple",
                                  paddingTop: "25px",
                                  backgroundColor: "white",
                                  width: 150,
                                  height: 150,
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  border: "1px solid gainsboro",
                                  borderRadius: 8,
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: 30,
                                    padding: 0,
                                    marginRight: 20,
                                  }}
                                >
                                  <CircleLoader
                                    loading={this.state.image_loader}
                                    color="blue"
                                    size={35}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                <img
                                  className="img-100 lazyloaded blur-up"
                                  src={this.state.imageUrl}
                                  alt="#"
                                  style={{
                                    zIndex: 6,
                                    cursor: "pointer",
                                    maxWidth: 150,
                                    minWidth: 150,
                                    minHeight: 150,
                                    maxHeight: 150,
                                    border: "1px solid gainsboro",
                                    borderRadius: 8,
                                  }}
                                  onClick={() => {
                                    document
                                      .getElementById("upload-image-input")
                                      .click();
                                  }}
                                />
                                <input
                                  id="upload-image-input"
                                  className="upload"
                                  type="file"
                                  style={{
                                    position: "absolute",
                                    zIndex: 5,
                                    maxWidth: "50px",
                                    marginTop: "20px",
                                  }}
                                  onChange={(e) => this._handleImgChange(e, 0)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="row final-booknow"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <button
                          className="btn"
                          type="submit"
                          style={{
                            fontSize: 14,
                            textAlign: "center",
                            cursor: "pointer",
                            color: "white",
                            fontWeight: "bold",
                            backgroundColor: "#18768f",
                            padding: 14,
                            width: "30%",
                            borderRadius: 5,
                            marginTop: 20,
                            minHeight: 50,
                            minWidth: 100,
                          }}
                        >
                          {!this.state.submit_loader && <>Add to List </>}
                          <HashLoader
                            loading={this.state.submit_loader}
                            color="white"
                            size={25}
                          />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {shipForMeList.length > 0 && (
              <>
                <div style={{ marginTop: 20, maxWidth: 600 }}>
                  <div
                    style={{
                      color: "#18768f",
                      textAlign: "left",
                      fontSize: 20,
                      background: "white",
                      padding: "10px 15px",
                      fontWeight: "bold",
                      borderRadius: 5,
                    }}
                  >
                    Added Products
                  </div>
                  <div
                    style={{
                      height: 1,
                      backgroundColor: "#18768f",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  />
                  <div
                    className="accordion theme-accordion accordion-padding"
                    id="accordion"
                  >
                    {shipForMeList.map((booking, index) => (
                      <div
                        class="card"
                        style={{
                          border: "0px",
                          paddingTop: 10,
                          paddingBottom: 15,
                          borderRadius: 5,
                          marginBottom: 10,
                        }}
                      >
                        <div
                          class="card-header"
                          style={{
                            backgroundColor: "white",
                            padding: 0,
                            borderBottom: "0px",
                          }}
                          id="headingOne"
                        >
                          <div
                            class="btn"
                            data-toggle="collapse"
                            data-target={`#collapse${index}`}
                            aria-expanded="true"
                            aria-controls={`collapse${index}`}
                            style={{
                              color: "black",
                              width: "100%",
                              textAlign: "left",
                              display: "block",
                              backgroundColor: "white",
                              paddingBottom: 0,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <span
                                style={{
                                  color: "black",
                                  paddingRight: 10,
                                  paddingTop: 7,
                                }}
                              >
                                {index + 1}
                              </span>
                              <img
                                className="lazyloaded blur-up"
                                src={
                                  booking.imageUrl && booking.imageUrl !== ""
                                    ? booking.imageUrl
                                    : man
                                }
                                alt="#"
                                style={{
                                  zIndex: 6,
                                  cursor: "pointer",
                                  maxWidth: 80,
                                  minWidth: 80,
                                  minHeight: 80,
                                  maxHeight: 80,
                                  border: "1px solid gainsboro",
                                  borderRadius: 10,
                                }}
                              />
                              <div
                                style={{
                                  overflow: "hidden",
                                  paddingLeft: 8,
                                  paddingTop: 5,
                                }}
                              >
                                <div
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: 13,
                                  }}
                                >
                                  {booking.othersProductName.slice(0, 50)}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 7,
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "lighter",
                                      textTransform: "capitalize",
                                      color: "#222",
                                    }}
                                  >
                                    Product type:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.productType}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: 7,
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "lighter",
                                      textTransform: "capitalize",
                                      color: "#222",
                                    }}
                                  >
                                    Total Weight:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.weight}kg
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: 15,
                                      bottom: 15,
                                    }}
                                  >
                                    <i
                                      className="fa fa-chevron-down"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id={`collapse${index}`}
                          class="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordion"
                        >
                          <div
                            class="card-body list-container"
                            style={{
                              paddingBottom: 5,
                              paddingRight: 0,
                              paddingTop: 10,
                            }}
                          >
                            <div>
                              <div style={{ paddingLeft: 10 }}>
                                <div className="row" style={{ marginTop: 5 }}>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Product Price:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.productsTotalCost}Tk
                                    </span>
                                  </div>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Ship By:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.shipBy}
                                    </span>
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 5 }}>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Quantity:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.productQuantity}pcs
                                    </span>
                                  </div>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Ship From:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.shipFrom}
                                    </span>
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 5 }}>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Total CBM:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.totalCbm}cm3
                                    </span>
                                  </div>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Ship to:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.shipTo}
                                    </span>
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 5 }}>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Description:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.productVariant}
                                    </span>
                                  </div>
                                  <div
                                    className="col"
                                    style={{
                                      color: "#222",
                                    }}
                                  >
                                    Valid to:{" "}
                                    <span
                                      style={{ color: "#777", fontSize: 14 }}
                                    >
                                      {booking.validTo}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="d-flex flex-row"
                                  style={{ marginLeft: 0, marginTop: 20 }}
                                >
                                  <div
                                    style={{
                                      background: "#18768f",
                                      color: "white",
                                      padding: "6px 15px",
                                      fontSize: 12,
                                      borderRadius: 7,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    data-target="#personalInfoModal"
                                    onClick={() => {
                                      this.props.getAllD2DRatesRedux(
                                        booking.method,
                                        booking.shipFrom
                                      );
                                      this.setState({
                                        imageUrl:
                                          booking.imageUrl &&
                                          booking.imageUrl !== ""
                                            ? booking.imageUrl
                                            : man,
                                        shipFrom: booking.shipFrom,
                                        method: booking.method,
                                        productType: booking.productType,
                                        result: booking.result,
                                        weight: booking.weight,
                                        date: booking.date,
                                        ctnQuantity: booking.ctnQuantity,
                                        totalCbm: booking.totalCbm,
                                        productContains:
                                          booking.productContains,
                                        productBrand: booking.productBrand,
                                        productsTotalCost:
                                          booking.productsTotalCost,
                                        shipTo: booking.shipTo,
                                        validTo: booking.validTo,
                                        shipmentMethod: booking.shipmentMethod,
                                        productQuantity:
                                          booking.productQuantity,
                                        othersProductName:
                                          booking.othersProductName,
                                        productUrl: booking.productUrl,
                                        productVariant: booking.productVariant,
                                        bookingObj: booking,
                                      });
                                    }}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    style={{
                                      background: "red",
                                      color: "white",
                                      padding: "6px 15px",
                                      fontSize: 12,
                                      borderRadius: 7,
                                      marginLeft: 15,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    data-target="#deleteExpenseModal"
                                    onClick={() => {
                                      this.setState({
                                        bookingObj: booking,
                                      });
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div style={{ maxWidth: 600, marginTop: 10 }}>
                  <div style={{ marginLeft: 10, color: "#333", fontSize: 13 }}>
                    Request Rate & Warehouse Address{" "}
                    <span style={{ color: "#18768f", fontSize: 14 }}>
                      ({shipForMeList.length})
                    </span>
                  </div>
                  <div
                    className="d-flex flex-row final-booknow"
                    style={{ justifyContent: "flex-end", marginRight: 0 }}
                  >
                    <button
                      className="btn"
                      onClick={async () => {
                        this.props.shipForMeList.map(async (booking) => {
                          await this.handleBookNowSubmit(booking);
                        });
                        toast.success(
                          "Your booking is successful! Our agent will contact you soon"
                        );
                      }}
                      style={{
                        fontSize: 12,
                        textAlign: "center",
                        cursor: "pointer",
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "#18768f",
                        padding: 10,

                        borderRadius: 5,
                        marginTop: 10,
                        minHeight: 40,
                        minWidth: 100,
                      }}
                    >
                      {!this.state.submit_loader2 && <> Request </>}
                      <HashLoader
                        loading={this.state.submit_loader2}
                        color="white"
                        size={25}
                      />
                    </button>
                  </div>
                </div>
              </>
            )}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="ship-for-me-image-container">
                <img
                  src={ShipForMeImage}
                  style={{
                    width: "100%",
                    borderRadius: 10,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#18768f",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Product request
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Ship By
                    </label>

                    <select
                      className="custom-select"
                      name="method"
                      value={this.state.method}
                      onChange={this.handleChange}
                    >
                      <option value="">Choose Method</option>
                      <option value="sea">Sea</option>
                      <option value="air">Air</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Ship From
                    </label>

                    <select
                      className="custom-select"
                      name="shipFrom"
                      value={this.state.shipFrom}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.setState({ productType: "" });
                      }}
                    >
                      <option value="">Select Country</option>
                      <option value="china">China</option>
                      <option value="uk">UK</option>
                      <option value="usa">Usa</option>
                      <option value="hongkong">Hongkong</option>
                      <option value="thailand">Thailand</option>
                      {this.state.method === "air" && (
                        <>
                          <option value="india">India</option>
                        </>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="othersProductName"
                      value={this.state.othersProductName}
                      onChange={this.handleChange2}
                      id="exampleFormControlInput1"
                      placeholder="Enter Product name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Product Type
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="productType"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange2}
                      value={this.state.productType}
                    >
                      <option value="">Select Product</option>

                      {this.props.d2dRates.map((rate) => (
                        <option value={rate.id} key={rate.id}>
                          {rate.id}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Total weight
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="weight"
                      value={this.state.weight}
                      onChange={this.handleChange2}
                      id="exampleFormControlInput1"
                      placeholder="Enter Product name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Product total Price
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="productsTotalCost"
                      value={this.state.productsTotalCost}
                      onChange={this.handleChange2}
                      id="exampleFormControlInput1"
                      placeholder="Enter discount amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Total Quantity
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="productQuantity"
                      value={this.state.productQuantity}
                      onChange={this.handleChange2}
                      id="exampleFormControlInput1"
                      placeholder="Enter discount amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Total CBM
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="totalCbm"
                      value={this.state.totalCbm}
                      onChange={this.handleChange2}
                      id="exampleFormControlInput1"
                      placeholder="Enter discount amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="productVariant"
                      value={this.state.productVariant}
                      onChange={this.handleChange2}
                      id="exampleFormControlInput1"
                      placeholder="Enter discount amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                        height: 80,
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Ship To
                    </label>
                    <input
                      className="form-control"
                      name="shipTo"
                      value={this.state.shipTo}
                      onChange={this.handleChange2}
                      id="exampleFormControlInput1"
                      placeholder="Enter expiration date"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      type="date"
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Valid To
                    </label>
                    <input
                      className="form-control"
                      name="validTo"
                      value={this.state.validTo}
                      id="exampleFormControlInput1"
                      placeholder="Enter expiration date"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      type="text"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss={
                    this.state.weight == "" ||
                    this.state.productType == "" ||
                    this.state.method == ""
                      ? ""
                      : "modal"
                  }
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    if (this.state.weight == "") {
                      alert("You must enter total weight of shipment.");
                      return;
                    }
                    if (this.state.productType == "") {
                      alert("You must select one product type.");
                      return;
                    }
                    if (this.state.method == "") {
                      alert("You must select one shipment method air/sea.");
                      return;
                    }
                    this.props.updateShipForMeListRedux({
                      ...this.state.bookingObj,
                      ...this.state,
                      result: this.handleDoorToDoorSubmit(
                        this.state.weight,
                        this.state.productType,
                        this.props.d2dRates,
                        this.state.method
                      ),
                      shipBy: this.state.method,
                      d2dSeaAir: this.state.method,
                    });
                  }}
                >
                  Update Request
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#18768f",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Product
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this product?</div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({
                      bookingObj: null,
                    });
                  }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteShipForMeListRedux(this.state.bookingObj);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    d2dRates: state.bookingRequests.d2dRates,
    currentUser: state.user.currentUser,
    shipForMeList: state.shipForMeList.shipForMeList,
  };
};
export default connect(
  mapStateToProps,
  {
    getAllD2DRatesRedux,
    setBookingRequestRedux,
    setShipForMeRedux,
    uploadShipForMeListRedux,
    updateShipForMeListRedux,
    deleteShipForMeListRedux,
    getAllShipForMeListRedux,
  }
)(ShipForMe);
