import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import "./product-list-item.css";
import { connect } from "react-redux";
import icon from "./G-icon.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: "InStock",
      quantity: 1,
      image: "",
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked,
      currency,
    } = this.props;
    const { open } = this.state;

    let RatingStars = [];
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }

    return (
      <div className="product-box">
        {product.availability ? (
          <>
            <div className="img-wrapper">
              <div
                className="front product-image-collection"
                style={{
                  backgroundImage: `url(${icon})`,
                  backgroundColor: "white",
                  backgroundSize: "130px 130px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                {product.availability == "taobao" ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}/${this.props.shop}/${
                      product.id
                    }`}
                    target="_blank"
                  >
                    <img
                      src={product.pictures[0]}
                      className="img-fluid product-image-collection"
                      alt=""
                    />
                  </Link>
                ) : (
                  <Link
                    to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                    target="_blank"
                  >
                    <img
                      src={product.pictures[0]}
                      className="img-fluid product-image-collection"
                      alt=""
                    />
                  </Link>
                )}
              </div>
              <div className="cart-info cart-wrap">
                {product.availability == "taobao" ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}/${this.props.shop}/${
                      product.id
                    }`}
                    title="Add to cart"
                    target="_blank"
                  >
                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                  </Link>
                ) : (
                  <Link
                    to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                    title="Add to cart"
                    target="_blank"
                  >
                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                  </Link>
                )}

                <a
                  onClick={onAddToWishlistClicked}
                  title="Add to Wishlist"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-heart" aria-hidden="true" />
                </a>
              </div>
            </div>
            <div className="product-detail">
              <div>
                <Link
                  to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                  target="_blank"
                >
                  <h6
                    style={{
                      paddingBottom: "0px",
                      marginTop: "5px",
                      color: "black",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      marginRight: 5,
                    }}
                    className="product-title-name"
                  >
                    {product.name.slice(0, 22)}
                  </h6>
                </Link>

                <h5 style={{ color: "#18768f", fontWeight: "bold" }}>
                  ৳{parseInt(product.salePrice * parseFloat(taka))}
                </h5>
                <div style={{ fontSize: "80%" }} className="product-name">
                  Total Sales:{product.totalSold}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: 5,
                    marginTop: 10,
                    borderTop: "1px solid gainsboro",
                    paddingTop: 5,
                    paddingRight: 5,
                  }}
                >
                  <div
                    style={{ fontSize: 12, marginTop: 4 }}
                    className="shop-name"
                  >
                    {product.shop_name.slice(0, 5)}
                  </div>
                  <div
                    style={{
                      fontSize: 11,
                      padding: "5px 7px",
                      border: "1px solid gainsboro",
                      borderRadius: 2,
                      background: "#eeeeee",
                      cursor: "pointer",
                    }}
                    className="view-seller"
                  >
                    <a
                      target="_blank"
                      href={`/collection/${product.shop_id}?shop_name=${
                        product.shop_name
                      }`}
                      style={{ color: "black" }}
                    >
                      View Seller
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="img-wrapper">
              <div
                className="front product-image-collection"
                style={{
                  background: "#ebebeb",
                }}
              >
                <Skeleton height={200} />
              </div>
            </div>
            <div className="product-detail">
              <div>
                <div
                  style={{
                    marginTop: 10,
                    width: 70,
                    height: 12,
                    background: "linear-gradient(90deg,#b7b7b7, #f3f3f3 )",
                    borderRadius: 3,
                  }}
                >
                  <Skeleton />
                </div>

                <div
                  style={{
                    marginTop: 10,
                    width: "90%",
                    height: 10,
                    borderRadius: 2,
                    background: "linear-gradient(90deg,#b7b7b7, #f3f3f3 )",
                  }}
                >
                  <Skeleton />
                </div>

                <div
                  style={{
                    marginTop: 10,
                    width: "60%",
                    height: 11,
                    borderRadius: 2,
                    background: "linear-gradient(90deg,#b7b7b7, #f3f3f3 )",
                  }}
                >
                  <Skeleton />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state.user.currency,
  };
};
export default connect(
  mapStateToProps,
  null
)(ProductListItem);
