const initialState = {
  products: [],
  symbol: "Tk",
  product_details: [],
  totalResults: 0,
};

const searchedProductsArrayReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCHED_PRODUCTS_ARRAY":
      return {
        ...state,
        products: [...state.products, ...action.payload],
        totalResults: action.totalResults,
      };
    case "EMPTY_SEARCHED_PRODUCTS_ARRAY":
      return { ...state, products: [], totalResults: 0 };
    default:
      return { ...state };
  }
};
export default searchedProductsArrayReducer;
