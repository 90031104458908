const INITIAL_STATE = {
  bookingRequests: [],
  bookingsArrayOfSingleUser: [],
  shipForMeOfSingleUser: [],
  shipForMeObj: null,
  bookingObj: null,
  d2dRates: [],
  productRequests: [],
  productRequestObj: null,
};

const setBookingRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_BOOKING_REQUEST":
      return {
        ...state,
        bookingRequests: [...state.bookingRequests, action.payload],
      };
    case "SET_PRODUCT_REQUEST":
      return {
        ...state,
        productRequests: [...state.productRequests, action.payload],
      };
    case "UPLOAD_REFUND_APPLY_PRODUCT_REQUEST":
      let orders = state.bookingsArrayOfSingleUser.map((order) => {
        if (order.bookingId == action.payload.bookingId) {
          return action.payload;
        } else {
          return order;
        }
      });
      return { ...state, bookingsArrayOfSingleUser: orders };
    case "GET_ALL_BOOKINGS_OF_SINGLE_USER":
      return {
        ...state,
        bookingsArrayOfSingleUser: action.payload,
      };
    case "GET_ALL_SHIP_FOR_ME_OF_SINGLE_USER":
      return {
        ...state,
        shipForMeOfSingleUser: action.payload,
      };
    case "GET_SINGLE_SHIP_FOR_ME":
      return {
        ...state,
        shipForMeObj: action.payload,
      };
    case "GET_SINGLE_PRODUCT_REQUEST":
      return {
        ...state,
        productRequestObj: action.payload,
      };
    case "SET_SHIP_FOR_ME":
      return {
        ...state,
        shipForMeOfSingleUser: [action.payload, ...state.shipForMeOfSingleUser],
      };
    case "UPDATE_SHIP_FOR_ME":
      return {
        ...state,
        shipForMeOfSingleUser: state.shipForMeOfSingleUser.map((booking) => {
          if (booking.bookingId === action.payload.bookingId) {
            return action.payload;
          } else {
            return booking;
          }
        }),
      };
    case "UPDATE_SHIPMENT_REQUEST":
      const filteredBooking3 = state.shipForMeOfSingleUser.map((booking) => {
        if (booking.bookingId == action.payload.bookingId) {
          return { ...action.payload };
        } else {
          return booking;
        }
      });

      return { ...state, shipForMeOfSingleUser: [...filteredBooking3] };
    case "UPDATE_SHIPMENT_PAYMENT_REQUEST":
      const filteredBooking2 = state.shipForMeOfSingleUser.map((booking) => {
        if (booking.bookingId == action.payload.bookingId) {
          return { ...action.payload, paymentRequested: true };
        } else {
          return booking;
        }
      });

      return { ...state, shipForMeOfSingleUser: [...filteredBooking2] };

    case "UPDATE_BOOKINGS_OF_SINGLE_USER":
      const newBookingsArray = state.bookingsArrayOfSingleUser.map(
        (booking) => {
          const newBooking = action.payload.productRequestArray.find(
            (booking1) => booking1.bookingId == booking.bookingId
          );

          if (newBooking) {
            return { ...newBooking, paymentStatus: "pending" };
          } else {
            return booking;
          }
        }
      );

      return { ...state, bookingsArrayOfSingleUser: newBookingsArray };
    case "GET_SINGLE_BOOKING":
      return {
        ...state,
        bookingObj: action.payload,
      };

    case "GET_ALL_D2D_RATES":
      return { ...state, d2dRates: [...action.payload] };
    default:
      return { ...state };
  }
};
export default setBookingRequestReducer;
