import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { getOrderTrackingResultRedux } from "../../../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./trackingDetails.css";
import { HashLoader } from "react-spinners";
class TrackingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      loader: false,
    };
  }
  componentDidMount = () => {
    console.log(this.props);
    const { orderObj } = this.props;
    if (orderObj && orderObj.trackingNo) {
      this.setState({ loader: true });
      this.props.getOrderTrackingResultRedux(orderObj.trackingNo);
      this.setState({ loader: false });
    }
  };
  componentWillReceiveProps = (nextProps) => {
    console.log(this.props);
    const { orderObj } = nextProps;
    if (this.props.orderObj) {
      if (orderObj.orderId != this.props.orderObj.orderId) {
        if (orderObj && orderObj.trackingNo) {
          this.setState({ loader: true });
          this.props.getOrderTrackingResultRedux(orderObj.trackingNo);
          this.setState({ loader: false });
        }
      }
    }
  };

  render() {
    console.log(this.props.match);
    const { currentUser, orderTrackingResult, orderObj } = this.props;
    let bookingsToShow = [];
    let parcelObj = {};
    console.log(orderObj);
    if (orderTrackingResult) {
      parcelObj = orderTrackingResult.parcelsArray[0];
    }
    if (this.state.loader) {
      return (
        <div
          style={{
            minWidth: "100%",
            minHeight: "100%",
            backgroundColor: "white",
            textAlign: "center",
            zIndex: 1000,
          }}
        >
          <HashLoader loading={this.state.loader} color="white" size={25} />
        </div>
      );
    }
    if (orderObj) {
      if (
        orderObj.orderStatus == "pending" ||
        orderObj.orderStatus == "reject"
      ) {
        return (
          <div
            className="d-lg-flex justify-content-center no-gutters mb-spacer-md"
            style={{
              width: "100%",
              height: 200,
              backgroundColor: "white",
              textAlign: "center",
              padding: "50px 0px",
              paddingTop: 100,
            }}
          >
            <div>Order Id: {orderObj.orderId}</div>
            <div className="row top-row" style={{ justifyContent: "center" }}>
              <div
                style={{
                  fontSize: 9,
                  color: "darkorange",
                  paddingBottom: 10,
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    color: "darkorange",
                    fontSize: 9,
                  }}
                >
                  {orderObj.orderStatus == "pending"
                    ? "Your order is still being processed."
                    : "Your order has been rejected."}
                </div>
              </div>
            </div>
            <div style={{ fontSize: 9, marginTop: 10 }}>
              {orderObj.orderStatus == "pending"
                ? "You will be informed soon"
                : "To reorder make the payment again or contact us"}
            </div>
          </div>
        );
      }
      if (!orderObj.orderNumber) {
        return (
          <div
            className="d-lg-flex justify-content-center no-gutters mb-spacer-md"
            style={{
              width: "100%",
              height: 200,
              backgroundColor: "white",
              textAlign: "center",
              padding: "50px 0px",
              paddingTop: 100,
            }}
          >
            <div className="row top-row" style={{ justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>Order Id: {orderObj.orderId}</div>
                <div style={{ fontWeight: "lighter", fontSize: 8 }}>
                  {orderObj.orderNumber &&
                    `Order Number: {orderObj.orderNUmber}`}
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: 9,
                color: "darkorange",
                paddingBottom: 10,
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "darkorange",
                  fontSize: 9,
                  marginTop: 5,
                }}
              >
                Your order has not been ordered yet.
              </div>
            </div>
            <div style={{ fontSize: 9, marginTop: 10 }}>
              It will be ordered within 2 working days
            </div>
          </div>
        );
      } else if (!orderObj.trackingNo) {
        return (
          <div
            className="d-lg-flex justify-content-center no-gutters mb-spacer-md"
            style={{
              width: "100%",
              height: 200,
              backgroundColor: "white",
              textAlign: "center",
              padding: "50px 0px",
              paddingTop: 100,
            }}
          >
            <div className="row" style={{ fontSize: 8, marginLeft: 10 }}>
              {" "}
              Order Id: {orderObj.orderId}
            </div>
            <div className="row" style={{ fontSize: 8, marginLeft: 10 }}>
              Order Number: {orderObj.orderNumber}
            </div>
            <div className="row top-row" style={{ justifyContent: "center" }}>
              <div
                style={{
                  fontSize: 9,
                  color: "darkorange",
                  paddingBottom: 10,
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    color: "darkorange",
                    fontSize: 9,
                  }}
                >
                  No Logistics
                </div>
              </div>
            </div>
            <div style={{ fontSize: 9, marginTop: 10 }}>
              Your order has not been shipped by the supplier yet.
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <Breadcrumb title={"Dashboard"} />

            {/*Dashboard section*/}

            {/* mobile view  */}
            {orderTrackingResult ? (
              <section
                className="section-b-space mobile-view"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <div className="container" style={{ padding: 10 }}>
                  <div className="row" style={{ padding: 10 }}>
                    <div className="col">
                      {bookingsToShow.map((booking) => {
                        return (
                          <div style={{ borderRadius: 10, marginBottom: 20 }}>
                            <div className="each-order-container-1">
                              <div className="order-header-1">
                                <div style={{ fontSize: 10 }}>
                                  booking number: {booking.bookingId}
                                  <span
                                    style={{
                                      fontSize: 8,
                                      color: "darkgray",
                                      marginLeft: 10,
                                    }}
                                  >
                                    {booking.date}
                                  </span>
                                </div>

                                <div
                                  style={{
                                    color: "darkorange",
                                    fontSize: 9,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {booking.paymentStatus &&
                                  booking.paymentStatus !== "rejected" ? (
                                    <div
                                      style={{
                                        color:
                                          booking.paymentStatus == "paid"
                                            ? "darkgreen"
                                            : "darkorange",
                                      }}
                                    >
                                      {booking.paymentStatus}
                                    </div>
                                  ) : booking.status == "Rates Provided" ? (
                                    <div
                                      onClick={() => {
                                        this.startToggleModal([booking]);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        color: "orange",
                                      }}
                                    >
                                      Pay to Order
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div
                              className="products-box"
                              style={{
                                borderBottomLeftRadius: 10,
                                borderBottomRightRadius: 10,
                              }}
                            >
                              <div>
                                <div className="row">
                                  <div className="col-3">
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        overflow: "hidden",
                                        backgroundImage: `url(${
                                          booking.imageUrl
                                        })`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="col"
                                    style={{
                                      padding: "0px 10px",
                                      paddingLeft: 0,
                                    }}
                                  >
                                    <div
                                      style={{ color: "#4a4a4a", fontSize: 8 }}
                                    >
                                      {booking.productVariant}
                                      {booking.orderId && (
                                        <div
                                          style={{
                                            fontSize: 8,
                                            color: "gray",
                                            marginTop: 10,
                                          }}
                                        >
                                          order Id:{" "}
                                          <span style={{ color: "black" }}>
                                            {booking.orderId}
                                          </span>
                                        </div>
                                      )}

                                      <div
                                        style={{
                                          fontSize: 8,
                                          color: "gray",
                                        }}
                                      >
                                        booking status:{" "}
                                        <span style={{ color: "black" }}>
                                          {booking.status}
                                        </span>
                                      </div>

                                      <div
                                        style={{
                                          fontSize: 8,
                                          color: "gray",
                                          marginTop: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            color: "white",
                                            padding: 5,
                                            border: "1px solid #f54c3c",
                                            borderRadius: 5,
                                            backgroundColor: "#f54c3c",
                                            cursor: "pointer",
                                            width: 40,
                                            height: 23,
                                            textAlign: "center",
                                          }}
                                          onClick={() => {
                                            if (booking.trackingNo) {
                                              this.props.history.push(
                                                `/pages/dashboard/buy-and-ship-for-me/my-request/${
                                                  booking.trackingNo
                                                }`
                                              );
                                            } else {
                                              this.props.history.push(
                                                `/pages/dashboard/buy-and-ship-for-me/my-request/no-tracking`
                                              );
                                            }
                                          }}
                                        >
                                          <i
                                            className="icofont-eye"
                                            style={{ fontSize: 8 }}
                                          >
                                            &nbsp;track
                                          </i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                    className="col-3"
                                  >
                                    <div
                                      style={{
                                        color: "rgb(98 98 98)",
                                        fontSize: 11,
                                      }}
                                    >
                                      {booking.unitPrice ? (
                                        `${booking.unitPrice}Tk`
                                      ) : (
                                        <div
                                          style={{
                                            color: "gray",
                                            fontSize: 9,
                                            fontWeight: "lighter",
                                          }}
                                        >
                                          unit Price: not given yet
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 11,
                                        marginLeft: 10,
                                      }}
                                    >
                                      X{booking.productQuantity}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginBottom: 5,
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 7,
                                    color: "gray",
                                    maxWidth: 50,
                                    paddingLeft: 10,
                                  }}
                                >
                                  excluding shipping charge
                                </div>
                                <div
                                  style={{
                                    fontSize: 7,
                                    color: "gray",
                                    marginTop: 5,
                                  }}
                                >
                                  {booking.localShipping
                                    ? `Including china courier cost ${
                                        booking.localShipping
                                      }Tk`
                                    : null}
                                </div>

                                <div style={{ fontSize: 8, color: "black" }}>
                                  {booking.totalRate ? (
                                    `Total amount ${booking.totalRate}Tk`
                                  ) : (
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 9,
                                        fontWeight: "lighter",
                                      }}
                                    >
                                      Total: not given yet
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  padding: "0px 10px",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 11,
                                    color: "gray",
                                    margin: "auto 0px",
                                  }}
                                >
                                  more
                                </div>

                                <div
                                  style={{
                                    padding: "10px 6px",
                                    border: "1px solid gainsboro",
                                    borderRadius: 20,
                                    fontSize: 8,
                                    color: "gray",
                                    marginLeft: 45,
                                  }}
                                >
                                  inside 24h ordered
                                </div>
                                <div
                                  style={{
                                    padding: "10px 6px",
                                    border: "1px solid darkorange",
                                    borderRadius: 20,
                                    fontSize: 8,
                                    color: "darkorange",
                                  }}
                                >
                                  fast refund
                                </div>
                                <div
                                  style={{
                                    padding: "10px 6px",
                                    border: "1px solid gainsboro",
                                    borderRadius: 20,
                                    fontSize: 8,
                                    color: "gray",
                                  }}
                                >
                                  fast delivery
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
          </div>
        );
      }
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  const orderId = ownProps.match.params.orderId;
  return {
    currentUser: state.user.currentUser,
    orderTrackingResult: state.orders.orderTrackingResult,
    orderObj: state.orders.ordersApi.find((order) => order.orderId == orderId),
  };
};

export default connect(
  mapStateToProps,
  { getOrderTrackingResultRedux }
)(TrackingDetails);
